import React, { useState } from 'react';
import { USDateFormat } from '../../../../utils/common';
import { BsEyeFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { BiSolidTrash } from 'react-icons/bi';
import { Skeleton } from '@mui/material';
import PropertySample from '../../../../assets/images/PropertySample.jpg';

interface IProps {
  item: any;
  gotoPropertyDetail: (id: string) => void;
  handleEdit: (item: any) => void;
  handleDelete: (item: any) => void;
}

function ListingsRowItem(props: IProps) {
  const { item, gotoPropertyDetail, handleEdit, handleDelete } = props;
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="property-listing-preview">
            {imageLoading && (
              <Skeleton animation='wave' variant="rounded" width={'100%'} height={550} />
            )}
            <img
              src={item?.rooms?.[0]?.imagesLink?.length > 0 ? item?.rooms?.[0]?.imagesLink[0] : PropertySample }
              alt="property-listing-preview"
              className="img-fluid"
              onLoad={() => setImageLoading(false)}
              style={{ display: imageLoading ? 'none' : 'block' }}
            />
          </div>
          <div className="property-details ms-2">
            <h5>{item?.title}</h5>
            <p>{item?.address?.street}</p>
          </div>
        </div>
      </td>
      <td>
        {USDateFormat(item?.rooms?.[0]?.availableFrom)}
        {/* on {USDateFormat(item?.available_date[0])} */}
      </td>
      <td className="text-capitalize">{item?.p_type}</td>
      <td>${item?.rooms?.[0]?.price}</td>
      <td>
        {item?.amenities.length < 3
          ? `${item?.amenities?.map((i: any) => i.name)}`
          : `${item?.amenities?.length}+ more`}
      </td>
      <td>
        <span
          className={`status ${
            item?.is_active ? 'for-active' : 'for-inactive'
          }`}
        >
          {item?.is_active ? `Active` : `InActive`}
        </span>
      </td>
      <td>
        <div className="d-flex align-items-center gap-3">
          <button
            type="button"
            onClick={() => {
              gotoPropertyDetail(item?._id);
            }}
          >
            <BsEyeFill />
          </button>
          <button
            type="button"
            onClick={() => {
              handleEdit(item?._id);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            onClick={() => {
              handleDelete(item);
            }}
          >
            <BiSolidTrash />
          </button>
        </div>
      </td>
    </tr>
  );
}

export default ListingsRowItem;
