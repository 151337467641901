import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CustomCalendar = (props: any) => (
  <div className="box-view">
    <Calendar
      localizer={localizer}
      events={props.events ?? []}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 650 }}
      onDoubleClickEvent={(e) => {
        if (props.onDoubleClickEvent && props.setEvent) {
          props.onDoubleClickEvent();
          props.setEvent(e);
        }
      }}
      views={
        props.views
          ? props.views
          : {
              month: true,
              day: true,
              week: true,
              agenda: true,
            }
      }
    />
  </div>
);

export default CustomCalendar;
