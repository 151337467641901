import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';

interface IProps {
  item: any;
}

const ListRowItem = (props: IProps) => {
  const { item } = props;
  const navigate = useNavigate();
  return (
    <tr>
      <td className="text-capitalize">{item?.requester}</td>
      <td className="text-capitalize">{item?.name}</td>
      <td>{item?.email}</td>
      <td>{item?.phone}</td>
      <td className="text-capitalize">{item?.state}</td>
      <td className="text-capitalize">{item?.city}</td>
      <td>
        <div className="d-flex align-items-center gap-3">
          <button
            type="button"
            onClick={() => navigate(`/dashboard/request/${item?._id}`)}
          >
            <BsEyeFill />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ListRowItem;
