import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { allUsersRoute, apiUrl } from '../../utils/APIRoutes';
import Contacts from './Contacts';
import Welcome from './Welcome';
import ChatContainer from './ChatContainer';
import { io, Socket } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import './Styles/Messaging.scss';
import Loader from '../CommonComponents/Loader/Loader';
import DashboardHeader from '../DashboardComponents/DashboardHeader/DashboardHeader';
import { useSocket } from '../../context/SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInUserState } from '../../redux/slices/LoginSlice';
import { activeUsers, chatState, resetChatData, setActiveUsers } from '../../redux/slices/ChatSlice';
import { AppDispatch } from '../../redux/store';
// import userone from '../../assets/message/user-one.jpg';

const Messageing = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const dispatch = useDispatch<AppDispatch>();
  const loggedInUser = useSelector(loggedInUserState);
  const onlineUsers = useSelector(activeUsers);
  const chat = useSelector(chatState);
  const [chatId, setChatId] = useState('');
  const [contacts, setContacts] = useState<any>([]);
  const [chatUser, setChatUser] = useState({userName:'', propertyName: '', pic: '', userId: '', active: false});
  const [isContactLoading, setIsContactLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<
    | {
        _id: string;
        firstName: string;
        lastName: string;
        userEmail: string;
        role: string;
        phoneNo: Number;
      }
    | undefined
  >(undefined);
  const [currentChat, setCurrentChat] = useState<any>(undefined);

  useEffect(() => {
    fetchCurrentUser();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (chat.isFromDashboardChat) {
      setChatId(chat.chatId);
      setCurrentChat(chat.data);
      const name = `${chat.data?.lastMessage?.users?.from?.firstName} ${chat.data?.lastMessage?.users?.from?.lastName}`;
      setChatUser({userName:name, propertyName: chat.data?.chatId?.propertyName, pic: chat.data?.lastMessage?.profilePic, userId: chat.data?.lastMessage?.users?.from?._id, active: chat.data?.active})
      dispatch(resetChatData());
    }
  }, [chat]);

  useEffect(() => {
    if (currentUser) {
      socket.emit('add-user', currentUser._id);
      fetchAllUsers();
    }
  }, [currentUser]);

  useEffect(() => {
    if (onlineUsers.length) {
      const idx = onlineUsers.findIndex((e:string ) => e === chatUser.userId);
      if(idx !== -1){
        setChatUser((prev) => ({...prev, active: true}));
      }
      else {
        setChatUser((prev) => ({...prev, active: false}));
      }
    }
  }, [onlineUsers]);

  useEffect(() => {
    socket.on('mark-readed', (res: any) => {
      if (res.ack) {
        const idx = contacts.findIndex(
          (e: any) => e?.message?.chatId?._id === res.chatId
        );
        if (idx !== -1) {
          const ctc = [...contacts];
          ctc[idx].message.notification = 0;
          setContacts(ctc);
        }
      }
    });
    socket.on('contact-notifications', (res: any) => {
      const idx = res?.contacts?.findIndex(
        (e: any) => e?.message?.chatId?._id === res?.chatId
      );
      if (idx !== -1) {
        const ctc = [...res?.contacts];
        ctc[idx].message.notification = 0;
        setContacts(ctc);
      }
      else {
        setContacts(res?.contacts);
      }
    });
    socket.on('set-active', (res: any) => {
      dispatch(setActiveUsers(res.activeUsers));
    });
    return () => {
      socket.off('mark-readed');
      socket.off('contact-notifications');
      socket.off('set-active');
    };
  }, [socket, contacts]);

  // handle chat change
  const handleChatChange = (chat: any, oppUser: string, pic: string, userId: string) => {
    setCurrentChat(chat);
    setChatId(chat?.chatId?._id);
    const idx = onlineUsers.findIndex((e:string ) => e === userId);
    let active = false;
    if(idx !== -1){
      active = true;
    }
    setChatUser({userName: oppUser, propertyName: chat?.chatId?.propertyName, pic, userId, active})
  };

  const fetchCurrentUser = async () => {
    const userFromLocalStorage = localStorage.getItem('user');
    if (userFromLocalStorage !== null) {
      setCurrentUser(JSON.parse(userFromLocalStorage));
    } else {
      return navigate('/login');
    }
  };

  const fetchAllUsers = async () => {
    setIsContactLoading(true);
    const data = await axios.get(
      `${allUsersRoute}/${currentUser?._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    setIsContactLoading(false);
    if (data?.data) {
      setContacts(data?.data);
    }
  };

  const updateContactOnAddNewChat = (chat_id: string, data: any, isCurrent: boolean) => {
    const idx = contacts.findIndex((e: any) => e?.message?.chatId?._id === chat_id);
    if (idx !== -1) {
      const ctc = [...contacts];
      const notify = ctc[idx].message.notification;
      if(isCurrent){
        ctc[idx].message.notification = 0;
      }
      else {
        ctc[idx].message.notification = notify + 1;
      }
      ctc[idx].message.lastMessage.message = data.message;
      ctc[idx].message.lastMessage.date = data.sendTime;
      setContacts(ctc);
    }
  }

  return (
    <div className="main-content">
      <DashboardHeader />
      <div className="message">
          <div className="row">
            <div className="col-4">
              <div className="contacts-list">
                {isContactLoading ? (
                  <Loader isSmallLoader={true} />
                ) : (
                  <Contacts
                    contacts={contacts}
                    currentUser={currentUser}
                    changeChat={handleChatChange}
                    chatId={chatId}
                  />
                )}
              </div>
            </div>

            {currentChat === undefined ? (
              <Welcome currentUser={currentUser} />
            ) : (
              <ChatContainer
                currentChat={currentChat}
                currentUser={currentUser}
                chatId={chatId}
                updateContact={updateContactOnAddNewChat}
                chatUser={chatUser}
                isContactLoading={isContactLoading}
              />
            )}
          </div>
      </div>
    </div>
  );
};

export default Messageing;
