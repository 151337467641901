import * as Yup from 'yup';
import { ListingSection } from '../../constants/ListingSection';

const roomSchema = Yup.object().shape({
  name: Yup.string().when('$details.isEntireUnit', {
    is: false,
    then: () => Yup.string().required('Field is required'),
    otherwise: () => Yup.string(),
  }),
  price: Yup.number()
    .required('Price is required')
    .min(1, 'Price should be greater than 0'),
  depositMoney: Yup.number()
    .required('Deposit money is required')
    .min(1, 'Deposit money should be greater than 0'),
  cleaningCharges: Yup.number()
    .required('Cleaning charges is required')
    .min(1, 'Cleaning charges should be greater than 0'),
  petFees: Yup.number()
    .required('Pet fees is required')
    .min(1, 'Pet fees should be greater than 0'),
  availableFrom: Yup.string().required('Available From is required'),
});

const nearbySchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  name: Yup.string().required('Name is required'),
  distance: Yup.string().required('Miles is required'),
});

const detailsSchema = Yup.object().shape({
  landlord: Yup.string().when('$isAdmin', {
    is: true,
    then: () => Yup.string().required('Landlord is required'),
    otherwise: () => Yup.string(),
  }),
  propertyTitle: Yup.string()
    .min(3, 'Property title must be 3 charecters long')
    .required('Property title is required.')
    .max(50, 'Property title too long! Keep it under 50 characters.'),
  propertyDescription: Yup.string()
    .required('Please give property description')
    .max(500, 'Property description too long! Keep it under 500 characters.'),
  propertyType: Yup.string()
    .min(1, 'Property Type must be Given')
    .required('Select property type'),
  propertyStatus: Yup.string().required(),
  propertyRooms: Yup.number().min(1, 'Rooms can not be less than 1').required(),
  propertyRoomType: Yup.array().of(roomSchema),
});

const locationSchema = Yup.object().shape({
  street: Yup.string()
    .typeError('Address must be a string')
    .required('Please give an Address.'),
  state: Yup.string()
    .typeError('Invalid state name')
    .required('Please provide State name'),
  city: Yup.string()
    .typeError('invalid city name')
    .required('Please provide a City name'),
  zip: Yup.string()
    .typeError('Invalid zip code')
    .required('Please provide a Zip code'),
});

const mainNearBySchema = Yup.object().shape({
  propertyCafeDetails: Yup.array().when('propertyCafe', {
    is: true,
    then: () =>
      Yup.array()
        .of(nearbySchema)
        .required('Property Cafe details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyEducationDetails: Yup.array().when('propertyEducation', {
    is: true,
    then: () =>
      Yup.array().of(nearbySchema).required('Education details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyGroceryStoreDetails: Yup.array().when('propertyGroceryStore', {
    is: true,
    then: () =>
      Yup.array()
        .of(nearbySchema)
        .required('Grocery Store details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyHealthAndMedicalDetails: Yup.array().when(
    'propertyHealthAndMedical',
    {
      is: true,
      then: () =>
        Yup.array()
          .of(nearbySchema)
          .required('Health and Medical details are required'),
      otherwise: () => Yup.array().nullable(),
    }
  ),
  propertyMilitaryBaseDetails: Yup.array().when('propertyMilitaryBase', {
    is: true,
    then: () =>
      Yup.array()
        .of(nearbySchema)
        .required('Military Base details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyMovieTheatersDetails: Yup.array().when('propertyMovieTheaters', {
    is: true,
    then: () =>
      Yup.array()
        .of(nearbySchema)
        .required('Movie Theaters details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyParksDetails: Yup.array().when('propertyParks', {
    is: true,
    then: () =>
      Yup.array().of(nearbySchema).required('Parks details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyRestaurantsDetails: Yup.array().when('propertyRestaurants', {
    is: true,
    then: () =>
      Yup.array().of(nearbySchema).required('Restaurants details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyShoppingCentersDetails: Yup.array().when('propertyShoppingCenters', {
    is: true,
    then: () =>
      Yup.array()
        .of(nearbySchema)
        .required('Shopping Centers details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
  propertyTransportationDetails: Yup.array().when('propertyTransportation', {
    is: true,
    then: () =>
      Yup.array()
        .of(nearbySchema)
        .required('Transportation details are required'),
    otherwise: () => Yup.array().nullable(),
  }),
});

const LisingSchema = Yup.object().shape({
  details: Yup.object().when('section', {
    is: ListingSection.Details,
    then: () => detailsSchema,
  }),
  location: Yup.object().when('section', {
    is: ListingSection.Location,
    then: () => locationSchema,
  }),
  nearBy: Yup.object().when('section', {
    is: ListingSection.NearBy,
    then: () => mainNearBySchema,
  }),
});

const ListingDetailSchema = Yup.object().shape({
  details: Yup.object().when('section', {
    is: ListingSection.Details,
    then: () => detailsSchema,
  }),
});
const ListingLocationSchema = Yup.object().shape({
  location: Yup.object().when('section', {
    is: ListingSection.Location,
    then: () => locationSchema,
  }),
});
const ListingNearBySchema = Yup.object().shape({
  nearBy: Yup.object().when('section', {
    is: ListingSection.NearBy,
    then: () => mainNearBySchema,
  }),
});

export {
  LisingSchema,
  ListingDetailSchema,
  ListingLocationSchema,
  ListingNearBySchema,
};
