import React, { useEffect, useState } from 'react';

import './Blog.scss';
import BlogCard from '../../components/BlogComponents/BlogCard/BlogCard';
import SignInPopupHOC from '../Login/hoc/SigninHoc';
import SignIn from '../Login/Signin';
import Loader from '../../components/CommonComponents/Loader/Loader';
import { toast } from 'react-toastify';
import axiosCall from '../../utils/axiosCall';
import {
  ToastType,
  showToastMessage,
  toastDefaults,
} from '../../utils/ToastOptionCommon';

type IProps = {
  showSignInPopup: boolean;
  signInToggleHandler: () => void;
};

const Blog = (props: IProps) => {
  // const { showSignInPopup, signInToggleHandler } = props;
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [view, setView] = useState(0);
  const localUser = localStorage.getItem('user');

  useEffect(() => {
    // check if user logged in
    // if (!localUser) {
    //   // console.log('USER not logged in');
    //   //toast.error('Please log in to view blogs', toastDefaults());
    //   showToastMessage('Please log in to view blogs', ToastType.Error);
    //   //signInToggleHandler();
    // } else {
      getAndSetAllBlogs();
    //}
  }, []);

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = Number(e.target.value);
    if (selectedValue === 1) {
      sortLatest(); // Latest
    } else {
      sortOld(); // Old
    }
    setView(selectedValue);
  };

  const getAndSetAllBlogs = async () => {
    try {
      setLoading(true);

      const result = await axiosCall.get('/blog/getAll');
      if (result) {
        // console.log(result.data);
        setBlogs(
          result.data.sort(
            (a: any, b: any) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );
      }
    } catch (error) {
      toast.error(`Could not fetch blogs, Try again later...`, toastDefaults());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sortLatest = () => {
    setBlogs((blogs) =>
      blogs?.sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
    );
  };

  const sortOld = () => {
    setBlogs((blogs) =>
      blogs?.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  };

  return (
    <>
      {/* {showSignInPopup && (
        <SignIn
          modalClosedSignIn={signInToggleHandler}
          modalClosedSignUp={() => {}}
        />
      )} */}
      {loading && <Loader />}
      <div className="blog">
        <div className="container-sm">
          <div className="row mb-4">
            <div className="col-9">
              <h1>Top Articles</h1>
            </div>
            <div className="col-3">
              <div
                className="sort-posts input-group bg-white border-1 border-light rounded-2 float-end"
                style={{ width: '150px' }}
              >
                <label
                  className="input-group-text bg-white pe-0 border-0"
                  htmlFor="sort"
                >
                  Sort :
                </label>
                <select
                  className="form-select border-0"
                  id="sort"
                  onChange={handleSortChange}
                  value={view}
                >
                  <option value={0}>Latest</option>
                  <option value={1}>Old</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            {blogs?.length > 0 &&
              blogs?.map(
                (blog: any) =>
                  blog?.status === 'publish' && (
                    <BlogCard key={blog._id} {...blog} />
                  )
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPopupHOC(Blog);
