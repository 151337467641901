import React from 'react';
import './Aboutus.scss';
import { IHomeApiResponse } from '../../../redux/slices/HomeSlice';
import { useNavigate } from 'react-router';
interface IProps {
  aboutUsData: IHomeApiResponse
}
function Aboutus(props: IProps) {
  const navigate = useNavigate();
  const { aboutUsData } = props;
  return (
    <section id='About-Us'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-5'>
            <div className='row achievement-count'>
              <div className='col-6 animate__animated animate__fadeInDown'>
                <div className='achievement mb-4'>
                  <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.88428 28.1498H12.0647V39.2686H1.88428" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.0645 29.0873H17.2536C17.4546 29.0873 17.6535 29.1204 17.8434 29.1837L24.1458 31.3116C24.3356 31.3759 24.5356 31.4081 24.7355 31.4081H31.5945C32.2887 31.4081 32.9428 31.7879 33.2381 32.4158C33.3486 32.6509 33.419 32.9322 33.4079 33.2607C33.43 33.328 33.3637 34.8903 32.3811 35.2701C32.3811 35.3143 22.3343 35.2701 22.3343 35.2701" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M32.3811 35.2719L40.3965 31.8781C40.3965 31.8781 41.9146 31.6551 42.4953 33.3289C42.5174 33.3731 43.0308 35.5171 40.7763 36.3429C40.8114 36.3429 30.4763 40.1024 26.1591 41.6727C24.9585 42.1098 23.6414 42.1088 22.4408 41.6707L12.0645 37.8841" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.957 6.60529H30.9084" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M32.6668 23V15.6667C32.6668 14.3166 31.5724 13.2222 30.2224 13.2222H27.7779C26.4279 13.2222 25.3335 14.3166 25.3335 15.6667V23" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M18 19.3333V10.7285C18 9.05773 18.7595 7.47752 20.0641 6.43377L25.1824 2.33916C27.4143 0.553614 30.5857 0.553614 32.8176 2.33916L37.9358 6.43376C39.2405 7.47751 40 9.05772 40 10.7285V19.3333C40 21.3584 38.3584 23 36.3333 23H21.6667C19.6416 23 18 21.3584 18 19.3333Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <h2>{aboutUsData.landlordCount}</h2>
                  <p>Landlords</p>
                </div>
              </div>

              <div className='col-6 animate__animated animate__fadeInDown'>
                <div className='achievement mb-4'>
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.63257 33.1498H14.813V44.2686H4.63257" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.813 34.0873H20.0022C20.2031 34.0873 20.402 34.1204 20.5919 34.1837L26.8943 36.3116C27.0842 36.3759 27.2841 36.4081 27.484 36.4081H34.343C35.0372 36.4081 35.6913 36.7879 35.9867 37.4158C36.0972 37.6509 36.1675 37.9322 36.1565 38.2607C36.1786 38.328 36.1122 39.8903 35.1297 40.2701C35.1297 40.3143 25.0828 40.2701 25.0828 40.2701" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M35.1297 40.2719L43.145 36.8781C43.145 36.8781 44.6631 36.6551 45.2438 38.3289C45.2659 38.3731 45.7793 40.5171 43.5248 41.3429C43.56 41.3429 33.2248 45.1024 28.9077 46.6727C27.7071 47.1098 26.3899 47.1088 25.1893 46.6707L14.813 42.8841" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M27.9106 14.8716V30.4703L31.8992 33.1498L35.71 30.7687V28.2088L33.9829 26.6013L35.71 25.1134L33.8644 23.4074L35.71 21.9587L33.9829 20.1723L35.71 18.386V14.8737" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M39.0146 12.3935C39.0146 12.4998 38.9587 12.5982 38.8673 12.6525L31.9636 16.7611C31.8694 16.8171 31.7523 16.8176 31.6577 16.7625L24.6074 12.6521C24.5147 12.5981 24.4578 12.499 24.4578 12.3917V5.46015C24.4578 5.35292 24.5147 5.25377 24.6074 5.19976L31.6577 1.08942C31.7523 1.03424 31.8694 1.03476 31.9636 1.0908L38.8673 5.19934C38.9587 5.25368 39.0146 5.35209 39.0146 5.45835V12.3935Z" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M31.7366 8.30094C32.686 8.30094 33.4556 7.53131 33.4556 6.58193C33.4556 5.63254 32.686 4.86292 31.7366 4.86292C30.7872 4.86292 30.0176 5.63254 30.0176 6.58193C30.0176 7.53131 30.7872 8.30094 31.7366 8.30094Z" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29.7056 11.6053H33.657" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M30.8296 16.2792V25.5142" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M30.8296 27.5909V28.9302" stroke="#212121" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <h2>{aboutUsData.tenantCount}</h2>
                  <p>Tenants</p>
                </div>
              </div>

              <div className='col-6 animate__animated animate__fadeInUp'>
                <div className='achievement'>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1641_1594)">
                      <path d="M29.1851 17.7778H26.2221C24.7505 17.7778 23.7036 18.9333 23.7036 20.2963V23.2593C23.7036 24.721 24.7604 25.7778 26.2221 25.7778H29.1851C30.5481 25.7778 31.7036 24.7309 31.7036 23.2593V20.2963C31.7036 18.9333 30.5481 17.7778 29.1851 17.7778ZM29.6295 23.2593C29.6295 23.5358 29.4123 23.7037 29.1851 23.7037H26.2221C26.0542 23.7037 25.9456 23.6543 25.8863 23.5951C25.8271 23.5358 25.7777 23.437 25.7777 23.2593V20.2963C25.7777 20.0691 25.9456 19.8518 26.2221 19.8518H29.1851C29.274 19.8518 29.3826 19.9012 29.4814 20C29.5802 20.0988 29.6295 20.2074 29.6295 20.2963V23.2593Z" fill="#212121" />
                      <path d="M39.5557 6.66669H36.5927C35.1211 6.66669 34.0742 7.82224 34.0742 9.18521V12.1482C34.0742 13.6099 35.131 14.6667 36.5927 14.6667H39.5557C40.9187 14.6667 42.0742 13.6198 42.0742 12.1482V9.18521C42.0742 7.82224 40.9187 6.66669 39.5557 6.66669ZM40.0001 12.1482C40.0001 12.4247 39.7829 12.5926 39.5557 12.5926H36.5927C36.4248 12.5926 36.3162 12.5432 36.2569 12.484C36.1977 12.4247 36.1483 12.3259 36.1483 12.1482V9.18521C36.1483 8.95805 36.3162 8.74076 36.5927 8.74076H39.5557C39.6446 8.74076 39.7532 8.79014 39.852 8.88891C39.9508 8.98767 40.0001 9.09632 40.0001 9.18521V12.1482Z" fill="#212121" />
                      <path d="M16.4642 46.0543L16.3753 45.9259H5.48148C3.51605 45.9259 2.07407 44.484 2.07407 42.5185V17.3333C2.07407 15.4568 3.51605 13.9259 5.48148 13.9259H15.4074V11.8519H5.48148C2.43951 11.8519 0 14.4 0 17.3333V42.5185C0 45.5506 2.44938 48 5.48148 48H18.0444L17.5407 47.4963C17.1951 47.1506 16.8395 46.6272 16.4642 46.0642V46.0543Z" fill="#212121" />
                      <path d="M29.1851 6.66669H26.2221C24.7505 6.66669 23.7036 7.82224 23.7036 9.18521V12.1482C23.7036 13.6099 24.7604 14.6667 26.2221 14.6667H29.1851C30.5481 14.6667 31.7036 13.6198 31.7036 12.1482V9.18521C31.7036 7.82224 30.5481 6.66669 29.1851 6.66669ZM29.6295 12.1482C29.6295 12.4247 29.4123 12.5926 29.1851 12.5926H26.2221C26.0542 12.5926 25.9456 12.5432 25.8863 12.484C25.8271 12.4247 25.7777 12.3259 25.7777 12.1482V9.18521C25.7777 8.95805 25.9456 8.74076 26.2221 8.74076H29.1851C29.274 8.74076 29.3826 8.79014 29.4814 8.88891C29.5802 8.98767 29.6295 9.09632 29.6295 9.18521V12.1482Z" fill="#212121" />
                      <path d="M41.7778 0H24.0001C20.5038 0 17.7778 2.82469 17.7778 6.22222V41.7778C17.7778 45.2741 20.5038 48 24.0001 48H41.7778C45.1754 48 48.0001 45.2741 48.0001 41.7778V6.22222C48.0001 2.81482 45.1852 0 41.7778 0ZM45.926 41.7778C45.926 44.1086 44.0297 45.9259 41.7778 45.9259H24.0001C21.6593 45.9259 19.8519 44.1185 19.8519 41.7778V6.22222C19.8519 3.97037 21.6692 2.07407 24.0001 2.07407H41.7778C44.0198 2.07407 45.926 3.98025 45.926 6.22222V41.7778Z" fill="#212121" />
                      <path d="M29.1851 29.6296H26.2221C24.7505 29.6296 23.7036 30.7852 23.7036 32.1482V35.1111C23.7036 36.5728 24.7604 37.6296 26.2221 37.6296H29.1851C30.5481 37.6296 31.7036 36.5827 31.7036 35.1111V32.1482C31.7036 30.7852 30.5481 29.6296 29.1851 29.6296ZM29.6295 35.1111C29.6295 35.3877 29.4123 35.5556 29.1851 35.5556H26.2221C26.0542 35.5556 25.9456 35.5062 25.8863 35.4469C25.8271 35.3877 25.7777 35.2889 25.7777 35.1111V32.1482C25.7777 31.921 25.9456 31.7037 26.2221 31.7037H29.1851C29.274 31.7037 29.3826 31.7531 29.4814 31.8519C29.5802 31.9506 29.6295 32.0593 29.6295 32.1482V35.1111Z" fill="#212121" />
                      <path d="M8.4443 29.6296C6.9727 29.6296 5.92578 30.7852 5.92578 32.1482V35.1111C5.92578 36.5728 6.98257 37.6296 8.4443 37.6296H11.4073C12.7702 37.6296 13.9258 36.5827 13.9258 35.1111V32.1482C13.9258 30.7852 12.7702 29.6296 11.4073 29.6296H8.4443ZM11.8517 32.1482V35.1111C11.8517 35.3877 11.6344 35.5556 11.4073 35.5556H8.4443C8.2764 35.5556 8.16776 35.5062 8.1085 35.4469C8.04924 35.3877 7.99986 35.2889 7.99986 35.1111V32.1482C7.99986 31.921 8.16776 31.7037 8.4443 31.7037H11.4073C11.4962 31.7037 11.6048 31.7531 11.7036 31.8519C11.8023 31.9506 11.8517 32.0593 11.8517 32.1482Z" fill="#212121" />
                      <path d="M39.5557 17.7778H36.5927C35.1211 17.7778 34.0742 18.9333 34.0742 20.2963V23.2593C34.0742 24.721 35.131 25.7778 36.5927 25.7778H39.5557C40.9187 25.7778 42.0742 24.7309 42.0742 23.2593V20.2963C42.0742 18.9333 40.9187 17.7778 39.5557 17.7778ZM40.0001 23.2593C40.0001 23.5358 39.7829 23.7037 39.5557 23.7037H36.5927C36.4248 23.7037 36.3162 23.6543 36.2569 23.5951C36.1977 23.5358 36.1483 23.437 36.1483 23.2593V20.2963C36.1483 20.0691 36.3162 19.8518 36.5927 19.8518H39.5557C39.6446 19.8518 39.7532 19.9012 39.852 20C39.9508 20.0988 40.0001 20.2074 40.0001 20.2963V23.2593Z" fill="#212121" />
                      <path d="M13.9258 20.2963C13.9258 18.9333 12.7702 17.7778 11.4073 17.7778H8.4443C6.9727 17.7778 5.92578 18.9333 5.92578 20.2963V23.2593C5.92578 24.721 6.98257 25.7778 8.4443 25.7778H11.4073C12.7702 25.7778 13.9258 24.7309 13.9258 23.2593V20.2963ZM11.8517 23.2593C11.8517 23.5358 11.6344 23.7037 11.4073 23.7037H8.4443C8.2764 23.7037 8.16776 23.6543 8.1085 23.5951C8.04924 23.5358 7.99986 23.437 7.99986 23.2593V20.2963C7.99986 20.0691 8.16776 19.8518 8.4443 19.8518H11.4073C11.4962 19.8518 11.6048 19.9012 11.7036 20C11.8023 20.0988 11.8517 20.2074 11.8517 20.2963V23.2593Z" fill="#212121" />
                      <path d="M39.5557 29.6296H36.5927C35.1211 29.6296 34.0742 30.7852 34.0742 32.1482V35.1111C34.0742 36.5728 35.131 37.6296 36.5927 37.6296H39.5557C40.9187 37.6296 42.0742 36.5827 42.0742 35.1111V32.1482C42.0742 30.7852 40.9187 29.6296 39.5557 29.6296ZM40.0001 35.1111C40.0001 35.3877 39.7829 35.5556 39.5557 35.5556H36.5927C36.4248 35.5556 36.3162 35.5062 36.2569 35.4469C36.1977 35.3877 36.1483 35.2889 36.1483 35.1111V32.1482C36.1483 31.921 36.3162 31.7037 36.5927 31.7037H39.5557C39.6446 31.7037 39.7532 31.7531 39.852 31.8519C39.9508 31.9506 40.0001 32.0593 40.0001 32.1482V35.1111Z" fill="#212121" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1641_1594">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h2>{aboutUsData.TotalProperties}</h2>
                  <p>Property Ready</p>
                </div>
              </div>

              <div className='col-6 animate__animated animate__fadeInUp'>
                <div className='achievement'>
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.8575 45.8767L6.69031 39.4876C4.73362 38.8354 3.41381 37.0042 3.41382 34.9417V7.79543C3.41382 6.25514 4.15426 4.80874 5.40382 3.90812C6.65337 3.0075 8.25969 2.76245 9.72094 3.24954L28.8882 9.6386C30.8449 10.2908 32.1647 12.122 32.1647 14.1845V41.3308C32.1647 42.8711 31.4242 44.3174 30.1747 45.2181C28.9251 46.1187 27.3188 46.3637 25.8575 45.8767Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.20557 3H36.9564C39.6029 3 41.7482 5.14537 41.7482 7.79181V34.1468C41.7482 36.7932 39.6029 38.9386 36.9564 38.9386H32.1646" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <h2>{aboutUsData.landlordCount}</h2>
                  <p>Page views</p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-7 ps-md-5 animate__animated animate__fadeInRight'>
            <h2>About Us </h2>
            <p>Furnished Home Locator is an all-in-one platform to find your next
              short-term rental. If you're a professional on the road and need a
              good place to stay, you will find it with us.</p>
            <p>We understood it wasn't easy for professionals to search for a safe
              rental in a town that's not their own. So we decided to step in and
              create a system that would help them.</p>
            <a className='btn btn-outline-primary' onClick={()=>navigate('/aboutus')}>Read More
              <svg width="19" height="12" viewBox="0 0 19 12" fill="#034991" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7539 6.84766L12.9727 11.6289C12.6211 11.9805 12.0938 11.9805 11.7773 11.6289C11.4258 11.3125 11.4258 10.7852 11.7773 10.4336L15.1172 7.09375H0.84375C0.351562 7.09375 0 6.74219 0 6.25C0 5.79297 0.351562 5.40625 0.84375 5.40625H15.1172L11.7773 2.06641C11.4258 1.75 11.4258 1.22266 11.7773 0.871094C12.0938 0.554688 12.6211 0.554688 12.9727 0.871094L17.7539 5.6875C18.0703 6.00391 18.0703 6.53125 17.7539 6.84766Z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section >
  );
}

export default Aboutus;
