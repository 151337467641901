import { useField } from 'formik';
import React from 'react';
import Select from 'react-select';

interface IProps {
  children: React.ReactNode;
  className: string;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  options: any[];
  changeHandler?: (value: any) => void;
  loading?: boolean;
  isLabelValueSame: boolean;
  isClearable?: boolean;
}

const FormikFormSelectField = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const value = props.isLabelValueSame
    ? { value: field.value, label: field.value }
    : field.value;
  return (
    <>
      <Select
        {...field}
        isClearable={props.isClearable ?? false}
        options={props.options}
        onChange={(e: any) => {
          helpers.setValue(e?.value ?? "");
        }}
        value={value}
        isLoading={props.loading}
      />
      {meta.touched && meta.error && (
        <span className="invalid-feedback">{meta.error}</span>
      )}
    </>
  );
};

export default FormikFormSelectField;
