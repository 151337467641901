import './PaymentPage.scss';
import { PaypalGateway } from '../PaymentGateway/PaypalGateway/PaypalGateway';
import { StripeGateway } from '../PaymentGateway/StripeGateway/StripeGateway';

interface IPorps {
  handleSubmit: (pkgId: string) => void;
  pkgId: string;
}

export const PaymentPage = (props: IPorps) => {
  return (
    <div className="payment-container">
      <PaypalGateway pkgId={props.pkgId} />
      <StripeGateway {...props} />
    </div>
  );
};
