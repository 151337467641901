// import './Services.scss';

import { useState } from 'react';
import { ToastType, showToastMessage } from '../../utils/ToastOptionCommon';
import Loader from '../../components/CommonComponents/Loader/Loader';
import axiosCall from '../../utils/axiosCall';

const initialInputData = {
  name: '',
  email: '',
  message: '',
};

export const isEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email.trim().split(' ')[0].toLowerCase());
};

export const ContactUs = () => {
  const [inputData, setInputData] = useState(initialInputData);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // validate inputs
    if (
      inputData?.name?.trim()?.length === 0 ||
      inputData?.email?.trim()?.length === 0 ||
      inputData?.message?.trim()?.length === 0
    ) {
      return showToastMessage(
        'Please fill all required fileds',
        ToastType.Error
      );
    }
    if (
      inputData?.message?.length > 500
    ) {
      return showToastMessage(
        'Your message is too long. Keep it below 500 charcters.',
        ToastType.Error
      );
    }
    if (!isEmail(inputData?.email)) {
      return showToastMessage('Please give a valid email id', ToastType.Error);
    }
    const url = '/usermessage/addMessage';

    const payload = {
      name: inputData?.name,
      email: inputData?.email,
      message: inputData?.message,
    };

    try {
      setIsLoading(true);

      const response = await axiosCall.post(url, payload);

      if (response.status === 200) {
        showToastMessage('Successfully sent your message', ToastType.Success);
      } else {
        showToastMessage(
          'Something wrong happend while sending your message',
          ToastType.Error
        );
      }
    } catch (error) {
      showToastMessage('Failed to submit your message', ToastType.Error);
      console.log(error);
    } finally {
      setIsLoading(() => false);
      setInputData(() => initialInputData);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInputData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="contact-us bg-white">
      <div className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row">
              <div className="col-12 animate__animated animate__fadeInDown">
                <h1 className='border-img-one'>Contact Us</h1>
                <p>
                Have questions about our products, features, or pricing? Our team of experts is here to assist you. Reach out to us, and we'll be happy to help!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="box-view">
                <h3 className="box-title text-uppercase">Send Us A Message</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      // placeholder="Your name"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Email<span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      // placeholder="Your email id"
                      name="email"
                      value={inputData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Message<span className="required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      style={{ minHeight: '180px' }}
                      // placeholder="Your message"
                      name="message"
                      value={inputData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box-view">
                <h3 className="box-title">Our Address</h3>
                <address className="mb-0">
                  Furnished Home Locators Privacy Department 1309 Coffeen
                  Avenue, STE 14393 Sheridan, Wyoming 82801
                </address>
              </div>

              <div className="box-view">
                <h3 className="box-title">Email Us</h3>
                <p>
                  For property related & General Inquiries:<br></br>{' '}
                  <a href="mailto:support@furnishedhomelocater.com">
                    support@furnishedhomelocater.com
                  </a>
                </p>
                <p className="mb-0">
                  Visit our corporate website FHL:<br></br>{' '}
                  <a href="www.furnishedhomelocator.com">
                    www.furnishedhomelocator.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
