import { useCallback, useEffect, useState } from 'react';
import { ToastType, showToastMessage } from '../utils/ToastOptionCommon';

const apiUrl = process.env.REACT_APP_API_URL;

export const useCertificate = () => {
  const [loading, setLoading] = useState(false);
  const [certificates, setCertificates] = useState([]);

  const getCertificates = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('accessToken');
    const res = await fetch(`${apiUrl}/document/certificates-type`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
    if (res?.error) {
      showToastMessage('Failed to get certificates', ToastType.Error);
    } else {
      const d = res?.map((e:any) => ({Id: e.id, Name: e.name}))
      setCertificates(d);
    }
  }, []);

  useEffect(() => {
    getCertificates();
  }, []);

  return { loading, certificates };
};
