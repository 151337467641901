export const Subscription = {
  SUBSCRIBE: 'SUBSCIBE',
  NOT_SUBSCRIBE: 'NOT_SUBSCRIBE',
  EXPIRED: 'EXPIRED',
};

export const getSubscriptionText = (str: string) => {
  let text = Subscription.NOT_SUBSCRIBE;
  switch (str) {
    case Subscription.EXPIRED:
      text = 'Your subscription has been expired. Click here to renew subscription!';
      break;
    case Subscription.NOT_SUBSCRIBE:
      text = 'You are unsubscribed user. Click here to buy subscription!';
      break;
    case Subscription.SUBSCRIBE:
      text = "Your subscription will expire in {LeftDays} days. You can upgrade subscription to use more functionalities!";
      break;
    default:
      text = Subscription.NOT_SUBSCRIBE;
      break;
  }
  return text;
};
