import React, { Dispatch, SetStateAction, useState } from 'react';
import callBackendAPI, { callAPI } from '../../../utils/callBackendAPI';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Row } from 'react-bootstrap';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { IoClose } from 'react-icons/io5';
import { signUpValidateSchema } from '../../../utils/yupSchemas/ResgistrationSchema';
import Busy from '../../../assets/gif/busy.gif';
import './SignupForm.scss';
import { toast } from 'react-toastify';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import FormikFormPasswordField from '../../../utils/FormikPasswordField';

interface IProps {
  submitHandler: (data: any) => void;
  onModalClosedSignUp: () => void;
  onModalClosedSignIn: () => void;
  initialValues: ISignUp;
  isLoading: boolean;
  setLoginError: Dispatch<SetStateAction<string>>;
  loginError: string;
  passwordError: string;
  setPasswordError: Dispatch<SetStateAction<string>>;
}

export interface ISignUp {
  FirstName?: string;
  LastName?: string;
  Email: string;
  Password: string;
  // PhoneNo: string;
  ConfirmPassword: string;
  Role: string;
  UserType: string;
  TermsConditions: boolean;
}

const SignupForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosedSignUp,
    onModalClosedSignIn,
    initialValues,
    isLoading,
    loginError,
    setLoginError,
    passwordError,
    setPasswordError,
  } = props;
  const [userEmail, setuserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleSubmit = async (values: ISignUp) => {
    // e.preventDefault();
    setLoginError(() => '');
    setPasswordError(() => '');
    setIsProgress(() => true);
    try {
      // if (values.Password !== values.ConfirmPassword) {
      //     setPasswordError(()=>'Passwords do not match');
      //     return;
      // }
      const response = await callAPI('POST', 'auth/register', null, {
        userEmail: values.Email,
        firstName: values.FirstName,
        lastName: values.LastName,
        password: values.Password,
        // phoneNo: values.PhoneNo,
        role: values.Role,
      });
      if (response.error) {
        setIsProgress(() => false);
        // setLoginError(response.error);
        toast.error(response.error.toString, toastDefaults());
      } else {
        setIsProgress(() => false);
        // window.alert('sign up successfully');
        toast.success('Sign up successfully', toastDefaults());
        handleSignInRoute();
      }
    } catch (error: any) {
      setIsProgress(() => false);
      if (error?.response?.status === 409) {
        toast.error('Email already exist', toastDefaults());
      } else {
        toast.error('Sign Up failed:', toastDefaults());
      }
    }
  };

  const handleSignInRoute = () => {
    onModalClosedSignUp();
    onModalClosedSignIn();
  };

  return (
    <>
      <Modal className="" show={true} centered>
        <Modal.Header>
          <Modal.Title className="ms-auto">Create your account</Modal.Title>
          <div
            onClick={() => {
              onModalClosedSignUp();
            }}
            className="icons8-close ms-auto"
          >
            <IoClose />
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={signUpValidateSchema}
          onSubmit={(values: ISignUp) => {
            handleSubmit(values);
          }}
        >
          {({ values }) => (
            <Form noValidate>
              {/* <FormObserver /> */}
              <Modal.Body>
                {loginError && <div style={{ color: 'red' }}>{loginError}</div>}

                <div className="mb-2">
                  <label htmlFor="firstName" className="form-label">
                    Enter your First Name
                  </label>
                  <Field
                    id="firstName"
                    name="FirstName"
                    type="text"
                    autoComplete="firstName"
                    required
                    className="form-control"
                    // invalidcharacter={[" "]}
                    placeholder=""
                    // as={FormikFormInput}
                  />
                  <ErrorMessage
                    component="div"
                    name="FirstName"
                    className="error"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="lastName" className="form-label">
                    Enter your Last Name
                  </label>
                  <Field
                    id="lastName"
                    name="LastName"
                    type="text"
                    autoComplete="lastName"
                    required
                    className="form-control"
                    // invalidcharacter={[" "]}
                    placeholder=""
                    // as={FormikFormInput}
                  />
                  <ErrorMessage
                    component="div"
                    name="LastName"
                    className="error"
                  />
                </div>

                <div className="mb-2">
                  <label htmlFor="email" className="form-label">
                    Enter your Email
                  </label>
                  <Field
                    id="email"
                    name="Email"
                    type="email"
                    autoComplete="email"
                    required
                    className="form-control"
                    // invalidcharacter={[" "]}
                    placeholder=""
                    // as={FormikFormInput}
                  />
                  <ErrorMessage
                    component="div"
                    name="Email"
                    className="error"
                  />
                </div>

                <div className="mb-2">
                  <label htmlFor="password" className="form-label">
                    Enter Password
                  </label>
                  <Field
                    id="password"
                    name="Password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="password"
                    required
                    className="form-control"
                    // invalidcharacter={[" "]}
                    placeholder=""
                    as={FormikFormPasswordField}
                  />
                  <ErrorMessage
                    component="div"
                    name="Password"
                    className="error"
                  />
                </div>

                {/* {passwordError && (
                      <div style={{ color: 'red' }}>{passwordError}</div>
                    )} */}

                {/* <div className="mb-2">
                                            <label htmlFor="confirmPassword" className="block ">Confirm Password</label>
                                            <Field
                                                id="confirmPassword"
                                                name="ConfirmPassword"
                                                type="password"
                                                autoComplete="confirmPassword"
                                                required
                                                className="block w-full rounded border-gray-300 pl-2"
                                                // invalidcharacter={[" "]}
                                                placeholder=""
                                            // as={FormikFormInput}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="email"
                                                className="error"
                                            />
                                        </div> */}

                {/* <div className="mb-2">
                                            <label htmlFor="role" className="block ">Role</label>
                                            <Field id="role" as="select" name="Role" className="block w-full rounded border-gray-300 pl-1" required>
                                                <option value="">Select Role</option>
                                                <option value="User">User</option>
                                                <option value="Admin">Admin</option>
                                                <option value="LandLord">LandLord</option>
                                            </Field>
                                            <ErrorMessage
                                                component="div"
                                                name="email"
                                                className="error"
                                            />
                                        </div> */}

                {/* <div className="mb-3">
                  <label htmlFor="phoneno" className="form-label">
                    Phone No
                  </label>
                  <Field
                    id="phoneno"
                    name="PhoneNo"
                    type="number"
                    autoComplete="phoneno"
                    required
                    min={10}
                    className="form-control"
                    placeholder=""
                  />
                  <ErrorMessage
                    component="div"
                    name="PhoneNo"
                    className="error"
                  />
                </div> */}

                <div className="mb-3">
                  <div className="d-flex gap-3">
                    <label className="form-label m-0">User Type:</label>
                    <div className="form-check form-check-inline">
                      <Field
                        type="radio"
                        name="Role"
                        value="Tenant"
                        className="form-check-input"
                      />
                      <label className="form-check-label">Tenant</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        type="radio"
                        name="Role"
                        value="Landlord"
                        className="form-check-input"
                      />
                      <label className="form-check-label">Landlord</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        type="radio"
                        name="Role"
                        value="Both"
                        className="form-check-input"
                      />
                      <label className="form-check-label">Both</label>
                    </div>

                    {/* <div role="group" aria-labelledby="role" className="">
                      <label className="cursor-pointer">
                        <Field
                          type="radio"
                          name="Role"
                          value="Tenant"
                          className=""
                        />
                        Tenant
                      </label>
                      <label className="cursor-pointer">
                        <Field
                          type="radio"
                          name="Role"
                          value="Landlord"
                          className=""
                        />
                        Landlord
                      </label>
                      <label className="cursor-pointer">
                        <Field
                          type="radio"
                          name="Role"
                          value="Both"
                          className=""
                        />
                        Both
                      </label>
                    </div> */}
                  </div>
                  <ErrorMessage component="div" name="Role" className="error" />
                </div>

                <div className="d-flex gap-2">
                  <Field
                    id="terms"
                    name="TermsConditions"
                    type="checkbox"
                    autoComplete="terms"
                    required
                    className="mb-3"
                  />
                  <p className="">
                    I have read and accept the{' '}
                    <Link
                      to="terms-and-conditions"
                      target="_blank"
                      className="text-primary"
                    >
                      Terms and Privacy Policy?
                    </Link>
                  </p>
                </div>
                <ErrorMessage
                  component="div"
                  name="TermsConditions"
                  className="error"
                />

                <Button
                  // disabled={!values.TermsConditions}
                  type="submit"
                  className="w-100 py-2 mt-3 mb-2"
                  // isLoading={false}
                >
                  {isProgress && (
                    <img
                      src={Busy}
                      alt="loading.."
                      className="me-2"
                      style={{ maxWidth: '16px' }}
                    />
                  )}
                  Register
                </Button>

                <p className="my-2 text-center">
                  Already have an account?
                  <a onClick={handleSignInRoute} className="text-primary">
                    {' '}
                    Sign In
                  </a>
                </p>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SignupForm;
