import {
  Autocomplete,
  Libraries,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api';
import FormikFieldLabelAutoComplete from '../../../../utils/FormikFieldLabelAutoComplete';
import { useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Library } from '../../../../constants/GoogleLibrary';
import { calculateDistance } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import {
  showToastMessage,
  ToastType,
} from '../../../../utils/ToastOptionCommon';

interface IProps {
  field: any;
  label: string;
  form: any;
  name: string;
  placeholder: string;
  addressType: string;
}

const Input = (props: IProps): JSX.Element => {
  const [field, meta, helpers] = useField(props);
  const inputRef = useRef<any>(null);
  const { address } = useSelector((state: any) => state.createListing);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    libraries: Library,
    region: 'us',
  });
  const { values, setFieldValue }: any = useFormikContext();
  const types = props.addressType?.split(',');

  const options = {
    //bounds: defaultBounds,
    types: types,
    componentRestrictions: { country: 'us' },
    fields: ['name', 'address_components', 'formatted_address', 'geometry'],
    strictBounds: false,
  };

  const handlePlaceChanged = () => {
    if (inputRef.current) {
      const place = inputRef.current.getPlace();
      if (place) {
        let nearByLat = Number(place.geometry?.location.lat()) ?? 0;
        let nearByLng = Number(place.geometry?.location.lng()) ?? 0;
        let contextValue = props.name.replace('.address', '');
        setFieldValue(`${contextValue}.address`, place.formatted_address);
        setFieldValue(`${contextValue}.name`, place.name);
        let nearbyDis = String(
          calculateDistance(
            Number(address.latitude) ?? 0,
            Number(address.longitude) ?? 0,
            nearByLat ?? 0,
            nearByLng ?? 0
          ).toFixed(2) ?? 0
        );
        //helpers.setValue(place.name ?? '');
        if (nearbyDis !== 'NaN') {
          setFieldValue(`${contextValue}.distance`, nearbyDis);
        } else {
          showToastMessage(
            'Please enter property address to calculate miles',
            ToastType.Info
          );
        }
      }
    }
  };
  return (
    <div>
      <label className="form-label">{props.label}</label>
      {props.label === 'Address' || props.label === 'Name' ? (
        isLoaded &&
        !loadError && (
          <Autocomplete
            options={options}
            onLoad={(ref) => (inputRef.current = ref)}
            onPlaceChanged={handlePlaceChanged}
          >
            <input
              {...field}
              // type="text"
              // id={props.name}
              // name={props.name}
              //value={props.value}
              className="form-control"
              placeholder=""
              // onFocus={() => { helpers.setValue(""); if (field.value) { document.getElementById(props.name)!.blur(); document.getElementById(props.name)!.focus(); } }}
            />
          </Autocomplete>
        )
      ) : (
        <input className="form-control" {...field} placeholder={''} />
      )}
    </div>
  );
};
export default Input;
