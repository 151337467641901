import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppDispatch } from '../../redux/store';
import {
  getHousingRequestById,
  getRequestById,
  resetGetByIdRequest,
} from '../../redux/slices/HousingRequestSlice';
import { ToastType, showToastMessage } from '../../utils/ToastOptionCommon';
import DashboardHeader from '../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import { Link } from 'react-router-dom';
import Loader from '../../components/CommonComponents/Loader/Loader';

const ShowHousingDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const res = useSelector(getRequestById);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    dispatch(getHousingRequestById({ id }));
  }, [id]);

  useEffect(() => {
    if (res.error) {
      showToastMessage(res.error, ToastType.Error);
    }
  }, [res.error]);

  useEffect(() => {
    if (res.success) {
      setData(res.data);
      dispatch(resetGetByIdRequest());
    }
  }, [res.success]);

  const FillDetails = ({ data, isAgency = false }: any) => {
    //console.log(data);
    return (
      <>
        <li className='col-sm-6'>
          <span>Name:</span> {data.firstName} {data.lastName}
        </li>
        {!isAgency && <li className='col-sm-6'><span>Email:</span> {data.email}</li>}
        {!isAgency && <li className='col-sm-6'><span>Phone:</span> {data.phone}</li>}
        <li className='col-sm-6'><span>Move In:</span> {data.moveIn}</li>
        <li className='col-sm-6'><span>Move Out:</span> {data.moveOut}</li>
        <li className='col-sm-6'><span>Travelling Reason:</span> {data.travelReason}</li>
        <li className='col-sm-6'><span>Working:</span> {data.working}</li>
        <li className='col-sm-6'><span>Occupant:</span> {data.occupant}</li>
        <li className='col-sm-6'><span>Property:</span> {data.propertyType}</li>
        <li className='col-sm-6'><span>Bedroom:</span> {data.bedroom}</li>
        <li className='col-sm-6'><span>Budget:</span> {data.budget}</li>
        <li className='col-sm-6'><span>With Pet:</span> {data.withPet}</li>
        <li className='col-sm-6'><span>Description:</span> {data.description}</li>
        {!isAgency && <li className='col-sm-6'><span>Contacted Via:</span> {data.contacted}</li>}
      </>
    );
  };

  return (
    <>
      <div className="main-content">
        <div className="">
          <DashboardHeader />

          <div className="below-header">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h1>Request Detail</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/dashboard/request">Request</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Request Detail
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div className="box-view">
            {res.loading ? (
              <Loader />
            ) : (
              <div className='traveler-details'>
                <h3 className='box-title'>{data.requester} Details</h3>
                <ul className='row p-0'>
                  <li className='col-sm-6'><span>State:</span> {data.state}</li>
                  <li className='col-sm-6'><span>City:</span> {data.city}</li>
                  {(data.requester === 'Traveler' || data.requester === 'Military') && (
                    <FillDetails data={data.traveler} />
                  )}
                  {data.requester === 'Agency' && (
                    <>
                      <li className='col-sm-6'><span>Name:</span> {data.agency.name}</li>
                      <li className='col-sm-6'><span>Email:</span> {data.agency.email}</li>
                      <li className='col-sm-6'><span>Phone:</span> {data.agency.contact}</li>
                      {
                        data?.agencies?.map((e: any, idx: number) => {
                          return <ul>
                            <h5>Traveler {idx + 1}</h5>
                            <FillDetails data={e} isAgency={true} />
                          </ul>
                        })
                      }
                    </>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowHousingDetail;
