import { createSlice } from '@reduxjs/toolkit';

interface IState {
  isLoading: boolean;
  error: string | undefined;
  success: boolean;
  data: {
    isFormDirty: boolean;
  };
}

const initialState: IState = {
  data: {
    isFormDirty: false,
  },
  isLoading: false,
  error: undefined,
  success: false,
};

export const LocationSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setFormDirty: (state, action) => {
      state.data.isFormDirty = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setFormDirty } = LocationSlice.actions;

export default LocationSlice.reducer;

export const LocationState = (state: any) => state.location.data.isFormDirty;
