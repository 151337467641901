export const createMultipartFormData = (
  data: any,
  formData: FormData
): FormData => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
      // If the value is an object, iterate over its properties
      Object.keys(data[key]).forEach((subKey) => {
        formData.append(`${key}[${subKey}]`, data[key][subKey]);
      });
    } else if (Array.isArray(data[key])) {
      // If the value is an array of objects, iterate over each object
      data[key].forEach((item: any, index: number) => {
        Object.keys(item).forEach((subKey) => {
          formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
        });
      });
    } else {
      // For scalar values
      formData.append(key, String(data[key]));
    }
  });

  return formData;
};
