import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import LabelDistanceHealthComponent from './LabelAndDistanceComponent/LabelDistanceHealthComponent';
import LabelDistanceEducationComponent from './LabelAndDistanceComponent/LabelDistanceEducationComponent';
import LabelDistanceTransportationComponent from './LabelAndDistanceComponent/LabelDistanceTransportationComponent';
import LabelCafeComponent from './LabelAndDistanceComponent/LabelCafeComponent';
import LabelGroceryStoreComponent from './LabelAndDistanceComponent/LabelGroceryStoreComponent';
import LabelMovieTheaterComponent from './LabelAndDistanceComponent/LabelMovieTheaterComponent';
import LabelParkComponent from './LabelAndDistanceComponent/LabelParkComponent';
import LabelRestaurantComponent from './LabelAndDistanceComponent/LabelRestaurantComponent';
import LabelShoppingCenterComponent from './LabelAndDistanceComponent/LabelShoppingCenterComponent';
import LabelMilitaryBaseComponent from './LabelAndDistanceComponent/LabelMilitaryBaseComponent';
import DisableSection from '../../../utils/DisableSection/DisableSection';
import { ListingSection } from '../../../constants/ListingSection';
import { BsPencilFill } from 'react-icons/bs';
import { appendOrigin } from '../../../utils/common';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import { ListingNearBySchema } from '../../../utils/yupSchemas/CreateListingSchema';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { NearBy } from '../../../constants/NearBy';
import { useDispatch } from 'react-redux';
import { setFormDirty } from '../../../redux/slices/LocationSlice';

export type Facilities = {
  name: string;
  description?: string;
  distance: 'string';
  address: string;
};

function NearByComponent({
  isEdit,
  handleSubmit,
  currentSection,
  setCurrentSection,
  initialValues,
  savedSection,
}: {
  isEdit?: any;
  handleSubmit: (formData: FormData) => void;
  currentSection: ListingSection;
  setCurrentSection: any;
  initialValues: any;
  savedSection?: any;
}) {
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = (values: any) => {
    let newProperty: any = {
      section: values.section,
    };
    let isError = false;
    const formData = new FormData();
    const data = { ...values.nearBy };
    const cafeDetails = data.propertyCafeDetails;
    const educationDetails = data.propertyEducationDetails;
    const groceryDetails = data.propertyGroceryStoreDetails;
    const healthMedicalDetails = data.propertyHealthAndMedicalDetails;
    const militaryBaseDetails = data.propertyMilitaryBaseDetails;
    const movieTheaterDetails = data.propertyMovieTheatersDetails;
    const parkDetails = data.propertyParksDetails;
    const restaurantDetails = data.propertyRestaurantsDetails;
    const shoppingCenterDetails = data.propertyShoppingCentersDetails;
    const transportationDetails = data.propertyTransportationDetails;
    const nearby_facilities: Facilities[] = [
      ...appendOrigin(cafeDetails, NearBy.Cafe),
      ...appendOrigin(educationDetails, NearBy.Education),
      ...appendOrigin(groceryDetails, NearBy.Grocery),
      ...appendOrigin(healthMedicalDetails, NearBy.Healthcare),
      ...appendOrigin(militaryBaseDetails, NearBy.MilitaryBase),
      ...appendOrigin(movieTheaterDetails, NearBy.MovieTheater),
      ...appendOrigin(parkDetails, NearBy.Park),
      ...appendOrigin(restaurantDetails, NearBy.Restaurant),
      ...appendOrigin(shoppingCenterDetails, NearBy.Shopping),
      ...appendOrigin(transportationDetails, NearBy.Transport),
    ];
    newProperty = {
      ...newProperty,
      nearby_facilities: nearby_facilities,
    };
    if (nearby_facilities?.length === 0) {
      isError = true;
    }
    if (isError) {
      return;
    }
    // Create Form
    createMultipartFormData(newProperty, formData);
    handleSubmit(formData);
  };

  const FormObject = () => {
    const { values, isSubmitting, dirty } = useFormikContext<any>();
    useEffect(() => {
      if (dirty && !isSubmitting) {
        dispatch(setFormDirty(dirty));
      }
    }, [dirty, isSubmitting]);
    useEffect(() => {
      if (
        values.nearBy.propertyCafe ||
        values.nearBy.propertyEducation ||
        values.nearBy.propertyGroceryStore ||
        values.nearBy.propertyHealthAndMedical ||
        values.nearBy.propertyMilitaryBase ||
        values.nearBy.propertyMovieTheaters ||
        values.nearBy.propertyParks ||
        values.nearBy.propertyRestaurants ||
        values.nearBy.propertyShoppingCenters ||
        values.nearBy.propertyTransportation
      ) {
        setShowError(false);
      }
    }, [
      values.nearBy.propertyCafe,
      values.nearBy.propertyEducation,
      values.nearBy.propertyGroceryStore,
      values.nearBy.propertyHealthAndMedical,
      values.nearBy.propertyMilitaryBase,
      values.nearBy.propertyMovieTheaters,
      values.nearBy.propertyParks,
      values.nearBy.propertyRestaurants,
      values.nearBy.propertyShoppingCenters,
      values.nearBy.propertyTransportation,
    ]);
    useEffect(() => {
      if (
        isSubmitting &&
        currentSection === ListingSection.NearBy &&
        !values.nearBy.propertyCafe &&
        !values.nearBy.propertyEducation &&
        !values.nearBy.propertyGroceryStore &&
        !values.nearBy.propertyHealthAndMedical &&
        !values.nearBy.propertyMilitaryBase &&
        !values.nearBy.propertyMovieTheaters &&
        !values.nearBy.propertyParks &&
        !values.nearBy.propertyRestaurants &&
        !values.nearBy.propertyShoppingCenters &&
        !values.nearBy.propertyTransportation
      ) {
        setShowError(true);
      }
    }, [isSubmitting]);
    return null;
  };

  return (
    <Formik
      initialValues={{ ...initialValues, section: ListingSection.NearBy }}
      enableReinitialize={true}
      validationSchema={ListingNearBySchema}
      onSubmit={submitHandler}
    >
      {(props: any) => (
        <Form>
          <FormObject />
          <div id="near-by">
            {currentSection !== ListingSection.NearBy && <DisableSection />}
            <div className="box-view">
              <h3 className="box-title">Near by</h3>
              {(isEdit || savedSection[ListingSection.NearBy]) && (
                <div title="Edit" className="edit-property">
                  {currentSection !== ListingSection.NearBy && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(ListingSection.NearBy);
                      }}
                    >
                      <BsPencilFill className="pencil_icon" />
                    </button>
                  )}
                  {currentSection === ListingSection.NearBy && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(-1);
                        props.resetForm();
                      }}
                    >
                      <IoCloseCircleOutline className="cancel_icon" />
                    </button>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyCafe"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyCafe}
                      onChange={() => {
                        // props.setFieldValue('propertyCafeDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyCafe',
                          !props.values.nearBy.propertyCafe
                        );
                      }}
                    />
                    Cafe
                  </label>
                  {props.values.nearBy.propertyCafe ? (
                    <FieldArray
                      name="nearBy.propertyCafeDetails"
                      render={(arrayHelpers) => (
                        <LabelCafeComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyEducation"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyEducation}
                      onChange={() => {
                        // props.setFieldValue('propertyEducationDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyEducation',
                          !props.values.nearBy.propertyEducation
                        );
                      }}
                    />
                    Education
                  </label>
                  {props.values.nearBy.propertyEducation ? (
                    <FieldArray
                      name="nearBy.propertyEducationDetails"
                      render={(arrayHelpers) => (
                        <LabelDistanceEducationComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyGroceryStore"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyGroceryStore}
                      onChange={() => {
                        // props.setFieldValue('propertyGroceryStoreDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyGroceryStore',
                          !props.values.nearBy.propertyGroceryStore
                        );
                      }}
                    />
                    Grocery Stores
                  </label>
                  {props.values.nearBy.propertyGroceryStore ? (
                    <FieldArray
                      name="nearBy.propertyGroceryStoreDetails"
                      render={(arrayHelpers) => (
                        <LabelGroceryStoreComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyHealthAndMedical"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyHealthAndMedical}
                      onChange={() => {
                        // props.setFieldValue('propertyHealthAndMedicalDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyHealthAndMedical',
                          !props.values.nearBy.propertyHealthAndMedical
                        );
                      }}
                    />
                    Health & Medical
                  </label>
                  {props.values.nearBy.propertyHealthAndMedical ? (
                    <FieldArray
                      name="nearBy.propertyHealthAndMedicalDetails"
                      render={(arrayHelpers) => (
                        <LabelDistanceHealthComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyMilitaryBase"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyMilitaryBase}
                      onChange={() => {
                        // props.setFieldValue('propertyMilitaryBaseDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyMilitaryBase',
                          !props.values.nearBy.propertyMilitaryBase
                        );
                      }}
                    />
                    Military Base
                  </label>
                  {props.values.nearBy.propertyMilitaryBase ? (
                    <FieldArray
                      name="nearBy.propertyMilitaryBaseDetails"
                      render={(arrayHelpers) => (
                        <LabelMilitaryBaseComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyMovieTheaters"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyMovieTheaters}
                      onChange={() => {
                        // props.setFieldValue('propertyMovieTheatersDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyMovieTheaters',
                          !props.values.nearBy.propertyMovieTheaters
                        );
                      }}
                    />
                    Movie Theaters
                  </label>
                  {props.values.nearBy.propertyMovieTheaters ? (
                    <FieldArray
                      name="nearBy.propertyMovieTheatersDetails"
                      render={(arrayHelpers) => (
                        <LabelMovieTheaterComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyParks"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyParks}
                      onChange={() => {
                        // props.setFieldValue('propertyParksDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyParks',
                          !props.values.nearBy.propertyParks
                        );
                      }}
                    />
                    Parks
                  </label>
                  {props.values.nearBy.propertyParks ? (
                    <FieldArray
                      name="nearBy.propertyParksDetails"
                      render={(arrayHelpers) => (
                        <LabelParkComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyRestaurants"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyRestaurants}
                      onChange={() => {
                        // props.setFieldValue('propertyRestaurantsDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyRestaurants',
                          !props.values.nearBy.propertyRestaurants
                        );
                      }}
                    />
                    Restaurants
                  </label>
                  {props.values.nearBy.propertyRestaurants ? (
                    <FieldArray
                      name="nearBy.propertyRestaurantsDetails"
                      render={(arrayHelpers) => (
                        <LabelRestaurantComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyShoppingCenters"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyShoppingCenters}
                      onChange={() => {
                        // props.setFieldValue('propertyShoppingCentersDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyShoppingCenters',
                          !props.values.nearBy.propertyShoppingCenters
                        );
                      }}
                    />
                    Shopping Centers
                  </label>
                  {props.values.nearBy.propertyShoppingCenters ? (
                    <FieldArray
                      name="nearBy.propertyShoppingCentersDetails"
                      render={(arrayHelpers) => (
                        <LabelShoppingCenterComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      name="nearBy.propertyTransportation"
                      className="form-check-input me-2"
                      checked={props.values.nearBy.propertyTransportation}
                      onChange={() => {
                        // props.setFieldValue('propertyTransportationDetails', [details]);
                        props.setFieldValue(
                          'nearBy.propertyTransportation',
                          !props.values.nearBy.propertyTransportation
                        );
                      }}
                    />
                    Transportation
                  </label>
                  {props.values.nearBy.propertyTransportation ? (
                    <FieldArray
                      name="nearBy.propertyTransportationDetails"
                      render={(arrayHelpers) => (
                        <LabelDistanceTransportationComponent
                          props={props}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                  ) : null}
                </div>
              </div>

              {/* Navigation */}
              {showError && (
                <div className="invalid-feedback">
                  Please select atleast one near by place.
                </div>
              )}

              <div className="row my-3">
                {isEdit && currentSection === ListingSection.NearBy && (
                  <div className="col-12 text-center">
                    <a
                      href="#amenitiesTab"
                      className="btn btn-outline-primary me-2"
                      onClick={() =>
                        setCurrentSection(ListingSection.Amenities)
                      }
                    >
                      Previous
                    </a>
                    <a href="#documentsTab">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => submit?.click()}
                      >
                        Update
                      </button>
                    </a>
                  </div>
                )}
                {!isEdit && currentSection === ListingSection.NearBy && (
                  <div className="col-12 text-center">
                    <a
                      href="#amenitiesTab"
                      className="btn btn-outline-primary me-2"
                      onClick={() =>
                        setCurrentSection(ListingSection.Amenities)
                      }
                    >
                      Previous
                    </a>
                    <a href="#documentsTab">
                      <button
                        // href="#documentsTab"
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => submit?.click()}
                      >
                        Next
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NearByComponent;
