import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatInput from './ChatInput';
import Messages from './Messages';
import { getAllMessagesRoute, sendMessageRoute } from '../../utils/APIRoutes';
import { useSocket } from '../../context/SocketProvider';
import { dateFormatForBE } from '../../utils/common';
import moment from 'moment';
import './Styles/ChatContainer.scss';
import userone from '../../assets/images/avatar.png';
import propertyImg from '../../assets/images/PropertySample.jpg';
import { useSelector } from 'react-redux';

const ChatContainer = ({
  currentChat,
  currentUser,
  chatId,
  updateContact,
  chatUser,
  isContactLoading
}) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [arrivalMessage, setArrivalMessage] = useState(undefined);
  // const [currentChatUser, setCurrentChatUser] = useState(null);
  // const [recieved, setRecieved] = useState(null);
  const socket = useSocket();
  const scrollRef = useRef();
  const userData = useSelector((state) => state.login.userData);
  const isAdmin = userData?.currentRole?.toLowerCase() === "admin";
  const [names, setNames] = useState({tenant: "", landlord: ""});
  const fetchAllMessages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getAllMessagesRoute}/${chatId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      setMessages(response?.data?.data);
      setNames(response?.data?.names);
      handleMarkAsRead(chatId, currentUser?._id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // fetch all messages
  useEffect(() => {
    fetchAllMessages();
  }, [currentChat, chatId]); // eslint-disable-line

  // socket.io message recieve
  useEffect(() => {
    socket.on('msg-receive', (res) => {
      if (res.chatId === currentChat.chatId?._id) {
        handleMarkAsRead(res.chatId, currentUser?._id);
        const msgs = { ...messages };
        const date = moment(res.data.sendTime).format(dateFormatForBE);
        msgs[date] = msgs[date] || [];
        msgs[date].push(res.data);
        setMessages(msgs);
        updateContact(res.chatId, res.data, true);
      } else {
        updateContact(res.chatId, res.data, false);
      }
    });

    return () => {
      socket.off('msg-receive');
    };
  }, [socket, messages, currentChat]);

  // check message from server using socket.io
  // useEffect(() => {
  //   if (arrivalMessage && ((currentUser?.currentRole === "Landlord") ? currentChat.senderId : currentChat.reciverId)) {
  //     setMessages((prev) => [...prev, arrivalMessage]);
  //   }
  // }, [arrivalMessage]);

  // change scroll to latest message
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behaviour: 'smooth' });
  }, [messages]);

  // Handle Send Messages
  const handleSendMsg = async (msg) => {
    let to =
      currentChat?.lastMessage?.users?.from._id !== currentUser._id
        ? currentChat?.lastMessage?.users?.from._id
        : currentChat?.lastMessage?.users?.to._id;
    const from = currentUser._id;
    //   await axios.post(sendMessageRoute, {
    //     from: currentUser._id,
    //     fromUserName: currentUser?.firstName + currentUser?.lastName,
    //     to: (currentUser?.currentRole === "Landlord") ? currentChat.senderId : currentChat.reciverId,
    //     message: msg,
    //     chatId: currentChat._id,
    //   },{
    //     headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    // });

    // socket.io send msg
    socket.emit('send-msg', {
      users: { from, to },
      message: msg,
      chatId: currentChat?.chatId?._id,
    });
    // Also mark as read if user is online.
    handleMarkAsRead(chatId, currentUser?._id);

    const msgs = { ...messages };
    const currentTime = new Date()?.toISOString();
    const date = moment(currentTime).format(dateFormatForBE);
    const data = { isMine: true, message: msg, sendTime: currentTime };
    msgs[date] = msgs[date] || [];
    msgs[date].push(data);
    setMessages(msgs);
    updateContact(currentChat?.chatId?._id, data, true);
  };

  const handleMarkAsRead = async (chatId, userId) => {
    // socket.io send msg
    socket.emit('mark-as-read', {
      chatId,
      userId,
    });
  };

  return (
    <div className="col-8">
      <div className='bg-white p-3 w-100 float-start'>
        {(!loading || !isContactLoading) && <div className="d-flex align-items-center gap-3 pb-3">
          <div className='position-relative'>
            <div className="user-img">
              <img src={chatUser.pic?.length ? chatUser.pic : isAdmin ? propertyImg : userone} className="img-fluid w-100" alt={`${isAdmin ? "property" : chatUser.userName}_pic`} />
            </div>
            {!isAdmin && <span className={`user-status ${chatUser.active ? "live-status" : "busy-status"}`}></span>}
          </div>
          <div className="fw-bold">{chatUser.propertyName}<br></br>{!isAdmin && <span className='fw-normal'>{chatUser.userName}</span>}</div>
        </div>}
        <Messages names={names} loading={loading} messages={messages} scrollRef={scrollRef} />
        {currentUser?.role[0].toString() === 'Admin' ? (
          ''
        ) : (
          <ChatInput handleSendMsg={handleSendMsg} />
        )}
      </div>
    </div>
  );
};

export default ChatContainer;
