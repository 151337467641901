import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { IoClose } from 'react-icons/io5';
import Busy from '../../../assets/gif/busy.gif';
import { useDispatch } from 'react-redux';
import FormikFormDateTimePicker from '../../../utils/FormikFieldDateTimePicker';



interface IProps {
  submitHandler: (data: any) => void;
  initialValues: IEvent;
  isLoading: boolean;
  onModalClosed: () => void;
  deleteHandler:(id:string)=>void;
}

export interface IEvent {
  id: string;
  title: string,
  // allDay: boolean,
  start: string,
  end: string
}

const AddCalenderForm = (props: IProps) => {
  const {
    submitHandler,
    initialValues,
    isLoading,
    deleteHandler
  } = props;
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Modal className="px-10 backdrop-blur-sm blue-theme" show={true} centered>
        <Modal.Header className="justify-between pb-0 bg-white border-none">
          <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
            {initialValues.id ? "Edit Event In Calender" : "Add Event To Calender"}
          </Modal.Title>
          <div
            onClick={() => { props.onModalClosed() }}
            className="icons8-close"
          >
            <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          // validationSchema={signInValidateSchema}
          onSubmit={submitHandler}
        // handleSubmit={}
        >
          {({ values }) => (
            <Form className="user-form space-y-6 text-deep_gray" noValidate>
              {/* <FormObserver /> */}
              <Modal.Body className="">
                <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex flex-col gap-2 mb-3">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 "
                      >
                        Enter your event name
                      </label>
                      <Field
                        id="title"
                        name="title"
                        type="title"
                        autoComplete="title"
                        required
                        className="form-control"
                        // invalidcharacter={[" "]}
                        placeholder=""
                      // as={FormikFormInput}
                      />
                      <ErrorMessage name="title" component="div" className='error' />
                    </div>

                    <div className="flex flex-col gap-2 mb-3">
                      <label
                        htmlFor="start"
                        className="block text-sm font-medium leading-6 "
                      >
                        Enter Start Date
                      </label>
                      <Field
                        id="start"
                        name="start"
                        type="start"
                        autoComplete="start"
                        required
                        className="form-control"
                        // invalidcharacter={[" "]}
                        placeholder=""
                        as={FormikFormDateTimePicker}
                      />
                      <ErrorMessage name="start" component="div" className='error' />
                    </div>

                    <div className="flex flex-col gap-2 mb-3">
                      <label
                        htmlFor="end"
                        className="block text-sm font-medium leading-6 "
                      >
                        Enter End Date
                      </label>
                      <Field
                        id="end"
                        name="end"
                        type="end"
                        autoComplete="end"
                        required
                        className="form-control"
                        // invalidcharacter={[" "]}
                        placeholder=""
                        as={FormikFormDateTimePicker}
                      />
                      <ErrorMessage name="end" component="div" className='error' />
                    </div>

                    <div>
                      <div className="m-auto my-3">
                        <Button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-deep_blue px-10
                                         py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-deep_blue 
                                         focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                                         focus-visible:outline-deep_blue uppercase me-3"
                        // onClick={() => {
                        //   handleSubmit(values);
                        // }}
                        >
                          {isProgress && (
                            <img src={Busy} alt="loading.." className='me-2' style={{ maxWidth: '16px' }} />
                          )}
                          {initialValues.id ? "Update" : "Add"}
                        </Button>
                        {initialValues.id ? <Button
                          className="ml-4 flex w-full justify-center rounded-md bg-deep_blue px-10
                        py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-deep_blue 
                        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                        focus-visible:outline-deep_blue uppercase"
                          onClick={() => deleteHandler(initialValues.id)}
                        >Delete</Button> : <></>}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddCalenderForm;
