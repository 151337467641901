import React, { useEffect, useRef, useState } from 'react';
import './Styles/Messages.scss';
import userone from '../../assets/images/avatar.png';
import { formatDate, formatPopupMsgDate } from '../../utils/formatDateTimeForMessage';
import { InView, useInView } from 'react-intersection-observer';
import Loader from '../CommonComponents/Loader/Loader';
import { useSelector } from 'react-redux';

const Messages = ({ messages, scrollRef, loading, names }) => {
  // const containerRef = useRef();
  // const { ref, inView, entry } = useInView({
  //   // threshold: 0.2,
  //   // root: containerRef,
  //   // trackVisibility: true,
  //   // delay: 100,
  // });
  const userData = useSelector((state) => state.login.userData);
  const [showingDate, setShowingDate] = useState('');
  const changeHandler = (e, entry, value) => {
    // console.log(e, entry, value);
    if (e) {
      setShowingDate(value);
    }
  };
  // const { ref, inView, entry } = useInView({onChange: changeHandler});

  // useEffect(() => {
  //   if (inView) {
  //     setShowingDate(entry.target.getAttribute('id'));
  //   }
  // }, [inView]);
  // https://react-intersection-observer.vercel.app/?path=/story/inview-component--multiple-observers
  return (
    <div className="chat mb-3" style={{ display: 'grid' }}>
      {loading ? (
        <Loader isSmallLoader={true} />
      ) : (
        <>
          {/* <div className="date_popup_container">
            {showingDate && <span className="date_popup">{formatPopupMsgDate(showingDate)}</span>}
          </div> */}
          {Object.keys(messages).map((e) => {
            return (
              <InView
                as="div"
                onChange={(inView, entry) => changeHandler(inView, entry, e)}
                key={e}
                id={e}
              >
                {({ inView, ref, entry }) => (
                  <div ref={ref} id={e}>
                    <div className="date-value">{formatPopupMsgDate(e)}</div>
                    <ul>
                      {messages &&
                        messages[e]?.map((message) => {
                          const formattedTime = formatDate(message?.sendTime);
                          return (
                            <li
                              className={`message ${
                                !message.isMine ? 'sended' : 'recieved'
                              }`}
                              ref={scrollRef}
                              key={message?.sendTime}
                            >
                              <div className="messages-user d-flex align-items-center">
                                {/* <div className="user-img">
                                  <img
                                    src={userone}
                                    className="img-fluid w-100"
                                    alt="..."
                                  />
                                  <span className="user-status busy-status"></span>
                                </div> */}
                                <div className="user-details ms-3">
                                  <span className="message-date-time">
                                    {formattedTime} {(userData?.currentRole?.toLowerCase() === "admin") ? (message.isMine) ? `| ${names?.tenant} (Tenant)` : `| ${names?.landlord} (Landlord)` : ""}
                                  </span>
                                  <p className="full-message">
                                    {message.message}
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </InView>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Messages;