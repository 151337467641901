import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { dateFormatForBE } from './common';

export default function FormikFormDateTimePicker(props: any) {
  const { disabled, min, max, label, outFormat } = props;
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} className="dateTimePicker">
          <DateTimePicker
            {...props}
            renderInput={(params: any) => <TextField {...params} />}
            label={label ? label : ''}
            value={field.value ? dayjs(field.value) : null}
            ampm={false}
            views={['year', 'day']}
            inputFormat={props.inputFormat ? props.inputFormat : dateFormatForBE}
            onChange={(newValue: any) => {
              if (outFormat?.length) {
                newValue = dayjs(newValue).format(outFormat);
              }
              helpers.setValue(newValue);
              if(props?.setHandler){
                props.setHandler();
              }
            }}
            minDateTime={min ? dayjs(min) : undefined}
            maxDateTime={max ? dayjs(max) : undefined}
            disabled={props.disabled}
          />
        </Stack>
      </LocalizationProvider>
      {/* {/ <FormikFormErrorHandler intl={props.intl} meta={meta} /> /} */}
      {touched && error && (
        <span data-testid="testError" className="text-danger errorMessage">
          {error}
        </span>
      )}
      {/* { 
        (error && <span data-testid="testError" className="errMessageRange text-danger errorMessage">{formatMessage(error)}</span>)} */}
    </>
  );
}
