import React, { useEffect, useRef, useState } from 'react';
import {
  CropperRef,
  Cropper,
  RectangleStencil,
  CircleStencil,
  Coordinates,
  CropperPreview,
  CropperPreviewRef,
  Priority,
  ImageRestriction,
  FixedCropper,
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import './ImageEditor.scss';
import { MdCropFree, MdFlip, MdRefresh } from 'react-icons/md';
import { RxReset } from 'react-icons/rx';
import { dataURItoBlob, imageUrlToBase64 } from './ImageEditorHelper';
import { MdOutlineFileUpload } from 'react-icons/md';
import { FaRegSave } from 'react-icons/fa';
import { showToastMessage, ToastType } from '../ToastOptionCommon';
import validator from 'validator';

export type StencilType = 'Rectangle' | 'Circle';

interface IProps {
  stencil?: StencilType;
  imgSrc: any;
  submitHandler: (file: Blob) => void;
}

const getStencil = (stencilType?: StencilType) => {
  if (stencilType) {
    if (stencilType === 'Rectangle') {
      return RectangleStencil;
    } else if (stencilType === 'Circle') {
      return CircleStencil;
    }
  }
  return RectangleStencil;
};

const ImageEditor = (props: IProps) => {
  const [src, setSrc] = useState<any>(null);
  const loadSrc = useRef(src);
  // const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  // const [image, setImage] = useState<string>();
  const fileRef = useRef<any>(null);
  const [file, setFile] = useState<Array<any>>([]);
  // const [imgData, setImgData] = useState<any>(null);
  const cropperRef = useRef<CropperRef | any>(null);
  const previewRef = useRef<CropperPreviewRef>(null);
  const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  useEffect(() => {
    if (file.length > 0) {
      if (!acceptedImageTypes.includes(file[0].type)) {
        showToastMessage(
          'Please select supported image format (ie. .jpg, .jpeg, .png)',
          ToastType.Error
        );
        return;
      }
      setSrc(URL.createObjectURL(file[0]));
    }
  }, [file]);

  const getImageData = async (url: string) => {
    const d = await imageUrlToBase64(url);
    setSrc(d);
  };

  useEffect(() => {
    if (props?.imgSrc && validator.isURL(props.imgSrc)) {
      getImageData(props.imgSrc);
    }
  }, [props.imgSrc]);

  const flip = (horizontal: boolean, vertical: boolean) => {
    if (cropperRef.current) {
      cropperRef.current.flipImage(horizontal, vertical);
    }
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      cropperRef.current.rotateImage(angle);
    }
  };

  // const onCrop = () => {
  //   if (cropperRef.current) {
  //     // setCoordinates(cropperRef.current.getCoordinates());
  //     // setSrc(data);
  //     const data = cropperRef.current.getCanvas()?.toDataURL();
  //     // setImgData(data);
  //   }
  // };

  const onSave = () => {
    if (cropperRef.current) {
      // setCoordinates(cropperRef.current.getCoordinates());
      // setSrc(data);
      const data = cropperRef.current.getCanvas()?.toDataURL();
      // setImgData(data);
      props.submitHandler(dataURItoBlob(data));
    }
  };

  const onUpdate = (cropper: CropperRef) => {
    previewRef.current?.update(cropper);
  };

  return (
    <div className="img-edit-container">
      {/* <div className='cropper_inner'> */}
      <Cropper
        ref={cropperRef}
        stencilComponent={getStencil(props.stencil)}
        imageRestriction={ImageRestriction.stencil}
        // crossOrigin="anonymous"
        // stencilSize={{
        //   width: 280,
        //   height: 280,
        // }}
        stencilProps={{
          // aspectRatio: 6/9,
          movable: true,
          resizable: true,
          grid: true,
        }}
        //     defaultCoordinates={{
        //         left: 100,
        //         top: 100,
        //         width: 400,
        //         height: 400,
        // }}
        priority={Priority.visibleArea}
        src={src}
        onUpdate={onUpdate}
      />
      {/* </div> */}
      <div className="vertical-buttons">
        <div className="icon_name">
          <button
            onClick={() => flip(true, false)}
            className="square-button"
            title="Flip Horizontal"
          >
            <MdFlip />
          </button>
          <div>Flip Horizontal</div>
        </div>
        <div className="icon_name">
          <button
            onClick={() => flip(false, true)}
            className="square-button"
            title="Flip Vertical"
          >
            <MdFlip className="rotate-90" />
          </button>
          <div>Flip Vertical</div>
        </div>
        <div className="icon_name">
          <button
            onClick={() => rotate(90)}
            className="square-button"
            title="Rotate Clockwise"
          >
            <MdRefresh />
          </button>
          <div>Rotate Clockwise</div>
        </div>
        <div className="icon_name">
          <button
            onClick={() => rotate(-90)}
            className="square-button"
            title="Rotate Counter-Clockwise"
          >
            <MdRefresh className="flip-horizontal" />
          </button>
          <div>Rotate Anti-Clockwise</div>
        </div>
        {/* <div className="icon_name">
          <button onClick={onCrop} className="square-button" title="Crop">
            <MdCropFree />
          </button>
          <div>Crop</div>
        </div> */}
        <div className="icon_name">
          <button
            onClick={() => {
              setSrc(loadSrc.current);
              setFile([]);
              fileRef.current.value = null;
            }}
            className="square-button"
            title="Reset"
          >
            <RxReset />
          </button>
          <div>Reset</div>
        </div>
        <div className="icon_name">
          <button
            onClick={() => {
              fileRef.current?.click();
            }}
            className="square-button"
            title="Upload"
          >
            <MdOutlineFileUpload />
          </button>
          <div>Upload</div>
        </div>
        <div className="icon_name">
          <button onClick={onSave} className="square-button" title="Save">
            <FaRegSave />
          </button>
          <div>Save</div>
        </div>
        <input
          style={{ display: 'none' }}
          ref={fileRef}
          onChange={(e: any) => {
            setFile(e.target.files);
          }}
          type="file"
          name="userPic"
          accept=".png,.jpg,.jpeg"
        />
      </div>
      {/* <div className="preview_container">
        <CropperPreview ref={previewRef} className="preview" />
      </div> */}
    </div>
  );
};

export default ImageEditor;
