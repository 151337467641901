import React, { useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { formatPhoneNumber } from './common';

interface IProps {
  children: React.ReactNode;
  className: string;
  name: string;
  onBlur: () => void;
  onChange: (e: any) => void;
  placeholder: string;
  value: any;
  type: string;
  disabled: boolean;
  readOnly: boolean;
  maxLength: number;
}

const FormikFormPhone = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? 'error' : ''}`;
  const _placeholder = props.placeholder;
  useEffect(() => {
    if (field.value?.length > props.maxLength) {
      helpers.setValue(field.value.slice(0, props.maxLength));
    }
  }, [field.value, helpers, props.maxLength]);

  return (
    <>
      <Input
        {...field}
        {...props}
        onChange={(event) => {
                if (event) {
                  // if (event.length > props.maxLength) {
                  //   event = event.slice(0, props.maxLength);
                  // }
                  helpers.setValue(formatPhoneNumber(event??""));
                }
              }
        }
        defaultCountry={'US'}
        countries={['US']}
      />
      {meta.touched && meta.error && (
        <span className="text-danger">{meta.error}</span>
      )}
    </>
  );
};

export default FormikFormPhone;
