import { ErrorMessage, Field, FieldArrayRenderProps } from 'formik';
import FormikFieldLabelAutoComplete from '../../../../utils/FormikFieldLabelAutoComplete';
import Input from '../Input/Input';

function LabelDistanceEducationComponent({
  props,
  arrayHelpers,
}: {
  props: any;
  arrayHelpers: FieldArrayRenderProps;
}) {
  let details = {
    address: '',
    name: '',
    distance: '',
  };

  return (
    <div>
      {props.values.nearBy.propertyEducationDetails?.map(
        (_: any, index: number) => {
          return (
            <div key={index} className="row align-items-end mt-3">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <Field
                      type="text"
                      name={`nearBy.propertyEducationDetails[${index}].address`}
                      placeholder=""
                      autoComplete="off"
                      className={`form-control `}
                      label="Address"
                      addressType="primary_school,school,secondary_school,university"
                      as={Input}
                    />
                    <ErrorMessage
                      component="div"
                      name={`nearBy.propertyEducationDetails[${index}].address`}
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="row  mt-2">
                  <div className="col-7">
                    <Field
                      type="text"
                      name={`nearBy.propertyEducationDetails[${index}].name`}
                      placeholder=""
                      autoComplete="off"
                      className={`form-control`}
                      label="Name"
                      addressType="primary_school,school,secondary_school,university"
                      as={Input}
                    />
                    <ErrorMessage
                      component="div"
                      name={`nearBy.propertyEducationDetails[${index}].name`}
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-3 px-0">
                    <Field
                      type="text"
                      name={`nearBy.propertyEducationDetails[${index}].distance`}
                      placeholder=""
                      autoComplete="off"
                      className={`form-control 
              `}
                      label="Miles"
                      as={Input}
                    />
                    <ErrorMessage
                      component="div"
                      name={`nearBy.propertyEducationDetails[${index}].distance`}
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-2">
                    {index ===
                      props.values.nearBy.propertyEducationDetails?.length -
                        1 && (
                      <div className="col-3">
                        <div className="d-flex align-items-end gap-2 pb-2" style={{marginTop: '36px'}}>
                          {props.values.nearBy.propertyEducationDetails
                            ?.length > 1 ? (
                            <button
                              className="rounded-full bg-light border d-flex align-items-center justify-content-center rounded-5 fs-4"
                              style={{
                                width: '20px',
                                height: '20px',
                                paddingBottom: '6px',
                              }}
                              onClick={() => {
                                if (
                                  props.values.nearBy.propertyEducationDetails
                                    ?.length >= 2
                                ) {
                                  arrayHelpers.remove(index);
                                } else {
                                  props.values.nearBy.propertyEducationDetails[0] =
                                    details;
                                  props.values.nearBy.propertyEducation = false;
                                }
                              }}
                            >
                              -
                            </button>
                          ) : null}

                          <button
                            className="rounded-full bg-light border d-flex align-items-center justify-content-center rounded-5 fs-6"
                            style={{
                              width: '20px',
                              height: '20px',
                              paddingBottom: '2px',
                            }}
                            onClick={() => arrayHelpers.push(details)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

export default LabelDistanceEducationComponent;
