import React, { useEffect, useState } from 'react';
import './LocationComponent.scss';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { setLocationListing } from '../../../redux/slices/CreateLisingSlice';
import CustomMapComponent from '../../ListingsComponents/CustomMapComponent';
import FormikFieldAutoComplete from '../../../utils/FormikFieldAutoComplete';
import DisableSection from '../../../utils/DisableSection/DisableSection';
import { ListingSection } from '../../../constants/ListingSection';
import { BsPencilFill } from 'react-icons/bs';
import { ListingLocationSchema } from '../../../utils/yupSchemas/CreateListingSchema';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { setFormDirty } from '../../../redux/slices/LocationSlice';

function LocationComponent({
  isEdit,
  handleSubmit,
  currentSection,
  setCurrentSection,
  initialValues,
  savedSection,
}: {
  isEdit?: boolean;
  handleSubmit: (formData: FormData) => void;
  currentSection: ListingSection;
  setCurrentSection: any;
  initialValues: any;
  savedSection?: any;
}) {
  const dispatch = useDispatch();

  const submitHandler = (values: any) => {
    let newProperty: any = {
      section: values.section,
    };
    const formData = new FormData();
    const data = { ...values.location };
    newProperty = {
      ...newProperty,
      address: {
        street: data.street,
        city: data.city,
        state: data.state,
        zip: data.zip,
        latitude: data.latitude ?? '',
        longitude: data.longitude ?? '',
      },
    };
    // Create Form
    createMultipartFormData(newProperty, formData);
    handleSubmit(formData);
    dispatch(setLocationListing({ address: newProperty?.address }));
  };

  const FormObject = () => {
    const { values, setFieldValue, dirty, isSubmitting } = useFormikContext<any>();

    useEffect(() => {
      if (dirty && !isSubmitting) {
        dispatch(setFormDirty(dirty));
      }
    }, [dirty, isSubmitting]);

    useEffect(() => {
      if (
        values.location.street ||
        values.location.city ||
        values.location.state ||
        values.location.zip
      ) {
        setFieldValue('location.street', values.location.street);
        setFieldValue('location.city', values.location.city);
        setFieldValue('location.state', values.location.state);
        setFieldValue('location.zip', values.location.zip);
      }
    }, [
      values.location.street,
      values.location.city,
      values.location.state,
      values.location.zip,
    ]);
    return null;
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        section: ListingSection.Location,
      }}
      validationSchema={ListingLocationSchema}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      {({ values, setFieldValue, touched, errors, resetForm }: any) => (
        <Form>
          <FormObject />
          <div id="location">
            {currentSection !== ListingSection.Location && <DisableSection />}
            <div className="box-view">
              <h3 className="box-title">Location</h3>
              {(isEdit || savedSection[ListingSection.Location]) && (
                <div title="Edit" className="edit-property">
                  {currentSection !== ListingSection.Location && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(ListingSection.Location);
                      }}
                    >
                      <BsPencilFill className="pencil_icon" />
                    </button>
                  )}
                  {currentSection === ListingSection.Location && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(-1);
                        resetForm();
                      }}
                    >
                      <IoCloseCircleOutline className="cancel_icon" />
                    </button>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="street">
                    Address
                  </label>
                  <Field
                    // type="text"
                    setFieldValue={setFieldValue}
                    name="location.street"
                    placeholder=""
                    autoComplete="off"
                    className={`form-control ${
                      touched.street && errors.street ? 'is-invalid' : ''
                    }`}
                    as={FormikFieldAutoComplete}
                  />
                  {/* </LoadScriptNext> */}
                  {/* <ErrorMessage
              component="div"
              name="street"
              className="invalid-feedback"
            /> */}
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                  <label className="form-label" htmlFor="city">
                    City
                  </label>
                  <Field
                    type="text"
                    name="location.city"
                    placeholder=""
                    // autoComplete="off"
                    className={`form-control ${
                      touched.city && errors.city ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="location.city"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                  <label className="form-label" htmlFor="state">
                    State
                  </label>
                  <Field
                    type="text"
                    name="location.state"
                    placeholder=""
                    // autoComplete="off"
                    className={`form-control ${
                      touched.state && errors.state ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="location.state"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                  <label className="form-label" htmlFor="zip">
                    Zip
                  </label>
                  <Field
                    type="number"
                    name="location.zip"
                    placeholder=""
                    // autoComplete="off"
                    className={`form-control ${
                      touched.zip && errors.zip ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="location.zip"
                    className="invalid-feedback"
                  />
                </div>
                {/* <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}` ?? ''} libraries={["places"]}> */}
                <div className="h-418px" style={{ width: '100%' }}>
                  <CustomMapComponent
                    mapClassName={''}
                    markers={[
                      {
                        lat: values.location.latitude
                          ? parseFloat(values.location.latitude)
                          : values.location.latitude,
                        lng: values.location.longitude
                          ? parseFloat(values.location.longitude)
                          : values.location.longitude,
                        propertyName: '',
                      },
                    ]}
                  />
                </div>
                {/* </LoadScript> */}
                <div id="amenitiesTab" className="row my-3">
                  {isEdit && currentSection === ListingSection.Location && (
                    <div className="col-12 text-center">
                      <a href="#createListingTab">
                        <div
                          // href="#create-listing"
                          className="btn btn-outline-primary me-2"
                          onClick={() =>
                            setCurrentSection(ListingSection.Details)
                          }
                        >
                          Previous
                        </div>
                      </a>
                      <a href="#amenitiesTab">
                        <button type="submit" className="btn btn-primary">
                          Update
                        </button>
                      </a>
                    </div>
                  )}
                  {!isEdit && currentSection === ListingSection.Location && (
                    <div className="col-12 text-center">
                      <a href="#createListingTab">
                        <div
                          // href="#create-listing"
                          className="btn btn-outline-primary me-2"
                          onClick={() =>
                            setCurrentSection(ListingSection.Details)
                          }
                        >
                          Previous
                        </div>
                      </a>
                      <a href="#amenitiesTab">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   handleNext();
                          //   // submit?.click();
                          // }}
                        >
                          Next
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LocationComponent;
