import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import './ErrorPage.scss';

type errorJsx = {
  status: number;
  messageHeading: string;
  messageBody: string;
};

function ErrorPage({
  error = {
    status: 404,
    messageHeading: "We can't find that page",
    messageBody:
      "Sorry, the page you are looking for doesn't exist or has been moved.",
  },
}: {
  error?: errorJsx;
}) {
  return (
    <div className="layout">
      <h1>🚧 Page Not Found 🚧</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <p>
        Please check the URL or return to the <a href="/">homepage</a>.
      </p>
      {/* <div className="my-12 flex items-center justify-center px-2 md:my-24 md:px-0">
        <div className="lg:flex lg:items-center lg:space-x-10">
          <img
            src="https://illustrations.popsy.co/white/looking-at-the-map.svg"
            alt="question-mark"
            className="h-[300px] w-auto"
          />
          <div>
            <p className="mt-6 text-sm font-semibold text-black">
              {error.status} error
            </p>
            <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
              {error.messageHeading}
            </h1>
            <p className="mt-4 text-gray-500">{error.messageBody}</p>
            <div className="mt-6 flex items-center space-x-3">
              <NavLink
                to="/"
                className="inline-flex items-center rounded-md border border-black px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
              >
                <BsArrowLeft size={16} className="mr-2" />
                Go back
              </NavLink>
              <NavLink
                to="/contact-us"
                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
              >
                Contact us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <hr /> */}
    </div>
  );
}

export default ErrorPage;
