import React from "react";
import Robot from "../../assets/gif/robot.gif";
// import './Styles/Welcome.scss';

const Welcome = ({ currentUser }) => {
  return (
    <div className="col-8">
      {/* <img src={Robot} alt="Welcome" /> */}
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <h1>
            Welcome, <span>{currentUser?.firstName}!</span>
          </h1>
          <h3>Please select a chat to start messaging.</h3>
          <span>Your chat is end to end encrypted with us.</span>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
