import { useNavigate, useParams } from 'react-router';
import './PaymentPage.scss';
// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { resetStatusData, sendPaymentStatus } from '../../../redux/slices/PaymentSlice';
// import { AppDispatch } from '../../../redux/store';

const PaymentSuccess = () => {
  // const params = useParams();
  // const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  // const payment = useSelector((state: any) => state.payment);

  // useEffect(() => {
  //   if (params && params?.sessionId) {
  //     dispatch(sendPaymentStatus({ sessionId: params.sessionId }));
  //   }
  // }, [params]);

  // useEffect(() => {
  //   if (payment.success) {
  //       dispatch(resetStatusData());
  //   }
  // }, [payment.success]);

  return (
      <div className="alert-box success shadow-sm text-center bg-white p-5 rounded-3 border">
        <svg className='shadow-sm' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.93-148.21 29.92-69.37 81.22-120.68t120.65-81.25Q401.15-860 480-860q63.2 0 119.6 19 56.4 19 103.48 53l-43.39 44.39q-38.77-26.7-83.99-41.54Q530.48-800 480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-21.08-2.77-41.69t-8.31-40.08l48.46-48.84q11 30.84 16.81 63.46Q860-514.54 860-480q0 78.85-29.92 148.2t-81.21 120.65q-51.29 51.3-120.63 81.22Q558.9-100 480.07-100Zm-56.84-209.85L267.08-466l42.15-42.15 114 114 394.62-395.23L860-747.23 423.23-309.85Z" /></svg>
        <h5>Your payment was successful </h5>
        <button className='btn btn-primary' onClick={() => {
          navigate('/dashboard');
        }}>Dashboard</button>
      </div>
  );
};

export default PaymentSuccess;
