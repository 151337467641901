import './ViewCEUs.scss';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../../../../../components/CommonComponents/Loader/Loader';
import {
  ceusInitialState,
  selectCeusById,
  resetAddCeusSuccess,
  resetGetCeusFilledDetail,
  resetAddCeusError,
  updateCeus,
  addCeus,
} from '../../../../../redux/slices/DocumentSlice';
import { Link } from 'react-router-dom';
import FormikFormInput from '../../../../../utils/FormikFormInput';
import {
  ToastType,
  showToastMessage,
} from '../../../../../utils/ToastOptionCommon';
import { useStateCity } from '../../../../../hooks/useStateCity';
import FormikFormSelectField from '../../../../../utils/FormikFormSelect';
import { ceusSchema } from '../../../../../utils/yupSchemas/CeusSchema';
import DocumentViewModal from '../../../../../components/PropertyDetail/DocumentViewModal/DocumentViewModal';
import DocumentPopupHOC from '../../../../../components/PropertyDetail/DocumentViewModal/hoc/DocumentPopupHoc';

const ViewCEUs = (props: {
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { states, stateLoading } = useStateCity({ state: '' });
  const [allState, setAllState] = useState<any>([]);
  const [fileOnEdit, setFileOnEdit] = useState<boolean>(false);
  const [showFileError, setshowFileError] = useState({
    isError: false,
    msg: '',
  });

  useEffect(() => {
    if (states?.length) {
      const state = states?.map((e: string) => {
        return { value: e, label: e };
      });
      setAllState(state);
    }
  }, [states]);

  const addCeusDetail = useSelector(
    (state: any) => state.document.addCeusDetail
  );
  const initialState = useSelector(ceusInitialState);
  const ceusDetail = useSelector(selectCeusById);

  const [files, setFiles] = useState<any>([]);
  const acceptedFileTypes = ['application/pdf'];

  useEffect(() => {
    if (showFileError && files?.length > 0) {
      checkSubmmiting();
    }
  }, [files]);

  useEffect(() => {
    if (addCeusDetail.error) {
      showToastMessage(addCeusDetail.error, ToastType.Error);
      dispatch(resetAddCeusError());
    }
  }, [addCeusDetail.error]);

  useEffect(() => {
    if (addCeusDetail.success) {
      if (ceusDetail.isEdit) {
        showToastMessage('CEUs updated successfully', ToastType.Success);
      } else {
        showToastMessage('CEUs added successfully', ToastType.Success);
      }
      navigate(-1);
      dispatch(resetAddCeusSuccess());
      dispatch(resetGetCeusFilledDetail());
    }
  }, [addCeusDetail.success]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    if (showFileError.isError) {
      setSubmitting(false);
      return;
    }
    const formData = new FormData();
    formData.append('ceus_document', files?.[0]);
    formData.append('state', values.state);
    formData.append('total', values.total);
    formData.append('required', values.required);
    formData.append('year', values.year);
    if (ceusDetail.isEdit) {
      dispatch(
        updateCeus({
          id: ceusDetail.id,
          body: formData,
        }) as unknown as UnknownAction
      );
    } else {
      dispatch(addCeus({ body: formData }) as unknown as UnknownAction);
    }
  };

  const checkSubmmiting = () => {
    if (
      (!ceusDetail.isEdit && files?.length === 0) ||
      (ceusDetail.isEdit && fileOnEdit && files?.length === 0)
    ) {
      setshowFileError({
        isError: true,
        msg: 'Please provide atleast one document',
      });
    } else if (
      files?.length > 0 &&
      !acceptedFileTypes?.includes(files?.[0]?.type)
    ) {
      setshowFileError({
        isError: true,
        msg: 'Please select supported file format (ie. .pdf)',
      });
      setFiles(() => []);
    } else {
      setshowFileError({ isError: false, msg: '' });
    }
  };

  const getDocName = (str: string) => {
    const x = str.split('/');
    return x[x?.length - 1];
  };

  return (
    <>
      {props.showDocumentPopup && (
        <DocumentViewModal
          onModalClosed={() => props.documentToggleHandler()}
          fileUrl={initialState?.docLink}
        />
      )}
      <div className="main-content">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>CEUs (Continuing Education Units)</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/CEUs">CEUs</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View CEUs
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {ceusDetail.loading ? (
          <Loader isSmallLoader={true} />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            onSubmit={() => {}}
          >
            {({ values, isSubmitting }) => (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="box-view shadow-sm mb-5 h-auto">
                    <Form
                      className="user-form space-y-6 text-deep_gray"
                      noValidate
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium leading-6 "
                        >
                          State :
                        </label>
                        <br />
                        {values?.state ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="total"
                          className="block text-sm font-medium leading-6 "
                        >
                          How many CUEs do you have for this state? :
                        </label>
                        <br />
                        {values?.total ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="year"
                          className="block text-sm font-medium leading-6 "
                        >
                          How many CUEs do you need for this state? :
                        </label>
                        <br />
                        {values?.required ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="year"
                          className="block text-sm font-medium leading-6 "
                        >
                          Year :
                        </label>
                        <br />
                        {values?.year ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label>
                          Document :
                          <br />
                        </label>
                        <br />
                        <span
                          className="old_doc_span"
                          onClick={() => props.documentToggleHandler()}
                        >
                          {getDocName(values?.document_url)}
                          <svg
                            height="20"
                            viewBox="0 0 24 24"
                            width="20"
                            className="cursor-pointer"
                          >
                            <path d="m10 3v2h-5v14h14v-5h2v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-16c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071v-16c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289zm7.586 2h-4.586v-2h8v8h-2v-4.586l-7 7-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default DocumentPopupHOC(ViewCEUs);
