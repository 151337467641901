import React from 'react';
import { Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import ImageEditor, { StencilType } from '../ImageEditor';

interface IProps {
  showMe: () => void;
  gridType?: StencilType;
  src: any;
  submitHandler: (file: Blob) => void;
}

const ImageEditorPopup = (props: IProps) => {
  return (
    <>
      <Modal
        className="backdrop-blur-sm blue-theme modal-lg z-40 float-right ml-auto mr-0 image-editor"
        show={true}
        // size={'xl'}
      >
        <Modal.Header className="justify-between pb-0 bg-white border-none">
          <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
            Image Editor
          </Modal.Title>
          <div
            onClick={() => {
              props.showMe();
            }}
            className="icons8-close"
          >
            <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
            <ImageEditor submitHandler={props.submitHandler} stencil={props.gridType} imgSrc={props.src} />
          </div>
        </Modal.Body>
      </Modal>{' '}
    </>
  );
};

export default ImageEditorPopup;
