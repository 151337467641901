import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import './Subscription.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { Link } from 'react-router-dom';
import { getAllPackages } from '../../../redux/slices/PackageSlice';
import PaymentPopup from '../../../components/CommonComponents/PaymentPage/PaymentPopup';
import ShowPaymentPageHOC from '../../../components/CommonComponents/PaymentPage/hoc/ShowPaymentPageHOC';
import Loader from '../../../components/CommonComponents/Loader/Loader';

interface IPorps {
  submitPackage: () => void;
  showPaymentPopup: boolean;
  showPaymentHandler: () => void;
}

const Subscription = (props: IPorps) => {
  const packages = useSelector((state: any) => state.packages);
  const dispatch = useDispatch<AppDispatch>();
  const [pkgId, setPkgId] = useState('');

  useEffect(() => {
    dispatch(getAllPackages({}));
  }, []);

  const clickHandler = (id: string) => {
    setPkgId(id);
    props.showPaymentHandler();
  };

  return (
    <>
      {props.showPaymentPopup && (
        <PaymentPopup showMe={props.showPaymentHandler} pkgId={pkgId} />
      )}
      <div className="main-content">
        <DashboardHeader />
        <div className="below-header">
          <div className="row align-items-end">
            <div className="col-sm-6">
              <h1>Package</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Package
                  </li>
                </ol>
              </nav>
            </div>
            {!packages.isLoading && packages.data?.plan?.isActive && (
              <div className="col-sm-6">
                {packages.data?.plan?.leftDays && (
                  <p className="renew-alert text-sm-end">
                    Expires in {packages.data?.plan?.leftDays} Day(s)
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="subscription">
          <div className="row">
            {packages.isLoading && <Loader isSmallLoader={true} />}
            {!packages.isLoading &&
              packages?.data?.packages?.map((e: any) => {
                const price =
                  e.currency === 'usd' ? `$${e.price}` : `${e.price}`;
                return (
                  <div key={e._id} className="col-md-4">
                    <div className="subscription-card">
                      <h3 className="plan-price">{price}</h3>
                      <h4 className="plan-name">{e.name}</h4>
                      <ul>
                        <li>{e.items?.propertyListing} Property Listings</li>
                        <li>{e.items?.days} Days Availability</li>
                        {e.items?.featureProperty ? (
                          <li>Feature Properties</li>
                        ) : (
                          <li>
                            <s>Feature Properties</s>
                          </li>
                        )}
                        {e.items?.support ? (
                          <li>Limited Support</li>
                        ) : (
                          <li>
                            <s>Limited Support</s>
                          </li>
                        )}
                      </ul>
                      {packages.data?.plan?.isActive &&
                      packages.data?.plan?.id === e._id ? (
                        <h4>Subscribed!</h4>
                      ) : (
                        <button
                          onClick={() => clickHandler(e._id)}
                          className="btn btn-primary"
                        >
                          Buy
                        </button>
                        // <PaymentPage
                        //   pkgId={e._id}
                        //   handleSubmit={handleSubmit}
                        // />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowPaymentPageHOC(Subscription);
