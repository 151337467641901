import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import Avatar from '../../../assets/images/avatar.png';
import './DashboardHeader.scss';
import {
  getSubscription,
  resetFetchData,
  resetUserData,
  setUserData,
} from '../../../redux/slices/LoginSlice';
import ConfirmationPopup from '../../../utils/ConfirmationPopup/ConfirmationPopup';
import axiosCall from '../../../utils/axiosCall';
import {
  showToastMessage,
  toastDefaults,
  ToastType,
} from '../../../utils/ToastOptionCommon';
import { toast } from 'react-toastify';
import { usePermissions } from '../../../utils/Permission/Permission';
import Loader from '../../CommonComponents/Loader/Loader';
import { AppDispatch } from '../../../redux/store';
import {
  getSubscriptionText,
} from '../../../constants/Subscription';
import { useSocket } from '../../../context/SocketProvider';
import { removeUser, setUser } from '../../../redux/slices/UserSlice';
import ImageEditorHOC from '../../../utils/ImageEditor/hoc/ImageEditorHOC';
import ImageEditorPopup from '../../../utils/ImageEditor/hoc/ImageEditorPopup';

type IProps = {
  hideListingBtn?: boolean;
  showPopup: boolean;
  showHandler: () => void;
};

function DashboardHeader(props: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const { userData, isSubscription } = useSelector((state: any) => state.login);
  const [doLogout, setDoLogout] = useState(false);
  const [totalNotificationCount, setTotalNotificationCount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const userImage = userData?.profileImage?.length
    ? userData?.profileImage
    : Avatar;
  const socket = useSocket();

  useEffect(() => {
    socket.on('update-total-notification-count', (res: any) => {
      setTotalNotificationCount(res);
    });

    socket.on('dashboard-notifications', (res: any) => {
      // console.log('dashboard-notifications:::::::', res);
      setTotalNotificationCount(res?.totalcount);
    });

    return () => {
      socket.off('update-total-notification-count');
      socket.off('dashboard-notifications');
    };
  }, [socket]);

  useEffect(() => {
    dispatch(getSubscription({}));
  }, []);

  useEffect(() => {
    if (isSubscription) {
      dispatch(resetFetchData());
    }
  }, [isSubscription]);

  const handleLogout = async () => {
    try {
      setLoading(true);
      const userID = userData?._id;
      dispatch(resetUserData(null));
      dispatch(removeUser());
      localStorage.clear();
      socket.emit('logout-me', userData._id);
      setDoLogout(false);
      navigate('/');
      await axiosCall.post('/auth/logout/' + userID);
    } catch (error) {
      toast.error('Some error occured while Logout', toastDefaults());
      console.error('Logout failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileFormSubmit = async (file: Blob) => {
    setLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }

    try {
      const url = '/user/updateUserProfilePic';
      const response = await axiosCall.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.status === 200) {
        const user = response?.data?.user;
        const data = {
          ...userData,
          firstName: user?.firstName,
          lastName: user?.lastName,
          userEmail: user?.userEmail,
          phoneNo: user?.phoneNo,
          role: user?.role,
          address: user?.address,
          aboutUser: user?.aboutUser,
          profileImage: user?.profileImage,
          isEmailVerified: user?.isEmailVerified,
          occupation: user?.occupation,
          keepPhoneNumberPrivate: user?.keepPhoneNumberPrivate,
          keepEmailPrivate: user?.keepEmailPrivate,
          messageNotification: user?.messageNotification,
        };
        dispatch(setUserData(data));
        dispatch(setUser(data));
        localStorage.setItem('user', JSON.stringify(data));
        showToastMessage('Profile picture updated successfully', ToastType.Success);
        // close popup
        props.showHandler();
      } else {
        showToastMessage('Could not update the user', ToastType.Error);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('User update failed...', ToastType.Error);
    } finally {
      setLoading(false);
    }
  };

  const updateImageHandler = async (file: Blob) => {
    await handleProfileFormSubmit(file);
  };

  const switchUserRole = (currentRole: string) => {
    const role = currentRole === 'Tenant' ? 'Landlord' : 'Tenant';
    const userDetail = JSON.parse(localStorage.getItem('user') ?? '');
    userDetail.currentRole = role;
    localStorage.setItem('user', JSON.stringify(userDetail));
    dispatch(setUserData(userDetail));
  };

  if (loading) {
    return <Loader />;
  }

  if (doLogout)
    return (
      <ConfirmationPopup
        onModalClose={() => setDoLogout(false)}
        onSubmit={() => {
          handleLogout();
        }}
        confirmationText="Are you sure you want to log out?"
        saveButtonText="Yes"
        cancelButtonText="No"
        modalTitle="Logout"
        isProgress={loading}
      />
    );

  return (
    <>
      {props.showPopup && (
        <ImageEditorPopup
          src={userImage}
          showMe={props.showHandler}
          submitHandler={updateImageHandler}
          gridType="Circle"
        />
      )}
      <div className="d-flex bg-white px-2 px-sm-3 py-2 border">
        <div className="d-flex align-items-center w-auto">
          <button
            className="btn border-0 p-0 ps-sm-2 d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#FHL-Sidebar-menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M140.001-254.616v-59.999h679.998v59.999H140.001Zm0-195.385v-59.998h679.998v59.998H140.001Zm0-195.384v-59.999h679.998v59.999H140.001Z" />
            </svg>
          </button>
          {/* <input
          type="text"
          placeholder=""
          className="form-control w-100"
          style={{ height: '40px' }}
        /> */}
          {hasPermission('landlord') ? (
            <NavLink
              to="/dashboard/Package"
              className="py-2 px-3 d-flex d-none d-lg-block"
            >
              {getSubscriptionText(userData?.subscription?.status).replace(
                '{LeftDays}',
                userData?.subscription?.leftDays
              )}
            </NavLink>
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center gap-4 ms-auto">
          {hasPermission('admin.landlord') && !props.hideListingBtn ? (
            <NavLink
              to="/dashboard/add-listing"
              className="btn btn-outline-primary py-2 px-2 px-sm-3 d-flex"
            >
              <svg
                className="me-2 d-none d-sm-block"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="#034991"
              >
                <path d="M450.001-290.001h59.998v-160h160v-59.998h-160v-160h-59.998v160h-160v59.998h160v160Zm30.066 190q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
              Create Listing
            </NavLink>
          ) : (
            <></>
          )}

          {/* <button type="button" className="btn p-0 position-relative">
          <svg height="30" viewBox="0 -960 960 960" width="30" fill="#858d9d">
            <path d="M172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H172.309ZM480-457.694 160-662.309v410q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h615.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-410L480-457.694ZM480-520l313.846-200H166.154L480-520ZM160-662.309V-720-252.309q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462H160v-422.309Z" />
          </svg>
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            0
          </span>
        </button>

        <button type="button" className="btn p-0 position-relative">
          <svg height="30" viewBox="0 -960 960 960" width="30" fill="#858d9d">
            <path d="M180.001-204.616v-59.998h72.308v-298.463q0-80.692 49.807-142.692 49.808-62 127.885-79.307v-24.923q0-20.833 14.57-35.416 14.57-14.584 35.384-14.584t35.429 14.584q14.615 14.583 14.615 35.416v24.923q78.077 17.307 127.885 79.307 49.807 62 49.807 142.692v298.463h72.308v59.998H180.001ZM480-497.692Zm-.068 405.383q-29.855 0-51.047-21.24-21.192-21.24-21.192-51.067h144.614q0 29.923-21.26 51.115-21.26 21.192-51.115 21.192ZM312.307-264.614h335.386v-298.463q0-69.462-49.116-118.577Q549.462-730.77 480-730.77q-69.462 0-118.577 49.116-49.116 49.115-49.116 118.577v298.463Z" />
          </svg>
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
            {totalNotificationCount}
          </span>
        </button> */}

          <div className="d-flex align-items-center gap-2">
            <div className="admin-img" onClick={props.showHandler}>
              <img src={userImage} alt="avatar" className="img-fluid w-100" />
            </div>
            {/* <RiImageEditFill color="red" /> */}
            <div className="text-left">
              <h6 className="mb-0">
                {userData?.firstName} {userData?.lastName}
              </h6>
              <span className="fw-normal float-start">
                {userData?.currentRole}
              </span>
            </div>
          </div>

          <div className="dropdown h-100">
            <button
              className="btn h-100 d-flex justify-content-center align-items-center gap-3 p-0 border-0 dropdown-toggle border-start ps-3 rounded-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul className="dropdown-menu">
              {userData?.role !== undefined && userData?.role?.length > 1 ? (
                <li
                  onClick={() => switchUserRole(userData?.currentRole)}
                  className="dropdown-item"
                >
                  Switch to{' '}
                  {userData?.currentRole === 'Tenant' ? 'Landlord' : 'Tenant'}
                </li>
              ) : (
                <></>
              )}
              <li>
                <NavLink to="/dashboard/setting" className="dropdown-item">
                  Profile
                </NavLink>
                {/* <a href="/dashboard/setting" className="dropdown-item">
                Profile
              </a> */}
              </li>
              <li>
                <a onClick={() => setDoLogout(true)} className="dropdown-item">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageEditorHOC(DashboardHeader);
