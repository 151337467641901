import { useState } from 'react';

const ShowPaymentPageHOC = (Component: any) =>
  function Comp(props: any) {
    const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);

    const showPaymentHandler = () => {
      setShowPaymentPopup(!showPaymentPopup);
    };

    return (
      <Component
        showPaymentPopup={showPaymentPopup}
        showPaymentHandler={showPaymentHandler}
        {...props}
      />
    );
  };

export default ShowPaymentPageHOC;
