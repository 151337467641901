import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IProperty } from '../../pages/Listings/Listings';
import axiosCall from '../../utils/axiosCall';

// export interface IFavProperty{
//     _id: string,
//     totalFavorites: number,
//     latestFavouriteDate:string,
//     propertie_id: string,
//     title: string,
//     description: string,
//     p_price: number,
//     p_type: string,
//     status: string
// }
export interface IHomeApiResponse {
  landlordCount: number,
  tenantCount: number,
  adminCount: number,
  GuestProperties: IProperty[],
  FavoriteProperties: IProperty[],
  FeaturedProperties: IProperty[],
  TotalProperties: number,
}
export interface IHome {
  home: IHomeApiResponse;
  loading: boolean;
  error: string | undefined;
  success: boolean;
  selectedUnit: { Id: number, Name: string, Value: number };
  subscribeEmail: {
    data: string,
    loading: boolean,
    error: string,
    success: boolean,
  }
}

const initialState: IHome = {
  home: {
    landlordCount: 0,
    tenantCount: 0,
    adminCount: 0,
    GuestProperties: [],
    FavoriteProperties: [],
    FeaturedProperties: [],
    TotalProperties: 0
  },
  loading: false,
  error: undefined,
  success: false,
  selectedUnit: { Id: 1, Name: "Room", Value: 1 },
  subscribeEmail: {
    data: "",
    loading: false,
    error: "",
    success: false,
  }
};

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    //set cerateListing state.createListing
    setHomeState: (state, action) => {
      const { home, error, loading, success } = action.payload;
      state.home = home;
      state.error = error;
      state.loading = loading;
      state.success = success;
    },
    setIsFavouriteHome: (state, action) => {
      const { id } = action.payload;
      // if (state.postFavPropertySuccess) {
      state.home.FavoriteProperties?.forEach((element, index) => {
        if (element._id === id) {
          state.home.FavoriteProperties[index].isFavoriteForThisUser = true;
        }
      })
      state.home.GuestProperties?.forEach((element, index) => {
        if (element._id === id) {
          state.home.GuestProperties[index].isFavoriteForThisUser = true;
        }
      })
      state.home.FeaturedProperties?.forEach((element, index) => {
        if (element._id === id) {
          state.home.FeaturedProperties[index].isFavoriteForThisUser = true;
        }
      })
    },
    setIsNotFavouriteHome: (state, action) => {
      const { id } = action.payload;
      // if (state.deleteFavPropertySuccess) {
      state.home.FavoriteProperties?.forEach((element, index) => {
        if (element._id === id) {
          state.home.FavoriteProperties[index].isFavoriteForThisUser = false;
        }
      })
      state.home.GuestProperties?.forEach((element, index) => {
        if (element._id === id) {
          state.home.GuestProperties[index].isFavoriteForThisUser = false;
        }
      })
      state.home.FeaturedProperties?.forEach((element, index) => {
        if (element._id === id) {
          state.home.FeaturedProperties[index].isFavoriteForThisUser = false;
        }
      })
      // }
    },
    setSelectedUnit: (state, action) => {
      const { unit } = action.payload;
      state.selectedUnit = unit;
    },
    resetSubscribeEmail: (state, action) => {
      const { subscribeEmail } = action.payload;
      state.subscribeEmail.error = subscribeEmail.error;
      state.subscribeEmail.loading = subscribeEmail.loading;
      state.subscribeEmail.success = subscribeEmail.success;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHome.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    builder.addCase(fetchHome.fulfilled, (state, action) => {
      state.loading = false;
      state.home = action.payload;
      state.success = true;
      state.error = undefined;
    })
    builder.addCase(fetchHome.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(addSubscribeEmail.pending, (state) => {
      state.subscribeEmail.loading = true;
      state.subscribeEmail.success = false;
      state.subscribeEmail.error = "";
    })
    builder.addCase(addSubscribeEmail.fulfilled, (state, action) => {
      state.subscribeEmail.loading = false;
      state.subscribeEmail.success = true;
      state.error = undefined;
    })
    builder.addCase(addSubscribeEmail.rejected, (state, action) => {
      state.subscribeEmail.loading = false;
      state.subscribeEmail.error = action.error.message ?? "";
      state.subscribeEmail.success = false;
    });
  },
});

export const {
  setHomeState,
  setIsNotFavouriteHome,
  setIsFavouriteHome,
  setSelectedUnit,
  resetSubscribeEmail
} = HomeSlice.actions;

export default HomeSlice.reducer;

export const fetchHome = createAsyncThunk("fetchHome", async (arg: { body: any }) => {
  try {
    const response = await axiosCall.get(
      '/publics/get/home'
    );
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data;
        return errorMessage;
      } else {
        console.error('Error calling backend API:', axiosError.message);
        return Promise.reject(axiosError.message);
      }
    } else {
      console.error('Error calling backend API:', error);
      return Promise.reject(error);
    }
  }
});

export const addSubscribeEmail = createAsyncThunk("subscribeEmail", async (arg: { body: any }) => {
  try {
    const response = await axiosCall.post(
      '/publics/subscribeByEmail', arg.body
    );
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data;
        return errorMessage;
      } else {
        console.error('Error calling backend API:', axiosError.message);
        return Promise.reject(axiosError.message);
      }
    } else {
      console.error('Error calling backend API:', error);
      return Promise.reject(error);
    }
  }
});

export const propertyTypeList = [{ Id: 1, Name: "Room", Value: 1 }, { Id: 2, Name: "House", Value: 2 }, { Id: 3, Name: "Condos", Value: 3 }, { Id: 4, Name: "Apartment", Value: 4 }, { Id: 5, Name: "Hotel", Value: 5 }]

