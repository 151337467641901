import { ToastOptions, toast } from 'react-toastify';



export const toastDefaults = (
  props?: ToastOptions<any>
): ToastOptions<undefined> => {
  return {
    position: 'top-right',
    theme: 'colored',
    autoClose: 3000,
    pauseOnHover: true,
    closeOnClick: true,
    closeButton: false,
    ...props,
  };
};

export enum ToastType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
}


export const showToastMessage = (message: string, type: ToastType) => {
  switch (type.toLowerCase()) {
    case ToastType.Error:
      toast.error(message, toastDefaults());
      break;

    case ToastType.Success:
      toast.success(message, toastDefaults());
      break;

    case ToastType.Info:
      toast.info(message, toastDefaults());
      break;
  }
};
