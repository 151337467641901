import React, { useState } from 'react';
// import Picker from 'emoji-picker-react'; //***not using Emoji Picker for now
// import { IoMdSend } from 'react-icons/io';
// import { BsEmojiSmileFill } from 'react-icons/bs';
// import './Styles/ChatInput.scss';

const ChatInput = ({ handleSendMsg }) => {
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState('');

  // // Handle Emoji Picker
  // const handleEmojiPickerHideShow = () => {
  //   setShowEmojiPicker(!showEmojiPicker);
  // };

  // // Handle Emoji Click
  // const handleEmojiClick = (e, emoji) => {
  //   let msg = message;
  //   msg += emoji.emoji;
  //   setMessage(msg);
  // };

  // Check if string is empty or contains whitespaces
  const isEmptyOrSpaces = (str) => {
    return /^\s*$/.test(str);
  };

  // Send Chat
  const sendChat = (e) => {
    e.preventDefault();
    if (!isEmptyOrSpaces(message)) {
      handleSendMsg(message);
      setMessage('');
    }
  };

  return (
    <form onSubmit={(e) => sendChat(e)}>
      <div className="position-relative w-100 float-start">
        <textarea className="form-control"
                  type="text"
                  placeholder=""
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}>
                  </textarea>
        <button type="submit" className="btn btn-primary sent-message-btn">Send Message</button>

      </div>
    </form>
  );
};

export default ChatInput;
