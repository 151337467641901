// Try not to change has all properties need to update again as old created takes old values of these constants.
// Use for Create/Update Listing and Search Property

export const NearBy = {
  Cafe: 'Cafe',
  Education: 'Education',
  Grocery: 'Grocery',
  Healthcare: 'Healthcare',
  MilitaryBase: 'Military Base',
  MovieTheater: 'Movie Theater',
  Park: 'Park',
  Restaurant: 'Restaurant',
  Shopping: 'Shopping',
  Transport: 'Transport',
  Hospital: 'Hospital' 
};

export const NearByOptions = [
  { label: NearBy.Cafe, value: NearBy.Cafe },
  { label: NearBy.Education, value: NearBy.Education },
  { label: NearBy.Grocery, value: NearBy.Grocery },
  { label: NearBy.Hospital, value: NearBy.Hospital }, // inplace of Healthcare
  { label: NearBy.MilitaryBase, value: NearBy.MilitaryBase },
  { label: NearBy.MovieTheater, value: NearBy.MovieTheater },
  { label: NearBy.Park, value: NearBy.Park },
  { label: NearBy.Restaurant, value: NearBy.Restaurant },
  { label: NearBy.Shopping, value: NearBy.Shopping },
  { label: NearBy.Transport, value: NearBy.Transport },
];
