import React, { useState } from 'react';

const CalenderPopupHOC = (Component: any) => 
function Comp(props: any){
    const [showCalenderPopup,setShowCalenderPopup] = useState<boolean>(false);

    const calenderToggleHandler = () => {
        setShowCalenderPopup(!showCalenderPopup);
    }
	
	return <Component showCalenderPopup={showCalenderPopup} calenderToggleHandler={calenderToggleHandler} {...props} />;
};

export default CalenderPopupHOC;