import React, { useEffect, useState } from 'react';
import { User } from '../Dashboard';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import { UnknownAction } from 'redux';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import { FaEdit } from 'react-icons/fa';
import { BiSolidTrash } from 'react-icons/bi';
import {
  fetchFavouriteData,
  resetGetFavouriteState,
} from '../../../redux/slices/FavouriteSlice';
import { BsEyeFill } from 'react-icons/bs';
import { dateTimeFormat, getPaginationNumberText } from '../../../utils/common';
import Pagination from '../../../components/CommonComponents/Pagination/Pagination';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import {
  getAllSubscriberEmail,
  resetGetAllSubscriberEmail,
} from '../../../redux/slices/SubscribeEmailSlice';
import moment from 'moment';

interface IProps {}
function SubscriberEmail(props: IProps) {
  const dispatch = useDispatch();
  const [CurrentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [TotalPages, setTotalPages] = useState(0);
  const userId = JSON.parse(localStorage.getItem('user') ?? '')?._id ?? '';
  const userData = useSelector((state: any) => state.login.userData);
  // const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { getAllSubscribeEmail } = useSelector(
    (state: any) => state.subscriberEmail
  );

  useEffect(() => {
    if (getAllSubscribeEmail.error) {
      showToastMessage('Error Fetching Subscriber Email', ToastType.Error);
    }
    dispatch(resetGetAllSubscriberEmail());
  }, [getAllSubscribeEmail.error]);

  // useEffect(() => {
  //   if (user !== null) callGetFavouriteAPI(CurrentPage, pageLimit);
  // }, [user]);

  useEffect(() => {
    dispatch(
      getAllSubscriberEmail({
        body: {},
      }) as unknown as UnknownAction
    );
  }, []);

  const callGetFavouriteAPI = (pageNum: number, limit: number) => {
    dispatch(
      fetchFavouriteData({
        body: {
          userId: userData?._id ?? userId,
          pageNo: pageNum,
          pageSize: limit,
        },
      }) as unknown as UnknownAction
    );
  };

  // useEffect(() => {
  //   const storedUser = localStorage.getItem('user');
  //   if (storedUser !== null) {
  //     setUser(JSON.parse(storedUser));
  //   }
  //   // console.log(user);
  // }, []);

  // const gotoPropertyDetail = (id: string) => {
  //     // navigate('/property-detail', {
  //     //     state: { Id: id },
  //     // });
  // };

  // const changePage = (event: any) => {
  //     const pageNumber = event.selected + 1;
  //     setCurrentPage(pageNumber);
  //     callGetFavouriteAPI(pageNumber, pageLimit);
  // };

  // useEffect(() => {
  //     setTotalPages(totalPages);
  // }, [totalPages]);

  const handleEdit = (item: any) => {};

  const handleDelete = (item: any) => {};

  return (
    <>
      <div className="main-content">
        <DashboardHeader />
        {(userData || userId) && (
          <div className="">
            {/* <DashboardHeader /> */}

            <div className="below-header">
              <div className="row align-items-center">
                <div className="col-sm-7">
                  <h1>Subscriber Email</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard">Dashboard</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Subscribed Email
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-sm-5 text-sm-end">
                  <div className="property-filter">
                    <form>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control w-100"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="property-listing listing-table mt-0">
              {getAllSubscribeEmail.loading ? (
                <Loader />
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          {/* <th>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                          />
                        </th> */}
                          {/* <th>Order ID</th> */}
                          <th>Email</th>
                          <th>Subscribed At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getAllSubscribeEmail?.data?.map(
                          (item: any, index: number) => {
                            return (
                              <tr key={item?.userId}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="property-details ms-2">
                                      <h5>{item?.email}</h5>
                                      {/* <p>{item?.description}</p> */}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {moment(item?.subscribed_at).format(
                                    dateTimeFormat
                                  )}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-3">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleDelete(item);
                                      }}
                                    >
                                      <BiSolidTrash />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* {TotalPages != 0 && TotalPages != undefined ? (
                    <Pagination
                      changePage={changePage}
                      totalPages={TotalPages}
                      text={getPaginationNumberText(
                        currentPage,
                        pageSizes,
                        totalPropertyCount,
                      )}
                    />
                  ) : (
                    <></>
                  )} */}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SubscriberEmail;
