import * as Yup from 'yup';

const agencySchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  moveIn: Yup.string().required('Move In date is required'),
  moveOut: Yup.string().required('Move Out date is required'),
  travelReason: Yup.string().required('Travel Reason is required'),
  working: Yup.string().required('Working information is required'),
  occupant: Yup.string().required('Occupant information is required'),
  propertyType: Yup.string().required('Property Type is required'),
  bedroom: Yup.string().required('Bedroom information is required'),
  budget: Yup.string().required('Budget information is required'),
  withPet: Yup.string().oneOf(['Yes', 'No']).required('Pet information is required'),
  description: Yup.string().required('Description is required'),
});

export const HomeRequestSchema = Yup.object({
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  agency: Yup.object().when('requester', {
    is: 'Agency',
    then: () => Yup.object({
      name: Yup.string().required("Agency name is required"),
      contact: Yup.string().required("Agency contact is required"),
      email: Yup.string().required("Agency email is required"),
    }),
  }),
  traveler: Yup.object().when('requester', {
    is: 'Traveler',
    then: () => Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      moveIn: Yup.string().required("Move-in date is required"),
      moveOut: Yup.string().required("Move-out date is required"),
      travelReason: Yup.string().required("Travel Reason is required"),
      working: Yup.string().required("Working information is required"),
      occupant: Yup.string().required("Occupant information is required"),
      propertyType: Yup.string().required("Property Type is required"),
      bedroom: Yup.string().required("Number of Bedrooms is required"),
      budget: Yup.string().required("Budget information is required"),
      withPet: Yup.string().oneOf(['Yes', 'No']).required("Pet information is required"),
      description: Yup.string().required("Description is required"),
      contacted: Yup.string().oneOf(['Phone', 'Properties']).required("Contact method is required"),
    }),
  }),
  agencies: Yup.array().when('requester', {
    is: 'Agency',
    then: () => Yup.array().of(agencySchema),
    otherwise: () => Yup.array().nullable(),
  }),
});

