import React, { useEffect, useState } from 'react';
import './Pagination.scss';
import ReactPaginate from 'react-paginate';

function Pagination(props: any) {
  return (
    <div className="bg-white px-3 py-2 paginate-container blue-theme">
      <div className="row align-items-center">
        <div className="col-sm-6">
          <span className="showing-txt">{props.text}</span>
        </div>
        <div className="col-sm-6">
          <div className="pagination-container">
            <ReactPaginate
              nextLabel=">"
              onPageChange={props.changePage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={props.totalPages}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              forcePage={props?.forcePage}
            />
            {/* <span>Rows per page: </span>
                            <select id="dropdown" onChange={changePageLimit}>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                            </select> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
