import React, { useEffect, useState } from 'react';
import './SavedSearch.scss';
import { NavLink } from 'react-router-dom';
import { IoMdArrowDropright } from 'react-icons/io';
import SearchBarSectionComponent from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import  FilterIcon from '../../../assets/images/filterIcon.png';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axiosCall from '../../../utils/axiosCall';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import { USDateFormat } from '../../../utils/common';

function SavedSearch() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const [mySearchHistory, setMySearchHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteSearchHistory, setDeleteSearchHistory] = useState<string | null>(null);
  const userData = useSelector((state: any) => state.login.userData);

  useEffect(() => {
    setAllSearch();
  }, []);

  const setAllSearch = async () => {
    setLoading(true);

    try {
      const url = `/searchhistory/getAllSearchHistoryByUserId/${userData._id}`;
      const result = await axiosCall.get(url);
      if (result) {
        setMySearchHistory(result.data);
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSearchByHistoryId = async (HistoryId: string) => {
    try {
      const url = `/searchhistory/getSearchHistoryDetailsByHistoryId/${HistoryId}`;
      const response = await axiosCall.get(url);
      if (response.status === 204) {
        setMySearchHistory((prevState) =>
          prevState.filter((item) => item._id !== HistoryId)
        );
        toast.success('Search History deleted successfully', toastDefaults());
      }
    } catch (error) {
      //console.log(error);
      toast.error('Search History could not be deleted...', toastDefaults());
    }
  };
  const deleteSearchHistoryByHistoryId = async (HistoryId: string) => {
    try {
      const url = `/searchhistory/deleteSingleSearchHistory/${HistoryId}`;
      const response = await axiosCall.delete(url);
      if (response.status === 204) {
        setMySearchHistory((prevState) =>
          prevState.filter((item) => item._id !== HistoryId)
        );

        toast.success('Search History deleted successfully', toastDefaults());
      }
    } catch (error) {
      //console.log(error);
      toast.error('Search History could not be deleted...', toastDefaults());
    }
  };
  const deleteAllSearchByUserId = async (UserId: string) => {
    try {
      const url = `/searchhistory/deleteAllSearchHistoryByUserID/${UserId}`;
      const response = await axiosCall.delete(url);
      if (response.status === 204) {
        setMySearchHistory((prevState) =>
          prevState.filter((SearchHistory) => SearchHistory._id !== UserId)
        );
        toast.success('Search History deleted successfully', toastDefaults());
      }
    } catch (error) {
      toast.error('Search History could not be deleted.', toastDefaults());
    }
  };

  return (
    <div className="main-content">
      {user && (
        <div className="">
          <SearchBarSectionComponent />

          <div className="below-header">
            <div className='row align-items-center'>
              <div className='col-sm-7'>
                <h1>Saved Search</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Saved Search</li>
                  </ol>
                </nav>
              </div>
              <div className='col-sm-5 text-sm-end'>
                <div className='property-filter'>
                  <form>
                    <div className='d-flex align-items-center gap-2'>
                      <input type="text" placeholder="" className="form-control w-100" />
                      <button type='button' className='btn btn-light bg-white d-flex align-items-center'>
                        <img
                          src={FilterIcon}
                          alt="filter"
                          className="object-cover"
                        />
                        Filters</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="">
            <h2 className="text-3xl font-heading font-bold">Saved Search</h2>
            <div className="flex items-center justify-between">
              <div className="flex gap-2 justify-center items-center">
                <NavLink to="/dashboard" className="text-deep_blue">
                  Dashboard
                </NavLink>
                <div className="">
                  <IoMdArrowDropright />
                </div>
                <NavLink to="/dashboard/saved-search">Saved Search</NavLink>
              </div>
              <div className="flex flex-row items-center justify-center gap-2">
                <input
                  type="text"
                  placeholder=""
                  className="bg-white border-1 rounded-md w-[20rem] px-3 py-1"
                />
                <button className="bg-white flex flex-row items-center justify-center gap-2 px-3 py-1 rounded-lg border-1 cursor-pointer">
                  <img src={FilterIcon} alt="filter" className="object-cover" />
                  Filters
                </button>
              </div>
            </div>
          </div> */}

          {loading ? (
            <Loader />
          ) : (
            <>
              {mySearchHistory?.length > 0 ? (

                <div className="saved-search">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Search</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mySearchHistory?.map((searchData) => (
                          <tr>
                            <td>{searchData.searchText}</td>
                            <td>{USDateFormat(searchData.createdAt)} </td>
                            <td>
                              <div className="d-flex align-items-center gap-3">
                                <button type="button">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.99994 11.9992C10.6562 11.9992 11.9988 10.6566 11.9988 9.00033C11.9988 7.34411 10.6562 6.00146 8.99994 6.00146C7.34371 6.00146 6.00107 7.34411 6.00107 9.00033C6.00107 10.6566 7.34371 11.9992 8.99994 11.9992Z"
                                      fill="#858D9D"
                                    />
                                    <path
                                      d="M17.4501 7.06527C16.2873 5.17147 13.6423 1.99414 9.00001 1.99414C4.35774 1.99414 1.71272 5.17147 0.549899 7.06527C-0.183259 8.25111 -0.183259 9.74947 0.549899 10.9353C1.71272 12.8291 4.35774 16.0065 9.00001 16.0065C13.6423 16.0065 16.2873 12.8291 17.4501 10.9353C18.1833 9.74947 18.1833 8.25111 17.4501 7.06527ZM9.00001 13.4986C6.51567 13.4986 4.50168 11.4846 4.50168 9.00029C4.50168 6.51595 6.51567 4.50196 9.00001 4.50196C11.4844 4.50196 13.4983 6.51595 13.4983 9.00029C13.4958 11.4836 11.4833 13.4961 9.00001 13.4986Z"
                                      fill="#858D9D"
                                    />
                                  </svg>
                                </button>
                                <button type="button">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.8786 0.521224L15.4788 1.12142C16.1737 1.81639 16.1737 2.92201 15.4788 3.61698L14.5311 4.56466L11.4353 1.4689L12.383 0.521224C13.078 -0.173741 14.1836 -0.173741 14.8786 0.521224ZM5.43337 7.47088L10.7088 2.19546L13.8045 5.29121L8.52912 10.5666C8.33959 10.7562 8.08687 10.9141 7.83416 11.0089L5.0227 11.925C4.76999 12.0197 4.4541 11.9566 4.26456 11.7354C4.04344 11.5459 3.98026 11.23 4.07502 10.9773L4.99112 8.16584C5.08588 7.91313 5.24383 7.66041 5.43337 7.47088ZM3.03258 1.84798H6.06515C6.60217 1.84798 7.07601 2.32182 7.07601 2.85884C7.07601 3.42744 6.60217 3.86969 6.06515 3.86969H3.03258C2.46397 3.86969 2.02172 4.34353 2.02172 4.88055V12.9674C2.02172 13.536 2.46397 13.9783 3.03258 13.9783H11.1194C11.6565 13.9783 12.1303 13.536 12.1303 12.9674V9.93485C12.1303 9.39783 12.5726 8.92399 13.1412 8.92399C13.6782 8.92399 14.152 9.39783 14.152 9.93485V12.9674C14.152 14.6417 12.7937 16 11.1194 16H3.03258C1.35834 16 0 14.6417 0 12.9674V4.88055C0 3.20632 1.35834 1.84798 3.03258 1.84798Z"
                                      fill="#858D9D"
                                    />
                                  </svg>
                                </button>
                                <button type="button"
                                  onClick={() => deleteSearchHistoryByHistoryId(searchData._id)}
                                >
                                  <svg
                                    width="16"
                                    height="18"
                                    viewBox="0 0 16 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.75 2.99999H12.425C12.0662 1.25518 10.5313 0.00225 8.75001 0H7.25C5.46866 0.00225 3.93381 1.25518 3.57501 2.99999H1.25002C0.835808 2.99999 0.500031 3.33577 0.500031 3.74998C0.500031 4.16419 0.835808 4.5 1.25002 4.5H2.00001V14.25C2.0025 16.32 3.67998 17.9975 5.75002 18H10.25C12.3201 17.9975 13.9975 16.32 14 14.25V4.5H14.75C15.1642 4.5 15.5 4.16422 15.5 3.75001C15.5 3.3358 15.1642 2.99999 14.75 2.99999ZM7.25003 12.75C7.25003 13.1642 6.91425 13.5 6.50004 13.5C6.0858 13.5 5.75002 13.1642 5.75002 12.75V8.25001C5.75002 7.8358 6.0858 7.50002 6.50001 7.50002C6.91422 7.50002 7.25 7.8358 7.25 8.25001V12.75H7.25003ZM10.25 12.75C10.25 13.1642 9.91424 13.5 9.50003 13.5C9.08582 13.5 8.75004 13.1642 8.75004 12.75V8.25001C8.75004 7.8358 9.08582 7.50002 9.50003 7.50002C9.91424 7.50002 10.25 7.8358 10.25 8.25001V12.75ZM5.12828 2.99999C5.44729 2.10171 6.2968 1.50114 7.25003 1.49998H8.75004C9.70327 1.50114 10.5528 2.10171 10.8718 2.99999H5.12828Z"
                                      fill="#858D9D"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

              ) : (
                <h6 className="text-secondary">
                  You saved history not found.
                </h6>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default SavedSearch;
