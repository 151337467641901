import React, { useEffect, useState } from 'react';
import './TenantsComponents.scss';
import { useNavigate } from 'react-router';
import axiosCall from '../../../../utils/axiosCall';
import {
  ToastType,
  showToastMessage,
  toastDefaults,
} from '../../../../utils/ToastOptionCommon';
import Loader from '../../../../components/CommonComponents/Loader/Loader';
import Pagination from '../../../../components/CommonComponents/Pagination/Pagination';
import { getPaginationNumberText } from '../../../../utils/common';
import TenantRowItem from './TenantRowItem';
import DashboardHeader from '../../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import { IGetAll } from '../../../../constants/interface/IGetAll';
import { ProfileViewModal } from '../../../../components/PropertyDetail/ProfileViewModal/ProfileViewModal';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../../../utils/useDebounce';
import { UnknownAction } from '@reduxjs/toolkit';
import {
  postApproveUser,
  resetApproveState,
} from '../../../../redux/slices/UserSlice';
import { toast } from 'react-toastify';
import ConfirmationPopupHOC from '../../../../utils/ConfirmationPopup/hoc/ConfirmationPopupHoc';
import ConfirmationPopup from '../../../../utils/ConfirmationPopup/ConfirmationPopup';

interface IProps {
  showConfirmationPopup: boolean;
  confirmationToggleHandler: () => void;
}

const TenantsComponents = (props: IProps) => {
  const navigate = useNavigate();
  const [tenants, setTenant] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [user, setUser] = useState<any>();
  const [uid, setUid] = useState('');
  const dispatch = useDispatch();
  const changePage = (event: any) => {
    const pageNumber = event.selected + 1;
    setCurrentPage(pageNumber);
  };

  // useEffect(() => {
  //   if (searchText) setCurrentPage(1);
  // }, [searchText]);

  useEffect(() => {
    callGetAllTenant({
      pageNo: currentPage,
      pageSize: pageLimit,
      text: searchText,
    });
  }, [currentPage, pageLimit]);

  const callGetAllTenant = async (data: IGetAll) => {
    setIsLoading(true);
    try {
      const response = await axiosCall.post(`/user/tenants`, data);
      if (!response) {
        showToastMessage("Failed to get tenant's", ToastType.Error);
        return;
      }

      setTenant(response.data?.tenants);
      setTotalPages(response.data?.totalPages);
      setPageLimit(response.data?.pageSizes);
      setRecordCount(response.data?.totaltenantCount);
    } catch (error) {
      showToastMessage("Failed to get tenant's from server", ToastType.Error);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (item: any) => {
    // navigate('/dashboard/edit-blog', {
    //   state: { blog: item },
    // });
  };

  const handleConfirmation = () => {
    let body = {
      isActive: !user.isActive,
      userId: user._id,
    };
    dispatch(postApproveUser({ body: body }) as unknown as UnknownAction);
  };

  const handleDisable = (item: any) => {
    setUser(() => {
      return { ...item };
    });
    props.confirmationToggleHandler();
  };

  const { approveUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (approveUser.success) {
      toast.success('Tenant Status Updated Successfully', toastDefaults());
      callGetAllTenant({
        pageNo: currentPage,
        pageSize: pageLimit,
        text: searchText,
      });
      dispatch(resetApproveState());
      props.confirmationToggleHandler();
    }
  }, [approveUser.success]);

  useEffect(() => {
    if (approveUser.error) {
      toast.error('Failed Updating Tenant Status', toastDefaults());
      dispatch(resetApproveState());
    }
  }, [approveUser.error]);

  const gotoDetail = (id: string) => {
    // navigate('/tenant-detail', {
    //   state: { b_id: id },
    // });
    setShowProfileModal(() => true);
    setUid(() => id);
  };

  const onSearchTextChanged = (value: string) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  useDebounce(
    () => {
      if(searchText){
        callGetAllTenant({
          pageNo: currentPage,
          pageSize: pageLimit,
          text: searchText,
        });
      }
    },
    [searchText],
    800
  );

  return (
    <>
      {props.showConfirmationPopup && (
        <ConfirmationPopup
          onModalClose={props.confirmationToggleHandler}
          onSubmit={handleConfirmation}
          confirmationText={
            user.isActive
              ? 'Are you sure, you want to disable the selected tenant.'
              : 'Are you sure, you want to enable the selected tenant.'
          }
          saveButtonText={'Yes'}
          cancelButtonText={'No'}
          modalTitle={'Update Tenant Status'}
          isProgress={approveUser.loading}
        />
      )}
      {showProfileModal && (
        <ProfileViewModal
          onModalClose={() => {
            setShowProfileModal((prev) => !prev);
            // callGetAllTenant({
            //   pageNo: currentPage,
            //   pageSize: pageLimit,
            //   text: searchText,
            // });
          }}
          userId={uid ?? ''}
          takeFeedback={true}
          modalHeader="Tenant Profile"
        />
      )}
      <div className="main-content">
        <DashboardHeader />
        <div className="">
          {/* <DashboardHeader /> */}
          <div className="below-header">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <h1>Tenants</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Tenants
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-5 text-sm-end">
                <div className="property-filter">
                  <form>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="text"
                        placeholder=""
                        className="form-control w-100"
                        onChange={(e) => onSearchTextChanged(e.target.value)}
                      />
                      {/* <button
                        type="button"
                        className="btn filter-btn d-flex align-items-center"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="#034991"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 11.125C0 10.7695 0.273438 10.4688 0.65625 10.4688H2.26953C2.54297 9.59375 3.39062 8.9375 4.375 8.9375C5.33203 8.9375 6.17969 9.59375 6.45312 10.4688H13.3438C13.6992 10.4688 14 10.7695 14 11.125C14 11.5078 13.6992 11.7812 13.3438 11.7812H6.45312C6.17969 12.6836 5.33203 13.3125 4.375 13.3125C3.39062 13.3125 2.54297 12.6836 2.26953 11.7812H0.65625C0.273438 11.7812 0 11.5078 0 11.125ZM3.5 11.125C3.5 11.6172 3.88281 12 4.375 12C4.83984 12 5.25 11.6172 5.25 11.125C5.25 10.6602 4.83984 10.25 4.375 10.25C3.88281 10.25 3.5 10.6602 3.5 11.125ZM8.75 6.75C8.75 7.24219 9.13281 7.625 9.625 7.625C10.0898 7.625 10.5 7.24219 10.5 6.75C10.5 6.28516 10.0898 5.875 9.625 5.875C9.13281 5.875 8.75 6.28516 8.75 6.75ZM9.625 4.5625C10.582 4.5625 11.4297 5.21875 11.7031 6.09375H13.3438C13.6992 6.09375 14 6.39453 14 6.75C14 7.13281 13.6992 7.40625 13.3438 7.40625H11.7031C11.4297 8.30859 10.582 8.9375 9.625 8.9375C8.64062 8.9375 7.79297 8.30859 7.51953 7.40625H0.65625C0.273438 7.40625 0 7.13281 0 6.75C0 6.39453 0.273438 6.09375 0.65625 6.09375H7.51953C7.79297 5.21875 8.64062 4.5625 9.625 4.5625ZM5.25 3.25C5.71484 3.25 6.125 2.86719 6.125 2.375C6.125 1.91016 5.71484 1.5 5.25 1.5C4.75781 1.5 4.375 1.91016 4.375 2.375C4.375 2.86719 4.75781 3.25 5.25 3.25ZM7.32812 1.71875H13.3438C13.6992 1.71875 14 2.01953 14 2.375C14 2.75781 13.6992 3.03125 13.3438 3.03125H7.32812C7.05469 3.93359 6.20703 4.5625 5.25 4.5625C4.26562 4.5625 3.41797 3.93359 3.14453 3.03125H0.65625C0.273438 3.03125 0 2.75781 0 2.375C0 2.01953 0.273438 1.71875 0.65625 1.71875H3.14453C3.41797 0.84375 4.26562 0.1875 5.25 0.1875C6.20703 0.1875 7.05469 0.84375 7.32812 1.71875Z"></path>
                        </svg>
                        Filters
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="blog-listing listing-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Reviews</th>
                      <th>Email</th>
                      <th>Phone No.</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants?.map((item: any) => {
                      return (
                        <TenantRowItem
                          key={item._id}
                          item={item}
                          gotoDetail={gotoDetail}
                          handleDisable={handleDisable}
                          handleEdit={handleEdit}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {totalPages != 0 && totalPages != undefined ? (
                <Pagination
                  changePage={changePage}
                  totalPages={totalPages}
                  forcePage={currentPage - 1}
                  text={getPaginationNumberText(
                    currentPage,
                    pageLimit,
                    recordCount
                  )}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationPopupHOC(TenantsComponents);
