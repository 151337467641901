import React, { useEffect, useRef, useState } from 'react';
import { User } from '../../../pages/Dashboard/Dashboard';
import axiosCall from '../../../utils/axiosCall';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import './DashboardSettingProfileEditComponent.scss';
import Loader from '../../CommonComponents/Loader/Loader';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../../utils/common';
import { usePermissions } from '../../../utils/Permission/Permission';
import userone from '../../../assets/images/avatar.png';
import Input from 'react-phone-number-input/input';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { Library } from '../../../constants/GoogleLibrary';
import { getPlaceDetails } from '../../../utils/Maps/MapHelper';
import ImageEditorHOC from '../../../utils/ImageEditor/hoc/ImageEditorHOC';
import ImageEditorPopup from '../../../utils/ImageEditor/hoc/ImageEditorPopup';
import { setUserData } from '../../../redux/slices/LoginSlice';
import { setUser } from '../../../redux/slices/UserSlice';

interface IProps {
  formInputState: User | undefined;
  setFormInputState: React.Dispatch<React.SetStateAction<User | undefined>>;
  setPlaceDetail: React.Dispatch<React.SetStateAction<any>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  handleProfileFormSubmit: () => Promise<void>;
  files: any[];
  setFiles: React.Dispatch<any>;
  keepPhoneNumberPrivate: boolean;
  setKeepPhoneNumberPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  keepEmailPrivate: boolean;
  setKeepEmailPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  messageNotification: boolean;
  setMessageNotification: React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
  showHandler: () => void;
}

const options = {
  componentRestrictions: { country: 'us' },
  fields: ['address_components', 'formatted_address'],
  strictBounds: false,
};

function DashboardSettingProfileEditComponent(props: IProps) {
  const {
    formInputState,
    setFormInputState,
    setIsEditing,
    handleProfileFormSubmit,
    files,
    setFiles,
    keepPhoneNumberPrivate,
    setKeepPhoneNumberPrivate,
    keepEmailPrivate,
    setKeepEmailPrivate,
    messageNotification,
    setMessageNotification,
    setPlaceDetail,
    showPopup,
    showHandler,
  } = props;
  const dispatch = useDispatch();
  const [occupations, setOccupations] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = useRef<any>(null);
  const userData = useSelector((state: any) => state.login.userData);
  const locationRef = useRef<any>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    libraries: Library,
    region: 'us',
  });
  const [phoneValue, setPhoneValue] = useState<any>(
    formInputState?.phoneNo ?? ''
  );
  const { hasPermission } = usePermissions();

  const userImage = formInputState?.profileImage?.length
    ? formInputState?.profileImage
    : userone;

  useEffect(() => {
    setFormInputState((prev: any) => ({
      ...prev,
      phoneNo: formatPhoneNumber(phoneValue ?? ''),
    }));
  }, [phoneValue, setFormInputState]);

  useEffect(() => {
    if (formInputState?.phoneNo) setPhoneValue(formInputState?.phoneNo);
  }, [formInputState]);

  const handleFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInputState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const setOccupation = (e: any) => {
    if (e.target.value !== 'Select Occupation') {
      setFormInputState((prev: any) => ({
        ...prev,
        occupation: e.target.value,
      }));
    }
  };

  const getAllOccupations = async () => {
    try {
      setLoading(true);
      const url = `/user/getOccupations`;
      const result = await axiosCall.get(url);
      setOccupations([{ name: 'Select Occupation', id: 0 }, ...result.data]);
    } catch (error) {
      showToastMessage('Error fetching data from server...', ToastType.Error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasPermission('tenant')) {
      getAllOccupations();
    }
  }, [userData.currentRole]);

  const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
  //   if (e.target?.files?.length > 1) {
  //     showToastMessage('Please select only one image', ToastType.Error);
  //     return;
  //   }
    // if (!acceptedImageTypes.includes(e.target.files?.[0].type)) {
    //   showToastMessage(
    //     'Please select supported image format (ie. .jpg, .jpeg, .png)',
    //     ToastType.Error
    //   );
    //   return;
    // }
  //   setFormInputState((prev: any) => ({
  //     ...prev,
  //     removeProfile: false,
  //   }));
  //   setFiles([e.target.files?.[0]]);
  // };

  // const selectFile = () => {
  //   hiddenFileInput.current.click();
  // };

  const removeFile = () => {
    setFiles(() => []);
    // hiddenFileInput.current.value = null;
    setFormInputState((prev: any) => ({
      ...prev,
      profileImage: '',
      removeProfile: true,
    }));
  };

  const handlePlaceChanged = () => {
    if (locationRef.current) {
      const place = locationRef.current.getPlace();
      if (place) {
        const e: any = {
          target: {
            name: 'address',
            value: place?.formatted_address,
          },
        };
        handleFormInputChange(e);
        const { state, city, zipCode } = getPlaceDetails(place);
        setPlaceDetail({ state, city, zipCode });
      }
    }
  };

  const handleProfilePicSubmit = async (file: Blob) => {
    setLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }

    try {
      const url = '/user/updateUserProfilePic';
      const response = await axiosCall.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.status === 200) {
        const user = response?.data?.user;
        const data = {
          ...userData,
          firstName: user?.firstName,
          lastName: user?.lastName,
          userEmail: user?.userEmail,
          phoneNo: user?.phoneNo,
          role: user?.role,
          address: user?.address,
          aboutUser: user?.aboutUser,
          profileImage: user?.profileImage,
          isEmailVerified: user?.isEmailVerified,
          occupation: user?.occupation,
          keepPhoneNumberPrivate: user?.keepPhoneNumberPrivate,
          keepEmailPrivate: user?.keepEmailPrivate,
          messageNotification: user?.messageNotification,
        };
        dispatch(setUserData(data));
        dispatch(setUser(data));
        localStorage.setItem('user', JSON.stringify(data));
        showToastMessage('Profile picture updated successfully', ToastType.Success);
        // close popup
        props.showHandler();
      } else {
        showToastMessage('Could not update the user', ToastType.Error);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('User update failed...', ToastType.Error);
    } finally {
      setLoading(false);
    }
  };

  const updateImageHandler = async (file: Blob) => {
    setFiles([file]);
    await handleProfilePicSubmit(file);
  };

  return (
    <>
      {props.showPopup && (
        <ImageEditorPopup
          src={userImage}
          showMe={props.showHandler}
          submitHandler={updateImageHandler}
          gridType="Circle"
        />
      )}
      <div>
        <div className="tab-content">
          <div id="Update" className="tab-pane active">
            <div className="row">
              <div className="col-12">
                <h3 className="mb-4">Update Profile</h3>
              </div>
            </div>
            {loading && <Loader />}
            <div className="row">
              <div className="col-md-7">
                <div className="mb-3">
                  <label htmlFor="First Name" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="First Name"
                    name="firstName"
                    placeholder=""
                    value={formInputState?.firstName}
                    onChange={handleFormInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Last Name" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Last Name"
                    placeholder=""
                    name="lastName"
                    value={formInputState?.lastName}
                    onChange={handleFormInputChange}
                  />
                </div>
                <div className="mb-3">
                  <div>
                    <label htmlFor="Phone" className="form-label">
                      Phone
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="phoneNo"
                      value={phoneValue}
                      onChange={setPhoneValue}
                      defaultCountry={'US'}
                      countries={['US']}
                    />
                  </div>
                  {hasPermission('landlord') && (
                    <div className="on-off-toggle d-flex gap-2 mt-2">
                      <span>Keep your number hidden?</span>
                      <input
                        type="checkbox"
                        id="switch"
                        checked={keepPhoneNumberPrivate}
                        onChange={() =>
                          setKeepPhoneNumberPrivate((prev) => !prev)
                        }
                        className="checkbox"
                      />
                      <label htmlFor="switch" className="toggle"></label>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Email"
                    placeholder=""
                    name="userEmail"
                    disabled
                    value={formInputState?.userEmail}
                    onChange={handleFormInputChange}
                  />
                  {hasPermission('landlord') && (
                    <div className="on-off-toggle d-flex gap-2 mt-2">
                      <span>Keep your email hidden?</span>
                      <input
                        type="checkbox"
                        id="switch-email"
                        checked={keepEmailPrivate}
                        onChange={() => setKeepEmailPrivate((prev) => !prev)}
                        className="checkbox"
                      />
                      <label htmlFor="switch-email" className="toggle"></label>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Location" className="form-label">
                    Location
                  </label>
                  {isLoaded && !loadError && (
                    <Autocomplete
                      options={options}
                      onLoad={(ref) => (locationRef.current = ref)}
                      onPlaceChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="Location"
                        placeholder=""
                        name="address"
                        value={formInputState?.address}
                        onChange={handleFormInputChange}
                      />
                    </Autocomplete>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="AboutMe" className="form-label">
                    About Me
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="AboutMe"
                    placeholder=""
                    name="aboutUser"
                    value={formInputState?.aboutUser}
                    onChange={handleFormInputChange}
                    maxLength={500}
                  />
                </div>
                {hasPermission('tenant') && (
                  <div className="mb-3">
                    <label className="form-label">Occupation</label>
                    <select onChange={setOccupation} className="form-select">
                      {occupations?.length > 0 &&
                        occupations.map((occupation: any) => (
                          <option
                            value={occupation._id}
                            key={occupation._id}
                            selected={
                              formInputState?.occupation === occupation._id
                            }
                          >
                            {occupation.name}
                          </option>
                        ))}
                    </select>
                    {/* <input
                  type="text"
                  className="form-control"
                  id="occupation"
                  placeholder=""
                  name="occupation"
                  value={formInputState?.occupation}
                  onChange={handleFormInputChange}
                /> */}
                  </div>
                )}

                {hasPermission('landlord.tenant') ? (
                  <div className="mb-3">
                    <div className="on-off-toggle d-flex gap-2 mt-2">
                      <h6>
                        Do you want to recieve message notification on mail?
                      </h6>
                      <input
                        type="checkbox"
                        id="switch-message"
                        checked={messageNotification}
                        onChange={() => setMessageNotification((prev) => !prev)}
                        className="checkbox"
                      />
                      <label
                        htmlFor="switch-message"
                        className="toggle"
                      ></label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="mb-3 d-flex gap-2">
                  <button
                    type="button"
                    onClick={handleProfileFormSubmit}
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {setIsEditing(false); setFiles([]);}}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-5">
                <div className="update-profile-img text-center">
                  {/* <p>Set Profile Image</p>
                <DropZone
                  files={files}
                  setFiles={setFiles}
                  className="bg-light border text-center p-4 rounded-3"
                  numberOfFiles={1}
                />
              */}
                  <div className="profile-img mb-4 mx-auto">
                    {files.length === 0 && (
                      <img
                        src={userImage}
                        className="img-fluid w-100"
                        alt={formInputState?.firstName + '_pic'}
                      />
                    )}
                    {files.length > 0 && (
                      <img
                        src={URL.createObjectURL(files[0]) ?? userone}
                        className="img-fluid w-100"
                        alt={formInputState?.firstName + '-profile-pic'}
                      />
                    )}
                    {/* {files.length > 0 && <img
                    src={
                      files?.length > 0
                        ? files[0].preview
                          ? files[0].preview
                          : URL.createObjectURL(files[0])
                        : ProfileImage
                    }
                    className="img-fluid w-100"
                    alt={formInputState?.firstName + '-profile-pic'}
                  />} */}
                  </div>
                  <div className="btn-group gap-2">
                    <button
                      type="button"
                      // onClick={selectFile}
                      onClick={props.showHandler}
                      className="btn rounded btn-secondary"
                    >
                      Update Image
                    </button>
                    <button
                      type="button"
                      onClick={removeFile}
                      className="btn rounded btn-secondary"
                    >
                      Remove Image
                    </button>
                  </div>
                  {/* <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    className="btn btn-secondary"
                    accept=".png,.jpg,.jpeg"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageEditorHOC(DashboardSettingProfileEditComponent);
