import React, { useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { propertiesList, property } from '../../../utils/dummyData';
import ListingCardComponent from '../../ListingsComponents/ListingCardComponent/ListingCardComponent';
import Slider from 'react-slick';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import './CardsPagination.scss';

const CardsPagination = (props: any) => {
  const items: propertiesList = props.items;
  const startIndex: number = props.startIndex;
  const endIndex: number = props.endIndex;
  const setStartIndex = props.setStartIndex;
  const setEndIndex = props.setEndIndex;

  const slideCount = items?.length;

  const handleNext = () => {
    if (endIndex < slideCount) {
      setStartIndex((prev: number) => prev + 3);
      setEndIndex((prev: number) => prev + 3);
    }
  };

  const handlePrev = () => {
    if (startIndex > 1) {
      setStartIndex((prev: number) => prev - 3);
      setEndIndex((prev: number) => prev - 3);
    }
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <div className="">
      {/* <div onClick={handlePrev} className="slider-arrow slider-arrow-left">
        <svg
          width="17"
          height="12"
          viewBox="0 0 17 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.21875 5.46887L5.46875 1.25012C5.75 0.937622 6.21875 0.937622 6.5 1.25012C6.8125 1.53137 6.8125 2.00012 6.5 2.28137L3.53125 5.25012H16.25C16.6562 5.25012 17 5.59387 17 6.00012C17 6.43762 16.6562 6.75012 16.25 6.75012L3.53125 6.78137L6.5 9.75012C6.8125 10.0314 6.8125 10.5001 6.5 10.7814C6.21875 11.0939 5.75 11.0939 5.46875 10.7814L1.21875 6.53137C0.90625 6.25012 0.90625 5.78137 1.21875 5.46887Z"
            fill="#212121"
          />
        </svg>
      </div> */}
      <Slider {...settings}>
        {items
          //.slice(startIndex, endIndex)
          .map((item: property) => (
            // <div
            //   // className={`${item.id >= startIndex && item.id <= endIndex
            //   //   ? 'col-md-6 col-lg-4 mb-4'
            //   //   : 'col-md-6 col-lg-4 mb-4'
            //   //   }`}
            //   key={item.id}
            // >
            <ListingCardComponent
              key={item.id}
              propertiesValues={item}
              signInToggleHandler={props.signInToggleHandler}
              favedId={props.favedId}
              setFavedId={props.setFavedId}
              isFeatureDisplay={true}
            />
            // </div>
          ))}
      </Slider>

      {/* <div onClick={handleNext} className="slider-arrow slider-arrow-right">
        <svg
          width="17"
          height="12"
          viewBox="0 0 17 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7812 6.53125L11.5312 10.75C11.25 11.0625 10.7812 11.0625 10.5 10.75C10.1875 10.4688 10.1875 10 10.5 9.71875L13.4687 6.75L0.749999 6.75C0.343749 6.75 -8.38712e-07 6.40625 -8.74228e-07 6C-9.12475e-07 5.5625 0.343749 5.25 0.749999 5.25L13.4687 5.21875L10.5 2.25C10.1875 1.96875 10.1875 1.5 10.5 1.21875C10.7812 0.906251 11.25 0.906251 11.5312 1.21875L15.7812 5.46875C16.0937 5.75 16.0937 6.21875 15.7812 6.53125Z"
            fill="#212121"
          />
        </svg>
      </div> */}
    </div>
  );
};

export default CardsPagination;
