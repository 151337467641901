import { Link } from 'react-router-dom';
import { UnderDevelopment } from '../../../utils/UnderDevelopment/UnderDevelopment';
import './Policy.scss';
import { Field, Form, Formik } from 'formik';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import { useEffect, useState } from 'react';
import axiosCall from '../../../utils/axiosCall';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import {
  decodeEncoded,
  getDocName,
  getDocNameFromUrl,
} from '../../../utils/common';
import DocumentPopupHOC from '../../../components/PropertyDetail/DocumentViewModal/hoc/DocumentPopupHoc';
import DocumentViewModal from '../../../components/PropertyDetail/DocumentViewModal/DocumentViewModal';
import { ReactComponent as PdfSvg } from '../../../assets/images/pdf-svg.svg';
import Spinner from '../../../utils/Spinner/Spinner';

interface IProps {
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}

const Policy = (props: IProps) => {
  const defaultValue = {};
  const acceptedImageTypes = ['application/pdf'];
  const [loading, setLoading] = useState(false);
  const [policies, setPolicies] = useState<any>();
  const [editTOC, setEditTOC] = useState<boolean>(false);
  const [editPrivacy, setEditPrivacy] = useState<boolean>(false);
  const [editRefund, setEditRefund] = useState<boolean>(false);
  const [editDisclaimer, setEditDisclaimer] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState('');
  const [docLoading, setDocLoading] = useState({
    toc: false,
    pp: false,
    rf: false,
    disc: false,
  });

  useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = async () => {
    try {
      setLoading(true);
      const url = '/policy/getPolcies';
      const response = await axiosCall.get(url);
      if (response?.status === 200) {
        setPolicies(response?.data);
      } else {
        showToastMessage('Could not get the policies.', ToastType.Error);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('Policy failed.', ToastType.Error);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileFormSubmit = async (
    e: React.ChangeEvent<HTMLInputElement> | any,
    type: string
  ) => {
    //setLoading(true);
    let actionName = 'updateTOC';
    let policyName = '';
    switch (type) {
      case 'TOC':
        actionName = 'updateTOC';
        policyName = 'Terms and Conditions';
        setDocLoading((prev) => ({ ...prev, toc: true }));
        break;
      case 'Privacy':
        actionName = 'updatePrivacyPolicy';
        policyName = 'Privacy Policy';
        setDocLoading((prev) => ({ ...prev, pp: true }));
        break;
      case 'Refund':
        actionName = 'updateRefundPolicy';
        policyName = 'Refund Policy';
        setDocLoading((prev) => ({ ...prev, rf: true }));
        break;
      case 'Disclaimer':
        actionName = 'updateDisclaimer';
        policyName = 'Disclaimer';
        setDocLoading((prev) => ({ ...prev, disc: true }));
        break;
    }

    const formData = new FormData();

    if (!acceptedImageTypes.includes(e.target.files?.[0].type)) {
      showToastMessage('Please select supported pdf format.', ToastType.Error);
      return;
    }
    formData.append('pdf', e.target.files?.[0]);

    try {
      const url = '/policy/' + actionName;
      const response = await axiosCall.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.status === 200) {
        setPolicies(response?.data);
        setEditTOC(false);
        setEditPrivacy(false);
        setEditRefund(false);
        setEditDisclaimer(false);
        showToastMessage(policyName + ' uploaded success.', ToastType.Success);
      } else {
        showToastMessage('Could not update the policy.', ToastType.Error);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('Policy update failed.', ToastType.Error);
    } finally {
      setDocLoading({ toc: false, pp: false, rf: false, disc: false });
    }
  };

  const handleSubmit = () => {};

  const docHandler = (url: string) => {
    setFileUrl(url);
    props.documentToggleHandler();
  };

  return (
    <>
      {props.showDocumentPopup && (
        <DocumentViewModal
          fileUrl={fileUrl}
          onModalClosed={props.documentToggleHandler}
        ></DocumentViewModal>
      )}
      <div className="main-content">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>Policy</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {loading && <Loader />}
        {!loading && (
          <Formik
            initialValues={defaultValue}
            enableReinitialize
            // validationSchema={certificateSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="box-view shadow-sm mb-5 h-auto">
                    <Form
                      className="user-form space-y-6 text-deep_gray"
                      noValidate
                    >
                      {policies && policies?.termsAndCondition !== '' && (
                        <div className="mb-3">
                          <label>
                            <input
                              type="checkbox"
                              checked={editTOC}
                              name="fileOnEdit"
                              onChange={() => setEditTOC(!editTOC)}
                            />{' '}
                            Would you like to update Terms And Condition.
                            <br />
                          </label>
                          {policies?.termsAndCondition?.length > 0 && (
                            <div className="doc_container">
                              <PdfSvg />{' '}
                              <span
                                onClick={() =>
                                  docHandler(policies?.termsAndCondition)
                                }
                                className="old_doc_span"
                              >
                                {decodeEncoded(
                                  getDocNameFromUrl(policies?.termsAndCondition)
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {(editTOC ||
                        !policies ||
                        (policies && policies?.termsAndCondition === '')) && (
                        <div className="mb-3">
                          <label className="form-label">
                            Terms And Conditions
                          </label>
                          {docLoading.toc && <Spinner />}
                          <input
                            type="file"
                            className="form-control"
                            id="document_url"
                            aria-describedby="document_url"
                            aria-label="Upload"
                            accept=".pdf"
                            onChange={(e) => {
                              handleProfileFormSubmit(e, 'TOC');
                            }}
                          />
                          {/* {showFileError.isError && (
                        <span
                          data-testid="testError"
                          className="text-danger errorMessage"
                        >
                          {showFileError.msg}
                        </span>
                      )} */}
                        </div>
                      )}

                      {policies && policies?.privacyPolicy !== '' && (
                        <div className="mb-3">
                          <label>
                            <input
                              type="checkbox"
                              checked={editPrivacy}
                              name="fileOnEdit"
                              onChange={() => setEditPrivacy(!editPrivacy)}
                            />{' '}
                            Would you like to update Privacy Policy.
                            <br />
                          </label>
                          {policies?.privacyPolicy?.length > 0 && (
                            <div className="doc_container">
                              <PdfSvg />{' '}
                              <span
                                onClick={() =>
                                  docHandler(policies?.privacyPolicy)
                                }
                                className="old_doc_span"
                              >
                                {decodeEncoded(
                                  getDocNameFromUrl(policies?.privacyPolicy)
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {(editPrivacy ||
                        !policies ||
                        (policies && policies?.privacyPolicy === '')) && (
                        <div className="mb-3">
                          <label className="form-label">Privacy Policy</label>
                          {docLoading.pp && <Spinner />}
                          <input
                            type="file"
                            className="form-control"
                            id="document_url"
                            aria-describedby="document_url"
                            aria-label="Upload"
                            accept=".pdf"
                            onChange={(e) => {
                              handleProfileFormSubmit(e, 'Privacy');
                            }}
                          />
                          {/* {showFileError.isError && (
                          <span
                            data-testid="testError"
                            className="text-danger errorMessage"
                          >
                            {showFileError.msg}
                          </span>
                        )} */}
                        </div>
                      )}

                      {policies && policies?.refundPolicy !== '' && (
                        <div className="mb-3">
                          <label>
                            <input
                              type="checkbox"
                              checked={editRefund}
                              name="fileOnEdit"
                              onChange={() => setEditRefund(!editRefund)}
                            />{' '}
                            Would you like to update Refund Policy.
                            <br />
                          </label>
                          {policies?.refundPolicy?.length > 0 && (
                            <div className="doc_container">
                              <PdfSvg />{' '}
                              <span
                                onClick={() =>
                                  docHandler(policies?.refundPolicy)
                                }
                                className="old_doc_span"
                              >
                                {decodeEncoded(
                                  getDocNameFromUrl(policies?.refundPolicy)
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {(editRefund ||
                        !policies ||
                        (policies && policies?.refundPolicy) === '') && (
                        <div className="mb-3">
                          <label className="form-label">Refund Policy</label>
                          {docLoading.rf && <Spinner />}
                          <input
                            type="file"
                            className="form-control"
                            id="document_url"
                            aria-describedby="document_url"
                            aria-label="Upload"
                            accept=".pdf"
                            onChange={(e) => {
                              handleProfileFormSubmit(e, 'Refund');
                            }}
                          />
                          {/* {showFileError.isError && (
                          <span
                            data-testid="testError"
                            className="text-danger errorMessage"
                          >
                            {showFileError.msg}
                          </span>
                        )} */}
                        </div>
                      )}

                      {policies && policies?.disclaimer !== '' && (
                        <div className="mb-3">
                          <label>
                            <input
                              type="checkbox"
                              checked={editDisclaimer}
                              name="fileOnEdit"
                              onChange={() =>
                                setEditDisclaimer(!editDisclaimer)
                              }
                            />{' '}
                            Would you like to update Disclaimer.
                            <br />
                          </label>
                          {policies?.disclaimer?.length > 0 && (
                            <div className="doc_container">
                              <PdfSvg />{' '}
                              <span
                                onClick={() => docHandler(policies?.disclaimer)}
                                className="old_doc_span"
                              >
                                {decodeEncoded(
                                  getDocNameFromUrl(policies?.disclaimer)
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {(editDisclaimer ||
                        !policies ||
                        (policies && policies?.disclaimer === '')) && (
                        <div className="mb-3">
                          <label className="form-label">Disclaimer</label>
                          {docLoading.disc && <Spinner />}
                          <input
                            type="file"
                            className="form-control"
                            id="document_url"
                            aria-describedby="document_url"
                            aria-label="Upload"
                            accept=".pdf"
                            onChange={(e) => {
                              handleProfileFormSubmit(e, 'Disclaimer');
                            }}
                          />
                          {/* {showFileError.isError && (
                        <span
                          data-testid="testError"
                          className="text-danger errorMessage"
                        >
                          {showFileError.msg}
                        </span>
                      )} */}
                        </div>
                      )}

                      {/* <div className="my-4">
                        <h5>Upload Document</h5>
                        <p>
                          Verify your certification by uploading a copy of it or
                          an official 3rd party verification.
                        </p>
                      </div> */}
                      {/* {isSubmitting && <Loader />} */}
                      {/* 
                    <div className="border-top mt-5 pt-3 text-end">
                      <button
                      type="button"
                      className="btn btn-light px-3 py-2 me-2"
                      onClick={() => {
                        navigate(-1);
                        dispatch(resetGetLicenseFilledDetail());
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                      <button
                      type="submit"
                      className="btn btn-primary px-3 py-2"
                      disabled={isSubmitting}
                      onClick={checkSubmmiting}
                    >
                      {addCertificateDetail.loading && (
                        <img
                          src={Busy}
                          alt="loading.."
                          className="me-2"
                          style={{ maxWidth: '16px' }}
                        />
                      )}
                      {certificateDetail.isEdit
                        ? 'Update Changes'
                        : 'Save Changes'}
                    </button>
                    </div> */}
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default DocumentPopupHOC(Policy);
