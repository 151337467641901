import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { USDateFormat, shortenString } from '../../../../utils/common';
import {
  BsEyeFill,
  BsFill0CircleFill,
  BsFillCheckCircleFill,
  BsFillCircleFill,
  BsFillDashCircleFill,
} from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { BiSolidTrash } from 'react-icons/bi';
import { Rating } from 'react-simple-star-rating';

interface IProps {
  item: any;
  gotoDetail: (id: string) => void;
  handleDisable: (item: any) => void;
  handleEdit: (item: any) => void;
}

const TenantRowItem = (props: IProps) => {
  const { item, gotoDetail, handleDisable, handleEdit } = props;
  const [imageLoading, setImageLoading] = useState(true);

  let totalRating = 0;
  if (item?.reviews?.length > 0) {
    item?.reviews?.map((review: any) => (totalRating += review?.rating));
  }

  const avgReview = Math.round(totalRating / item?.reviews?.length) || 0;

  return (
    <tr>
      {/* <td>
          <div className="d-flex align-items-center">
            <div className="property-listing-preview">
              {imageLoading && (
                <Skeleton animation='wave' variant="rounded" width={'100%'} height={550} />
              )}
              <img
                src={item?.image || propertylistingpreview}
                alt="property-listing-preview"
                className="img-fluid rounded-md"
                onLoad={() => setImageLoading(false)}
                style={{ display: imageLoading ? 'none' : 'block' }}
              />
            </div>
            <div className="property-details ms-2">
              <h5>{item?.title}</h5>
            </div>
          </div>
        </td> */}
      {/* <td>{USDateFormat(item?.createdAt)}</td>
        <td>{shortenString(item?.content[0])}</td> */}
      <td>{`${item?.firstName} ${item?.lastName}`}</td>
      <td>
        <Rating
          initialValue={avgReview}
          // onPointerEnter={()=>{}}
          // onPointerLeave={()=>{}}
          // onPointerMove={handleRating}
          SVGstyle={{
            display: 'inline',
            height: '15px',
            width: '15px',
          }}
          readonly={true}
        />
      </td>
      <td>{item?.userEmail || ''}</td>
      <td>{item?.phoneNo || ''}</td>
      <td>
        <span
          className={`status ${item?.isActive ? 'for-active' : 'for-inactive'}`}
        >
          {item?.isActive ? `Active` : `InActive`}
        </span>
      </td>
      <td>
        <div className="d-flex align-items-center gap-3">
          <button
            type="button"
            onClick={() => {
              gotoDetail(item?._id);
            }}
          >
            <BsEyeFill />
          </button>
          {/* <button
            type="button"
            onClick={() => {
              handleEdit(item);
            }}
          >
            <FaEdit />
          </button>*/}
          <button
            type="button"
            title={item.isActive ? 'Disable' : 'Enable'}
            onClick={() => {
              handleDisable(item);
            }}
          >
            {item.isActive ? (
              <BsFillDashCircleFill />
            ) : (
              <BsFillCheckCircleFill />
            )}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TenantRowItem;
