import { Button, Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Busy from "../../assets/gif/busy.gif"
import './ConfirmationPopup.scss'



interface IProps {
    onModalClose: () => void;
    onSubmit: () => void;
    confirmationText: string;
    saveButtonText: string;
    cancelButtonText: string;
    modalTitle: string;
    isProgress: boolean;
    icon?: React.ReactElement;
}

const ConfirmationPopup = (props: IProps) => {
    const { onModalClose, onSubmit, confirmationText, icon, saveButtonText, cancelButtonText, modalTitle, isProgress } = props;

    return (
        <>
            <Modal
                show={true}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        {modalTitle}
                    </Modal.Title>
                    <div
                        onClick={() => {
                            onModalClose();
                        }}
                        className="icons8-close ms-auto"
                    >
                        <IoClose />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {icon}{confirmationText}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="py-2" onClick={() => { onSubmit(); }}>
                        {isProgress && (
                            <img src={Busy} alt="loading.." className='me-2' style={{maxWidth: '16px'}} />
                        )}
                        {saveButtonText}</Button>
                    <Button className="py-2" variant="secondary" onClick={() => { onModalClose() }}>{cancelButtonText}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConfirmationPopup;
