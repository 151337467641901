import './CEUs.scss';
import '../Documents.scss';
import { useNavigate } from 'react-router';
import Busy from '../../../../assets/gif/busy.gif';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import {
  deleteCeusById,
  deleteCeusDetail,
  getAllCeus,
  getCeus,
  getCeusById,
  resetDeleteCeusDetail,
  resetGetCeus,
  resetGetCeusDetail,
  resetGetCeusFilledDetail,
  selectCeusById,
} from '../../../../redux/slices/DocumentSlice';
import { useEffect, useState } from 'react';
import {
  ToastType,
  showToastMessage,
} from '../../../../utils/ToastOptionCommon';
import { BiSolidTrash } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';

export const CEUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const ceus = useSelector(getCeus);
  const getCeusDetail = useSelector(selectCeusById);
  const deleteCeus = useSelector(deleteCeusDetail);
  const [deleteId, setDeleteId] = useState('');
  const [isView, setIsView] = useState(false);

  const callGetAll = () => {
    dispatch(getAllCeus());
    dispatch(resetGetCeus());
    dispatch(resetGetCeusFilledDetail());
  };

  useEffect(() => {
    callGetAll();
  }, []);

  useEffect(() => {
    if (ceus.error) {
      showToastMessage(ceus.error, ToastType.Error);
    }
    dispatch(resetGetCeus());
  }, [ceus.error]);

  useEffect(() => {
    if (getCeusDetail.success) {
      isView
        ? navigate('/dashboard/view-CEUs')
        : navigate('/dashboard/fill-CEUs');
      dispatch(resetGetCeusDetail());
      setIsView(() => false);
    }
    if (getCeusDetail.error) {
      showToastMessage('Failed to get ceus detail', ToastType.Error);
    }
  }, [getCeusDetail]);

  useEffect(() => {
    if (deleteCeus.success) {
      showToastMessage('CEUs deleted successfully', ToastType.Success);
      callGetAll();
      dispatch(resetDeleteCeusDetail());
      setDeleteId('');
    }
    if (deleteCeus.error) {
      showToastMessage('Failed to delete CEUs', ToastType.Error);
      dispatch(resetDeleteCeusDetail());
      setDeleteId('');
    }
  }, [deleteCeus]);

  const deleteHandler = (id: string) => {
    dispatch(deleteCeusById({ id }));
    setDeleteId(id);
  };

  const editHandler = (id: string) => {
    dispatch(getCeusById({ id }));
  };
  const viewHandler = (id: string) => {
    dispatch(getCeusById({ id }));
    setIsView(() => true);
  };

  return (
    <div className="main-content">
      <div className="below-header">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>CEUs (Continuing Education Units) </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  CEUs
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <button
              className="btn btn-outline-primary py-2 px-3 d-flex float-end"
              onClick={() => navigate('/dashboard/fill-CEUs')}
            >
              <svg
                className="me-2"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="#034991"
              >
                <path d="M450.001-290.001h59.998v-160h160v-59.998h-160v-160h-59.998v160h-160v59.998h160v160Zm30.066 190q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path>
              </svg>
              Add CEUs
            </button>
          </div>
        </div>
      </div>

      {!ceus?.loading && ceus?.data?.length === 0 ? (
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6 offset-md-3 box-view text-center">
              {/* <h2 className="text-secondary mb-3" style={{ fontSize: '80px' }}>
                Oops!
              </h2> */}
              <h4 className="mb-1">No data Found</h4>
              <p>Please add new CEUs.</p>
              {/* <a
                href="#"
                className="btn btn-primary mx-auto text-uppercase mt-3"
              >
                Go to home page
              </a> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div>{ceus.toString}</div>

      {ceus?.data?.map((e: any) => {
        return (
          <div key={e._id} className="box-view">
            <div className="d-flex">
              <h3 className="box-title">{e.state}</h3>

              <button
                type="button"
                className="update-license btn p-0 ms-auto"
                onClick={() => editHandler(e._id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5f6368"
                >
                  <path d="M200-200h50.46l409.46-409.46-50.46-50.46L200-250.46V-200Zm-60 60v-135.38l527.62-527.39q9.07-8.24 20.03-12.73 10.97-4.5 23-4.5t23.3 4.27q11.28 4.27 19.97 13.58l48.85 49.46q9.31 8.69 13.27 20 3.96 11.31 3.96 22.62 0 12.07-4.12 23.03-4.12 10.97-13.11 20.04L275.38-140H140Zm620.38-570.15-50.23-50.23 50.23 50.23Zm-126.13 75.9-24.79-25.67 50.46 50.46-25.67-24.79Z" />
                </svg>
              </button>
              <button
                className="update-license btn p-0 del_btn"
                type="button"
                onClick={() => {
                  viewHandler(e._id);
                }}
              >
                <BsFillEyeFill />
              </button>
              <button
                className="update-license btn p-0 del_btn"
                type="button"
                onClick={() => {
                  deleteHandler(e._id);
                }}
              >
                {deleteCeus.loading && deleteId === e._id ? (
                  <img
                    src={Busy}
                    alt="loading.."
                    style={{ maxWidth: '16px' }}
                  />
                ) : (
                  <BiSolidTrash />
                )}
              </button>
            </div>
            <div className="registered-user">
              <ul>
                <li>
                  <span>Total CEUs - </span>
                  {e.total}
                </li>
                <li>
                  <span>Required CEUs - </span>
                  {e.required}
                </li>
                <li>
                  <span>Year - </span>
                  {e.year}
                </li>
              </ul>
              {e.total > e.required ? (
                <span className="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z" />
                  </svg>
                  You met the required CEUs for {e.state} state.
                </span>
              ) : (
                <span className="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z" />
                  </svg>
                  You did not met the required CEUs for {e.state} state.
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
