import React, { useState } from 'react';

const SignInPopupHOC = (Component: any) => 
function Comp(props: any){
    const [showSignInPopup,setShowSignInPopup] = useState<boolean>(false);

    const signInToggleHandler = () => {
        setShowSignInPopup(!showSignInPopup);
    }
	
	return <Component showSignInPopup={showSignInPopup} signInToggleHandler={signInToggleHandler} {...props} />;
};

export default SignInPopupHOC;