import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../pages/Dashboard/Dashboard';
import { BsBuilding } from 'react-icons/bs';
import { FaEye, FaStar } from 'react-icons/fa';
import { FaDollarSign } from 'react-icons/fa6';
import './StatsSectionComponent.scss';
import userone from '../../../assets/images/avatar.png';
import { formatDate } from '../../../utils/formatDateTimeForMessage';
import axios from 'axios';
import { getAllDashboardNotification } from '../../../utils/APIRoutes';
import { usePermissions } from '../../../utils/Permission/Permission';
import Loader from '../../CommonComponents/Loader/Loader';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import axiosCall from '../../../utils/axiosCall';
import { useSocket } from '../../../context/SocketProvider';
import { useNavigate } from 'react-router';
import { AppDispatch } from '../../../redux/store';
import { activeUsers, setChatData } from '../../../redux/slices/ChatSlice';
import BarChart from '../../../utils/Charts/BarChart/BarChart';
import { BarChartOption } from '../../../utils/Charts/BarChart/BarChartOption';
import { USDateFormat } from '../../../utils/common';

type propCard = {
  title: string;
  value?: number | string;
  icon: JSX.Element;
  isVisible: boolean;
  clickableLink?: boolean;
};

function StatsSectionComponent(props: any) {
  const { hasPermission } = usePermissions();
  const [allPropertiesCount, setAllPropertiesCount] = useState(0);
  const [totalTenants, setTotalTenants] = useState(0);
  const [totalLandlords, setTotalLandlords] = useState(0);
  const [totalFavourites, setTotalFavourites] = useState(0);
  const [topFavourites, setTopFavourites] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const onlineUsers = useSelector(activeUsers);
  const propCardsData: propCard[] = [
    {
      title: 'All Properties',
      value: allPropertiesCount,
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#848BFF] from-[#3640E1]">
          <BsBuilding className="text-white" />
        </div>
      ),
      isVisible: hasPermission('admin.landlord'),
    },
    {
      title: 'Total Views',
      value: 0,
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FF9663] from-[#FA702C]">
          <FaEye className="text-white" />
        </div>
      ),
      isVisible: hasPermission('landlord'),
    },
    {
      title: 'Total Landlords',
      value: totalLandlords,
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FF9663] from-[#FA702C]">
          <FaEye className="text-white" />
        </div>
      ),
      isVisible: hasPermission('admin'),
    },
    {
      title: 'Total Tenants',
      value: totalTenants,
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FF9663] from-[#FA702C]">
          <FaEye className="text-white" />
        </div>
      ),
      isVisible: hasPermission('admin'),
    },
    {
      title: 'Total Favorites',
      value: totalFavourites,
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FFA2B8] from-[#FB517A]">
          <FaEye className="text-white" />
        </div>
      ),
      isVisible: hasPermission('tenant'),
    },
    {
      title: 'Calculator',
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FF9663] from-[#FA702C]">
          <FaEye className="text-white" />
        </div>
      ),
      isVisible: hasPermission('tenant'),
    },
    {
      title: 'Veriafied Link',
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#86FFB6] from-[#2DCF6D]">
          <FaStar className="text-white" />
        </div>
      ),
      isVisible: hasPermission('landlord'),
    },
    {
      title: 'Total Income',
      value: totalIncome,
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FFA2B8] from-[#FB517A]">
          <FaDollarSign className="text-white" />
        </div>
      ),
      isVisible: hasPermission('admin'),
    },
    {
      title: 'GSA.Gov link',
      value: 'https://www.gsa.gov/travel/plan-book/per-diem-rates',
      icon: (
        <div className="p-2 rounded-full bg-gradient-to-tl to-[#FFA2B8] from-[#FB517A]">
          <FaDollarSign className="text-white" />
        </div>
      ),
      isVisible: hasPermission('tenant'),
      clickableLink: true,
    },
  ];

  const userData: User = useSelector((state: any) => state.login.userData);

  const socket = useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [messageNotification, setMessageNotification] = useState<any>();
  const [AllNotification, setAllNotification] = useState<any>([]);
  const [refreshNotificaion, setRefreshNotificaion] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartOptions, setchartOptions] = useState<Highcharts.Options>({});

  useEffect(() => {
    if (userData?.currentRole?.toLowerCase() === 'admin') {
      setchartOptions(BarChartOption.DashboardAdmin);
    } else if (userData?.currentRole?.toLowerCase() === 'landlord') {
      setchartOptions(BarChartOption.DashboardLandlord);
    }
  }, []);

  // fetch dashboard stats
  useEffect(() => {
    (async () => {
      let url = 'dashboard/data';
      try {
        setIsLoading(true);
        const result = await axiosCall.get(url);
        // console.log(result.data);
        setAllPropertiesCount(() => result.data.userPropertyCount);
        // console.log(userData);
        if (userData.role.includes('Admin')) {
          setTotalLandlords(() => result.data.landlordsCount);
          setTotalTenants(() => result.data.tenantsCount);
          setTotalIncome(() => result.data.totalIncome);
        }
        if (userData.role.includes('Tenant')) {
          setTotalFavourites(() => result.data.totalFavourites);
          setTopFavourites(() => result.data.topFavourites);
        }
      } catch (error) {
        showToastMessage("Can't fetch data", ToastType.Error);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const convertToUTC = (yearMonthString: string): number => {
    const [year, month] = yearMonthString.split('-').map(Number);
    return Date.UTC(year, month - 1, 1); // Set day to 1 to represent the start of the month
  };

  const getChartData = async () => {
    try {
      setChartLoading(true);
      const data = {
        role: userData.currentRole,
      };
      const response = await axiosCall.post('/dashboard/chart-data', data);
      if (userData?.currentRole?.toLowerCase() === 'admin') {
        const propertiesData = response.data?.properties?.map((item: any) => [
          convertToUTC(item.date),
          item.count,
        ]);
        const subscriptionData = response.data?.subscription?.map(
          (item: any) => [convertToUTC(item.date), item.amount]
        );
        let series: any = BarChartOption.DashboardAdmin.series;
        series[0].data = propertiesData;
        series[1].data = subscriptionData;
        setchartOptions({
          ...BarChartOption.DashboardAdmin,
          series: series,
        });
      } else if (userData?.currentRole?.toLowerCase() === 'landlord') {
        const propertiesData = response.data?.properties?.map((item: any) => [
          new Date(item.date).getTime(),
          item.count,
        ]);
        const reviewData = response.data?.reviews?.map((item: any) => [
          item.name,
          item.review,
        ]);
        let series: any = BarChartOption.DashboardLandlord.series;
        let xAxis: any = BarChartOption.DashboardLandlord.xAxis;
        series[0].data = propertiesData;
        series[1].data = reviewData;
        xAxis[1].categories = response.data?.reviews?.map(
          (item: any) => item.name
        );
        setchartOptions({
          ...BarChartOption.DashboardLandlord,
          series: series,
          xAxis,
        });
      }
    } catch (error) {
      showToastMessage('Failed to get chart data', ToastType.Error);
    } finally {
      setChartLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.currentRole?.toLowerCase() !== 'tenant') {
      getChartData();
    }
  }, [userData?.currentRole]);

  const markContactActive = (cntct: Array<any>) => {
    if (cntct?.length) {
      let ctc = [...cntct];
      ctc.forEach((e: any) => {
        if (onlineUsers.includes(e?.lastMessage?.users?.from?._id)) {
          e.lastMessage.users.from.active = true;
        } else {
          e.lastMessage.users.from.active = false;
        }
      });
      return ctc;
    }
    return cntct;
  };

  useEffect(() => {
    if (AllNotification?.length) {
      setAllNotification(markContactActive(AllNotification));
    }
  }, [onlineUsers]);

  // fetch all messages
  useEffect(() => {
    const fetchAllMessages = async () => {
      const response = await axios.get(
        `${getAllDashboardNotification}/${userData?._id}`
      );
      if(response.data){
        setAllNotification(markContactActive(response?.data?.message));
      }
      // console.log("------->>",response.data)
    };
    fetchAllMessages();
  }, []); // eslint-disable-line

  useEffect(() => {
    socket.on('receiver-notifications', (res: any) => {
      setAllNotification(res?.message);
    });

    return () => {
      socket.off('receiver-notifications');
    };
  }, [socket]);

  const handleViewChat = async (chatId: string, item: any, active: boolean) => {
    navigate('/dashboard/message');
    item.active = active;
    dispatch(setChatData({ chatId, data: item }));
    // socket.io send msg
    // socket.emit('mark-as-read', {
    //   senderId,
    //   UserId,
    // });
    // console.log( senderId, UserId)
    // setRefreshNotificaion((prevTrigger) => !prevTrigger);
  };

  const title =
    userData?.currentRole?.toLowerCase() === 'admin'
      ? 'Property & Subscription'
      : 'Property & Views';

  const gotoPropertyDetail = (id: string) => {
    navigate(`/property-detail/${id}`);
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="earning-overview">
        <div className="row">
          {propCardsData?.map(
            (data, index) =>
              data.isVisible && (
                <div key={index} className="col-sm-6 col-xl-3 mb-0 mb-sm-4">
                  <div className="box-view h-100">
                    {/* <div className="d-flex"> */}
                      <div className='text-center'>
                        <p>{data.title}</p>
                        {!data.clickableLink ? (
                          <h2>
                            {data.title === 'Total Income'
                              ? data.value?.toLocaleString('en-US', {
                                  currency: 'USD',
                                  style: 'currency',
                                  maximumFractionDigits: 0,
                                })
                              : data.title === 'Total Views'
                              ? data.value?.toLocaleString('en-US', {
                                  currency: 'USD',
                                  maximumFractionDigits: 0,
                                })
                              : data.value}
                          </h2>
                        ) : (
                          <a href={data.value?.toString()} target="_blank">
                            {data.value}
                          </a>
                        )}
                      </div>
                      {/* <div className="ms-auto">{data.icon}</div> */}
                    {/* </div> */}
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <div className="row">
        {hasPermission('admin.landlord') ? (
          <BarChart
            isLoading={chartLoading}
            options={chartOptions}
            title={title}
          />
        ) : (
          <div className="col-md-8">
            <div className="box-view h-100">
              <h3 className="box-title">Favorites Properties</h3>
              <div className="table-responsive">
                <table
                  className="table table-hover"
                  style={{ minWidth: 'auto' }}
                >
                  <thead>
                    <tr>
                      <th className="bg-white">Property</th>
                      <th className="bg-white">Property Type</th>
                      <th className="bg-white">Price</th>
                      <th className="bg-white">Available</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topFavourites?.map((item: any, index: number) => {
                      return (
                        <tr
                          key={item?._id}
                          onClick={() => gotoPropertyDetail(item?._id)}
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="property-listing-preview">
                                <img
                                  src={item?.imagesLink?.[0]}
                                  alt="property-listing-preview"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="property-details ms-2">
                                <h5>{item?.title}</h5>
                                <p>{item?.description}</p>
                              </div>
                            </div>
                          </td>
                          <td className=" text-capitalize">{item?.p_type}</td>
                          <td>{item?.rooms?.[0].price}</td>
                          <td>
                            {USDateFormat(item?.rooms?.[0]?.availableFrom)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <div className="col-md-4">
          <div className="box-view h-100">
            <h3 className="box-title">Messages</h3>
            {AllNotification?.length < 1 ? <div className='no-msg'>No message received.</div> : <></>}
            <div className="messages">
              {AllNotification?.map((item: any) => {
                const name = `${item?.lastMessage?.users?.from?.firstName} ${item?.lastMessage?.users?.from?.lastName}`;
                const activeStatus =
                  item?.lastMessage?.users?.from?.active ?? false;
                return (
                  <div
                    key={item?.chatId?._id}
                    className="messages-user d-flex align-items-center"
                    onClick={() =>
                      handleViewChat(item?.chatId?._id, item, activeStatus)
                    }
                  >
                    <div className="user-img">
                      <img
                        src={
                          item?.lastMessage?.profilePic?.length
                            ? item?.lastMessage?.profilePic
                            : userone
                        }
                        className="img-fluid w-100"
                        alt={`${name}_pic`}
                      />
                      <span
                        className={`user-status user-auto-status ${
                          activeStatus ? 'live-status' : 'busy-status'
                        }`}
                      ></span>
                    </div>
                    <div className="user-details ms-3">
                      <a>
                        <h4 className="user-name mb-0">{name}</h4>
                        <div className="message-excerpt">
                          {(item?.lastMessage?.message &&
                            item?.lastMessage?.message.length) > 30
                            ? item?.lastMessage?.message?.slice(0, 30) + '...'
                            : item?.lastMessage?.message}
                          <br></br>
                          <span className="message-date-time">
                            {formatDate(item?.lastMessage?.date)}
                          </span>
                        </div>
                      </a>
                    </div>
                    <span className="message-count">
                      {item?.notification > 9 ? (
                        <>
                          {' '}
                          9 <span className="plus-icon">+</span>
                        </>
                      ) : (
                        item?.notification
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsSectionComponent;
