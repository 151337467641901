import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import {
  getPaymentStatus,
  resetStatusData,
} from '../../../redux/slices/PaymentSlice';

const ReturnPage = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const payment = useSelector((state: any) => state.payment);

  useEffect(() => {
    if (params && params?.sessionId) {
      dispatch(getPaymentStatus({ id: params.sessionId }));
    }
  }, [params]);

  useEffect(() => {
    if (payment.getResult) {
      if (payment.success) {
        navigate('/payment/success');
      } else {
        navigate('/payment/failed');
      }
      dispatch(resetStatusData());
    }
  }, [payment.getResult, payment.success]);

  return (
    <div className="alert-box success shadow-sm text-center bg-white p-5 rounded-3 border">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-60q-117 0-212.35-57.93-95.34-57.92-149.57-155.23v119.31h-60v-220h220v60H164.46q43.38 87.39 128.23 140.62Q377.54-120 480-120q73.46 0 137.81-27.15 64.34-27.16 112.65-74.12 48.31-46.96 77.58-110.92 29.27-63.96 31.58-137.81h59.99q-1.92 85.23-35.65 159.85-33.73 74.61-90.46 130.38Q716.77-124 641.19-92 565.61-60 480-60Zm-28.31-147.69v-50.46q-43.92-10.24-73.04-36.47-29.11-26.23-44.65-70.76L384.61-386q14.31 39.08 40.97 59.39 26.65 20.3 60.42 20.3 34.15 0 61.31-17.04 27.15-17.03 27.15-54.65 0-31.31-23.34-49.88-23.35-18.58-87.12-41.97-61.69-22.15-89-49.61-27.31-27.46-27.31-72.54 0-38.69 27.93-70.85 27.92-32.15 78.07-41v-48.46h54.62v48.46q33.69 2.62 62.04 25.35 28.34 22.73 40.88 54.65L561.85-604q-10.31-22.23-29.47-36.96-19.15-14.73-50.38-14.73-37.31 0-58.5 17.69-21.19 17.69-21.19 46T423-547.92q20.69 15.77 83.77 37.31 72 26 96.77 58.3Q628.31-420 628.31-378q0 28.62-10.58 50.23-10.58 21.62-27.65 36.35-17.08 14.73-38.89 23.27-21.81 8.53-44.88 12v48.46h-54.62ZM60.39-490q2.69-87.15 36.8-161.96 34.12-74.81 91.23-130.19 57.12-55.39 132.12-86.62Q395.54-900 480-900q115.85 0 212.35 58.12 96.5 58.11 149.57 156.58v-120.85h60v220h-220v-60h113.62Q753.31-732 668.27-786 583.23-840 480-840q-71.92 0-136.08 26.77-64.15 26.77-112.84 73.34-48.69 46.58-78.54 110.54-29.85 63.96-32.16 139.35H60.39Z" /></svg>
      <h5>Please hold on while we process your payment...</h5>
    </div>
  );
};

export default ReturnPage;
