import React, { useState } from 'react';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import { User } from '../../../pages/Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import axiosCall from '../../../utils/axiosCall';

const initialState = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

interface IProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function DashboardSettingChangePassword(props: IProps) {
  const [formInputState, setFormInputState] = useState(initialState);
  const userData: User | undefined = useSelector(
    (state: any) => state.login.userData
  );

  const { setLoading } = props;

  const handleFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInputState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = async () => {
    // check if all fields are filled
    if (
      formInputState.oldPassword.trim()?.length === 0 ||
      formInputState.newPassword.trim()?.length === 0 ||
      formInputState.confirmNewPassword.trim()?.length === 0
    ) {
      showToastMessage('Please fill all fields', ToastType.Error);
      setFormInputState(initialState);
      return;
    }
    // validate new passwords are equal
    if (formInputState.newPassword !== formInputState.confirmNewPassword) {
      showToastMessage(
        'New password and confirm password does not match ',
        ToastType.Error
      );
      setFormInputState(initialState);
      return;
    }
    //  Check if old password and new password is same
    if (formInputState.oldPassword === formInputState.newPassword) {
      showToastMessage(
        'New password can not be same as old password',
        ToastType.Error
      );
      setFormInputState(initialState);
      return;
    }
    //  build the body
    const data = {
      userEmail: userData?.userEmail,
      oldPassword: formInputState.oldPassword,
      newPassword: formInputState.newPassword,
    };
    // Do the update pwd
    setLoading(true);
    try {
      const url = '/auth/forgatePassword';
      const response = await axiosCall.post(url, data);
      if (response.status === 200) {
        showToastMessage('Successfully set new password', ToastType.Success);
      } else {
        showToastMessage('Please put correct Password', ToastType.Error);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('Could not Update Password', ToastType.Error);
    } finally {
      setFormInputState(initialState);
      setLoading(false);
    }
  };

  return (
    <div id="ChangePassword" className="tab-pane mt-3 ">
      <div className="row">
        <div className="col-12">
          <h3 className="box-title">Change Password</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="mb-3">
            <label htmlFor="OldPassword" className="form-label">
              Old Password
            </label>
            <input
              type="password"
              name="oldPassword"
              value={formInputState?.oldPassword}
              onChange={handleFormInputChange}
              className="form-control"
              id="OldPassword"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <label htmlFor="NewPassword" className="form-label">
              New Password
            </label>
            <input
              type="password"
              className="form-control"
              name="newPassword"
              value={formInputState?.newPassword}
              onChange={handleFormInputChange}
              id="NewPassword"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <label htmlFor="ConfirmNewPassword" className="form-label">
              Confirm New Password
            </label>
            <input
              type="password"
              className="form-control"
              name="confirmNewPassword"
              value={formInputState?.confirmNewPassword}
              onChange={handleFormInputChange}
              id="ConfirmNewPassword"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleFormSubmit}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSettingChangePassword;
