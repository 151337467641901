import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import axiosCall from '../../../utils/axiosCall';
import { useLocation } from 'react-router-dom';
import DisableSection from '../../../utils/DisableSection/DisableSection';
import { ListingSection } from '../../../constants/ListingSection';
import { BsPencilFill } from 'react-icons/bs';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import { IoCloseCircleOutline } from 'react-icons/io5';
import FormikFormInput from '../../../utils/FormikFormInput';
import { setFormDirty } from '../../../redux/slices/LocationSlice';
import { useDispatch } from 'react-redux';

export type Amenity = {
  _id: string;
  id: number;
  name: string;
  order: number;
  icon: string;
  defaultInclude: boolean;
};

function DetailedInfoComponent({
  isEdit,
  handleSubmit,
  currentSection,
  setCurrentSection,
  initialValues,
  savedSection
}: {
  isEdit?: any;
  handleSubmit: (formData: FormData) => void;
  currentSection: ListingSection;
  setCurrentSection: any;
  initialValues: any;
  savedSection?: any;
}) {
  const location = useLocation();
  const [amenities, setAmenities] = useState<Amenity[]>([]);
  const lengthRef = useRef<number>(amenities.length);
  const [showOther, setShowOther] = useState(false);
  const [showError, setShowError] = useState(false);
  const otherId = useRef('');
  const dispatch = useDispatch();

  const getAllAmenities = async () => {
    try {
      const response = await axiosCall.get('/properties/getAmenities');
      if (response) {
        // console.log(response.data);
        setAmenities(response.data);
        const other = response.data?.find(
          (amenity: any) => amenity?.name?.toLowerCase() === 'others'
        );
        otherId.current = other?.id ?? '';
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllAmenities();
  }, []);

  const submitHandler = (values: any) => {
    if (showOther && values?.amenities?.other === '') {
      setShowError(true);
      return;
    }
    let newProperty: any = {
      section: values.section,
    };
    const formData = new FormData();
    const data = { ...values.amenities };
    let amenitiesObjArray = amenities.filter((amenity) =>
      data.amenities.includes(amenity.id.toString())
    );
    // filter the array based on order
    const selectedAmenities = amenitiesObjArray
      .sort((a: Amenity, b: Amenity) => a.order - b.order)
      .map((e) => e.id);
    newProperty = {
      ...newProperty,
      p_video_path: data.p_video_path,
    };
    if (showOther) {
      newProperty.other = data.other;
    }
    // Create Form
    createMultipartFormData(newProperty, formData);
    Object.values(selectedAmenities).forEach((item: any, index) => {
      formData.append(`amenities[${index}]`, item);
    });
    handleSubmit(formData);
  };

  const FormObject = () => {
    const { setFieldValue, values, dirty, isSubmitting } = useFormikContext<any>();
    useEffect(() => {
      if (dirty && !isSubmitting && currentSection === ListingSection.Amenities) {
        dispatch(setFormDirty(dirty));
      }
    }, [dirty, isSubmitting]);
    useEffect(() => {
      if (
        (location.pathname === '/dashboard/add-listing' &&
          amenities.length !== lengthRef.current) ||
        values?.amenities?.amenities?.length === 0
      ) {
        const checkedAmenities = amenities
          ?.filter((amenity) => amenity.order <= 3)
          .map((e) => e.id.toString());
        setFieldValue('amenities.amenities', checkedAmenities);
        lengthRef.current = amenities.length;
      }
    }, [amenities]);

    useEffect(() => {
      if (
        values?.amenities?.amenities?.length > 0 &&
        values?.amenities?.amenities.includes(otherId.current.toString())
      ) {
        setShowOther(true);
      } else {
        setShowOther(false);
        setFieldValue('amenities.other', '');
      }
    }, [values?.amenities?.amenities]);

    useEffect(() => {
      if (values?.amenities?.other?.length > 0) {
        setShowError(false);
      }
    }, [values?.amenities?.other]);
    return null;
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        section: ListingSection.Amenities,
      }}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      {({ values, setFieldValue, touched, errors, resetForm }) => (
        <Form>
          <FormObject />
          <div id="detailed-info">
            {currentSection !== ListingSection.Amenities && <DisableSection />}
            <div className="box-view">
              <h3 className="box-title">Detailed Information</h3>
              {(isEdit || savedSection[ListingSection.Amenities]) && (
                <div title="Edit" className="edit-property">
                  {currentSection !== ListingSection.Amenities && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(ListingSection.Amenities);
                      }}
                    >
                      <BsPencilFill className="pencil_icon" />
                    </button>
                  )}
                  {currentSection === ListingSection.Amenities && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(-1);
                        resetForm();
                      }}
                    >
                      <IoCloseCircleOutline className="cancel_icon" />
                    </button>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-xl-4 col-sm-6 mb-3">
                  <label className="form-label" htmlFor="p_video_path">
                    Video URL
                  </label>
                  <Field
                    type="text"
                    name="amenities.p_video_path"
                    placeholder=""
                    autoComplete="off"
                    className={`form-control ${
                      touched.p_video_path && errors.p_video_path
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="amenities.p_video_path"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <h3 id="checkbox-group" className="box-title mb-3 fs-5">
                Amenities
              </h3>
              <div className="" aria-labelledby="checkbox-group">
                <div className="row">
                  {amenities?.length > 0 &&
                    amenities?.map((amenity: Amenity) => (
                      <div
                        key={amenity.id}
                        className="col-xl-4 col-lg-4 col-md-6 mb-3"
                      >
                        <label key={amenity.id}>
                          <Field
                            type="checkbox"
                            name="amenities.amenities"
                            value={amenity.id.toString()}
                            className="me-1"
                            disabled={amenity.defaultInclude}
                          />
                          {amenity.name}
                        </label>
                        {showOther &&
                          amenity.name.toLowerCase() === 'others' && (
                            <>
                              <div className="col-xl-6 col-lg-4 mb-3">
                                <Field
                                  type="text"
                                  name="amenities.other"
                                  className={`me-1 form-control ${
                                    showError ? 'is-invalid' : ''
                                  }`}
                                  as={FormikFormInput}
                                />
                              </div>
                              {showError ? (
                                <div className="invalid-feedback">
                                  Field is required
                                </div>
                              ) : null}
                            </>
                          )}
                      </div>
                    ))}
                </div>
              </div>

              <div id="nearByTab" className="row my-3">
                {isEdit && currentSection === ListingSection.Amenities && (
                  <div className="col-12 text-center">
                    <a href="#locationTab">
                      <div
                        //
                        className="btn btn-outline-primary me-2"
                        onClick={() =>
                          setCurrentSection(ListingSection.Location)
                        }
                      >
                        Previous
                      </div>
                    </a>
                    <a href="#nearByTab">
                      <button
                        // href="#near-by"
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => submit?.click()}
                      >
                        Update
                      </button>
                    </a>
                  </div>
                )}
                {!isEdit && currentSection === ListingSection.Amenities && (
                  <div className="col-12 text-center">
                    <a href="#locationTab">
                      <div
                        // href="#location"
                        className="btn btn-outline-primary me-2"
                        onClick={() =>
                          setCurrentSection(ListingSection.Location)
                        }
                      >
                        Previous
                      </div>
                    </a>
                    <a href="#nearByTab">
                      <button
                        // href="#near-by"
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => submit?.click()}
                      >
                        Next
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default DetailedInfoComponent;
