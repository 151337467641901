import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import AppRoute from './Routes/AppRoute';

function App() {
  const router = createBrowserRouter([{ path: '*', element: <AppRoute /> }]);

  return <RouterProvider router={router} />;
}

export default App;
