import React, { FC, ReactElement } from "react";
import { Role, usePermissions } from "../utils/Permission/Permission"
import { Navigate } from "react-router";

type ProtectedRouteProps = {
    permission: any;
    children: ReactElement;
}

export const ProtectedRoute: FC<ProtectedRouteProps> =({
    permission, children
}) => {
    const { hasPermission } = usePermissions();

    if(!hasPermission(permission)){
        return <Navigate to='/404' />;
    }

    return children;
}