import React, { useRef, useState } from 'react';
import { FieldProps, useField } from 'formik';
import { Rating } from 'react-simple-star-rating';

interface PlaceFieldProps extends FieldProps {
    description: string;
}
interface IProps {
    description: string;
    name: string;
    onBlur: () => void;
    onChange: () => void;
    placeholder: string;
    value: string;
    disabled: boolean;
    readOnly: boolean;
    maxLength: number;
    shouldnotshowerror: boolean;
    isnegativeallowed: boolean;
    isnegativeanddotallowed: boolean;
    min: number;
    max: number;
    defaultvaluecondition: boolean;
    initialvalue: number;
    show_error_on_tooltip?: boolean;
    type: string;
    autoComplete: string;
    setFieldValue: any;
}
const FormikFieldStarRating: React.FC<IProps> = ({
    description,
    ...props
}: IProps) => {
    const [field, meta, helpers] = useField(props);
    const handleRating = (rate: number) => {
        helpers.setValue(rate)
        // other logic
    }
    return (
        <div>
            <Rating
                onClick={handleRating}
                initialValue={field.value}
                // onPointerEnter={()=>{}}
                // onPointerLeave={()=>{}}
                // onPointerMove={handleRating}
                SVGstyle={{ 'display': 'inline' }}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};
export default FormikFieldStarRating;
