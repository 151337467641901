import React, { useState } from 'react';

const DocumentPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showDocumentPopup, setShowDocumentPopup] = useState<boolean>(false);

    const documentToggleHandler = () => {
      setShowDocumentPopup(!showDocumentPopup);
    };

    return (
      <Component
        showDocumentPopup={showDocumentPopup}
        documentToggleHandler={documentToggleHandler}
        {...props}
      />
    );
  };

export default DocumentPopupHOC;
