import parsePhoneNumberFromString from 'libphonenumber-js';
import { Facilities } from '../components/AddListingsComponents/NearByComponent/NearByComponent';

export const USDateFormat = (dateString: any) => {
  const date = new Date(dateString);
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '-' + day + '-' + year;
};

export const AvailableDateFormat = (dateString: any) => {
  const date = new Date(dateString);

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day;
};

export const shortenString = (inptString: string): string => {
  const maxLength = 90;
  if (inptString.length > maxLength) {
    return (
      inptString.replace(/(<([^>]+)>)/gi, '').substring(0, maxLength - 3) +
      '...'
    );
  } else {
    return inptString.replace(/(<([^>]+)>)/gi, '');
  }
};

export const calculateRoomPrice = (
  price: number,
  deposit: number,
  cleaningCharges: number,
  petFees: number
): number => {
  // calculation logic
  return price + deposit + cleaningCharges + petFees;
};

export const getPaginationNumberText = (
  currentPage: number,
  pageSize: number,
  totalCount: number
) => {
  const startCount =
    currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1;
  const endCount = totalCount > pageSize ? currentPage * pageSize : totalCount;

  return `Showing ${startCount} - ${endCount} from ${totalCount}`;
};

export const getRoomsPaginationNumberText = (
  currentPage: number,
  pageSize: number,
  totalCount: number
) => {
  const startCount = 1;
  const endCount = pageSize;

  return `Showing ${startCount} - ${endCount} from ${totalCount}`;
};

export const stringSentenceCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const dateFormatForBE = 'MM/DD/YYYY';

export const dateTimeFormat = 'MM/DD/YYYY HH:mm:ss';

export const statesOptions = [
  { Id: 1, Name: 'Alabama' },
  { Id: 2, Name: 'Alaska' },
  { Id: 3, Name: 'Arizona' },
  { Id: 4, Name: 'Arkansas' },
  { Id: 5, Name: 'California' },
  { Id: 6, Name: 'Colorado' },
  { Id: 7, Name: 'Connecticut' },
  { Id: 8, Name: 'Delaware' },
  { Id: 9, Name: 'Florida' },
  { Id: 10, Name: 'Georgia' },
  { Id: 11, Name: 'Hawaii' },
  { Id: 12, Name: 'Idaho' },
  { Id: 13, Name: 'Illinois' },
  { Id: 14, Name: 'Indiana' },
  { Id: 15, Name: 'Iowa' },
  { Id: 16, Name: 'Kansas' },
  { Id: 17, Name: 'Kentucky' },
  { Id: 18, Name: 'Louisiana' },
  { Id: 19, Name: 'Maine' },
  { Id: 20, Name: 'Maryland' },
  { Id: 21, Name: 'Massachusetts' },
  { Id: 22, Name: 'Michigan' },
  { Id: 23, Name: 'Minnesota' },
  { Id: 24, Name: 'Mississippi' },
  { Id: 25, Name: 'Missouri' },
  { Id: 26, Name: 'Montana' },
  { Id: 27, Name: 'Nebraska' },
  { Id: 28, Name: 'Nevada' },
  { Id: 29, Name: 'New Hampshire' },
  { Id: 30, Name: 'New Jersey' },
  { Id: 31, Name: 'New Mexico' },
  { Id: 32, Name: 'New York' },
  { Id: 33, Name: 'North Carolina' },
  { Id: 34, Name: 'North Dakota' },
  { Id: 35, Name: 'Ohio' },
  { Id: 36, Name: 'Oklahoma' },
  { Id: 37, Name: 'Oregon' },
  { Id: 38, Name: 'Pennsylvania' },
  { Id: 39, Name: 'Rhode Island' },
  { Id: 40, Name: 'South Carolina' },
  { Id: 41, Name: 'South Dakota' },
  { Id: 42, Name: 'Tennessee' },
  { Id: 43, Name: 'Texas' },
  { Id: 44, Name: 'Utah' },
  { Id: 45, Name: 'Vermont' },
  { Id: 46, Name: 'Virginia' },
  { Id: 47, Name: 'Washington' },
  { Id: 48, Name: 'West Virginia' },
  { Id: 49, Name: 'Wisconsin' },
  { Id: 50, Name: 'Wyoming' },
];

export const americasCountries = [
  'US',
  'CA',
  'MX',
  'GT',
  'BZ',
  'SV',
  'HN',
  'NI',
  'CR',
  'PA',
  'BS',
  'CU',
  'HT',
  'DO',
  'JM',
  'TT',
  'BB',
  'LC',
  'VC',
  'GD',
  'AG',
  'DM',
  'VC',
  'KY',
  'VG',
  'AI',
  'KN',
  'TC',
  'BQ',
  'SX',
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'EC',
  'GF',
  'GY',
  'PY',
  'PE',
  'SR',
  'UY',
  'VE',
];

export function formatPhoneNumber(e164Number: any) {
  const phoneNumber = parsePhoneNumberFromString(e164Number);

  if (!phoneNumber) {
    return e164Number;
  }

  const countryCallingCode = phoneNumber.countryCallingCode;
  const nationalNumber = phoneNumber.nationalNumber;

  // Use a regular expression to format the national number
  const formattedNationalNumber = nationalNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '($1) $2-$3'
  );
  return `+${countryCallingCode} ${formattedNationalNumber}`;
}

// export function convertToE164(formattedNumber:any) {
//   const phoneNumber = parsePhoneNumberFromString(formattedNumber, 'US'); // Default to US if country code is missing

//   if (!phoneNumber) {
//     return null;
//   }

//   return phoneNumber.format('E.164');
// }

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  //console.log(lat1, lon1, lat2, lon2);
  const toRadians = (degree: number): number => {
    return (degree * Math.PI) / 180;
  };

  const R = 3958.8; // Radius of the Earth in miles
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
};

export const getDocName = (str: string) => {
  const x = str?.split('/');
  return str !== '' && str !== undefined ? x[x.length - 1] : str;
};

export const getDocNameFromUrl = (str: string) => {
  const z = str?.split('?')[0];
  const x = z?.split('/');
  return str !== '' && str !== undefined ? x[x.length - 1] : str;
};

export const appendOrigin = (array: any, originName: string): Facilities[] => {
  return array
    ?.filter(
      (obj: any) => obj.description?.length !== 0 && obj.distance.length !== 0
    )
    ?.map((obj: any) => ({
      name: originName,
      distance: obj.distance,
      address: obj.address,
    }));
};

export const decodeEncoded = (encodedStr: string) => {
  let decodedStr = encodedStr;
  let previousStr;

  // Loop until the decoded string is the same as the previous one
  do {
    previousStr = decodedStr;
    try {
      decodedStr = decodeURIComponent(previousStr);
    } catch (e) {
      // If an error occurs, break the loop
      break;
    }
  } while (decodedStr !== previousStr);

  return decodedStr;
};
