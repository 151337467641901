import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import './AddLicense.scss';
import { Button } from 'react-bootstrap';
import FormikFormDateTimePicker from '../../../../../utils/FormikFieldDateTimePicker';
import Busy from '../../../../../assets/gif/busy.gif';
import { dateFormatForBE } from '../../../../../utils/common';
import FormikFormSearchSelectField from '../../../../../utils/FormikFieldSearchSelect';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import {
  addLicense,
  licenseInitialState,
  resetAddLicenseError,
  resetAddLicenseSuccess,
  resetGetLicenseFilledDetail,
  selectGetLicenseById,
  updateLicense,
} from '../../../../../redux/slices/DocumentSlice';
import moment from 'moment';
import {
  ToastType,
  showToastMessage,
} from '../../../../../utils/ToastOptionCommon';
import { useNavigate } from 'react-router';
import Loader from '../../../../../components/CommonComponents/Loader/Loader';
import DocumentPopupHOC from '../../../../../components/PropertyDetail/DocumentViewModal/hoc/DocumentPopupHoc';
import DocumentViewModal from '../../../../../components/PropertyDetail/DocumentViewModal/DocumentViewModal';

const apiUrl = process.env.REACT_APP_API_URL;

const AddLicense = (props: {
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.login.userData);
  const [fileOnEdit, setFileOnEdit] = useState<boolean>(false);
  const [countryState, setCountryState] = useState([]);

  const addLicenseDetail = useSelector(
    (state: any) => state.document.addLicenseDetail
  );
  const initialState = useSelector(licenseInitialState);
  const licenseDetail = useSelector(selectGetLicenseById);

  const [files, setFiles] = useState<any>([]);
  const acceptedFileTypes = ['application/pdf'];

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    const res = await fetch(`${apiUrl}/country/United States`)
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        //console.log(e);
      });
    if (res?.error) {
      showToastMessage('Failed to get states', ToastType.Error);
    } else {
      const state = res?.states?.map((e: string, idx: number) => {
        return { Id: idx, Name: e };
      });
      setCountryState(state);
    }
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    if (
      (!licenseDetail.isEdit && files.length === 0) ||
      (licenseDetail.isEdit && fileOnEdit && files.length === 0)
    ) {
      showToastMessage('Please provide atleast one document', ToastType.Error);
      setSubmitting(false);
      return;
    }
    if (files.length > 0 && !acceptedFileTypes?.includes(files?.[0]?.type)) {
      showToastMessage(
        'Please select supported file format (ie. .pdf)',
        ToastType.Error
      );
      setFiles(() => []);
      setSubmitting(false);
      return;
    }

    let date = values.expiration_date;
    if (values.expiration_date.$d) {
      date = moment(values.expiration_date.$d).format(dateFormatForBE);
    }

    const formData = new FormData();
    formData.append('user_id', userData?._id ?? '');
    formData.append('license_document', files?.[0]);
    formData.append('license_state', values.license_state);
    formData.append('license_number', values.license_number);
    formData.append('expiration_date', date);
    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    if (licenseDetail.isEdit) {
      dispatch(
        updateLicense({
          id: licenseDetail.id,
          body: formData,
        }) as unknown as UnknownAction
      );
    } else {
      dispatch(addLicense({ body: formData }) as unknown as UnknownAction);
    }
  };

  useEffect(() => {
    if (addLicenseDetail.error) {
      showToastMessage(addLicenseDetail.error, ToastType.Error);
      dispatch(resetAddLicenseError(null));
    }
  }, [addLicenseDetail.error]);

  useEffect(() => {
    if (addLicenseDetail.success) {
      if (licenseDetail.isEdit) {
        showToastMessage('License Updated Successfully', ToastType.Success);
      } else {
        showToastMessage('License Added Successfully', ToastType.Success);
      }
      navigate(-1);
      dispatch(resetAddLicenseSuccess(null));
      dispatch(resetGetLicenseFilledDetail());
    }
  }, [addLicenseDetail.success]);

  const getDocName = (str: string) => {
    const x = str?.split('/');
    return str !== '' ? x[x.length - 1] : str;
  };

  return (
    <>
      {props.showDocumentPopup && (
        <DocumentViewModal
          onModalClosed={() => props.documentToggleHandler()}
          fileUrl={initialState?.docLink}
        />
      )}
      <div className="main-content">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>Licenses</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard/Licenses">Licenses</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {licenseDetail.isEdit ? 'Edit License' : 'Add License'}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {licenseDetail.loading ? (
          <Loader isSmallLoader={true} />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={Yup.object().shape({
              license_state: Yup.string().required(
                'Please select a License State'
              ),
              license_number: Yup.string().required(
                'Please select a License Number'
              ),
              expiration_date: Yup.string().required(
                'Please select a License Expiration date'
              ),
              first_name: Yup.string().required('Please give your First Name'),
              last_name: Yup.string().required('Please give your Last Name'),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="box-view shadow-sm mb-5 h-auto">
                    <Form
                      className="user-form space-y-6 text-deep_gray"
                      noValidate
                    >
                      <div className="my-4">
                        {/* <h5>Name on license</h5> */}
                        <p>
                          Please provide information as stated on your license.
                        </p>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium leading-6 "
                        >
                          First Name
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="first_name"
                          name="first_name"
                          type="text"
                          autoComplete="first_name"
                          required
                          className="form-control"
                          // invalidcharacter={[" "]}
                          placeholder=""
                          // as={FormikFormInput}
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="text-danger errorMessage"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-medium leading-6 "
                        >
                          Last Name
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="last_name"
                          name="last_name"
                          type="text"
                          autoComplete="last_name"
                          required
                          className="form-control"
                          // invalidcharacter={[" "]}
                          placeholder=""
                          // as={FormikFormInput}
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="text-danger errorMessage"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="license_state"
                          className="block text-sm font-medium leading-6 "
                        >
                          State
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="license_state"
                          name="license_state"
                          type="license_state"
                          autoComplete="license_state"
                          required
                          // className="form-control"
                          placeholder=""
                          options={countryState}
                          as={FormikFormSearchSelectField}
                        />
                        <ErrorMessage
                          name="license_state"
                          component="div"
                          className="text-danger errorMessage"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="license_number"
                          className="block text-sm font-medium leading-6 "
                        >
                          License Number
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="license_number"
                          name="license_number"
                          type="text"
                          autoComplete="license_number"
                          required
                          className="form-control"
                          // invalidcharacter={[" "]}
                          placeholder=""
                          // as={FormikFormInput}
                        />
                        <ErrorMessage
                          name="license_number"
                          component="div"
                          className="text-danger errorMessage"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="expiration_date"
                          className="block text-sm font-medium leading-6 "
                        >
                          Expiration Date
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="expiration_date"
                          name="expiration_date"
                          type="expiration_date"
                          autoComplete="expiration_date"
                          required
                          inputFormat={dateFormatForBE}
                          outputFormat={dateFormatForBE}
                          className="form-control"
                          // invalidcharacter={[" "]}
                          placeholder=""
                          as={FormikFormDateTimePicker}
                        />
                        {/* <ErrorMessage
                        name="expiration_date"
                        component="div"
                        className="error"
                      /> */}
                      </div>

                      {licenseDetail?.isEdit && (
                        <div className="mb-3">
                          <label>
                            <input
                              type="checkbox"
                              checked={fileOnEdit}
                              name="fileOnEdit"
                              onChange={() => setFileOnEdit(!fileOnEdit)}
                            />{' '}
                            Would you like to update existing document.
                            <br />
                          </label>
                          <span className="old_doc_span">
                            {getDocName(values?.license_document_url)}
                            <svg
                              height="20"
                              viewBox="0 0 24 24"
                              width="20"
                              onClick={() => props.documentToggleHandler()}
                              className="cursor-pointer"
                            >
                              <path d="m10 3v2h-5v14h14v-5h2v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-16c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071v-16c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289zm7.586 2h-4.586v-2h8v8h-2v-4.586l-7 7-1.414-1.414z" />
                            </svg>
                          </span>
                        </div>
                      )}
                      {(!licenseDetail?.isEdit ||
                        (licenseDetail?.isEdit && fileOnEdit)) && (
                        <>
                          <div className="my-4">
                            <h5>Upload License</h5>
                            <p>
                              Verify your license by uploading a copy of it or
                              an official 3rd party verification.
                            </p>
                          </div>
                          {isSubmitting && <Loader />}

                          <div className="mb-3">
                            <input
                              type="file"
                              className="form-control"
                              id="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              accept=".pdf"
                              onChange={(e) => {
                                setFiles(() => e.target.files);
                              }}
                            />
                            {/* <Field
                                                    id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                                    name="license_type"
                                                    type="file"
                                                    required
                                                    className="form-control"
                                                    placeholder=""
                                                // as={FormikFormInput}
                                                />
                                                <ErrorMessage name="license_type" component="div" className='error' /> */}
                          </div>
                        </>
                      )}

                      <div className="border-top mt-5 pt-3 text-end">
                        <button
                          type="button"
                          className="btn btn-light px-3 py-2 me-2"
                          onClick={() => {
                            navigate(-1);
                            dispatch(resetGetLicenseFilledDetail());
                          }}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary px-3 py-2"
                          disabled={isSubmitting}
                        >
                          {addLicenseDetail.loading && (
                            <img
                              src={Busy}
                              alt="loading.."
                              className="me-2"
                              style={{ maxWidth: '16px' }}
                            />
                          )}
                          {licenseDetail.isEdit
                            ? 'Update Changes'
                            : 'Save Changes'}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>

      {/* <FormObserver />
            <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex flex-col gap-2 mb-3">
                    </div>

                    <div className="flex flex-col gap-2 mb-3">
                        

                    <div className="flex flex-col gap-2 mb-3">
                        <label
                            htmlFor="end"
                            className="block text-sm font-medium leading-6 "
                        >
                            Enter End Date
                        </label>
                        <Field
                            id="end"
                            name="end"
                            type="end"
                            autoComplete="end"
                            required
                            className="form-control"
                            // invalidcharacter={[" "]}
                            placeholder=""
                            as={FormikFormDateTimePicker}
                        />
                        <ErrorMessage name="end" component="div" className='error' />
                    </div>

                    <div>
                        <div className="m-auto my-3">
                            <Button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-deep_blue px-10
                                             py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-deep_blue 
                                             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                                             focus-visible:outline-deep_blue uppercase"
                            // onClick={() => {
                            //   handleSubmit(values);
                            // }}
                            >
                                
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Form >
                )}
            </Formik > */}
    </>
  );
};

export default DocumentPopupHOC(AddLicense);
