import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { IoClose } from 'react-icons/io5';
import Busy from '../../../assets/gif/busy.gif';
import { useDispatch } from 'react-redux';
import FormikFormDateTimePicker from '../../../utils/FormikFieldDateTimePicker';
import CustomCalendar from '../../CalenderComponents/Calender';

interface IProps {
  onModalClosed: () => void;
  events: any;
}

export interface IEvent {
  id: string;
  title: string;
  // allDay: boolean,
  start: string;
  end: string;
}

const CalenderViewModal = (props: IProps) => {
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        className="px-10 backdrop-blur-sm blue-theme modal-lg"
        show={true}
        centered
        size={'xl'}
      >
        <Modal.Header className="justify-between pb-0 bg-white border-none">
          <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
            Calender
          </Modal.Title>
          <div
            onClick={() => {
              props.onModalClosed();
            }}
            className="icons8-close"
          >
            <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="mt-2" style={{ height: '100%', width: '100%' }}>
            <CustomCalendar
              events={props.events}
              views={{
                month: true,
                day: false,
                week: false,
                agenda: false,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CalenderViewModal;
