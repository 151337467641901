import { useEffect, useState } from 'react';
import './Reviews.scss';
import axiosCall from '../../../utils/axiosCall';
import { useSelector } from 'react-redux';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import { USDateFormat } from '../../../utils/common';
import ConfirmationPopup from '../../../utils/ConfirmationPopup/ConfirmationPopup';
import UserAvatar from '../../../assets/images/user.png';
import { toast } from 'react-toastify';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import { Rating } from 'react-simple-star-rating';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import { useNavigate } from 'react-router';

interface IProps {}

function Reviews(props: IProps) {
  const [myReviews, setMyReviews] = useState<any[]>([]);
  const [visitorReviews, setVisitorReviews] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteReview, setDeleteReview] = useState<string | null>(null);
  const userData = useSelector((state: any) => state.login.userData);
  
  const navigate = useNavigate();

  useEffect(() => {
    getAllReviwes();
  }, []);

  const getAllReviwes = async () => {
    setLoading(true);
    try {
      let id = userData?._id;
      if(!id){
        const storedUser = localStorage.getItem('user');
        if (storedUser !== null) {
          const user = JSON.parse(storedUser)
          id = user?._id;
        }
      }
      const url = `/reviews/getAllReviewByUserId/${id}`;
      const result = await axiosCall.get(url);
      if (result) {
        console.log(result.data);
        // setMu reviews
        setMyReviews(result.data.my_reviews);
        // set visitor reviews
        setVisitorReviews(result.data.visitor_reviews);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteReviewById = async (reviewId: string) => {
    try {
      const url = `/reviews/delete/${reviewId}`;
      const response = await axiosCall.delete(url);
      if (response.status === 204) {
        // console.log('Review deleted');
        setMyReviews((prevState) =>
          prevState.filter((review) => review._id !== reviewId)
        );
        setVisitorReviews((prevState) =>
          prevState.filter((review) => review._id !== reviewId)
        );
        toast.success('Review deleted successfully', toastDefaults());
      }
    } catch (error) {
      console.log(error);
      toast.error('Review could not be deleted...', toastDefaults());
    }
  };

  return (
    <div className="main-content">
      <DashboardHeader />
      {loading ? (
        <Loader />
      ) : (
        <div className="review-page">
          {deleteReview != null && (
            <ConfirmationPopup
              onModalClose={() => setDeleteReview(null)}
              onSubmit={() => {
                deleteReviewById(deleteReview);
                setDeleteReview(null);
                // getAllReviwes();
              }}
              confirmationText="Are you sure you want to delete this review?"
              saveButtonText="Yes"
              cancelButtonText="No"
              modalTitle="Delete this review"
              isProgress={false}
            />
          )}
          <div className='row'>
            <div className='col-xl-6'>
              <div className="box-view h-100">
                <h3 className="mb-4">My Reviews</h3>
                {myReviews?.length > 0 ? (
                  myReviews?.map((review) => (
                    <div className="user-review mb-5" key={review?._id}>
                      <div className="d-flex mt-3">
                        <div className="review-user-img">
                          <img // to be replaced with user image
                            src={UserAvatar}
                            alt="landlord Image"
                            className="img-fluid"
                          />
                        </div>
                        <div className="ms-3 w-100">
                          <h6>
                            Your review on{' '}
                            <a
                              href="#"
                              onClick={() =>
                                navigate(
                                  `/property-detail/${review?.propertyId}`
                                )
                              }
                            >
                              {review?.propertyName.length !== 0
                                ? review?.propertyName
                                : '-'}
                            </a>
                            <div className="review-stars float-end ms-auto">
                              <Rating
                                initialValue={review.rating}
                                // onPointerEnter={()=>{}}
                                // onPointerLeave={()=>{}}
                                // onPointerMove={handleRating}
                                SVGstyle={{
                                  display: 'inline',
                                  height: '20px',
                                  width: '20px',
                                }}
                                readonly={true}
                              />
                            </div>
                          </h6>
                          <span className="review-date">
                            {USDateFormat(review?.createdAt)}
                          </span>
                          <p>{review?.content}</p>
                          <div className="review-action d-flex align-items-center gap-3">
                            {/* Edit Button */}
                            <button type="button">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 16 16"
                                fill="#034991"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M14.8786 0.521224L15.4788 1.12142C16.1737 1.81639 16.1737 2.92201 15.4788 3.61698L14.5311 4.56466L11.4353 1.4689L12.383 0.521224C13.078 -0.173741 14.1836 -0.173741 14.8786 0.521224ZM5.43337 7.47088L10.7088 2.19546L13.8045 5.29121L8.52912 10.5666C8.33959 10.7562 8.08687 10.9141 7.83416 11.0089L5.0227 11.925C4.76999 12.0197 4.4541 11.9566 4.26456 11.7354C4.04344 11.5459 3.98026 11.23 4.07502 10.9773L4.99112 8.16584C5.08588 7.91313 5.24383 7.66041 5.43337 7.47088ZM3.03258 1.84798H6.06515C6.60217 1.84798 7.07601 2.32182 7.07601 2.85884C7.07601 3.42744 6.60217 3.86969 6.06515 3.86969H3.03258C2.46397 3.86969 2.02172 4.34353 2.02172 4.88055V12.9674C2.02172 13.536 2.46397 13.9783 3.03258 13.9783H11.1194C11.6565 13.9783 12.1303 13.536 12.1303 12.9674V9.93485C12.1303 9.39783 12.5726 8.92399 13.1412 8.92399C13.6782 8.92399 14.152 9.39783 14.152 9.93485V12.9674C14.152 14.6417 12.7937 16 11.1194 16H3.03258C1.35834 16 0 14.6417 0 12.9674V4.88055C0 3.20632 1.35834 1.84798 3.03258 1.84798Z" />
                              </svg>
                            </button>
                            {/* Delete Button */}
                            <button
                              type="button"
                              onClick={() => setDeleteReview(review._id)}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 16 18"
                                fill="#034991"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M14.75 2.99999H12.425C12.0662 1.25518 10.5313 0.00225 8.75001 0H7.25C5.46866 0.00225 3.93381 1.25518 3.57501 2.99999H1.25002C0.835808 2.99999 0.500031 3.33577 0.500031 3.74998C0.500031 4.16419 0.835808 4.5 1.25002 4.5H2.00001V14.25C2.0025 16.32 3.67998 17.9975 5.75002 18H10.25C12.3201 17.9975 13.9975 16.32 14 14.25V4.5H14.75C15.1642 4.5 15.5 4.16422 15.5 3.75001C15.5 3.3358 15.1642 2.99999 14.75 2.99999ZM7.25003 12.75C7.25003 13.1642 6.91425 13.5 6.50004 13.5C6.0858 13.5 5.75002 13.1642 5.75002 12.75V8.25001C5.75002 7.8358 6.0858 7.50002 6.50001 7.50002C6.91422 7.50002 7.25 7.8358 7.25 8.25001V12.75H7.25003ZM10.25 12.75C10.25 13.1642 9.91424 13.5 9.50003 13.5C9.08582 13.5 8.75004 13.1642 8.75004 12.75V8.25001C8.75004 7.8358 9.08582 7.50002 9.50003 7.50002C9.91424 7.50002 10.25 7.8358 10.25 8.25001V12.75ZM5.12828 2.99999C5.44729 2.10171 6.2968 1.50114 7.25003 1.49998H8.75004C9.70327 1.50114 10.5528 2.10171 10.8718 2.99999H5.12828Z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h6 className="text-secondary">
                    You have written no reviews.
                  </h6>
                )}
              </div>
            </div>

            <div className='col-xl-6'>
              <div className="box-view h-100">
                <h3 className="mb-4">Visitor Reviews</h3>
                {visitorReviews?.length > 0 ? (
                  visitorReviews?.map((review) => (
                    <div className="user-review mb-5" key={review?._id}>
                      <div className="d-flex mt-3">
                        <div className="review-user-img">
                          <img
                            src={UserAvatar}
                            alt="landlord Image"
                            className="img-fluid"
                          />
                        </div>
                        <div className="ms-3 w-100">
                          <h6>
                            {review?.reviewerName} on{' '}
                            <a
                              href="#"
                              onClick={() =>
                                navigate(
                                  `/property-detail/${review?.propertyId}`
                                )
                              }
                            >
                              {review?.propertyName.length !== 0
                                ? review?.propertyName
                                : '-'}
                            </a>
                            <div className="review-stars float-end ms-auto">
                              <Rating
                                initialValue={review.rating}
                                // onPointerEnter={()=>{}}
                                // onPointerLeave={()=>{}}
                                // onPointerMove={handleRating}
                                SVGstyle={{
                                  display: 'inline',
                                  height: '20px',
                                  width: '20px',
                                }}
                                readonly={true}
                              />
                            </div>
                          </h6>
                          <span className="review-date">
                            {USDateFormat(review?.createdAt)}
                          </span>

                          <p>{review?.content}</p>
                          <div className="review-action d-flex align-items-center gap-3">
                            {/* Edit Button */}
                            {/* <button type="button">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 16 16"
                            fill="#034991"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.8786 0.521224L15.4788 1.12142C16.1737 1.81639 16.1737 2.92201 15.4788 3.61698L14.5311 4.56466L11.4353 1.4689L12.383 0.521224C13.078 -0.173741 14.1836 -0.173741 14.8786 0.521224ZM5.43337 7.47088L10.7088 2.19546L13.8045 5.29121L8.52912 10.5666C8.33959 10.7562 8.08687 10.9141 7.83416 11.0089L5.0227 11.925C4.76999 12.0197 4.4541 11.9566 4.26456 11.7354C4.04344 11.5459 3.98026 11.23 4.07502 10.9773L4.99112 8.16584C5.08588 7.91313 5.24383 7.66041 5.43337 7.47088ZM3.03258 1.84798H6.06515C6.60217 1.84798 7.07601 2.32182 7.07601 2.85884C7.07601 3.42744 6.60217 3.86969 6.06515 3.86969H3.03258C2.46397 3.86969 2.02172 4.34353 2.02172 4.88055V12.9674C2.02172 13.536 2.46397 13.9783 3.03258 13.9783H11.1194C11.6565 13.9783 12.1303 13.536 12.1303 12.9674V9.93485C12.1303 9.39783 12.5726 8.92399 13.1412 8.92399C13.6782 8.92399 14.152 9.39783 14.152 9.93485V12.9674C14.152 14.6417 12.7937 16 11.1194 16H3.03258C1.35834 16 0 14.6417 0 12.9674V4.88055C0 3.20632 1.35834 1.84798 3.03258 1.84798Z" />
                          </svg>
                        </button> */}
                            {/* Delete Button */}
                            <button
                              type="button"
                              onClick={() => setDeleteReview(review._id)}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 16 18"
                                fill="#034991"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M14.75 2.99999H12.425C12.0662 1.25518 10.5313 0.00225 8.75001 0H7.25C5.46866 0.00225 3.93381 1.25518 3.57501 2.99999H1.25002C0.835808 2.99999 0.500031 3.33577 0.500031 3.74998C0.500031 4.16419 0.835808 4.5 1.25002 4.5H2.00001V14.25C2.0025 16.32 3.67998 17.9975 5.75002 18H10.25C12.3201 17.9975 13.9975 16.32 14 14.25V4.5H14.75C15.1642 4.5 15.5 4.16422 15.5 3.75001C15.5 3.3358 15.1642 2.99999 14.75 2.99999ZM7.25003 12.75C7.25003 13.1642 6.91425 13.5 6.50004 13.5C6.0858 13.5 5.75002 13.1642 5.75002 12.75V8.25001C5.75002 7.8358 6.0858 7.50002 6.50001 7.50002C6.91422 7.50002 7.25 7.8358 7.25 8.25001V12.75H7.25003ZM10.25 12.75C10.25 13.1642 9.91424 13.5 9.50003 13.5C9.08582 13.5 8.75004 13.1642 8.75004 12.75V8.25001C8.75004 7.8358 9.08582 7.50002 9.50003 7.50002C9.91424 7.50002 10.25 7.8358 10.25 8.25001V12.75ZM5.12828 2.99999C5.44729 2.10171 6.2968 1.50114 7.25003 1.49998H8.75004C9.70327 1.50114 10.5528 2.10171 10.8718 2.99999H5.12828Z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h6 className="text-secondary">
                    There are no reviews on your listed properties.
                  </h6>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reviews;
