import React from 'react';
import './ChooseUs.scss';
import whychoseusimg from '../../../assets/images/why-chose-us.jpg';
import { FaShower } from 'react-icons/fa';

function ChooseUs() {
    return (
        <section id='Why-Choose-Us' className='bg-white'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-6 animate__animated animate__fadeInLeft'>
                        <div className='section-header'>
                            <h2>Why Choose Us</h2>
                            <p>We facilitate a smooth streamlined approach for tenants and landlords utilizing our platform,
                                by responding to rising demand for furnished home locations.</p>
                        </div>
                        <div className='key-points'>
                            <div className='d-flex gap-2 mb-3'>
                                <div className='key-points-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#74CBD9"><path d="M177.69-90v-220h100v220h-100Zm198.47 0v-491q-56.16 17-79.04 61.15-22.89 44.16-26.35 102.16h-60q3.46-118.77 75.19-186.31T480-671.54q102.31 0 155-41.8 52.69-41.81 55-142.81h60q-1.15 86.46-41.15 147.11-40.01 60.66-125.01 81.19V-90h-59.99v-237.69h-87.7V-90h-59.99ZM480-724.62q-30.69 0-52.27-21.57-21.58-21.58-21.58-52.27 0-30.69 21.58-52.27 21.58-21.58 52.27-21.58 30.69 0 52.27 21.58 21.58 21.58 21.58 52.27 0 30.69-21.58 52.27-21.58 21.57-52.27 21.57Z" /></svg>
                                </div>
                                <div className="key-points-details">
                                    <h3 className="">Convenience</h3>
                                    <p className="">Our platform offers a one-stop solution for finding furnished properties,
                                        saving users the time and hassle of searching multiple websites or contacting numerous landlords.</p>
                                </div>
                            </div>

                            <div className='d-flex gap-2 mb-3'>
                                <div className='key-points-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#74CBD9"><path d="M252.31-292.31H300v-60h360v60h47.69v-154.46q0-28.46-16.69-49.46T647.69-524v-59.08q0-30.3-21-51.3-21-21-51.31-21H384.62q-30.31 0-51.31 21-21 21-21 51.3V-524q-26.62 6.77-43.31 27.77t-16.69 49.46v154.46ZM300-400v-46.77q0-14 9.62-23.61 9.61-9.62 23.61-9.62h293.54q14 0 23.61 9.62 9.62 9.61 9.62 23.61V-400H300Zm60-127.69v-67.69q0-5.39 3.46-8.85t8.85-3.46h215.38q5.39 0 8.85 3.46t3.46 8.85v67.69H360ZM172.31-100Q142-100 121-121q-21-21-21-51.31v-615.38Q100-818 121-839q21-21 51.31-21h615.38Q818-860 839-839q21 21 21 51.31v615.38Q860-142 839-121q-21 21-51.31 21H172.31Zm0-60h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-615.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v615.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM160-160v-640 640Z" /></svg>
                                </div>
                                <div className="key-points-details">
                                    <h3 className="">Personalized Matching</h3>
                                    <p className="">We utilize advanced algorithms to match users with properties that meet their specific needs and preferences,
                                        ensuring a tailored and efficient search experience.</p>
                                </div>
                            </div>

                            <div className='d-flex gap-2 mb-3'>
                                <div className='key-points-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#74CBD9"><path d="M860-707.69v455.38Q860-222 839-201q-21 21-51.31 21H172.31Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31Zm-700 83.85h640v-83.85q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v83.85Zm0 127.68v243.85q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-243.85H160ZM160-240v-480 480Z" /></svg>
                                </div>
                                <div className="key-points-details">
                                    <h3 className="">Wide Range of Properties</h3>
                                    <p className="">With a diverse selection of furnished properties available for short to midterm stays,
                                        users have access to a variety of options to suit their budget, location preferences, and lifestyle.</p>
                                </div>
                            </div>
                            <div className='d-flex gap-2 mb-3'>
                                <div className='key-points-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#74CBD9"><path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z" /></svg>
                                </div>
                                <div className="key-points-details">
                                    <h3 className="">Verified Listings</h3>
                                    <p className="">We thoroughly vet all property listings to ensure accuracy, reliability,
                                        and compliance with our quality standards, giving users peace of mind when making their rental decisions.</p>
                                </div>
                            </div>
                            <div className='d-flex gap-2 mb-3'>
                                <div className='key-points-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#74CBD9"><path d="M455.39-140v-60h292.3q5 0 8.66-3.08 3.65-3.07 3.65-8.07v-279.77q0-114.7-82.08-193.12-82.07-78.42-197.92-78.42t-197.92 78.42Q200-605.62 200-490.92v235.53h-30q-28.77 0-49.38-20.03Q100-295.46 100-324.23v-77.69q0-19.46 11.08-35.66 11.08-16.19 28.92-26.03l1.85-51.08q4.92-65.31 33.92-121t74.38-96.96q45.39-41.27 104.77-64.31Q414.31-820 480-820t124.77 23.04q59.08 23.04 104.77 64t74.38 96.65q28.69 55.7 34.23 121l1.85 50.08q17.46 8.23 28.73 23.54Q860-426.38 860-407.54v89.31q0 18.84-11.27 34.15-11.27 15.31-28.73 23.54v49.39q0 29.53-21.19 50.34Q777.61-140 747.69-140h-292.3Zm-87.7-269.23q-14.69 0-25.04-9.96-10.34-9.96-10.34-24.66 0-14.69 10.34-24.84 10.35-10.16 25.04-10.16 14.7 0 25.04 10.16 10.35 10.15 10.35 24.84 0 14.7-10.35 24.66-10.34 9.96-25.04 9.96Zm224.62 0q-14.7 0-25.04-9.96-10.35-9.96-10.35-24.66 0-14.69 10.35-24.84 10.34-10.16 25.04-10.16 14.69 0 25.04 10.16 10.34 10.15 10.34 24.84 0 14.7-10.34 24.66-10.35 9.96-25.04 9.96ZM254.85-472q-6.23-97.92 60.92-167.58 67.15-69.65 166.23-69.65 83.23 0 146.88 51.5 63.66 51.5 77.27 133.34-85.23-1-157.5-44.76-72.27-43.77-110.96-120-15.23 74.61-63.84 131.92-48.62 57.31-119 85.23Z" /></svg>
                                </div>
                                <div className="key-points-details">
                                    <h3 className="">Responsive Customer Support</h3>
                                    <p className="">Our dedicated customer support team is available to assist users at every step of their journey,
                                        from initial inquiries to post-booking assistance, ensuring a seamless and satisfactory experience.</p>
                                </div>
                            </div>
                            <div className='d-flex gap-2 mb-3'>
                                <div className='key-points-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#74CBD9"><path d="M709.23-140H288.46v-480l265.39-263.84L587.69-850q6.23 6.23 10.35 16.5 4.11 10.27 4.11 19.35V-804l-42.46 184h268q28.54 0 50.42 21.89Q900-576.23 900-547.69v64.61q0 6.23-1.62 13.46-1.61 7.23-3.61 13.47L780.15-185.69q-8.61 19.23-28.84 32.46T709.23-140Zm-360.77-60h360.77q4.23 0 8.65-2.31 4.43-2.31 6.74-7.69L840-480v-67.69q0-5.39-3.46-8.85t-8.85-3.46H483.85L534-779.23 348.46-594.46V-200Zm0-394.46V-200v-394.46Zm-60-25.54v60H160v360h128.46v60H100v-480h188.46Z" /></svg>
                                </div>
                                <div className="key-points-details">
                                    <h3 className="">Flexibility</h3>
                                    <p className="">Whether users need a short-term rental for a few months, or a midterm stay for 6 months,
                                        our platform caters to their flexible accommodation needs, allowing for easy transitions and extensions as required.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-md-6 animate__animated animate__fadeInRight'>
                        <div className="section-feature-img">
                            <img src={whychoseusimg} className='img-fluid w-100' alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
        </section>



    );
}

export default ChooseUs;
