import { UnknownAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { IProperty } from '../../pages/Listings/Listings';
import axiosCall from '../../utils/axiosCall';
import { ListingSection } from '../../constants/ListingSection';

export interface IReview {
  _id: string;
  propertyId: string;
  userId: string;
  reviewerName: string;
  rating: number;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IPropertyDetail {
  property: IProperty;
  isLoading: boolean;
  error: string | undefined;
  success: boolean;
  addReviewsLoading: boolean;
  addReviewsError: string | undefined;
  addReviewsSuccess: boolean;
  fetchPropertySummaryLoading: boolean;
  fetchPropertySummaryError: string | undefined;
  fetchPropertySummarySuccess: boolean;
  propertySummary: Array<{
    Id: string;
    Name: string;
    isEntireUnit: boolean;
    rooms: Array<{ Id: string; Name: string }>;
  }>;
  approvePropertyLoading: boolean;
  approvePropertyError: string | undefined;
  approvePropertySuccess: boolean;
}

const initialState: IPropertyDetail = {
  property: {
    section: -1,
    other: '',
    p_price: 0,
    status: '',
    noOfGarages: 0,
    isFeaturedProperty: false,
    imagesLink: [''],
    totalFavorites: 0,
    averageRating: 0,
    _id: '',
    title: '',
    description: '',
    price: 0,
    yearly_tax_rate: 0,
    association_fee: 0,
    after_price: 0,
    p_type: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      latitude: 0,
      longitude: 0,
      _id: '',
    },
    size_in_ft: 0,
    rooms: [
      {
        name: '',
        price: 0,
        bathroomType: '',
        amenities: [{ id: 0, name: '', order: -1, icon: '', _id: '' }],
        _id: '',
        depositMoney: 0,
        cleaningCharges: 0,
        petFees: 0,
        availableFrom: '',
        imagesLink: [],
      },
    ],
    bedrooms: '',
    bathroom: '',
    available_date: [''],
    amenities: [{ id: 0, name: '', order: -1, icon: '', _id: '' }],
    user_id: '',
    user_type: 0,
    is_active: false,
    created_at: '',
    latitude: '',
    longitude: '',
    views: 0,
    p_video_path: '',
    more_info: '',
    __v: 0,
    nearby_facilities: [
      {
        name: '',
        description: '',
        distance: '',
        _id: '',
      },
    ],
    state: '',
    city: '',
    zip: '',
    isFavoriteForThisUser: false,
    isApproved: false,
    review: [],
    LandlordName: '',
    documentsLink: [],
    docsName: '',
  },
  isLoading: false,
  error: undefined,
  success: false,
  addReviewsLoading: false,
  addReviewsError: undefined,
  addReviewsSuccess: false,
  fetchPropertySummaryLoading: false,
  fetchPropertySummaryError: undefined,
  fetchPropertySummarySuccess: false,
  approvePropertyLoading: false,
  approvePropertyError: undefined,
  approvePropertySuccess: false,
  propertySummary: [],
};

export const PropertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    //set cerateListing state.createListing
    setpropertyState: (state, action) => {
      const { property, error, loading, success } = action.payload;
      state.property = property;
      state.error = error;
      state.isLoading = loading;
      state.success = success;
    },
    resetpropertyState: (state) => {
      state.success = false;
      state.error = undefined;
      state.isLoading = false;
    },
    resetpropertyData: (state) => {
      state.property = initialState.property;
    },
    setIsFavouritePropertyDetail: (state, action) => {
      state.property.isFavoriteForThisUser = true;
    },
    setIsNotFavouritePropertyDetail: (state, action) => {
      state.property.isFavoriteForThisUser = false;
    },
    resetReviewState: (state, action) => {
      state.addReviewsLoading = false;
      state.addReviewsSuccess = false;
      state.addReviewsError = undefined;
    },
    resetfetchPropertySummaryState: (state, action) => {
      // state.fetchPropertySummaryLoading = false;
      state.fetchPropertySummarySuccess = false;
      state.fetchPropertySummaryError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPropertyById.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(fetchPropertyById.fulfilled, (state, action) => {
      const amenities = action.payload?.amenities?.find((e: any) => e.isSpecific === true);
      if(amenities){
        action.payload.other = amenities?.name ?? '';
      }
      state.isLoading = false;
      state.property = { ...action.payload, section: action.payload?.section ?? -1 };
      state.success = true;
      state.error = undefined;
    });
    builder.addCase(fetchPropertyById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(addReviewsProperty.pending, (state) => {
      state.addReviewsLoading = true;
      state.addReviewsError = undefined;
    });
    builder.addCase(addReviewsProperty.fulfilled, (state, action) => {
      state.addReviewsLoading = false;
      state.addReviewsSuccess = true;
      state.addReviewsError = undefined;
    });
    builder.addCase(addReviewsProperty.rejected, (state, action) => {
      state.addReviewsLoading = false;
      state.addReviewsError = action.error.message;
      state.addReviewsSuccess = false;
    });
    builder.addCase(fetchPropertySummaryByUserId.pending, (state) => {
      state.fetchPropertySummaryLoading = true;
      state.fetchPropertySummaryError = undefined;
    });
    builder.addCase(fetchPropertySummaryByUserId.fulfilled, (state, action) => {
      state.fetchPropertySummaryLoading = false;
      state.propertySummary = action.payload;
      state.fetchPropertySummarySuccess = true;
      state.fetchPropertySummaryError = undefined;
    });
    builder.addCase(fetchPropertySummaryByUserId.rejected, (state, action) => {
      state.fetchPropertySummaryLoading = false;
      state.fetchPropertySummaryError = action.error.message;
      state.fetchPropertySummarySuccess = false;
    });
    builder.addCase(approveProperty.pending, (state) => {
      state.approvePropertyLoading = true;
      state.approvePropertyError = undefined;
    });
    builder.addCase(approveProperty.fulfilled, (state, action) => {
      state.approvePropertyLoading = false;
      state.approvePropertySuccess = true;
      state.approvePropertyError = undefined;
      state.property.isApproved = !state.property.isApproved;
    });
    builder.addCase(approveProperty.rejected, (state, action) => {
      state.approvePropertyLoading = false;
      state.approvePropertyError = action.error.message;
      state.approvePropertySuccess = false;
    });
  },
});

export const {
  setpropertyState,
  resetReviewState,
  resetfetchPropertySummaryState,
  setIsFavouritePropertyDetail,
  setIsNotFavouritePropertyDetail,
  resetpropertyState,
  resetpropertyData,
} = PropertySlice.actions;

export default PropertySlice.reducer;

export const propertyById = (state: any) => state.property.property;

export const fetchPropertyById = createAsyncThunk(
  'fetchProperty',
  async (arg: { Id: string, section: number }) => {
    try {
      const response = await axiosCall.get(`/properties/get/${arg.Id}`);
      return {...response?.data, section: arg.section};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);
export const fetchPropertySummaryByUserId = createAsyncThunk(
  'fetchPropertySummary',
  async (arg: { Id: string }) => {
    try {
      let requestBody = {
        UserID: arg.Id,
        // propertyType: "Apartment",
        // status: "available",
        // isActive: true,
        // isFeaturedProperty: true,
        // pageNo: 1,
        // pageSize: 10
      };
      const response = await axiosCall.post(
        `/properties/getPropertiesSummaryByUserId`,
        requestBody
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

// export const fetchReviewsPropertyById = createAsyncThunk(
//   'fetchReview',
//   async (arg: { Id: string }) => {
//     try {
//       const response = await axiosCall.get(`/reviews/get/${arg.Id}`);
//       return response?.data;
//     } catch (error) {
//       if (axios.isAxiosError(error)) {
//         const axiosError = error as AxiosError;
//         if (axiosError.response) {
//           const errorMessage = axiosError.response.data;
//           return errorMessage;
//         } else {
//           console.error('Error calling backend API:', axiosError.message);
//           return Promise.reject(axiosError.message);
//         }
//       } else {
//         console.error('Error calling backend API:', error);
//         return Promise.reject(error);
//       }
//     }
//   }
// );

export const addReviewsProperty = createAsyncThunk(
  'addReview',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.post('/reviews/create', arg.body);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const approveProperty = createAsyncThunk(
  'approveProperty',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.post(
        '/properties/approveProperty',
        arg.body
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);
