import { useState } from 'react';

const ShowPdfHOC = (Component: any) =>
  function Comp(props: any) {
    const [showPdfPopup, setShowPdfPopup] = useState<boolean>(false);

    const showPdfHandler = () => {
      setShowPdfPopup(!showPdfPopup);
    };

    return (
      <Component
        showPdfPopup={showPdfPopup}
        showPdfHandler={showPdfHandler}
        {...props}
      />
    );
  };

export default ShowPdfHOC;
