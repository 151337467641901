import React from 'react';
import { ListingSection } from '../../../constants/ListingSection';
import { useLocation } from 'react-router-dom';

type IProps = {
  isEditListing: boolean;
  section: number;
};

function HeadingStatusComponent(props: IProps) {
  const { isEditListing } = props;
  const location = useLocation();
  return (
    <div className="below-header-sticky">
      <div className="below-header">
        <div className="row align-items-center">
          {isEditListing ? (
            <div className="col-xl-6">
              <h1>Edit Your Property</h1>
            </div>
          ) : (
            <div className="col-xl-6">
              <h1>Add New Property</h1>
            </div>
          )}

          <div className="col-xl-6 text-xl-end">
            <div className="btn-group tabs-pills-btn" role="group">
              <a
                href="#createListingTab"
                className={`btn ${
                  (props.section === ListingSection.Details &&
                    location.hash === '') ||
                  location.hash === '#createListingTab'
                    ? // (props.section === -1 && location.hash === '') ||
                      // (props.section === -99 && location.hash === '')
                      'active'
                    : ''
                }`}
              >
                Create
              </a>
              <a
                href="#locationTab"
                className={`btn ${
                  (props.section === ListingSection.Location &&
                    location.hash === '') ||
                  location.hash === '#locationTab'
                    ? 'active'
                    : ''
                }`}
              >
                Location
              </a>
              <a
                href="#amenitiesTab"
                className={`btn ${
                  (props.section === ListingSection.Amenities &&
                    location.hash === '') ||
                  location.hash === '#amenitiesTab'
                    ? 'active'
                    : ''
                }`}
              >
                Amenities
              </a>
              <a
                href="#nearByTab"
                className={`btn ${
                  (props.section === ListingSection.NearBy &&
                    location.hash === '') ||
                  location.hash === '#nearByTab'
                    ? 'active'
                    : ''
                }`}
              >
                Near By
              </a>
              <a
                href="#documentsTab"
                className={`btn ${
                  (props.section === ListingSection.Documents &&
                    location.hash === '') ||
                  location.hash === '#documentsTab'
                    ? 'active'
                    : ''
                }`}
              >
                Documents
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="category-component max-w-[1180px] mx-auto">
        {isEditListing ? (
          <div className="h-16 w-[267px]">
            <h2 className="font-heading font-bold text-3xl">
              Edit Your Property
            </h2>
            <p className="font-body text-base text-deep_gray">
              We are glad to see you again!
            </p>
          </div>
        ) : (
          <div className="h-16 w-[267px]">
            <h2 className="font-heading font-bold text-3xl">
              Add New Property
            </h2>
            <p className="font-body text-base text-deep_gray">
              We are glad to see you again!
            </p>
          </div>
        )}
        <div className="category-filter gap-2 text-sm font-body">
          <div className="flex flex-row items-center justify-center h-11 bg-[#ffffff] rounded-[8px] border-solid border-[#eeeeee] border-[1px]">
            <div
              className={`ml-1 h-9 px-4 py-2 cursor-pointer   ${selectedListingView === 1 &&
                ' font-semibold rounded-[6px] bg-[#EEEEEE]'
                }`}
              onClick={() => setSelectedListingView(1)}
            >
              <a href="#create-listing">Create</a>
            </div>
            <div
              className={`h-9 px-4 py-2 cursor-pointer  ${selectedListingView === 2 &&
                ' font-semibold rounded-[6px] bg-[#EEEEEE]'
                }`}
              onClick={() => setSelectedListingView(2)}
            >
              <a href="#location">Location</a>
            </div>
            <div
              className={`h-9 px-4 mr-1 py-2 cursor-pointer  ${selectedListingView === 3 &&
                ' font-semibold rounded-[6px] bg-[#EEEEEE]'
                }`}
              onClick={() => setSelectedListingView(3)}
            >
              <a href="#detailed-info">Detailed</a>
            </div>
            <div
              className={`h-9 px-4 mr-1 py-2 cursor-pointer  ${selectedListingView === 4 &&
                ' font-semibold rounded-[6px] bg-[#EEEEEE]'
                }`}
              onClick={() => setSelectedListingView(4)}
            >
              <a href="#near-by">Near By</a>
            </div>
            <div
              className={`h-9 px-4 mr-1 py-2 cursor-pointer  ${selectedListingView === 5 &&
                ' font-semibold rounded-[6px] bg-[#EEEEEE]'
                }`}
              onClick={() => setSelectedListingView(5)}
            >
              <a href="#add-media">Media</a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default HeadingStatusComponent;
