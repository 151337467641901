import { useSelector } from 'react-redux';

export enum Role {
  Admin = 'Admin',
  Landlord = 'Landlord',
  Tenant = 'Tenant',
  None = 'None',
}

const permissions = {
  admin: [Role.Admin],
  landlord: [Role.Landlord],
  tenant: [Role.Tenant],
  'admin.landlord': [Role.Admin, Role.Landlord],
  'landlord.tenant': [Role.Tenant, Role.Landlord],
  'admin.tenant': [Role.Admin, Role.Tenant],
  'all.user': [Role.Admin, Role.Landlord, Role.Tenant],
};

export type PermissionName = keyof typeof permissions;

const getUserRole = () =>
  (JSON.parse(localStorage.getItem('user') ?? 'null')?.currentRole || 'None') as Role;

export const usePermissions = () => {
  const userRole: Role = getUserRole();
  const hasPermission = (permissionName: PermissionName) => {
    return permissions[permissionName].includes(Role[userRole]);
  };

  return { hasPermission };
};

export const checkPermissions = () => {
  const userRole: Role = getUserRole();
  const hasPermission = (permissionName: PermissionName) => {
    return permissions[permissionName].includes(Role[userRole]);
  };

  return { hasPermission };
};
