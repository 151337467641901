import React, { useState } from 'react';
import './FeaturedHomes.scss';
import '../../../assets/images/home1.jpg';
import CardsPagination from '../../CommonComponents/CardsPagination/CardsPagination';
import { dummyPropertyData } from '../../../utils/dummyData';
import SignInPopupHOC from '../../../pages/Login/hoc/SigninHoc';
import SignUpPopupHOC from '../../../pages/SignUp/hoc/SignupHoc';
import SignIn from '../../../pages/Login/Signin';
import SignUp from '../../../pages/SignUp/Signup';

interface IProps {
  featuredProperties: any[];
  signInToggleHandler: () => void;
  showSignInPopup: boolean
  signUpToggleHandler: () => void;
  showSignUpPopup: boolean;
  favedId: string;
  setFavedId: React.Dispatch<React.SetStateAction<string>>;
}
function FeaturedHomes(props: IProps) {
  const { featuredProperties } = props;
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(4);

  return (
    <>
      {props.showSignInPopup && (
        <SignIn
          modalClosedSignIn={props.signInToggleHandler}
          modalClosedSignUp={props.signUpToggleHandler}
        />
      )}

      {props.showSignUpPopup && (
        <SignUp
          modalClosedSignUp={props.signUpToggleHandler}
          modalClosedSignIn={props.signInToggleHandler}
        />
      )}

      <section id="featured-properties" className='properties'>
        <div className="container">
          <div className='row mb-4'>
            <div className='col-12'>
              <h2>Featured Properties</h2>
              {/* <p>Get some Inspirations from 800+ Properties</p> */}
            </div>
          </div>

            <CardsPagination
              items={featuredProperties}
              startIndex={startIndex}
              endIndex={endIndex}
              setEndIndex={setEndIndex}
              setStartIndex={setStartIndex}
              signInToggleHandler={props.signInToggleHandler}
              favedId={props.favedId} setFavedId={props.setFavedId}
            />
        </div>
      </section>
    </>
  );
}

export default SignUpPopupHOC(SignInPopupHOC(FeaturedHomes));
