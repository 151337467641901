import React, { useEffect, useState } from 'react';
import './ListingCardComponent.scss';
import {
  FaHeart,
  FaRegHeart,
  FaCouch,
  FaShower,
  FaBed,
  FaPalette,
  FaPaw,
  FaStar,
  FaWifi,
  FaAirFreshener,
  FaCampground,
  FaHandsWash,
  FaCookie,
  FaGripLinesVertical,
  FaFire,
  FaSnowflake,
  FaCoffee,
  FaWater,
  FaRunning,
} from 'react-icons/fa';
import { property } from '../../../utils/dummyData';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import {
  deleteFavProperty,
  postFavProperty,
  resetListingState,
  setIsFavourite,
} from '../../../redux/slices/ListingSlice';
import { toast } from 'react-toastify';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import { FaDoorClosed, FaHouseFloodWater } from 'react-icons/fa6';
import { redirect, useNavigate } from 'react-router';
import { AvailableDateFormat, USDateFormat } from '../../../utils/common';
import { Carousel } from 'react-bootstrap';
import PropertySample from '../../../assets/images/PropertySample.jpg';

interface IProps {
  propertiesValues: property;
  signInToggleHandler: () => void;
  favedId: string;
  setFavedId: React.Dispatch<React.SetStateAction<string>>;
  isFeatureDisplay?: boolean;
  selectedView?: number;
}
function ListingCardComponent(props: IProps) {
  const [isLiked, setIsLiked] = useState<undefined | boolean>(
    props.propertiesValues.isFavourite ?? false
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUnLike = (idToUnLike: any) => {
    if (localStorage.getItem('isLoggedin')) {
      dispatch(
        deleteFavProperty({
          body: {
            user_id: JSON.parse(localStorage.getItem('user') ?? '')._id ?? '',
            propertie_id: props.propertiesValues._id,
          },
        }) as unknown as UnknownAction
      );
      props.setFavedId(idToUnLike);
    } else {
      props.signInToggleHandler();
    }
  };

  const handleLike = (idToLike: any) => {
    if (localStorage.getItem('isLoggedin')) {
      dispatch(
        postFavProperty({
          body: {
            user_id: JSON.parse(localStorage.getItem('user') ?? '')._id ?? '',
            propertie_id: props.propertiesValues._id,
          },
        }) as unknown as UnknownAction
      );
      // dispatch(setIsFavourite({id:idToLike}));
      props.setFavedId(idToLike);
    } else {
      props.signInToggleHandler();
    }
  };

  const gotoDetail = (id: string) => {
    navigate(`/property-detail/${id}`);
  };
  return (
    // <div className='row'>
    <div className={props.isFeatureDisplay ? 'px-2 h-100' : props.selectedView == 2 ? 'col-md-4 mb-4' : 'col-md-6 mb-4'}>
      <div className="properties-card shadow-sm bg-white">
        {/* {isNew && (
        <div className="z-100 fixed bg-gradient-to-r from-white to-[#79CEDC] bg-opacity-70 rounded-lg px-2 py-1 m-2">
          <p>New</p>
        </div>
      )}
        // TODO: FIX the icons
      {isFeatured && (
        <div className="z-100 fixed bg-gradient-to-r from-white to-[#79CEDC] bg-opacity-70 rounded-lg px-2 py-1 m-2">
          <p>Featured</p>
        </div>
      )} */}

        <div className="properties-thumb">
          {props.propertiesValues.fillImg?.length > 0 ? (
            <Carousel className="h-100 w-100">
              {props.propertiesValues.fillImg?.map((image, i) => (
                <Carousel.Item key={i}>
                  <img
                    onClick={() => {
                      gotoDetail(props.propertiesValues.propertyId);
                    }}
                    // src={propertyDetail.imagesLink.length > 0 ? propertyDetail.imagesLink : villa} // logic added for now
                    src={image} // logic added for now
                    alt="villa"
                    style={{
                      width: '-webkit-fill-available',
                      height: '200px',
                    }}
                    className=""
                  />
                  {/* <Carousel.Caption>
                                                <h3>First slide label</h3>
                                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                            </Carousel.Caption> */}
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <img
              onClick={() => {
                gotoDetail(props.propertiesValues.propertyId);
              }}
              src={PropertySample}
              alt={props.propertiesValues.title + 'fillImg'}
              className=""
              style={{
                width: '-webkit-fill-available',
                height: '200px',
              }}
            />
          )}

          {/* <div className="properties-thumb-top">
            {props.propertiesValues.isFeatured && (
              <span className="thumb-label feature-label">Feature</span>
            )}
          </div>

          <div className="properties-thumb-left-top">
            {props.propertiesValues.isNew && (
              <span className="thumb-label new-label">New</span>
            )}
          </div> */}
          <div className="properties-thumb-left-bottom">
            {props.propertiesValues.type && (
              <span
                className="thumb-label property-type-label"
                style={{ textTransform: 'capitalize' }}
              >
                {props.propertiesValues.type}
              </span>
            )}
          </div>

          {/* <div className="properties-thumb-bottom">
            {props.propertiesValues.availabilityDate && (
              <span className="thumb-label available-label">Available</span>
            )}
          </div> */}
        </div>

        <div className="p-3">
          <div className="d-flex align-items-center">
            <p className="price-label">
              <span>
                {props.propertiesValues.price.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                })}
              </span>
              /month
            </p>
            <h6 className="available-span">
              Available{' '}
              {AvailableDateFormat(props?.propertiesValues?.availabilityDate)}
            </h6>
            <div className="ms-auto">
              {/* <img src={LoveIcon} alt="love-icon" /> */}
              {props.propertiesValues.isFavourite ? (
                <FaHeart
                  onClick={() => handleUnLike(props.propertiesValues._id)}
                  className="wishlist-icon-red"
                />
              ) : (
                <FaRegHeart
                  className="wishlist-icon"
                  onClick={() => handleLike(props.propertiesValues._id)}
                />
              )}
            </div>
          </div>
          <h3
            className="properties-title my-2"
            onClick={() => {
              gotoDetail(props.propertiesValues.propertyId);
            }}
          >
            {props.propertiesValues.title}
          </h3>
          <ul
            className="aminities-section"
            onClick={() => {
              gotoDetail(props.propertiesValues.propertyId);
            }}
          >
            {props.propertiesValues.features
              ?.slice(0, 6)
              ?.map((feature: any, index: number) => (
                <li key={feature._id}>
                  <div className="d-flex align-items-center">
                    <div
                      dangerouslySetInnerHTML={{ __html: feature.icon }}
                      className="me-1"
                    ></div>
                    {/* {GetIconForFeature(feature.name)} */}
                    <span>{feature.name}</span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default ListingCardComponent;
