import './UnderDevelopment.scss';

export const UnderDevelopment = () => {
  return (
    <div className='layout'>
      <h1>🚧 Under Development 🚧</h1>
      <p>We're working hard to bring you something awesome!</p>
      <p>Stay tuned and check back soon.</p>
    </div>
  );
};
