import { PayPalButtons } from '@paypal/react-paypal-js';
import './PaypalGateway.scss';
import { useNavigate } from 'react-router';
import { ToastType, showToastMessage } from '../../../../utils/ToastOptionCommon';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { paypalCancelHandler } from '../../../../redux/slices/PaymentSlice';
import { useState } from 'react';
const apiUrl = process.env.REACT_APP_API_URL;

interface IPorps {
  pkgId: string;
}

export const PaypalGateway = (props: IPorps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const style: any = {
    color: 'blue',
    // shape: 'pill',
    label: 'pay',
    height: 40,
    tagline: 'false',
    layout: 'horizontal',
  };
  const [orderId, setOrderId] = useState('');

  const createOrder = async (data: any) => {
    try{
    // Order is created on the server and the order id is returned
      return fetch(`${apiUrl}/payment/paypal/createOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          plan: props.pkgId,
          paymentSource: data?.paymentSource
        }),
      })
        .then((response) => response.json())
        .then((order) => {
          setOrderId(order.id);
          return order.id
        });
    }
    catch(ex){
      //console.log(ex);
      showToastMessage('Error occured!', ToastType.Error);
    }

  };

  const onApprove = async (data: any) => {
    try{
    // Order is captured on the server and the response is returned to the browser
    return fetch(`${apiUrl}/payment/paypal/captureOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        id: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.success) {
          navigate('/payment/success');
        } else {
          navigate('/payment/failed');
        }
      });
    }
    catch(ex){
      //console.log(ex);
      showToastMessage('Error occured!', ToastType.Error);
    }
  };

  const onError = (err: any) =>{
    dispatch(paypalCancelHandler({id: orderId, error: 2}));
  }

  const onCancel = (data: Record<string, unknown>, actions: any) =>{
    dispatch(paypalCancelHandler({id: data.orderID, error: 1}));
  }

  return (
    <>
      <PayPalButtons
        style={style}
        className="paypal-button-container"
        createOrder={(data) => createOrder(data)}
        onApprove={(data) => onApprove(data)}
        onError={onError}
        onCancel={onCancel}
      />
    </>
  );
};
