import React, { useState } from 'react';

const AddCalenderPopupHOC = (Component: any) => 
    
function Comp(props: any){
    const [showAddCalenderPopup,setShowAddCalenderPopup] = useState<boolean>(false);

    const addCalenderToggleHandler = () => {
        setShowAddCalenderPopup(!showAddCalenderPopup);
    }
	
	return <Component showAddCalenderPopup={showAddCalenderPopup} addCalenderToggleHandler={addCalenderToggleHandler} {...props} />;
};

export default AddCalenderPopupHOC;