import axios, { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';

const initialState = {
  chatData: {
    isFromDashboardChat: false,
    chatId: '',
    data: null,
  },
  activeUsers: []
};

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatData: (state, action) => {
      state.chatData.isFromDashboardChat = true;
      state.chatData.chatId = action.payload.chatId;
      state.chatData.data = action.payload.data;
    },
    resetChatData: (state) => {
      state.chatData.isFromDashboardChat = false;
      state.chatData.chatId = '';
      state.chatData.data = null;
    },
    setActiveUsers: (state, action) => {
      state.activeUsers = action.payload;
    },
  },
});

export const { setChatData, resetChatData, setActiveUsers } = ChatSlice.actions;

export default ChatSlice.reducer;

export const chatState = (state: any) => state.chat.chatData;
export const activeUsers = (state: any) => state.chat.activeUsers;
