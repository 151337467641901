import './AddCertificate.scss';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import './AddCertificate.scss';
import FormikFormDateTimePicker from '../../../../../utils/FormikFieldDateTimePicker';
import Busy from '../../../../../assets/gif/busy.gif';
import { dateFormatForBE } from '../../../../../utils/common';
import FormikFormSearchSelectField from '../../../../../utils/FormikFieldSearchSelect';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import {
  addCertificate,
  certificateInitialState,
  resetAddCertificateError,
  resetAddCertificateSuccess,
  resetGetCertificateFilledDetail,
  resetGetLicenseFilledDetail,
  selectCertificateById,
  updateCertificate,
} from '../../../../../redux/slices/DocumentSlice';
import moment from 'moment';
import {
  ToastType,
  showToastMessage,
} from '../../../../../utils/ToastOptionCommon';
import { useNavigate } from 'react-router';
import Loader from '../../../../../components/CommonComponents/Loader/Loader';
import { Link } from 'react-router-dom';
import { certificateSchema } from '../../../../../utils/yupSchemas/CertificateSchema';
import FormikFormInput from '../../../../../utils/FormikFormInput';
import { useCertificate } from '../../../../../hooks/useCertificate';
import DocumentViewModal from '../../../../../components/PropertyDetail/DocumentViewModal/DocumentViewModal';
import DocumentPopupHOC from '../../../../../components/PropertyDetail/DocumentViewModal/hoc/DocumentPopupHoc';

const AddCertificate = (props: {
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, certificates } = useCertificate();
  const [fileOnEdit, setFileOnEdit] = useState<boolean>(false);
  const [showFileError, setshowFileError] = useState({
    isError: false,
    msg: '',
  });

  const addCertificateDetail = useSelector(
    (state: any) => state.document.addCertificateDetail
  );
  const initialState = useSelector(certificateInitialState);
  const certificateDetail = useSelector(selectCertificateById);

  const [files, setFiles] = useState<any>([]);
  const acceptedFileTypes = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  const sampleCertificateTypeArray = [
    { Id: 1, Name: 'SANE: Sexual Assault Nurse Examiner' },
    { Id: 2, Name: '(ABMP) Hyperthermia: (ABMP) Hyperthermia' },
  ];

  useEffect(() => {
    if (showFileError && files?.length > 0) {
      checkSubmmiting();
    }
  }, [files]);

  useEffect(() => {
    if (addCertificateDetail.error) {
      showToastMessage(addCertificateDetail.error, ToastType.Error);
      dispatch(resetAddCertificateError());
    }
  }, [addCertificateDetail.error]);

  useEffect(() => {
    if (addCertificateDetail.success) {
      if (certificateDetail.isEdit) {
        showToastMessage('Certificate Updated Successfully', ToastType.Success);
      } else {
        showToastMessage('Certificate Added Successfully', ToastType.Success);
      }
      navigate(-1);
      dispatch(resetAddCertificateSuccess());
      dispatch(resetGetCertificateFilledDetail());
    }
  }, [addCertificateDetail.success]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    if (showFileError.isError) {
      setSubmitting(false);
      return;
    }
    let date = values.expiration_date;
    if (values.expiration_date.$d) {
      date = moment(values.expiration_date.$d).format(dateFormatForBE);
    }
    const formData = new FormData();
    formData.append('certificate_document', files?.[0]);
    formData.append('certification_type', values.certification_type);
    formData.append('issuing_body', values.issuing_body);
    formData.append('expiration_date', date);
    if (certificateDetail.isEdit) {
      dispatch(
        updateCertificate({
          id: certificateDetail.id,
          body: formData,
        }) as unknown as UnknownAction
      );
    } else {
      dispatch(addCertificate({ body: formData }) as unknown as UnknownAction);
    }
  };

  const checkSubmmiting = () => {
    if (
      (!certificateDetail.isEdit && files?.length === 0) ||
      (certificateDetail.isEdit && fileOnEdit && files?.length === 0)
    ) {
      setshowFileError({
        isError: true,
        msg: 'Please provide atleast one document',
      });
    } else if (
      files?.length > 0 &&
      !acceptedFileTypes?.includes(files?.[0]?.type)
    ) {
      setshowFileError({
        isError: true,
        msg: 'Please select supported file format (ie. .pdf, .jpg, .jpeg, .png)',
      });
      setFiles(() => []);
    } else {
      setshowFileError({ isError: false, msg: '' });
    }
  };

  const getDocName = (str: string) => {
    const x = str?.split('/');
    return x[x?.length - 1];
  };

  return (
    <>
      {props.showDocumentPopup && (
        <DocumentViewModal
          onModalClosed={() => props.documentToggleHandler()}
          fileUrl={initialState?.docLink}
        />
      )}
      <div className="main-content">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>Certifications</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/certificates">Certifications</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {certificateDetail.isEdit
                      ? 'Edit Certificate'
                      : 'Add Certificate'}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {certificateDetail.loading ? (
          <Loader isSmallLoader={true} />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={certificateSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="box-view shadow-sm mb-5 h-auto">
                    <Form
                      className="user-form space-y-6 text-deep_gray"
                      noValidate
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="certification_type"
                          className="block text-sm font-medium leading-6 "
                        >
                          Certification Type
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="certification_type"
                          name="certification_type"
                          type="text"
                          autoComplete="certification_type"
                          required
                          loading={loading}
                          className="form-control"
                          placeholder=""
                          options={certificates}
                          as={FormikFormSearchSelectField}
                        />
                        <ErrorMessage
                          name="certification_type"
                          component="div"
                          className="text-danger errorMessage"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="issuing_body"
                          className="block text-sm font-medium leading-6"
                        >
                          Issuing Body
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="issuing_body"
                          name="issuing_body"
                          type="text"
                          autoComplete="issuing_body"
                          className="form-control"
                          as={FormikFormInput}
                        />
                        <div className="my-4 mt-0 pt-0 mb-0 pb-0">
                          <p>Ex: American Heart Association (AHA)</p>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="expiration_date"
                          className="block text-sm font-medium leading-6 "
                        >
                          Expiration Date
                          <span className="required">*</span>
                        </label>
                        <Field
                          id="expiration_date"
                          name="expiration_date"
                          type="expiration_date"
                          autoComplete="expiration_date"
                          required
                          inputFormat={dateFormatForBE}
                          outputFormat={dateFormatForBE}
                          className="form-control"
                          placeholder=""
                          as={FormikFormDateTimePicker}
                        />
                        <div className="my-4 mt-0 pt-0 mb-0 pb-0">
                          <p>
                            No specific expiration day? Select the 1st of the
                            month.
                          </p>
                        </div>
                      </div>

                      {certificateDetail.isEdit && (
                        <div className="mb-3">
                          <label>
                            <input
                              type="checkbox"
                              checked={fileOnEdit}
                              name="fileOnEdit"
                              onChange={() => setFileOnEdit(!fileOnEdit)}
                            />{' '}
                            Would you like to update existing document.
                            <br />
                          </label>
                          <span className="old_doc_span">
                            {getDocName(values?.document_url)}
                            <svg
                              height="20"
                              viewBox="0 0 24 24"
                              width="20"
                              onClick={() => props.documentToggleHandler()}
                              className="cursor-pointer"
                            >
                              <path d="m10 3v2h-5v14h14v-5h2v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-16c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071v-16c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289zm7.586 2h-4.586v-2h8v8h-2v-4.586l-7 7-1.414-1.414z" />
                            </svg>
                          </span>
                        </div>
                      )}
                      {(!certificateDetail.isEdit ||
                        (certificateDetail.isEdit && fileOnEdit)) && (
                        <>
                          <div className="my-4">
                            <h5>Upload Document</h5>
                            <p>
                              Verify your certification by uploading a copy of
                              it or an official 3rd party verification.
                            </p>
                          </div>
                          {isSubmitting && <Loader />}

                          <div className="mb-3">
                            <input
                              type="file"
                              className="form-control"
                              id="document_url"
                              aria-describedby="document_url"
                              aria-label="Upload"
                              accept=".pdf, .jpeg, .jpg, .png"
                              onChange={(e) => {
                                setFiles(() => e.target.files);
                              }}
                            />
                            {showFileError.isError && (
                              <span
                                data-testid="testError"
                                className="text-danger errorMessage"
                              >
                                {showFileError.msg}
                              </span>
                            )}
                          </div>
                        </>
                      )}

                      <div className="border-top mt-5 pt-3 text-end">
                        <button
                          type="button"
                          className="btn btn-light px-3 py-2 me-2"
                          onClick={() => {
                            navigate(-1);
                            dispatch(resetGetLicenseFilledDetail());
                          }}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary px-3 py-2"
                          disabled={isSubmitting}
                          onClick={checkSubmmiting}
                        >
                          {addCertificateDetail.loading && (
                            <img
                              src={Busy}
                              alt="loading.."
                              className="me-2"
                              style={{ maxWidth: '16px' }}
                            />
                          )}
                          {certificateDetail.isEdit
                            ? 'Update Changes'
                            : 'Save Changes'}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default DocumentPopupHOC(AddCertificate);
