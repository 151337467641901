import { useField } from "formik";
import React, { useState, useEffect } from "react";
import Select, { DropdownIndicatorProps, components } from 'react-select';

interface IOption {
  Id: number | string; Name: string; hidden?: boolean
}

interface IProps {
  children: React.ReactNode;
  className: string;
  "data-testid"?: string;
  intl: any;//IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  options: Array<IOption>;
  label: string;
  typeofoptionid: string;
  changeHandler?: (value: any) => void
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<{ label: string; value: string | number; key: string | number; }, false>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="material-symbols-outlined select-arrow">expand_more</span>
    </components.DropdownIndicator>
  );
};

const FormikFormSearchSelectField = (props: IProps) => {
  const [focus, setFocus] = useState(false);
  const [field, meta, helpers] = useField(props);
  const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;

  const { typeofoptionid } = props;

  useEffect(() => {
    setFocus(field.value !== "")
  }, [field.value])

  const value = props.options.find((op) => op.Name === field.value);
  let selectedValue: { label: string; value: number | string; key: number | string } = {
    label: '',
    value: " ",
    key: 0
  }
  if (value)
    selectedValue = {
      label: value.Name,
      value: value.Name,
      key: value.Id
    }
  const customNoOptionsMessage = () => (
    <div>
      "Select"
    </div>
  );
  const filterOptions = (candidate: any, input: string) => {
    return candidate?.label?.toLowerCase().includes(input?.toLowerCase());
  };

  return (
    <div data-testid={props["data-testid"]}>
      <Select
        {...props}
        {...field}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(field.value !== "")}
        isDisabled={props.disabled}
        value={selectedValue}
        filterOption={filterOptions}
        noOptionsMessage={customNoOptionsMessage}
        options={props.options.filter(option => !option.hidden)?.map((option) => {
          return {
            label: option.Name,
            value: option.Name,
            key: option.Id
          }
        })}
        // inputId="ss-input"
        // classNamePrefix="search-select"
        // className={props.className + ' ' + errorClass}
        onChange={(option: any) => {
          props.changeHandler?.(option.value);
          helpers.setValue(option.value)
        }}
        aria-label={props["data-testid"]}
        components={{ DropdownIndicator }}
      />
      {/* {props.label?.length > 0 && <label className={`${focus ? "label-focus" : ""}`} ><span>{props.label}</span></label>} */}
    </div>
  );
};

export default FormikFormSearchSelectField;