import { Button } from 'react-bootstrap';
import { UnderDevelopment } from '../../../../utils/UnderDevelopment/UnderDevelopment';
import './Licenses.scss';
import '../Documents.scss';
import Busy from '../../../../assets/gif/busy.gif';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLicenseById,
  deleteLicenseDetail,
  getAllLicense,
  getLicenseById,
  resetDeleteLicenseDetail,
  resetGetLicense,
  resetGetLicenseDetail,
  selectGetLicenseById,
} from '../../../../redux/slices/DocumentSlice';
import moment from 'moment';
import { UnknownAction } from '@reduxjs/toolkit';
import {
  ToastType,
  showToastMessage,
} from '../../../../utils/ToastOptionCommon';
import { AppDispatch } from '../../../../redux/store';
import { BiSolidTrash } from 'react-icons/bi';
import Loader from '../../../../components/CommonComponents/Loader/Loader';
import { BsFillEyeFill } from 'react-icons/bs';

const apiUrl = process.env.REACT_APP_API_URL;

export const Licenses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userId = JSON.parse(localStorage.getItem('user') ?? '')?._id ?? '';
  const userData = useSelector((state: any) => state.login.userData);
  const [deleteId, setDeleteId] = useState('');
  const [isView, setIsView] = useState(false);

  const getAllLicenseDetails = useSelector(
    (state: any) => state.document.getAllLicenseDetails
  );
  const getLicenseDetail = useSelector(selectGetLicenseById);
  const deleteLicense = useSelector(deleteLicenseDetail);
  const callGetAllLicense = () => {
    dispatch(
      getAllLicense({
        body: { userId: userData?._id ?? userId },
      }) as unknown as UnknownAction
    );
  };
  useEffect(() => {
    callGetAllLicense();
    dispatch(resetGetLicense(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getAllLicenseDetails.error) {
      showToastMessage(getAllLicenseDetails.error, ToastType.Error);
    }
    dispatch(resetGetLicense(null));
  }, [getAllLicenseDetails.error]);

  const editLicenseHandler = (id: string) => {
    dispatch(getLicenseById({ id }));
  };
  const viewLicenseHandler = (id: string) => {
    setIsView(() => true);
    dispatch(getLicenseById({ id }));
  };

  useEffect(() => {
    if (getLicenseDetail.success) {
      isView
        ? navigate('/dashboard/view-license')
        : navigate('/dashboard/fill-license');
      dispatch(resetGetLicenseDetail());
      setIsView(() => false);
    }
    if (getLicenseDetail.error) {
      showToastMessage('Failed to get license detail', ToastType.Error);
    }
  }, [getLicenseDetail]);

  useEffect(() => {
    if (deleteLicense.success) {
      showToastMessage('License deleted successfully', ToastType.Success);
      callGetAllLicense();
      dispatch(resetDeleteLicenseDetail());
      setDeleteId('');
    }
    if (deleteLicense.error) {
      showToastMessage('Failed to delete license', ToastType.Error);
      dispatch(resetDeleteLicenseDetail());
      setDeleteId('');
    }
  }, [deleteLicense]);

  const deleteLicenseHandler = (id: string) => {
    dispatch(deleteLicenseById({ id }));
    setDeleteId(id);
  };

  return (
    <div className="main-content">
      <div className="below-header">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>Licenses</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Licenses
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <button
              className="btn btn-outline-primary py-2 px-3 d-flex float-end"
              onClick={() => navigate('/dashboard/fill-license')}
            >
              <svg
                className="me-2"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="#034991"
              >
                <path d="M450.001-290.001h59.998v-160h160v-59.998h-160v-160h-59.998v160h-160v59.998h160v160Zm30.066 190q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path>
              </svg>
              Add License
            </button>
          </div>
        </div>
      </div>

      {!getAllLicenseDetails.loading &&
      getAllLicenseDetails?.data?.length === 0 ? (
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6 offset-md-3 box-view text-center">
              {/* <h2 className="text-secondary mb-3" style={{ fontSize: '80px' }}>
                Oops!
              </h2> */}
              <h4 className="mb-1">No data Found</h4>
              <p>Please add new License.</p>
              {/* <a
                href="#"
                className="btn btn-primary mx-auto text-uppercase mt-3"
              >
                Go to home page
              </a> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {getAllLicenseDetails.loading ? (
        <Loader isSmallLoader={true} />
      ) : (
        getAllLicenseDetails?.data?.map((license: any, index: number) => {
          return (
            <div className="box-view" key={license._id}>
              <div className="d-flex">
                <h3 className="box-title">{license.license_state ?? ''}</h3>

                <button
                  onClick={() => editLicenseHandler(license._id)}
                  type="button"
                  className="update-license btn p-0 ms-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M200-200h50.46l409.46-409.46-50.46-50.46L200-250.46V-200Zm-60 60v-135.38l527.62-527.39q9.07-8.24 20.03-12.73 10.97-4.5 23-4.5t23.3 4.27q11.28 4.27 19.97 13.58l48.85 49.46q9.31 8.69 13.27 20 3.96 11.31 3.96 22.62 0 12.07-4.12 23.03-4.12 10.97-13.11 20.04L275.38-140H140Zm620.38-570.15-50.23-50.23 50.23 50.23Zm-126.13 75.9-24.79-25.67 50.46 50.46-25.67-24.79Z" />
                  </svg>
                </button>
                <button
                  className="update-license btn p-0 del_btn"
                  type="button"
                  onClick={() => {
                    viewLicenseHandler(license._id);
                  }}
                >
                  {deleteLicense.loading && deleteId === license._id ? (
                    <img
                      src={Busy}
                      alt="loading.."
                      style={{ maxWidth: '16px' }}
                    />
                  ) : (
                    <BsFillEyeFill />
                  )}
                </button>
                <button
                  className="update-license btn p-0 del_btn"
                  type="button"
                  onClick={() => {
                    deleteLicenseHandler(license._id);
                  }}
                >
                  {deleteLicense.loading && deleteId === license._id ? (
                    <img
                      src={Busy}
                      alt="loading.."
                      style={{ maxWidth: '16px' }}
                    />
                  ) : (
                    <BiSolidTrash />
                  )}
                </button>
              </div>
              <div className="registered-user">
                <ul>
                  <li className="user-name">
                    <span>Name:</span>
                    {`${license.first_name ?? ''} ${license.last_name ?? ''}`}
                  </li>
                  {/* <li>
                  <span>Occupation:</span>
                  {userData?.occupation?.name ?? ''}
                </li> */}
                  <li>
                    <span>License:</span>
                    {license.license_number ?? ''}
                  </li>
                  <li>
                    <span>Expires:</span>
                    {license.expiration_date ?? ''}
                  </li>
                </ul>

                {new Date(license.expiration_date) >= new Date() ? (
                  <span className="license-status active">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#5f6368"
                    >
                      <path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z" />
                    </svg>
                    Active
                  </span>
                ) : (
                  <span className="license-status expired">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#5f6368"
                    >
                      <path d="M480-290.77q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm-30-146.15h60v-240h-60v240ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                    </svg>
                    Expired
                  </span>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
