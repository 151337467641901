import React from 'react';
import './RegisterCta.scss';
import ArrowCta from '../../../assets/images/arrowCTA.png';
import { useNavigate } from 'react-router';

function RegisterCta() {
  const navigate = useNavigate();

  return (
    <section id='Register-Now'>
      <h1 className='animate__animated animate__fadeInDown'>Unlock Exclusive Benefits By Registering Your Home At This Moment</h1>
      <div className="d-table mx-auto mt-5 position-relative animate__animated animate__fadeInUp">
        <button className='btn btn-light' onClick={() => navigate('/list-property/room')}>Register Now</button>
        <img src={ArrowCta} className='cta-arrow' alt="cta-arrow" />
      </div>
    </section>
  );
}

export default RegisterCta;
