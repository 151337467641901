import '../HousingRequest.scss';
import HousingRequestBanner from '../../assets/housing-request/images/housing-request-banner.jpg';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { HomeRequestSchema } from '../../../utils/yupSchemas/HomeRequestSchema';
import Busy from '../../../assets/gif/busy.gif';
import {
  IAgencies,
  IInitRequest,
  ISelect,
  Select,
  agenciesState,
  travelerState,
} from '../../../redux/slices/HousingRequestSlice';
import FormikFormDateTimePicker from '../../../utils/FormikFieldDateTimePicker';
import FormikFormSelectField from '../../../utils/FormikFormSelect';
import FormikFormInput from '../../../utils/FormikFormInput';
import { useEffect, useRef, useState } from 'react';
import * as lodash from 'lodash';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import { dateFormatForBE } from '../../../utils/common';
import FormikFormPhone from '../../../utils/FormikFieldPhoneNumberInput';

interface IProps {
  initialValues: IInitRequest;
  submitHandler: (data: IInitRequest) => void;
  loading: boolean;
  resetRef: any;
}
const apiUrl = process.env.REACT_APP_API_URL;

export const HousingRequestForm = (props: IProps) => {
  const { initialValues } = props;
  const [datePicker, setDatePicker] = useState(-1);
  const [setChangeHandler, setSetChangeHandler] = useState(false);
  const requesterRef = useRef<string>('Traveler');
  const stateRef = useRef<string>('');
  const [countryState, setCountryState] = useState([]);
  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateloading, setStateLoading] = useState(false);

  const TravelReasonOptions = [
    { value: 'Corporate/Business', label: 'Corporate/Business' },
    { value: 'Student', label: 'Student' },
    { value: 'Travel Nurses', label: 'Travel Nurses' },
    { value: 'Traveling Healthcare', label: 'Traveling Healthcare' },
    { value: 'Allied Health', label: 'Allied Health' },
    { value: 'Physician', label: 'Physician' },
    { value: 'Transition and Location', label: 'Transition and Location' },
    { value: 'Employee Transfer', label: 'Employee Transfer' },
    { value: 'Military', label: 'Military' },
    { value: 'Permanent Change Of Station(PCS)', label: 'Permanent Change Of Station(PCS)' },
    { value: 'Oil & Gas Company', label: 'Oil & Gas Company' },
    { value: 'Insurance Realocation', label: 'Insurance Realocation' },
    { value: 'Temporary Housing', label: 'Temporary Housing' },
    { value: 'Business Traveler', label: 'Business Traveler' },
    // { value: 'Digital Nomad', label: 'Digital Nomad' },
    // { value: 'Long Term Housing', label: 'Long Term Housing' },
    // { value: 'Other', label: 'Other' },
  ];
  
  const OccupantOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5+', label: '5+' },
  ];

  const RequesterOptions = [
    { value: "Traveler", label: "Traveler" },
    { value: "Agency", label: "Agency" },
    { value: "Military", label: "Military" },
  ];

  const BedroomOptions = [
    { value: 'Studio', label: 'Studio' },
    { value: '1 Bedroom', label: '1 Bedroom' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4+', label: '4+' },
  ];

  const PropertyOptions = [
    { value: 'Any Available Property', label: 'Any Available Property' },
    { value: 'Private Unit or Room', label: 'Private Unit or Room' },
    { value: 'Hotel', label: 'Hotel' },
  ];

  const BudgetOptions = [
    { value: '<$1200', label: '<$1200' },
    { value: '$1200-$1600', label: '$1200-$1600' },
    { value: '$1600-$2000', label: '$1600-$2000' },
    { value: '$2000-$2400', label: '$2000-$2400' },
    { value: '$2400-$2800', label: '$2400-$2800' },
    { value: '$2800-$3200', label: '$2800-$3200' },
    { value: '$3200+', label: '$3200+' },
  ];

  const StateCityComponent = (props: { ctx: string }) => {
    return (
      <>
        <div className="col-md-6 mb-3">
          <label className="form-label">Property State</label>
          <Field
            as={FormikFormSelectField}
            isLabelValueSame={true}
            id="State"
            name={`state`}
            className="form-select"
            options={countryState}
            loading={stateloading}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">Property City</label>
          <Field
            as={FormikFormSelectField}
            isLabelValueSame={true}
            id="City"
            name={`city`}
            className="form-select"
            options={city}
            loading={loading}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    getStates();
  }, []);

  const setHandler = (i: number) => {
    setDatePicker(i);
    setSetChangeHandler(!setChangeHandler);
  };

  const FormValue = () => {
    const { values, setFieldValue, resetForm } =
      useFormikContext<IInitRequest>();
    useEffect(() => {
      if (values.requester !== requesterRef.current) {
        resetForm({
          values: {
            ...values,
            city: '',
            state: '',
            agency: {
              name: '',
              contact: '',
              email: '',
            },
            termCondition: false,
            traveler: travelerState,
            agencies: [agenciesState],
          },
        });
        requesterRef.current = values.requester;
      }
    }, [values.requester]);

    useEffect(() => {
      if (values.state !== '' && values.state !== stateRef.current) {
        getCities(values.state);
        stateRef.current = values.state;
        setFieldValue('city', '');
      }
    }, [values.state]);

    useEffect(() => {
      if (datePicker !== -1 && datePicker !== -99) {
        let moveInDate = new Date(values.agencies[datePicker].moveIn);
        moveInDate.setMonth(moveInDate.getMonth() + 1);
        moveInDate.setDate(moveInDate.getDate() - 1);
        setFieldValue(
          `agencies[${datePicker}].moveOut`,
          dayjs(moveInDate).format(dateFormatForBE)
        );
      } else if (datePicker === -99) {
        let moveInDate = new Date(values.traveler.moveIn);
        moveInDate.setMonth(moveInDate.getMonth() + 1);
        moveInDate.setDate(moveInDate.getDate() - 1);
        setFieldValue(
          `traveler.moveOut`,
          dayjs(moveInDate).format(dateFormatForBE)
        );
      }
    }, [datePicker, setChangeHandler]);

    return null;
  };

  const getStates = async () => {
    setStateLoading(true);
    const res = await fetch(`${apiUrl}/country/United States`)
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        //console.log(e);
      })
      .finally(() => {
        setStateLoading(false);
      });
    if (res?.error) {
      showToastMessage('Failed to get states', ToastType.Error);
    } else {
      const state = res?.states?.map((e: string) => {
        return { value: e, label: e };
      });
      setCountryState(state);
    }
  };

  const getCities = async (state: string) => {
    setLoading(true);
    const res = await fetch(`${apiUrl}/country/United States/${state}`)
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        //console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
    if (res?.error) {
      showToastMessage('Failed to get cities', ToastType.Error);
    } else {
      const city = res?.cities?.map((e: string) => {
        return { value: e, label: e };
      });
      setCity(city);
    }
  };

  const TravelerComponent = (tprops: { ctx: string; index: number }) => {
    const { index: i, ctx } = tprops;
    const { values } = useFormikContext<IInitRequest>();
    return (
      <>
        <div id="traveler">
          <div className="row">
            {values.requester === 'Agency' && (
              <>
                <div className="col-md-6 mb-3">
                  <label className="form-label">First Name</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.firstName`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Last Name</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.lastName`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
              </>
            )}
            {(values.requester === 'Traveler' || values.requester === 'Military') && (
              <>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Your First Name</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.firstName`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Your Last Name</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.lastName`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Your Email</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.email`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Your Phone</label>
                  <Field
                    as={FormikFormPhone}
                    name={`${ctx}.phone`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
              </>
            )}
            {/* {values.requester === 'Military' && (
              <>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Your First Name</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.firstName`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Your Last Name</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.lastName`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Your Email</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.email`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Your Phone</label>
                  <Field
                    as={FormikFormInput}
                    name={`${ctx}.phone`}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
              </>
            )} */}

            <div className="col-md-3 mb-3">
              <label className="form-label">Move-In Date</label>
              <Field
                id="MoveIn"
                name={`${ctx}.moveIn`}
                setHandler={() => setHandler(i)}
                type="MoveIn"
                autoComplete="MoveIn"
                required
                className="form-control"
                placeholder=""
                outFormat={dateFormatForBE}
                inputFormat={dateFormatForBE}
                disablePast
                min={new Date().setDate(new Date().getDate() + 1)}
                as={FormikFormDateTimePicker}
              />
              <ErrorMessage name="moveIn" component="div" className="error" />
            </div>

            <div className="col-md-3 mb-3">
              <label className="form-label">Move-Out Date</label>
              <Field
                id="MoveOut"
                name={`${ctx}.moveOut`}
                type="MoveOut"
                autoComplete="MoveOut"
                required
                className="form-control"
                placeholder=""
                outFormat={dateFormatForBE}
                inputFormat={dateFormatForBE}
                disablePast
                min={new Date().setMonth(new Date().getMonth() + 1)}
                as={FormikFormDateTimePicker}
              />
              <ErrorMessage name="moveOut" component="div" className="error" />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">Reason For Travel</label>

              <Field
                as={FormikFormSelectField}
                isLabelValueSame={true}
                id="TravelReason"
                name={`${ctx}.travelReason`}
                className="form-select"
                options={TravelReasonOptions}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">Your Occupation</label>
              <Field
                as={FormikFormInput}
                name={`${ctx}.working`}
                className="form-control"
                type="text"
                placeholder=""
              />
            </div>
            {(values.requester === 'Traveler' || values.requester === 'Military')&& (
              <>
                <StateCityComponent ctx="" />
                <div className="col-12">
                  <h3 className="box-title mt-4 mb-3">
                    Additional Information:
                  </h3>
                </div>
              </>
            )}

            <div className="col-md-6 mb-3">
              <label className="form-label">Total occupants</label>
              <Field
                as={FormikFormSelectField}
                isLabelValueSame={true}
                id="Occupant"
                name={`${ctx}.occupant`}
                className="form-select"
                options={OccupantOptions}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">Property Type</label>
              <Field
                as={FormikFormSelectField}
                isLabelValueSame={true}
                id="PropertyType"
                name={`${ctx}.propertyType`}
                className="form-select"
                options={PropertyOptions}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">
                Total number of bedrooms do you need?
              </label>
              <Field
                as={FormikFormSelectField}
                isLabelValueSame={true}
                id="Bedroom"
                name={`${ctx}.bedroom`}
                className="form-select"
                options={BedroomOptions}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">Your maximum budget?</label>
              <Field
                as={FormikFormSelectField}
                isLabelValueSame={true}
                id="Budget"
                name={`${ctx}.budget`}
                className="form-select"
                options={BudgetOptions}
              />
            </div>

            <div className="col-md-6 col-12">
              <div className="d-flex mb-3 mt-2">
                <h6 className="me-3 mb-0">Are pets coming along?</h6>
                <div className="form-check form-check-inline">
                  <Field
                    type="radio"
                    name={`${ctx}.withPet`}
                    value="Yes"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="Yes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Field
                    type="radio"
                    name={`${ctx}.withPet`}
                    value="No"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="No">
                    No
                  </label>
                </div>
              </div>
            </div>

            {(values.requester === 'Traveler' || values.requester === 'Military') && (
              <>
                <div className="col-12">
                  <div className="col-12">
                    <h3 className="box-title mt-4 mb-3">
                      Landlord will contact you by
                    </h3>
                  </div>
                  <div className="form-check">
                    <Field
                      type="radio"
                      name="traveler.contacted"
                      value="Phone"
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="Phone">
                      Call/message on given mobile number
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      type="radio"
                      name="traveler.contacted"
                      value="Properties"
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="Properties">
                      Message me on platform only
                    </label>
                  </div>
                  <div className="col-12 mt-4">
                    <Field
                      component="textarea"
                      name={`traveler.description`}
                      className="form-control"
                      placeholder=""
                    />
                    <ErrorMessage
                      className="text-danger"
                      component="div"
                      name="traveler.description"
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <label>
                      <Field type="checkbox" name="termCondition" />
                      &nbsp; I have read and agree to{' '}
                      <Link to="/terms-and-conditions">
                        Furnished Home Locator Terms of Use.
                      </Link>
                    </label>
                  </div>
                </div>
                <div className="row pt-4">
                  {/* <div className="col-md-6">
                    <p className="mb-0">
                      Never Wire Funds to Property Owners! Legitimate landlords
                      will never ask for money to be wired.
                    </p>
                  </div> */}

                  <div className="col-12 text-center">
                    <button
                      disabled={!values.termCondition}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {props.loading && (
                        <img
                          src={Busy}
                          alt="loading.."
                          className="me-2"
                          style={{ maxWidth: '16px' }}
                        />
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
            {values.requester === 'Agency' && (
              <>
                <div className="col-md-6">
                  <Field
                    component="textarea"
                    name={`${ctx}.description`}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name={`${ctx}.description`}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="housing-request">
      <div className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className='border-img-one'>Housing Inquiry</h1>
                <p>
                  Are you in hurry?? just fill
                  out a housing Inquiry form. Landlords in your preferred area,
                  who meet your criteria and have available properties, will
                  then contact you directly!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="request-points bg-white">
        <div className="container">
          <div className="row">
            {/* <div className="col-4 border-end">
              <h3>Our Listings</h3>
              <p>
                After you fill out the form below, you’re directed to our map
                where you can see all of our listings and contact owners
                directly.
              </p>
            </div> */}
            <div className="col-md-8">
              <ul>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M382-253.85 168.62-467.23 211.38-510 382-339.38 748.62-706l42.76 42.77L382-253.85Z" />
                  </svg>
                  Fill out the housing inquiry form.
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M382-253.85 168.62-467.23 211.38-510 382-339.38 748.62-706l42.76 42.77L382-253.85Z" />
                  </svg>
                  We'll promptly notify suitable property owners.
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M382-253.85 168.62-467.23 211.38-510 382-339.38 748.62-706l42.76 42.77L382-253.85Z" />
                  </svg>
                  Property owners will reach out to you directly if they meet
                  your accommodation requirements.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="housing-request-form">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>Fill below form to reach multiple landlord!</h2>
              <p className="mb-4">
               Landlord with available Rooms, House, Condos, Apartment and Hotel in your preferred area will contact you
                Shortly!
              </p>
            </div>

            <div className="col-12">
              <div className="box-view">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={HomeRequestSchema}
                  onSubmit={(values: any) => {
                    props.submitHandler(values);
                  }}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <Form>
                      <div className="d-sm-flex justify-content-center mb-4 mt-2 p-3 bg-light border shadow-sm fs-5">
                        {/* <h6 className="me-3 fs-5 mb-2 mb-sm-0 lh-1">I am a</h6> */}
                        <div className="col-md-6 mb-3 d-flex justify-content-center">
                          <label className="form-label me-4 align-content-center">You are filling form as a </label>
                          <Field
                            as={FormikFormSelectField}
                            isLabelValueSame={true}
                            id="Requester"
                            name="requester"
                            className="form-select requester"
                            options={RequesterOptions}
                          />
                        </div>
                      </div>
                      {values?.requester === 'Traveler' && (
                        <TravelerComponent index={-99} ctx="traveler" />
                      )}
                      {values?.requester === 'Military' && (
                        <TravelerComponent index={-99} ctx="traveler" />
                      )}
                      <FormValue />
                      {values?.requester === 'Agency' && (
                        <div id="agency">
                          <div className="row">
                            <StateCityComponent ctx="" />
                            {values?.agencies?.map(
                              (e: IAgencies, idx: number) => {
                                return (
                                  <div key={idx}>
                                    <div className="col-12">
                                      <h3 className="box-title bg-light text-primary p-3 my-3 border">
                                        Traveler # {idx + 1}
                                      </h3>
                                    </div>

                                    <TravelerComponent
                                      index={idx}
                                      ctx={`agencies[${idx}]`}
                                    />
                                  </div>
                                );
                              }
                            )}
                            <div className="col-12 row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Agency Name
                                </label>
                                <Field
                                  as={FormikFormInput}
                                  className="form-control"
                                  name="agency.name"
                                  type="text"
                                  placeholder=""
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Agency Contact
                                </label>
                                <Field
                                  as={FormikFormInput}
                                  className="form-control"
                                  name="agency.contact"
                                  type="text"
                                  placeholder=""
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Agency Email
                                </label>
                                <Field
                                  as={FormikFormInput}
                                  className="form-control"
                                  name="agency.email"
                                  type="text"
                                  placeholder=""
                                />
                              </div>

                              <div className="col-md-6 mt-4">
                                <label>
                                  <Field type="checkbox" name="termCondition" />
                                  &nbsp; I have read and agree to{' '}
                                  <Link to="/terms-and-conditions">
                                    Furnished Home Locator Terms of Use.
                                  </Link>
                                </label>
                              </div>
                            </div>
                            <div className="row border-top pt-4">
                              <div className="col-md-6">
                                <div className="d-flex justify-content-end align-items-center gap-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const newValue = lodash.cloneDeep(
                                        values.agencies
                                      );
                                      newValue.push(agenciesState);
                                      setFieldValue('agencies', newValue);
                                    }}
                                    className="btn btn-outline-primary px-3 d-flex border-0"
                                  >
                                    <svg
                                      className="me-2"
                                      height="24"
                                      viewBox="0 -960 960 960"
                                      width="24"
                                      fill="#034991"
                                    >
                                      <path d="M450.001-290.001h59.998v-160h160v-59.998h-160v-160h-59.998v160h-160v59.998h160v160Zm30.066 190q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path>
                                    </svg>
                                    Add another traveler
                                  </button>
                                  <button
                                    disabled={!values.termCondition}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    {props.loading && (
                                      <img
                                        src={Busy}
                                        alt="loading.."
                                        className="me-2"
                                        style={{ maxWidth: '16px' }}
                                      />
                                    )}
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ display: 'none' }}
                        onClick={() => resetForm()}
                        ref={props.resetRef}
                      >
                        Reset
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
