import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { SocketProvider } from './context/SocketProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const paypalOptions = {
  clientId:
    'AYP7XT9FKedJX0hoiHrY6mjDdGXBnW-kQVUyhFh_iS3X-ETfhFTfTKqLnHtWuRpMl8DoDwPIi6kEHcQP',
  currency: 'USD',
  intent: 'capture',
};

root.render(
  <>
    <Provider store={store}>
      <SocketProvider>
        <PayPalScriptProvider options={paypalOptions}>
          <App />
        </PayPalScriptProvider>
      </SocketProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
