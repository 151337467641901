import { createContext, useContext } from 'react';
import io, { Socket } from 'socket.io-client';
import { apiUrl } from '../utils/APIRoutes';

interface IProps {
  children: React.ReactNode;
}

// const socketOptions = {  transports: ['polling'] };
const socketOptions = {  transports: ['websocket', ''], upgrade: false };

const socket = io(apiUrl, socketOptions);
socket.on('connect_error', (err) => {
  console.log('connect_error', err);
  setTimeout(() => {
    socket.connect();
  }, 5000);
});

socket.on('connect', () => {
  const engine = socket.io.engine;
  console.log(engine.transport.name);
  // socket.emit('ping', null);
});

const SocketContext = createContext<Socket>(socket);

const useSocket = () => useContext(SocketContext);

const SocketProvider = (props: IProps) => {
  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};

export { useSocket, SocketProvider };
