import './ViewCertificate.scss';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FormikFormDateTimePicker from '../../../../../utils/FormikFieldDateTimePicker';
import { dateFormatForBE } from '../../../../../utils/common';
import FormikFormSearchSelectField from '../../../../../utils/FormikFieldSearchSelect';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import {
  addCertificate,
  certificateInitialState,
  resetAddCertificateError,
  resetAddCertificateSuccess,
  resetGetCertificateFilledDetail,
  resetGetLicenseFilledDetail,
  selectCertificateById,
  updateCertificate,
} from '../../../../../redux/slices/DocumentSlice';
import moment from 'moment';
import {
  ToastType,
  showToastMessage,
} from '../../../../../utils/ToastOptionCommon';
import { useNavigate } from 'react-router';
import Loader from '../../../../../components/CommonComponents/Loader/Loader';
import { Link } from 'react-router-dom';
import { certificateSchema } from '../../../../../utils/yupSchemas/CertificateSchema';
import FormikFormInput from '../../../../../utils/FormikFormInput';
import { useCertificate } from '../../../../../hooks/useCertificate';
import DocumentViewModal from '../../../../../components/PropertyDetail/DocumentViewModal/DocumentViewModal';
import DocumentPopupHOC from '../../../../../components/PropertyDetail/DocumentViewModal/hoc/DocumentPopupHoc';

const ViewCertificate = (props: {
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, certificates } = useCertificate();
  const [fileOnEdit, setFileOnEdit] = useState<boolean>(false);
  const [showFileError, setshowFileError] = useState({
    isError: false,
    msg: '',
  });

  const addCertificateDetail = useSelector(
    (state: any) => state.document.addCertificateDetail
  );
  const initialState = useSelector(certificateInitialState);
  const certificateDetail = useSelector(selectCertificateById);

  const [files, setFiles] = useState<any>([]);
  const acceptedFileTypes = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  const sampleCertificateTypeArray = [
    { Id: 1, Name: 'SANE: Sexual Assault Nurse Examiner' },
    { Id: 2, Name: '(ABMP) Hyperthermia: (ABMP) Hyperthermia' },
  ];

  useEffect(() => {
    if (showFileError && files?.length > 0) {
      checkSubmmiting();
    }
  }, [files]);

  useEffect(() => {
    if (addCertificateDetail.error) {
      showToastMessage(addCertificateDetail.error, ToastType.Error);
      dispatch(resetAddCertificateError());
    }
  }, [addCertificateDetail.error]);

  useEffect(() => {
    if (addCertificateDetail.success) {
      if (certificateDetail.isEdit) {
        showToastMessage('Certificate Updated Successfully', ToastType.Success);
      } else {
        showToastMessage('Certificate Added Successfully', ToastType.Success);
      }
      navigate(-1);
      dispatch(resetAddCertificateSuccess());
      dispatch(resetGetCertificateFilledDetail());
    }
  }, [addCertificateDetail.success]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    if (showFileError.isError) {
      setSubmitting(false);
      return;
    }
    let date = values.expiration_date;
    if (values.expiration_date.$d) {
      date = moment(values.expiration_date.$d).format(dateFormatForBE);
    }
    const formData = new FormData();
    formData.append('certificate_document', files?.[0]);
    formData.append('certification_type', values.certification_type);
    formData.append('issuing_body', values.issuing_body);
    formData.append('expiration_date', date);
    if (certificateDetail.isEdit) {
      dispatch(
        updateCertificate({
          id: certificateDetail.id,
          body: formData,
        }) as unknown as UnknownAction
      );
    } else {
      dispatch(addCertificate({ body: formData }) as unknown as UnknownAction);
    }
  };

  const checkSubmmiting = () => {
    if (
      (!certificateDetail.isEdit && files?.length === 0) ||
      (certificateDetail.isEdit && fileOnEdit && files?.length === 0)
    ) {
      setshowFileError({
        isError: true,
        msg: 'Please provide atleast one document',
      });
    } else if (
      files?.length > 0 &&
      !acceptedFileTypes?.includes(files?.[0]?.type)
    ) {
      setshowFileError({
        isError: true,
        msg: 'Please select supported file format (ie. .pdf, .jpg, .jpeg, .png)',
      });
      setFiles(() => []);
    } else {
      setshowFileError({ isError: false, msg: '' });
    }
  };

  const getDocName = (str: string) => {
    const x = str?.split('/');
    return x[x?.length - 1];
  };

  return (
    <>
      {props.showDocumentPopup && (
        <DocumentViewModal
          onModalClosed={() => props.documentToggleHandler()}
          fileUrl={initialState?.docLink}
        />
      )}
      <div className="main-content">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>Certifications</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/certificates">Certifications</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Certificate
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {certificateDetail.loading ? (
          <Loader isSmallLoader={true} />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            onSubmit={() => {}}
          >
            {({ values, isSubmitting }) => (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="box-view shadow-sm mb-5 h-auto">
                    <Form
                      className="user-form space-y-6 text-deep_gray"
                      noValidate
                    >
                      <div className="mb-3">
                        <label className="block text-sm font-medium leading-2">
                          Certification Type :
                        </label>
                        <br />
                        {values?.certification_type ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label className="block text-sm font-medium leading-6">
                          Issuing Body :
                        </label>
                        <br />
                        {values?.issuing_body ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="expiration_date"
                          className="block text-sm font-medium leading-6 "
                        >
                          Expiration Date :
                        </label>
                        <br />
                        {values?.expiration_date ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label>
                          Document :
                          <br />
                        </label>
                        <br />
                        <span
                          className="old_doc_span"
                          onClick={() => props.documentToggleHandler()}
                        >
                          {getDocName(values?.document_url)}
                          <svg
                            height="20"
                            viewBox="0 0 24 24"
                            width="20"
                            className="cursor-pointer"
                          >
                            <path d="m10 3v2h-5v14h14v-5h2v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-16c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071v-16c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289zm7.586 2h-4.586v-2h8v8h-2v-4.586l-7 7-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default DocumentPopupHOC(ViewCertificate);
