import React, { useEffect, useState } from 'react';
import './Settings.scss';
import { Link } from 'react-router-dom';
import { IoMdArrowDropright } from 'react-icons/io';
import DashboardSettingProfileComponent from '../../../components/DashboardComponents/DashboardSettingComponent/DashboardSettingProfileComponent';
import { User } from '../Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import DashboardSettingProfileEditComponent from '../../../components/DashboardComponents/DashboardSettingComponent/DashboardSettingProfileEditComponent';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import axiosCall from '../../../utils/axiosCall';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import {
  loggedInUserState,
  setUserData,
} from '../../../redux/slices/LoginSlice';
import DashboardSettingChangePassword from '../../../components/DashboardComponents/DashboardSettingComponent/DashboardSettingChangePassword';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import {
  fetchUserById,
  resetUserDetailSuccess,
  setUser,
  userDetail,
} from '../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../redux/store';
import { usePermissions } from '../../../utils/Permission/Permission';
import { set } from 'lodash';

function Settings() {
  const userDetails: User | undefined = useSelector(
    (state: any) => state.login.userData
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [formInputState, setFormInputState] = useState<User | undefined>();
  const [placeDetail, setPlaceDetail] = useState<any>({});
  const [keepPhoneNumberPrivate, setKeepPhoneNumberPrivate] = useState(
    userDetails?.keepPhoneNumberPrivate ?? false
  );
  const [keepEmailPrivate, setKeepEmailPrivate] = useState(
    userDetails?.keepEmailPrivate ?? false
  );
  const [messageNotification, setMessageNotification] = useState(
    userDetails?.messageNotification ?? false
  );
  const [settingPassword, setSettingPassword] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const { hasPermission } = usePermissions();

  // useEffect(() => {
  //   if (userDetails?.profileImage) {
  //     getRawImages();
  //   }
  // }, [isEditing]);

  useEffect(() => {
    setKeepPhoneNumberPrivate(
      () => userDetails?.keepPhoneNumberPrivate ?? false
    );
    setKeepEmailPrivate(() => userDetails?.keepEmailPrivate ?? false);
  }, [userDetails]);

  //   const getRawImages = async () => {
  //     const imgUrl: any = userDetails?.profileImage;
  //     const imgObjects: any = [];
  //     const filename = extractFilenameFromUrl(imgUrl);
  //     const img = await fetchAndConvertImage(imgUrl, filename);
  //     if (img) {
  //       imgObjects.push(img);
  //     }
  //     // console.log(imgObjects);
  //     setFiles(() => imgObjects);
  //   };

  // function extractFilenameFromUrl(url: string): string {
  //   // Split the URL by '/'
  //   const parts = url.split('/');
  //   // Get the last part of the path which should contain the filename
  //   const filenameWithParams = parts[parts.length - 1];
  //   // Extract the filename by removing any query parameters
  //   const filename = filenameWithParams.split('?')[0];
  //   return filename;
  // }
  // async function fetchAndConvertToBlob(url: string): Promise<any> {
  //   try {
  //     const response = await fetch(url);
  //     // console.log({response});
  //     const blob = await response.blob();
  //     return blob;
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return;
  // }

  // function blobToFile(blob: Blob, fileName: string): File {
  //   // Create a new File object from the Blob
  //   return new File([blob], fileName, { type: blob?.type });
  // }

  // async function fetchAndConvertImage(img: string, fileName: string) {
  //   try {
  //     const blob = await fetchAndConvertToBlob(img);
  //     const file = blobToFile(blob, fileName);
  //     return file;
  //   } catch (error) {
  //     console.error('Error fetching or converting image:', error);
  //     throw error;
  //   }
  // }

  const dispatch: any = useDispatch<AppDispatch>();

  const loggedInUser = useSelector(loggedInUserState);
  const user_detail = useSelector(userDetail);
  const userJson = localStorage.getItem('user') ?? '';
  const userData = userJson ? JSON.parse(userJson) : null;
  useEffect(() => {
    if (user_detail.success) {
      dispatch(setUserData({ ...userData, ...user_detail?.data }));
      dispatch(setUser({ ...userData, ...user_detail?.data }));
      dispatch(resetUserDetailSuccess());
    }
  }, [user_detail.success]);

  useEffect(() => {
    if (loggedInUser?._id) {
      dispatch(fetchUserById({ body: { userId: loggedInUser?._id } }));
    }
  }, []);

  useEffect(() => {
    setFormInputState(userDetails);
  }, [isEditing]);

  const handleProfileFormSubmit = async () => {
    setLoading(true);
    const body = {
      userEmail: formInputState?.userEmail,
      firstName: formInputState?.firstName,
      lastName: formInputState?.lastName,
      phoneNo: formInputState?.phoneNo,
      address: formInputState?.address,
      aboutUser: formInputState?.aboutUser,
      keepPhoneNumberPrivate: keepPhoneNumberPrivate || false,
      keepEmailPrivate: keepEmailPrivate || false,
      messageNotification: messageNotification || false,
      isRemoveProfile: formInputState?.removeProfile || false,
      place: placeDetail,
    };

    const formData = new FormData();

    createMultipartFormData(body, formData);
    if (hasPermission('tenant') && formInputState?.occupation) {
      formData.append('occupation', formInputState.occupation);
    }
    if (files.length) {
      formData.append('image', files?.[0]);
    }

    try {
      const url = '/user/updateUser';
      const response = await axiosCall.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.status === 200) {
        const user = response?.data?.user;
        const data = {
          ...userData,
          firstName: user?.firstName,
          lastName: user?.lastName,
          userEmail: user?.userEmail,
          phoneNo: user?.phoneNo,
          role: user?.role,
          address: user?.address,
          aboutUser: user?.aboutUser,
          profileImage: user?.profileImage,
          isEmailVerified: user?.isEmailVerified,
          occupation: user?.occupation,
          keepPhoneNumberPrivate: user?.keepPhoneNumberPrivate,
          keepEmailPrivate: user?.keepEmailPrivate,
          messageNotification: user?.messageNotification,
        };
        dispatch(setUserData(data));
        dispatch(setUser(data));
        localStorage.setItem('user', JSON.stringify(data));

        showToastMessage('User updated successfully', ToastType.Success);
        // console.log(response.data.user);
        setIsEditing(false);
      } else {
        showToastMessage('Could not update the user', ToastType.Error);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('User update failed...', ToastType.Error);
    } finally {
      setLoading(false);
      setFiles([]);
    }
  };

  return (
    <div className="main-content" style={{ height: '100vh' }}>
      <DashboardHeader />
      {loading && <Loader />}
      <div className="profile">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-sm-7">
              <h1>Profile</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Update
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-5 text-sm-end">
              <div
                className="btn-group nav nav-pills tabs-pills-btn"
                role="group"
              >
                <a
                  className="btn active"
                  data-bs-toggle="tab"
                  href="#Update"
                  onClick={() => setSettingPassword(false)}
                >
                  Update
                </a>
                <a
                  className="btn"
                  data-bs-toggle="tab"
                  href="#ChangePassword"
                  onClick={() => setSettingPassword(true)}
                >
                  Change Password
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="update-profile box-view">
          {!settingPassword && isEditing ? (
            <>
              <DashboardSettingProfileEditComponent
                formInputState={formInputState}
                setPlaceDetail={setPlaceDetail}
                setFormInputState={setFormInputState}
                setIsEditing={setIsEditing}
                handleProfileFormSubmit={handleProfileFormSubmit}
                files={files}
                setFiles={setFiles}
                keepPhoneNumberPrivate={keepPhoneNumberPrivate}
                setKeepPhoneNumberPrivate={setKeepPhoneNumberPrivate}
                keepEmailPrivate={keepEmailPrivate}
                setKeepEmailPrivate={setKeepEmailPrivate}
                messageNotification={messageNotification}
                setMessageNotification={setMessageNotification}
              />
            </>
          ) : (
            <>
              <DashboardSettingProfileComponent
                userDetails={userDetails}
                setIsEditing={setIsEditing}
                isMessageNotification={messageNotification}
                setIsMessageNotification={setMessageNotification}
              />
            </>
          )}
          {settingPassword && (
            <DashboardSettingChangePassword setLoading={setLoading} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
