import React, { Dispatch, SetStateAction, useState } from 'react';
import callBackendAPI from '../../../utils/callBackendAPI';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { IoClose } from 'react-icons/io5';
import { signInValidateSchema } from '../../../utils/yupSchemas/ResgistrationSchema';
import Busy from '../../../assets/gif/busy.gif';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../redux/slices/LoginSlice';
import "../../SignUp/form/SignupForm.scss"
import { toast } from 'react-toastify';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import FormikFormPasswordField from '../../../utils/FormikPasswordField';


interface IProps {
  submitHandler: (data: any) => void;
  onModalClosedSignIn: () => void;
  onModalClosedSignUp: () => void;
  initialValues: ISignin;
  isLoading: boolean;
  setLoginError: Dispatch<SetStateAction<string>>;
  loginError: string;
}

export interface ISignin {
  Email: string;
  Password: string;
}

const SigninForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosedSignIn,
    onModalClosedSignUp,
    initialValues,
    isLoading,
    loginError,
    setLoginError,
  } = props;
  const [userEmail, setuserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values: ISignin) => {
    // e.preventDefault();
    setLoginError(() => '');
    setIsProgress(() => true);
    try {
      const response = await callBackendAPI('POST', 'auth/login', null, {
        userEmail: values.Email,
        password: values.Password,
      });
      if (response.error) {
        setIsProgress(() => false);
        setLoginError(() => response.error);
      } else {
        setIsProgress(() => false);
        const { token, userData } = response.data;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('isLoggedin', 'true');
        dispatch(setUserData(response?.data.userData));
        onModalClosedSignIn();
        navigate('/dashboard');
      }
    } catch (error) {
      setIsProgress(() => false);
      // setLoginError(() => 'something went wrong!!!');
      // console.error('Sign in failed:', error);
      toast.error('Sign in failed: Please check Email or Password', toastDefaults());
      // toast.error(`Sign in failed: ${error}`, toastDefaults());
    }
  };

  const handleSignInRoute = () => {
    onModalClosedSignUp();
    onModalClosedSignIn();
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      <Modal show={true} centered>
        <Modal.Header>
          <Modal.Title className='ms-auto'>
            Login to your account
          </Modal.Title>
          <div
            onClick={() => {
              onModalClosedSignIn();
            }}
            className="icons8-close ms-auto"
          >
            <IoClose />
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={signInValidateSchema}
          onSubmit={(values: ISignin) => {
            handleSubmit(values);
          }}
        // handleSubmit={}
        >
          {({ values }) => (
            <Form noValidate>
              {/* <FormObserver /> */}
              <Modal.Body className="">
                {loginError && <div style={{ color: 'red' }}>{loginError}</div>}
                <div className="">
                  <div className="">
                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Enter your email
                      </label>
                      <Field
                        id="email"
                        name="Email"
                        type="email"
                        autoComplete="email"
                        required
                        className="form-control"
                        // invalidcharacter={[" "]}
                        placeholder=""
                      // as={FormikFormInput}
                      />
                      <ErrorMessage name="Email" component="div" className='error' />
                    </div>

                    <div className='mb-2'>
                      <label
                        htmlFor="password"
                        className="form-label"
                      >
                        Enter Password
                      </label>
                      <Field
                        id="password"
                        name="Password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="password"
                        required
                        className="form-control"
                        placeholder=""
                      as={FormikFormPasswordField}
                      />
                      <ErrorMessage name="Password" component="div" className='error' />
                      <div className="m-auto my-3">
                        <Button
                          type="submit"
                          className="w-100 py-2 mt-3 mb-2"
                        // onClick={() => {
                        //   handleSubmit(values);
                        // }}
                        >
                          {isProgress && (
                            <img src={Busy} alt="loading.." className='me-2' style={{maxWidth: '16px'}} />
                          )}
                          Submit
                        </Button>
                      </div>
                      <div>
                        <p className="mt-0 mb-1 text-center text-sm text-dark">
                          Not a member yet?
                          <a
                            onClick={handleSignInRoute}
                            className="my-2 text-center text-primary"
                          >
                            {' '}
                            Sign Up
                          </a><br></br>
                          <a href='#' className="my-2 mx-auto mt-2 d-table text-primary">Forgot Username or Password?</a>
                        </p>
                      </div>                        
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SigninForm;
