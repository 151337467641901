import React, { useState } from 'react';
import HeadingStatusComponent from '../../../components/AddListingsComponents/HeadingStatusComponent/HeadingStatusComponent';
import CreateListingComponent from '../../../components/AddListingsComponents/CreateListingComponent/CreateListingComponent';
import LocationComponent from '../../../components/AddListingsComponents/LocationComponent/LocationComponent';
import {
  ListingAmenitiesInitialValues,
  ListingDetailInitialValues,
  ListingLocationInitialValues,
  ListingNearByInitialValues,
} from '../../../utils/addListingInitialValue';
import DetailedInfoComponent from '../../../components/AddListingsComponents/DetailedInfoComponent/DetailedInfoComponent';
import AddMediaComponent from '../../../components/AddListingsComponents/AddMediaComponent/AddMediaComponent';
import NearByComponent from '../../../components/AddListingsComponents/NearByComponent/NearByComponent';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { resetProperty } from '../../../redux/slices/CreateLisingSlice';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import axiosCall from '../../../utils/axiosCall';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import { ListingSection } from '../../../constants/ListingSection';
import NavigationPrompt from '../../../utils/NavigationPrompt/NavigationPrompt';
import { IoWarningOutline } from 'react-icons/io5';
import { setFormDirty } from '../../../redux/slices/LocationSlice';

function AddListings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [propertyId, setPropertyId] = useState('');
  const [currentSection, setCurrentSection] = useState(ListingSection.Details);
  const [savedSection, setSavedSection] = useState<any>({
    [ListingSection.Details]: false,
    [ListingSection.Location]: false,
    [ListingSection.Amenities]: false,
    [ListingSection.NearBy]: false,
    [ListingSection.Documents]: false,
  });

  const handleSubmit = async (formData: FormData) => {
    try {
      setIsLoading(true);
      formData.append(`propertyId`, propertyId);
      let response: any;
      if (propertyId && currentSection === ListingSection.Details) {
        const url = `/properties/update/${propertyId}`;
        // edit request
        response = await axiosCall.put(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const url = `properties/create`;
        response = await axiosCall.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      const data = response.data;
      if (response.status === 201 || response.status === 200) {
        setSavedSection((prev: any) => ({ ...prev, [currentSection]: true }));
        if (currentSection === ListingSection.Documents) {
          if (data?.subscription && !data.subscription.isActive) {
            toast.info('Please subscribe a package!', toastDefaults());
            navigate('/dashboard/Package');
          } else {
            toast.success('Successfully Listed Property', toastDefaults());
            navigate('/dashboard/listing');
          }
          dispatch(resetProperty());
        } else {
          setCurrentSection(currentSection + 1);
        }
        dispatch(setFormDirty(false));
        setPropertyId(data?.updatedProperty?._id);
      } else {
        dispatch(setFormDirty(true));
        toast.error('Failed to add property listing.', toastDefaults());
      }
    } catch (error: any) {
      dispatch(setFormDirty(true));
      console.error(`Add Listing error: ${error}`);
      if(error?.response?.status === 409){
        toast.error('Property title already exist.', toastDefaults());
      }
      else {
        toast.error('Failed to add property listing.', toastDefaults());
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="createListingTab" className="main-content">
      {isLoading && <Loader />}

      <NavigationPrompt
        confirmationText={
          'Are you sure you want to leave without saving changes?'
        }
        icon={<IoWarningOutline className="m-1 warn-popup" />}
        saveButtonText={'Leave'}
        cancelButtonText={'Stay'}
        modalTitle={'Confirmation'}
        isProgress={false}
      />

      {/* Add property heading */}
      <DashboardHeader hideListingBtn={true} />
      <HeadingStatusComponent isEditListing={false} section={currentSection} />

      <div className="font-body">
        <CreateListingComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={ListingDetailInitialValues}
          savedSection={savedSection}
        />
        {/* Location */}
        <LocationComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={ListingLocationInitialValues}
          savedSection={savedSection}
        />
        {/* Detailed Information */}
        <DetailedInfoComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={ListingAmenitiesInitialValues}
          savedSection={savedSection}
        />
        {/* Near by */}
        <NearByComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={ListingNearByInitialValues}
          savedSection={savedSection}
        />
        {/* Media & Submit */}
        <AddMediaComponent
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          isEdit={false}
          setIsDocUpdate={null}
          handleSubmit={handleSubmit}
          savedSection={savedSection}
        />
      </div>
    </div>
  );
}

export default AddListings;
