import Highcharts from 'highcharts';

export interface IOptions {
  DashboardAdmin: Highcharts.Options;
  DashboardLandlord: Highcharts.Options;
}

const commonOptions: Highcharts.Options = {
  chart: {
    renderTo: 'chart',
    type: 'column',
  },
  title: {
    text: '',
    align: 'center',
  },
  subtitle: {
    text: 'Source: FurnishedHomeLocator.com',
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
  },
  // plotOptions: {
  //   column: {
  //     pointPadding: 0.2,
  //     borderWidth: 0,
  //   },
  // },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: [
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'viewFullscreen',
          'printChart',
          'separator',
          'resetZoom',
        ],
      },
    },
  },
  navigation: {
    buttonOptions: {
      theme: {
        fill: '#ffffff',
      },
    },
  },
  lang: {
    resetZoom: 'Reset zoom',
    resetZoomTitle: 'Reset zoom level 1:1',
    // noData: "No data found"
  },
//   noData: {
//     style: {
//       fontWeight: 'bold',
//       fontSize: '15px',
//       color: '#303030',
//       textAlign: 'centre',
//     },
//     position: {
//       align: 'center',
//     },
//   },
};

const DashboardAdminLandlordOptions: Highcharts.Options = {
  ...commonOptions,
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Date',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#666',
      },
    },
    //   tickInterval: 24 * 3600 * 1000, // 5 days in milliseconds
  },
  yAxis: [
    {
      title: {
        text: 'Property count',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
        },
      },
      // labels: {
      //   formatter: function () {
      //     return Number(this.value).toFixed(0);
      //   },
      // },
    },
    {
      title: {
        text: 'Amount',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
        },
      },
      // tooltipValueFormat: {

      // },
      labels: {
        formatter: function () {
          return `$${this.value}`;
        },
      },
    },
  ],
  // tooltip: {
  //   formatter: function () {
  //     const seriesName = this.series.name;
  //     const yValue:any = this.y;
  //     const xValue:any = this.x;
  //     const pointCategory = this.point.category;
  //     if (seriesName === 'Subscription Amount') {
  //       return `<b>${seriesName}</b><br/>${Highcharts.dateFormat('%Y-%m', xValue)}: $${yValue}`;
  //     } else {
  //       return `<b>${seriesName}</b><br/>${Highcharts.dateFormat('%Y-%m', xValue)}: ${yValue}`;
  //     }
  //   }
  // },
  series: [
    {
      type: 'column',
      name: 'Property',
      data: [],
      color: 'skyblue',
      pointIntervalUnit: 'month',
      yAxis: 0,
    },
    {
      type: 'column',
      name: 'Subscription Amount',
      data: [],
      color: '#FF9663',
      pointIntervalUnit: 'month',
      yAxis: 1,
    },
  ],
};

const DashboardLandlordOptions: Highcharts.Options = {
  ...commonOptions,
  xAxis: [
    {
      type: 'datetime',
      title: {
        text: "Month 'Year",
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
        },
      },
    },
    {
      type: 'category',
      title: {
        text: 'Property Name',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
        },
      },
      categories: [],
      opposite: true, // Place the second x-axis on the opposite side
    },
  ],
  yAxis: [
    {
      title: {
        text: 'Property Counts',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
        },
      },
    },
    {
      title: {
        text: 'Property Views',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
        },
      },
      opposite: true, // Place the second y-axis on the opposite side
    },
  ],
  series: [
    {
      type: 'column',
      name: 'Property Counts',
      data: [],
      color: 'skyblue',
      yAxis: 0,
      xAxis: 0,
    },
    {
      type: 'column',
      name: 'Property Views',
      data: [],
      color: '#FF9663',
      yAxis: 1,
      xAxis: 1,
    },
  ],
};
//   xAxis: [
//     {
//       type: 'datetime',
//       title: {
//         text: 'Date',
//         style: {
//           fontSize: '16px',
//           fontWeight: 'bold',
//           color: '#666',
//         },
//       },
//     },
//     {
//       type: 'category',
//       title: {
//         text: 'Property Name',
//         style: {
//           fontSize: '16px',
//           fontWeight: 'bold',
//           color: '#666',
//         },
//       },
//     },
//   ],
//   yAxis: [
//     {
//       title: {
//         text: 'Property count',
//         style: {
//           fontSize: '16px',
//           fontWeight: 'bold',
//           color: '#666',
//         },
//       },
//     },
//     {
//       title: {
//         text: 'Property Review',
//         style: {
//           fontSize: '16px',
//           fontWeight: 'bold',
//           color: '#666',
//         },
//       },
//     },
//   ],
//   series: [
//     {
//       type: 'column',
//       name: 'Property Count',
//       data: [],
//       color: 'skyblue',
//       pointIntervalUnit: 'day',
//       yAxis: 0,
//     },
//     {
//       type: 'column',
//       name: 'Property Review',
//       data: [],
//       color: '#FF9663',
//       pointIntervalUnit: 'day',
//       yAxis: 1,
//     },
//     {
//       type: 'column',
//       name: 'Date',
//       data: [],
//       color: '#FF9663',
//       pointIntervalUnit: 'day',
//       xAxis: 0,
//     },
//     {
//       type: 'column',
//       name: 'Property Name',
//       data: ["A", "B", "C"],
//       color: '#FF9663',
//       xAxis: 1,
//     },
//   ],

export const BarChartOption: IOptions = {
  DashboardAdmin: DashboardAdminLandlordOptions,
  DashboardLandlord: DashboardLandlordOptions,
};
