import React, { useState } from 'react';

const ConfirmationPopupHOC = (Component: any) => 
function Comp(props: any){
    const [showConfirmationPopup,setShowConfirmationPopup] = useState<boolean>(false);

    const confirmationToggleHandler = () => {
        setShowConfirmationPopup(!showConfirmationPopup);
    }
	
	return <Component showConfirmationPopup={showConfirmationPopup} confirmationToggleHandler={confirmationToggleHandler} {...props} />;
};

export default ConfirmationPopupHOC;