import React, { useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';

interface IProps {
  children: React.ReactNode;
  className: string;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  value: string;
  type: string;
  disabled: boolean;
  readOnly: boolean;
  maxLength: number;
}

const FormikFormInput = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? 'is-invalid' : ''}`;
  const _placeholder = props.placeholder;
  useEffect(() => {
    if (field.value?.length > props.maxLength) {
      helpers.setValue(field.value.slice(0, props.maxLength));
    }
  }, [field.value, helpers, props.maxLength]);

  return (
    <>
      <input
        {...field}
        {...props}
        placeholder={""}
        className={props.className + ' ' + errorClass}
        disabled={props.disabled}
        readOnly={props.readOnly}
        type={props.type ? props.type : 'text'}
        onChange={(event) => {
          if (event.target.value?.length > props.maxLength)
            event.target.value = event.target.value.slice(0, props.maxLength);
          helpers.setValue(event.target.value);
        }}
      />
      {meta.touched && meta.error && (
        <div className="invalid-feedback">
          {meta.error}
        </div>
      )}
    </>
  );
};

export default FormikFormInput;
