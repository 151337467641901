import './PrivacyPolicy.scss';
import { PDFRender } from '../../../utils/PDFViewer/PDFRender';
import { PdfPreviewType } from '../../../constants/previewType';
import { useEffect, useState } from 'react';
import axiosCall from '../../../utils/axiosCall';
import Loader from '../../../components/CommonComponents/Loader/Loader';

export const PrivacyPolicy = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDoc();
  }, []);

  const getDoc = async () => {
    try {
      setLoading(true);
      const url = '/policy/getPolicy/2';
      const response = await axiosCall.get(url);
      if (response?.status === 200) {
        setUrl(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="">
      {loading && <Loader isSmallLoader />}
      {url?.length > 0 && (
        <PDFRender previewType={PdfPreviewType.Url} url={url} fromHomePage={true}/>
      )}
    </div>
  );
};
