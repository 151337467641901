import React, { useState } from 'react';
import { USDateFormat, shortenString } from '../../../../utils/common';
import propertylistingpreview from '../../../../assets/images/property-listing-preview.jpg';
import { BsEyeFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { BiSolidTrash } from 'react-icons/bi';
import { Skeleton } from '@mui/material';

interface IProps {
  item: any;
  gotoBlogDetail: (id: string) => void;
  handleDelete: (item: any) => void;
  handleEdit: (item: any) => void;
}

function BlogRowItem(props: IProps) {
  const { item, gotoBlogDetail, handleDelete, handleEdit } = props;
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="property-listing-preview">
            {imageLoading && (
              <Skeleton animation='wave' variant="rounded" width={'100%'} height={550} />
            )}
            <img
              src={item?.image || propertylistingpreview}
              alt="property-listing-preview"
              className="img-fluid rounded-md"
              onLoad={() => setImageLoading(false)}
              style={{ display: imageLoading ? 'none' : 'block' }}
            />
          </div>
          <div className="property-details ms-2">
            <h5>{item?.title}</h5>
          </div>
        </div>
      </td>
      <td>{USDateFormat(item?.createdAt)}</td>
      <td>{shortenString(item?.content[0])}</td>
      <td>
        <span
          className={`status ${
            item?.status === 'publish' ? 'for-active' : 'for-inactive'
          }`}
        >
          {item?.status === 'publish' ? `Published` : `Draft`}
        </span>
      </td>
      <td>
        <div className="d-flex align-items-center gap-3">
          <button
            type="button"
            onClick={() => {
              gotoBlogDetail(item?._id);
            }}
          >
            <BsEyeFill />
          </button>
          <button
            type="button"
            onClick={() => {
              handleEdit(item);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            onClick={() => {
              handleDelete(item);
            }}
          >
            <BiSolidTrash />
          </button>
        </div>
      </td>
    </tr>
  );
}

export default BlogRowItem;
