import { UnknownAction } from '@reduxjs/toolkit';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import Loader from '../../../../../components/CommonComponents/Loader/Loader';
import DocumentViewModal from '../../../../../components/PropertyDetail/DocumentViewModal/DocumentViewModal';
import DocumentPopupHOC from '../../../../../components/PropertyDetail/DocumentViewModal/hoc/DocumentPopupHoc';
import {
  addLicense,
  licenseInitialState,
  resetAddLicenseError,
  resetAddLicenseSuccess,
  resetGetLicenseFilledDetail,
  selectGetLicenseById,
  updateLicense,
} from '../../../../../redux/slices/DocumentSlice';
import FormikFormDateTimePicker from '../../../../../utils/FormikFieldDateTimePicker';
import FormikFormSearchSelectField from '../../../../../utils/FormikFieldSearchSelect';
import {
  ToastType,
  showToastMessage,
} from '../../../../../utils/ToastOptionCommon';
import { dateFormatForBE } from '../../../../../utils/common';
import './ViewLicense.scss';

const apiUrl = process.env.REACT_APP_API_URL;

const ViewLicense = (props: {
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.login.userData);
  const [fileOnEdit, setFileOnEdit] = useState<boolean>(false);
  const [countryState, setCountryState] = useState([]);

  const addLicenseDetail = useSelector(
    (state: any) => state.document.addLicenseDetail
  );
  const initialState = useSelector(licenseInitialState);
  const licenseDetail = useSelector(selectGetLicenseById);

  const [files, setFiles] = useState<any>([]);
  const acceptedFileTypes = ['application/pdf'];

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    const res = await fetch(`${apiUrl}/country/United States`)
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        //console.log(e);
      });
    if (res?.error) {
      showToastMessage('Failed to get states', ToastType.Error);
    } else {
      const state = res?.states?.map((e: string, idx: number) => {
        return { Id: idx, Name: e };
      });
      setCountryState(state);
    }
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    if (
      (!licenseDetail.isEdit && files.length === 0) ||
      (licenseDetail.isEdit && fileOnEdit && files.length === 0)
    ) {
      showToastMessage('Please provide atleast one document', ToastType.Error);
      setSubmitting(false);
      return;
    }
    if (files.length > 0 && !acceptedFileTypes?.includes(files?.[0]?.type)) {
      showToastMessage(
        'Please select supported file format (ie. .pdf)',
        ToastType.Error
      );
      setFiles(() => []);
      setSubmitting(false);
      return;
    }

    let date = values.expiration_date;
    if (values.expiration_date.$d) {
      date = moment(values.expiration_date.$d).format(dateFormatForBE);
    }

    const formData = new FormData();
    formData.append('user_id', userData?._id ?? '');
    formData.append('license_document', files?.[0]);
    formData.append('license_state', values.license_state);
    formData.append('license_number', values.license_number);
    formData.append('expiration_date', date);
    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    if (licenseDetail.isEdit) {
      dispatch(
        updateLicense({
          id: licenseDetail.id,
          body: formData,
        }) as unknown as UnknownAction
      );
    } else {
      dispatch(addLicense({ body: formData }) as unknown as UnknownAction);
    }
  };

  useEffect(() => {
    if (addLicenseDetail.error) {
      showToastMessage(addLicenseDetail.error, ToastType.Error);
      dispatch(resetAddLicenseError(null));
    }
  }, [addLicenseDetail.error]);

  useEffect(() => {
    if (addLicenseDetail.success) {
      if (licenseDetail.isEdit) {
        showToastMessage('License Updated Successfully', ToastType.Success);
      } else {
        showToastMessage('License Added Successfully', ToastType.Success);
      }
      navigate(-1);
      dispatch(resetAddLicenseSuccess(null));
      dispatch(resetGetLicenseFilledDetail());
    }
  }, [addLicenseDetail.success]);

  const getDocName = (str: string) => {
    const x = str?.split('/');
    return str !== '' ? x[x.length - 1] : str;
  };

  return (
    <>
      {props.showDocumentPopup && (
        <DocumentViewModal
          onModalClosed={() => props.documentToggleHandler()}
          fileUrl={initialState?.docLink}
        />
      )}
      <div className="main-content">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>Licenses</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard/Licenses">Licenses</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View License
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {licenseDetail.loading ? (
          <Loader isSmallLoader={true} />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            onSubmit={() => {}}
          >
            {({ values, isSubmitting }) => (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="box-view shadow-sm mb-5 h-auto">
                    <Form
                      className="user-form space-y-6 text-deep_gray"
                      noValidate
                    >
                      <div className="mb-3">
                        <label className="block text-sm font-medium leading-6 ">
                          First Name :
                        </label>
                        <br />
                        {values?.first_name ?? '-'}
                      </div>
                      <div className="mb-3">
                        <label className="block text-sm font-medium leading-6 ">
                          Last Name :
                        </label>
                        <br />
                        {values?.last_name ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label className="block text-sm font-medium leading-6 ">
                          State :
                        </label>
                        <br />
                        {values?.license_state ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label className="block text-sm font-medium leading-6 ">
                          License Number :
                        </label>
                        <br />
                        {values?.license_number ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="expiration_date"
                          className="block text-sm font-medium leading-6 "
                        >
                          Expiration Date :
                        </label>
                        <br />
                        {values?.expiration_date ?? '-'}
                      </div>

                      <div className="mb-3">
                        <label>
                          Document :
                          <br />
                        </label>
                        <br />
                        <span
                          className="old_doc_span"
                          onClick={() => props.documentToggleHandler()}
                        >
                          {getDocName(values?.license_document_url)}
                          <svg
                            height="20"
                            viewBox="0 0 24 24"
                            width="20"
                            className="cursor-pointer"
                          >
                            <path d="m10 3v2h-5v14h14v-5h2v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-16c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071v-16c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289zm7.586 2h-4.586v-2h8v8h-2v-4.586l-7 7-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>

      {/* <FormObserver />
            <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex flex-col gap-2 mb-3">
                    </div>

                    <div className="flex flex-col gap-2 mb-3">
                        

                    <div className="flex flex-col gap-2 mb-3">
                        <label
                            htmlFor="end"
                            className="block text-sm font-medium leading-6 "
                        >
                            Enter End Date
                        </label>
                        <Field
                            id="end"
                            name="end"
                            type="end"
                            autoComplete="end"
                            required
                            className="form-control"
                            // invalidcharacter={[" "]}
                            placeholder=""
                            as={FormikFormDateTimePicker}
                        />
                        <ErrorMessage name="end" component="div" className='error' />
                    </div>

                    <div>
                        <div className="m-auto my-3">
                            <Button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-deep_blue px-10
                                             py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-deep_blue 
                                             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                                             focus-visible:outline-deep_blue uppercase"
                            // onClick={() => {
                            //   handleSubmit(values);
                            // }}
                            >
                                
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Form >
                )}
            </Formik > */}
    </>
  );
};

export default DocumentPopupHOC(ViewLicense);
