import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaRegUserCircle } from 'react-icons/fa';
import Logo from '../../../assets/images/logo7.png';
import './Navbar.scss';
import SignInPopupHOC from '../../../pages/Login/hoc/SigninHoc';
import SignIn from '../../../pages/Login/Signin';
import SignUpPopupHOC from '../../../pages/SignUp/hoc/SignupHoc';
import SignUp from '../../../pages/SignUp/Signup';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import DashboardNavbar from './DashboardNavbar';
import { resetUserData, setUserData } from '../../../redux/slices/LoginSlice';
import { jwtDecode } from 'jwt-decode';
import axiosCall from '../../../utils/axiosCall';
import ConfirmationPopup from '../../../utils/ConfirmationPopup/ConfirmationPopup';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import Loader from '../Loader/Loader';
import { usePermissions } from '../../../utils/Permission/Permission';
import { useSocket } from '../../../context/SocketProvider';
import { removeUser } from '../../../redux/slices/UserSlice';

interface IProps {
  showSignInPopup: boolean;
  signInToggleHandler: () => void;
  showSignUpPopup: boolean;
  signUpToggleHandler: () => void;
  isDashboard: boolean;
}

function TopNav(props: IProps) {
  const {
    signInToggleHandler,
    showSignInPopup,
    signUpToggleHandler,
    showSignUpPopup,
    isDashboard,
  } = props;
  const userData = useSelector((state: any) => state.login.userData);
  const isLoggedIn = userData ? true : false;
  // const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [doLogout, setDoLogout] = useState(false);
  const token = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const { hasPermission } = usePermissions();
  const socket = useSocket();

  // console.log(isLoggedIn);
  useEffect(() => {
    const localUser = localStorage.getItem('user');

    if (localUser && token) {
      let decodedToken: any = jwtDecode(token);
      let currentDate = new Date();
      const userData = JSON.parse(localUser);

      // check if valid
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        // logout user
        navigate('/');
        handleLogout()
      } else {
        // Schedule logout when token expires
      }
    }
  }, [navigate]);

  const handleLogout = async () => {
    try {
      setLoading(true);
      const userID = userData?._id;
      navigate('/');
      dispatch(resetUserData(null));
      dispatch(removeUser());
      localStorage.clear();
      socket.emit("logout-me", userData?._id);
      setDoLogout(false);
      await axiosCall.post('/auth/logout/' + userID);
    } catch (error) {
     // showToastMessage('Some error occured while Logout', ToastType.Error);
      console.error('Logout failed:', error);
    } finally {
      setLoading(false);
    }
  };

  // if (userData) {
  //   setIsLoggedIn(userData?.token !== undefined);
  // } else {
  //   setIsLoggedIn(false);
  // }

  // check if we are in /dashboard

  // show if it's a dashboard view
  if (isDashboard) {
    return <DashboardNavbar />;
  }

  if (loading) {
    return <Loader />;
  }

  if (doLogout)
    return (
      <>
        <ConfirmationPopup
          onModalClose={() => setDoLogout(false)}
          onSubmit={() => {
            handleLogout();
          }}
          confirmationText="Are you sure you want to log out?"
          saveButtonText="Yes"
          cancelButtonText="No"
          modalTitle="Logout"
          isProgress={loading}
        />
      </>
    );

  return (
    <React.Fragment>
      {showSignInPopup && (
        <SignIn
          modalClosedSignIn={signInToggleHandler}
          modalClosedSignUp={signUpToggleHandler}
        />
      )}

      {showSignUpPopup && (
        <SignUp
          modalClosedSignUp={signUpToggleHandler}
          modalClosedSignIn={signInToggleHandler}
        />
      )}

      <header className="fixed-top">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-auto col-xl pe-0">
              <nav className="navbar navbar-expand-xl">
                <div className="container-fluid p-0">
                  <button
                    className="navbar-toggler ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <a className="navbar-brand" onClick={() => navigate('/')}>
                    <img src={Logo} alt="Logo" className="logo img-fluid" />
                  </a>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <NavLink to="/" className={'nav-link'}>
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/aboutus" className={'nav-link'}>
                          About Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/services" className={'nav-link'}>
                          Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/housing-inquiry" className={'nav-link'}>
                          Housing Inquiry
                        </NavLink>
                      </li>

                      {!hasPermission('tenant') ? (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Property Listing
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <NavLink
                              to="/list-property/room"
                              className={'nav-link'}
                            >
                              Room
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/list-property/house"
                              className={'nav-link'}
                            >
                              House
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/list-property/condos"
                              className={'nav-link'}
                            >
                              Condos
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/list-property/apartment"
                              className={'nav-link'}
                            >
                              Apartment
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/list-property/hotel"
                              className={'nav-link'}
                            >
                              Hotel
                            </NavLink>
                          </li>
                        </ul>
                      </li>):<></>}
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Resources
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <NavLink
                              to="/resources/tenant"
                              className={'nav-link'}
                            >
                              Tenant
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/resources/landlord"
                              className={'nav-link'}
                            >
                              Landlord
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/blog" className={'nav-link'}>
                          Blog
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>

              {/* <Navbar
                                expand="xl"
                                className="navbar"
                            >
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                                <Navbar.Brand>
                                    <NavLink to="/">
                                        <img src={Logo} alt="Logo" className="logo img-fluid" />
                                    </NavLink>
                                </Navbar.Brand>

                                <Navbar id="basic-navbar-nav" className='ms-auto'>
                                    <Nav activeKey={location.pathname}>
                                        <ul className='navbar-nav'>
                                            <li className='nav-item'>
                                                <NavLink to="/" className={'nav-link'}>Home</NavLink>
                                            </li>
                                            <li className='nav-item'>
                                                <NavLink to="/aboutus" className={'nav-link'}>About Us</NavLink>
                                            </li>
                                            <li className='nav-item'>
                                                <NavLink to="/services" className={'nav-link'}>Services</NavLink>
                                            </li>
                                            <li className='nav-item'>
                                                <NavLink to="/housing-inquiry" className={'nav-link'}>Post Request</NavLink>
                                            </li>
                                            <li className='nav-item'>
                                                <NavDropdown
                                                    title="Property Listing"
                                                    id="basic-nav-dropdown"
                                                    className="m-0"
                                                >
                                                    <li className='nav-item'>
                                                        <NavLink to="/listings/1" className={'nav-link'}>Room</NavLink>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <NavLink to="/listings/2" className={'nav-link'}>House</NavLink>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <NavLink to="/listings/3" className={'nav-link'}>Condos</NavLink>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <NavLink to="/listings/4" className={'nav-link'}>Apartment</NavLink>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <NavLink to="/listings/5" className={'nav-link'}>Hotel</NavLink>
                                                    </li>
                                                </NavDropdown>
                                            </li>
                                            <li className='nav-item'>
                                                <NavDropdown
                                                    title="Resources"
                                                    id="basic-nav-dropdown"
                                                    className="m-0"
                                                >
                                                    <li className='nav-item'>
                                                        <NavLink to="/resources/tenant" className={'nav-link'}>Tenant</NavLink>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <NavLink to="/resources/landlord" className={'nav-link'}>Landlord</NavLink>
                                                    </li>
                                                </NavDropdown>
                                            </li>
                                            <li className='nav-item'>
                                                <NavLink to="/blog" className={'nav-link'}>Blog</NavLink>
                                            </li>
                                        </ul>
                                    </Nav>
                                </Navbar>
                            </Navbar> */}
            </div>

            <div className="col col-xl-auto ps-0">
              <div className="header-right d-flex align-items-center justify-content-end">
                {isLoggedIn ? (
                  <>
                    <NavLink
                      className="btn btn-link d-flex align-items-center"
                      to="/dashboard"
                    >
                      Dashboard
                    </NavLink>
                    <button
                      className="btn btn-primary"
                      onClick={() => setDoLogout(true)}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-link d-flex align-items-center"
                      onClick={signUpToggleHandler}
                    >
                      <svg
                        className="me-2 mt-1"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#034991"
                      >
                        <path d="M14.0273 14.7852C13.2188 13.4141 11.7773 12.5 10.125 12.5H7.875C6.1875 12.5 4.74609 13.4141 3.97266 14.7852C5.20312 16.1562 6.99609 17 9 17C10.9688 17 12.7617 16.1562 14.0273 14.7852ZM0 10.25C0 7.05078 1.6875 4.09766 4.5 2.48047C7.27734 0.863281 10.6875 0.863281 13.5 2.48047C16.2773 4.09766 18 7.05078 18 10.25C18 13.4844 16.2773 16.4375 13.5 18.0547C10.6875 19.6719 7.27734 19.6719 4.5 18.0547C1.6875 16.4375 0 13.4844 0 10.25ZM9 10.8125C9.87891 10.8125 10.7227 10.3555 11.1797 9.54688C11.6367 8.77344 11.6367 7.82422 11.1797 7.01562C10.7227 6.24219 9.87891 5.75 9 5.75C8.08594 5.75 7.24219 6.24219 6.78516 7.01562C6.32812 7.82422 6.32812 8.77344 6.78516 9.54688C7.24219 10.3555 8.08594 10.8125 9 10.8125Z" />
                      </svg>
                      Sign up
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={signInToggleHandler}
                    >
                      Member Login
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default SignUpPopupHOC(SignInPopupHOC(TopNav));
