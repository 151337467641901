import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IProperty } from '../../pages/Listings/Listings';
import axiosCall from '../../utils/axiosCall';
const apiUrl = process.env.REACT_APP_API_URL;

export interface IFavourite {
    _id: string,
    propertie_id: string,
    title: string,
    description: string,
    p_price: number,
    p_type: string,
    status: string,
    totalFavorites: number
}

export interface IApiResponse {
    favoriteProperties: IFavourite[];
    totalPages: number;
    currentPage: number;
    pageSizes: number;
    totalFavouritePropertyCount: number;
}

export interface IFavouriteState {
    getFavouriteData: IApiResponse;
    getFavouriteLoading: boolean;
    getFavouriteSuccess: boolean;
    getFavouriteError: string | undefined;
}

const initialState: IFavouriteState = {
    getFavouriteData: {
        favoriteProperties: [],
        totalPages: 1,
        currentPage: 1,
        pageSizes: 10,
        totalFavouritePropertyCount: 1,
    },
    getFavouriteLoading: false,
    getFavouriteSuccess: false,
    getFavouriteError: ""
};

export const FavouriteSlice = createSlice({
    name: 'favourite',
    initialState,
    reducers: {
        //set cerateListing state.createListing
        getListing: (state, action) => {
            const { listing, error, loading } = action.payload;
        },
        resetGetFavouriteState: (state, action) => {
            state.getFavouriteLoading= false;
            state.getFavouriteSuccess= false;
            state.getFavouriteError= "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFavouriteData.pending, (state) => {
            state.getFavouriteLoading = true;
            state.getFavouriteError = "";
        });
        builder.addCase(fetchFavouriteData.fulfilled, (state, action) => {
            state.getFavouriteLoading = false;
            state.getFavouriteData = action.payload;
            state.getFavouriteSuccess = true;
        });
        builder.addCase(fetchFavouriteData.rejected, (state, action) => {
            state.getFavouriteLoading = false;
            state.getFavouriteError = action.error.message;
            state.getFavouriteSuccess = false;
        });
    },
});

export const { getListing, resetGetFavouriteState } = FavouriteSlice.actions;

export default FavouriteSlice.reducer;

export const fetchFavouriteData = createAsyncThunk<any, any>(
    'fetchFavouriteData',
    async (args: { body: any }) => {
        try {
            const response = await axiosCall.post(
                `/favorites/getFavoritePropertiesByUserId`,args.body
            );
            return response?.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    const errorMessage = axiosError.response.data;
                    return errorMessage;
                } else {
                    console.error('Error calling backend API:', axiosError.message);
                    return Promise.reject(axiosError.message);
                }
            } else {
                console.error('Error calling backend API:', error);
                return Promise.reject(error);
            }
        }
    }
);