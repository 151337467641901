import React, { useCallback, useState } from 'react';
import callBackendAPI from '../../utils/callBackendAPI';
import { useNavigate } from 'react-router-dom';
import SigninForm from './form/SigninForm';

interface IProps {
  modalClosedSignIn: () => void;
  modalClosedSignUp: () => void;
}

const SignIn = (props: IProps) => {
  const { modalClosedSignIn, modalClosedSignUp } = props;
  const [userEmail, setuserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const onModalClosedSignIn = useCallback(() => {
    modalClosedSignIn();
    navigate('/');
  }, [modalClosedSignIn]);

  const onModalClosedSignUp = useCallback(() => {
    modalClosedSignUp();
  }, [modalClosedSignUp]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoginError(() => '');
    try {
      const response = await callBackendAPI('POST', 'auth/login', null, {
        userEmail,
        password,
      });
      if (response.error) {
        setLoginError(() => response.error);
      } else {
        const { token, userData } = await response.data;

        localStorage.setItem('accessToken', token);
        localStorage.setItem('user', JSON.stringify(userData));

        localStorage.setItem('isLoggedin', 'true');
        navigate('/dashboard');
      }
    } catch (error) {
      setLoginError(() => 'something went wrong!!!');
      console.error('Sign in failed:', error);
    }
  };

  return (
    <>
      <SigninForm
        submitHandler={handleSubmit}
        onModalClosedSignIn={onModalClosedSignIn}
        onModalClosedSignUp={onModalClosedSignUp}
        initialValues={{ Email: '', Password: '' }}
        isLoading={false}
        setLoginError={setLoginError}
        loginError={loginError}
      />
    </>
  );
};

export default SignIn;

{
  /* <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-gray-100 rounded shadow-md h-auto">
<div className="sm:mx-auto sm:w-full sm:max-w-sm">
  <img
    className="mx-auto h-10 w-auto"
    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
    alt="Your Company"
  />
  <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
    Sign in to your account
  </h2>
</div>

<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
  <form className="space-y-6" action="#" method="POST">
    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
        Email address
      </label>
      <div className="mt-2">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>

    <div>
      <div className="flex items-center justify-between">
        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
          Password
        </label>
        <div className="text-sm">
          <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
            Forgot password?
          </a>
        </div>
      </div>
      <div className="mt-2">
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>

    <div>
      <button
        type="submit"
        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Sign in
      </button>
    </div>
  </form>

  <p className="mt-10 text-center text-sm text-gray-500">
    Not a member?{' '}
    <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
      Start a 14 day free trial
    </a>
  </p>
</div>
</div> */
}

{
  /* <div className="flex justify-center items-center h-screen">
        <form onSubmit={handleSubmit} className="bg-gray-100 p-8 rounded shadow-md w-1/3 h-auto">
            {loginError && <div style={{ color: 'red' }}>{loginError}</div>}

            <h2 className="text-2xl font-bold mb-4">Sign In</h2>
            <div className="mb-4">
                <label htmlFor="userEmail" className="block text-gray-700">Email</label>
                <input type="email" id="userEmail" value={userEmail} onChange={(e) => setuserEmail(e.target.value)} className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
            </div>
            <div className="mb-4">
                <label htmlFor="password" className="block text-gray-700">Password</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
            </div>
            <button type="submit" className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">Sign In</button>
        </form>
    </div> */
}
