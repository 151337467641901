import React from 'react';
import { Modal } from 'react-bootstrap';
import Loader from '../Loader/Loader';
import { IoClose } from 'react-icons/io5';
import { PaymentPage } from './PaymentPage';
import { useDispatch, useSelector } from 'react-redux';
import StripePopup from '../PaymentGateway/StripeGateway/StripePopup';
import { AppDispatch } from '../../../redux/store';
import { createCheckoutSession } from '../../../redux/slices/PaymentSlice';

interface IProps {
  pkgId: string;
  showMe: () => void;
}

const PaymentPopup = (props: IProps) => {
  const payment = useSelector((state: any) => state.payment);
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (id: string) => {
    const data = {
      plan: id,
      paymentSource: 'stripe',
    };
    dispatch(createCheckoutSession(data));
  };
  
  return (
    <>
      <StripePopup />
      <Modal className="px-10 backdrop-blur-sm blue-theme" show={true} centered>
        <Modal.Header className="justify-between pb-0 bg-white border-none">
          <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
            Pay using
            {payment.isLoading && <Loader isSmallLoader={true} />}
          </Modal.Title>
          <div
            onClick={() => {
              props.showMe();
            }}
            className="icons8-close"
          >
            <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
            <PaymentPage
              pkgId={props.pkgId}
              handleSubmit={handleSubmit}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentPopup;
