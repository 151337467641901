import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IProperty } from '../../pages/Listings/Listings';
import axiosCall from '../../utils/axiosCall';

// export interface IFavProperty{
//     _id: string,
//     totalFavorites: number,
//     latestFavouriteDate:string,
//     propertie_id: string,
//     title: string,
//     description: string,
//     p_price: number,
//     p_type: string,
//     status: string
// }
export interface ICalenderApiResponse {
  _id: string;
  title: string;
  start: string;
  end: string;
  room_id: string;
  user_id: string;
  createdAt: string;
}

export interface ICalender {
  calenderEvents: ICalenderApiResponse[];
  loading: boolean;
  error: string | undefined;
  success: boolean;
  addCalenderLoading: boolean;
  addCalenderError: string | undefined;
  addCalenderSuccess: boolean;
  deleteCalenderLoading: boolean;
  deleteCalenderError: string | undefined;
  deleteCalenderSuccess: boolean;
}

const initialState: ICalender = {
  calenderEvents: [],
  loading: true,
  error: undefined,
  success: false,
  addCalenderLoading: false,
  addCalenderError: '',
  addCalenderSuccess: false,
  deleteCalenderLoading: false,
  deleteCalenderError: '',
  deleteCalenderSuccess: false,
};

export const CalenderSlice = createSlice({
  name: 'calender',
  initialState,
  reducers: {
    //set cerateListing state.createListing
    setCalenderState: (state, action) => {
      const { calenderEvents, error, loading, success } = action.payload;
      state.calenderEvents = calenderEvents;
      state.error = error;
      // state.loading = loading;
      state.success = success;
      // state.addCalenderLoading = false;
      state.addCalenderError = '';
      state.addCalenderSuccess = false;
    },
    resetAddCalenderState: (state, action) => {
      // state.addCalenderLoading = false;
      state.addCalenderError = '';
      state.addCalenderSuccess = false;
    },
    resetFetchCalenderState: (state, action) => {
      // state.loading = false;
      state.error = '';
      state.success = false;
    },
    resetDeleteCalenderState: (state, action) => {
      // state.deleteCalenderLoading = false;
      state.deleteCalenderError = '';
      state.deleteCalenderSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCalenderEvent.pending, (state) => {
      state.addCalenderLoading = true;
      state.addCalenderError = undefined;
    });
    builder.addCase(addCalenderEvent.fulfilled, (state, action) => {
      state.addCalenderLoading = false;
      state.addCalenderSuccess = true;
      state.addCalenderError = undefined;
    });
    builder.addCase(addCalenderEvent.rejected, (state, action) => {
      state.addCalenderLoading = false;
      state.addCalenderError = action.error.message;
      state.addCalenderSuccess = false;
    });
    builder.addCase(fetchCalenderEvent.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCalenderEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.calenderEvents = action.payload;
      state.error = undefined;
    });
    builder.addCase(fetchCalenderEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(deleteCalenderEvent.pending, (state) => {
      state.deleteCalenderLoading = true;
      state.deleteCalenderError = undefined;
    });
    builder.addCase(deleteCalenderEvent.fulfilled, (state, action) => {
      state.deleteCalenderLoading = false;
      state.deleteCalenderSuccess = true;
      state.deleteCalenderError = undefined;
    });
    builder.addCase(deleteCalenderEvent.rejected, (state, action) => {
      state.deleteCalenderLoading = false;
      state.deleteCalenderError = action.error.message;
      state.deleteCalenderSuccess = false;
    });
  },
});

export const {
  setCalenderState,
  resetAddCalenderState,
  resetFetchCalenderState,
  resetDeleteCalenderState,
} = CalenderSlice.actions;

export default CalenderSlice.reducer;

export const addCalenderEvent = createAsyncThunk(
  'addCalenderEvent',
  async (arg: { body: any }) => {
    try {
      let url = arg.body.event.id
        ? `/calender/update/${arg.body.event.id}`
        : '/calender/create';

      const response = arg.body.event.id
        ? await axiosCall.put(url, arg.body.event)
        : await axiosCall.post(url, arg.body.event);

      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const fetchCalenderEvent = createAsyncThunk(
  'fetchCalenderEvent',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.get(
        `/calender/getByPropertyId/${arg.body.PropertyId}`
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const deleteCalenderEvent = createAsyncThunk(
  'deleteCalenderEvent',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.delete(
        `/calender/delete/${arg.body.id}`,
        arg.body.event
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);
