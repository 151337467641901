import React from 'react';
import './MapViewComponent.scss';
import CustomMapComponent from '../CustomMapComponent';

interface IProps {
  markers: { lat: number; lng: number; propertyName: string }[];
}
function MapViewComponent(props: IProps) {
  const { markers } = props;
  return (
    <>
      <div className="mb-5" style={{ height: '600px', width: '100%' }}>
        <CustomMapComponent mapClassName={''} markers={markers} />
      </div>
    </>
  );
}

export default MapViewComponent;
