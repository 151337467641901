import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const axiosCall = axios.create({
  baseURL: apiUrl, // our API base URL
});

// Request interceptor for adding the bearer token
axiosCall.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken'); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Export the api instance
export default axiosCall;