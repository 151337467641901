import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _id: '',
  title: '',
  description: '',
  p_type: '',
  status: '',
  numberOfRooms: 0,
  rooms: [],
  user_id: '',
  address: {
    street: '',
    city: '',
    state: '',
    zip: '',
    latitude: 0,
    longitude: 0,
  },
  noOfGarages: 0,
  isEntireUnit: false,
  p_video_path: '',
  amenities: [],
  nearby_facilities: [],
};

export const CreateListingSlice = createSlice({
  name: 'createListing',
  initialState,
  reducers: {
    //set cerateListing state.createListing
    // setCreateListing: (state, action) => {
    //   const {
    //     title,
    //     description,
    //     p_type,
    //     status,
    //     rooms,
    //     user_id,
    //     isEntireUnit,
    //     numberOfRooms,
    //   } = action.payload;
    //   state.title = title;
    //   state.description = description;
    //   state.p_type = p_type;
    //   state.status = status;
    //   state.isEntireUnit = isEntireUnit;
    //   state.numberOfRooms = numberOfRooms;
    //   state.rooms = rooms;
    //   state.user_id = user_id;
    // },
    // set location listing
    setLocationListing: (state, action) => {
      const { address } = action.payload;

      state.address = { ...state.address, ...address };
    },
    //set DetailsListing state.createListing
    setDetailedListing: (state, action) => {
      const { noOfGarages, p_video_path, amenities } = action.payload;
      state.noOfGarages = noOfGarages;
      state.p_video_path = p_video_path;
      state.amenities = amenities;
    },
    // set Nearby facilities
    setNearbyAminities: (state, action) => {
      const { nearby_facilities } = action.payload;

      state.nearby_facilities = nearby_facilities;
    },

    // set property
    setProperty: (state, action) => action.payload,

    // reset property
    resetProperty: () => initialState,
  },
});

export const {
  // setCreateListing,
  setLocationListing,
  setDetailedListing,
  setNearbyAminities,
  setProperty,
  resetProperty,
} = CreateListingSlice.actions;

export const locationState = (state: any) => state.createListing.address;

export default CreateListingSlice.reducer;
