import React, { useEffect, useState } from 'react';
import { User } from '../Dashboard';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import { UnknownAction } from 'redux';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import { FaEdit } from 'react-icons/fa';
import { BiSolidTrash } from 'react-icons/bi';
import { fetchFavouriteData, resetGetFavouriteState } from '../../../redux/slices/FavouriteSlice';
import { BsEyeFill } from 'react-icons/bs';
import { getPaginationNumberText } from '../../../utils/common';
import Pagination from '../../../components/CommonComponents/Pagination/Pagination';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';

interface IProps {

}
function FavouriteProperties(props: IProps) {
  const dispatch = useDispatch();
  const [CurrentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [TotalPages, setTotalPages] = useState(0);
  const userId = JSON.parse(localStorage.getItem('user') ?? '')?._id ?? '';
  const userData = useSelector((state: any) => state.login.userData);
  // const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const {
    getFavouriteData,
    getFavouriteLoading,
    getFavouriteSuccess,
    getFavouriteError
  } = useSelector((state: any) => state.favourites);

  const {
    favoriteProperties,
    totalPages,
    currentPage,
    pageSizes,
    totalPropertyCount,
  } = getFavouriteData;

  useEffect(() => {
    if (getFavouriteError) {
      showToastMessage("Error Fetching Favourite Properties", ToastType.Error);
    }
    dispatch(resetGetFavouriteState(null));
  }, [getFavouriteError]);

  // useEffect(() => {
  //   if (user !== null) callGetFavouriteAPI(CurrentPage, pageLimit);
  // }, [user]);

  useEffect(() => {
    callGetFavouriteAPI(CurrentPage, pageLimit);
  }, []);

  const callGetFavouriteAPI = (pageNum: number, limit: number) => {
    dispatch(
      fetchFavouriteData({
        body: {
          userId: userData?._id ?? userId,
          pageNo: pageNum,
          pageSize: limit,
        },
      }) as unknown as UnknownAction
    );
  };

  // useEffect(() => {
  //   const storedUser = localStorage.getItem('user');
  //   if (storedUser !== null) {
  //     setUser(JSON.parse(storedUser));
  //   }
  //   // console.log(user);
  // }, []);

  const gotoPropertyDetail = (id: string) => {
    navigate(`/property-detail/${id}`);
  };

  const changePage = (event: any) => {
    const pageNumber = event.selected + 1;
    setCurrentPage(pageNumber);
    callGetFavouriteAPI(pageNumber, pageLimit);
  };

  useEffect(() => {
    setTotalPages(totalPages);
  }, [totalPages]);

  const handleEdit = (item: any) => {

  };

  const handleDelete = (item: any) => {

  };

  return (
    <>
      <div className="main-content">
        <DashboardHeader />
        {(userData || userId) && (
          <div className="">
            {/* <DashboardHeader /> */}

            <div className="below-header">
              <div className="row align-items-center">
                <div className="col-sm-7">
                  <h1>Favorite Properties</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard">Dashboard</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        My Favorites
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-sm-5 text-sm-end">
                  <div className="property-filter">
                    <form>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control w-100"
                        />
                        <button
                          type="button"
                          className="btn filter-btn d-flex align-items-center"
                        >
                          {/* <img
                            src={FilterIcon}
                            alt="filter"
                            className=""
                          /> */}
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="#034991"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0 11.125C0 10.7695 0.273438 10.4688 0.65625 10.4688H2.26953C2.54297 9.59375 3.39062 8.9375 4.375 8.9375C5.33203 8.9375 6.17969 9.59375 6.45312 10.4688H13.3438C13.6992 10.4688 14 10.7695 14 11.125C14 11.5078 13.6992 11.7812 13.3438 11.7812H6.45312C6.17969 12.6836 5.33203 13.3125 4.375 13.3125C3.39062 13.3125 2.54297 12.6836 2.26953 11.7812H0.65625C0.273438 11.7812 0 11.5078 0 11.125ZM3.5 11.125C3.5 11.6172 3.88281 12 4.375 12C4.83984 12 5.25 11.6172 5.25 11.125C5.25 10.6602 4.83984 10.25 4.375 10.25C3.88281 10.25 3.5 10.6602 3.5 11.125ZM8.75 6.75C8.75 7.24219 9.13281 7.625 9.625 7.625C10.0898 7.625 10.5 7.24219 10.5 6.75C10.5 6.28516 10.0898 5.875 9.625 5.875C9.13281 5.875 8.75 6.28516 8.75 6.75ZM9.625 4.5625C10.582 4.5625 11.4297 5.21875 11.7031 6.09375H13.3438C13.6992 6.09375 14 6.39453 14 6.75C14 7.13281 13.6992 7.40625 13.3438 7.40625H11.7031C11.4297 8.30859 10.582 8.9375 9.625 8.9375C8.64062 8.9375 7.79297 8.30859 7.51953 7.40625H0.65625C0.273438 7.40625 0 7.13281 0 6.75C0 6.39453 0.273438 6.09375 0.65625 6.09375H7.51953C7.79297 5.21875 8.64062 4.5625 9.625 4.5625ZM5.25 3.25C5.71484 3.25 6.125 2.86719 6.125 2.375C6.125 1.91016 5.71484 1.5 5.25 1.5C4.75781 1.5 4.375 1.91016 4.375 2.375C4.375 2.86719 4.75781 3.25 5.25 3.25ZM7.32812 1.71875H13.3438C13.6992 1.71875 14 2.01953 14 2.375C14 2.75781 13.6992 3.03125 13.3438 3.03125H7.32812C7.05469 3.93359 6.20703 4.5625 5.25 4.5625C4.26562 4.5625 3.41797 3.93359 3.14453 3.03125H0.65625C0.273438 3.03125 0 2.75781 0 2.375C0 2.01953 0.273438 1.71875 0.65625 1.71875H3.14453C3.41797 0.84375 4.26562 0.1875 5.25 0.1875C6.20703 0.1875 7.05469 0.84375 7.32812 1.71875Z" />
                          </svg>
                          Filters
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="">
              <h2 className="text-3xl font-heading font-bold">
                Property Listing
              </h2>
              <div className="flex items-center justify-between">
                <div className="flex gap-2 justify-center items-center">
                  <NavLink to="/dashboard" className="text-deep_blue">
                    Dashboard
                  </NavLink>
                  <div className="">
                    <IoMdArrowDropright />
                  </div>
                  <NavLink to="/dashboard/listing">Listing</NavLink>
                </div>
                <div className="flex flex-row items-center justify-center gap-2">
                  <input
                    type="text"
                    placeholder=""
                    className="bg-white border-1 rounded-md w-[20rem] px-3 py-1"
                  />
                  <button className="bg-white flex flex-row items-center justify-center gap-2 px-3 py-1 rounded-lg border-1 cursor-pointer">
                    <img
                      src={FilterIcon}
                      alt="filter"
                      className="object-cover"
                    />
                    Filters
                  </button>
                </div>
              </div>
            </div> */}

            <div className="property-listing listing-table mt-0">
              {getFavouriteLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          {/* <th>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                          />
                        </th> */}
                          {/* <th>Order ID</th> */}
                          <th>Property</th>
                          <th>Property Type</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {favoriteProperties?.map(
                          (item: any, index: number) => {
                            return (
                              <tr key={item?._id}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="property-listing-preview"
                                    >
                                      <img
                                        src={item?.imagesLink?.[0]}
                                        alt="property-listing-preview"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="property-details ms-2">
                                      <h5>{item?.title}</h5>
                                      <p>{item?.description}</p>
                                    </div>
                                  </div>
                                </td>
                                <td>{item?.p_type}</td>
                                <td>{item?.p_price}</td>
                                <td>
                                  <div className="d-flex align-items-center gap-3">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        gotoPropertyDetail(item?._id);
                                      }}
                                    >
                                      <BsEyeFill />
                                    </button>
                                    {/* <button
                                    type="button"
                                    onClick={() => {
                                      handleEdit(item);
                                    }}
                                  >
                                    <FaEdit />
                                  </button> */}
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleDelete(item);
                                      }}
                                    >
                                      <BiSolidTrash />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  {TotalPages != 0 && TotalPages != undefined ? (
                    <Pagination
                      changePage={changePage}
                      totalPages={TotalPages}
                      text={getPaginationNumberText(
                        currentPage,
                        pageSizes,
                        totalPropertyCount,
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FavouriteProperties;
