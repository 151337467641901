import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useMemo, useState } from 'react';
import './PDFViewer.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import { PdfPreviewType } from '../../constants/previewType';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface IProps {
  url?: string;
  file?: any;
  previewType: PdfPreviewType;
  fromHomePage?: boolean;
}

export const PDFRender = (props: IProps) => {
  const [totalPages, setTotalPages] = useState();
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setTotalPages(numPages);
  };
  const url = useMemo<any>(() => ({ url: props.url }), [props.url]);

  return (
    <div className={`${props.fromHomePage ? 'pdf-container' : 'pdf-popup-container'}`}>
      <Document
        file={props.previewType === PdfPreviewType.File ? props.file : url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.apply(null, Array(totalPages))
          .map((x, i) => i + 1)
          .map((page) => {
            return (
              <Page
                key={page}
                scale={1.5}
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={true}
                // renderMode='none'
              />
            );
          })}
      </Document>
    </div>
  );
};
