import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastDefaults } from '../../../utils/ToastOptionCommon';
import { toast } from 'react-toastify';
import axiosCall from '../../../utils/axiosCall';
import { resetProperty } from '../../../redux/slices/CreateLisingSlice';
import { useParams } from 'react-router';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import LocationComponent from '../../../components/AddListingsComponents/LocationComponent/LocationComponent';
import DetailedInfoComponent, {
  Amenity,
} from '../../../components/AddListingsComponents/DetailedInfoComponent/DetailedInfoComponent';
import NearByComponent from '../../../components/AddListingsComponents/NearByComponent/NearByComponent';
import AddMediaComponent from '../../../components/AddListingsComponents/AddMediaComponent/AddMediaComponent';
import HeadingStatusComponent from '../../../components/AddListingsComponents/HeadingStatusComponent/HeadingStatusComponent';
import CreateListingComponent from '../../../components/AddListingsComponents/CreateListingComponent/CreateListingComponent';

import {
  fetchPropertyById,
  propertyById,
  resetpropertyData,
} from '../../../redux/slices/PropertySlice';
import { UnknownAction } from '@reduxjs/toolkit';
import { nearby } from '../../../utils/addListingInitialValue';
import { ListingSection, SectionData } from '../../../constants/ListingSection';
import NavigationPrompt from '../../../utils/NavigationPrompt/NavigationPrompt';
import { IoWarningOutline } from 'react-icons/io5';

function EditListing() {
  const { id }: any = useParams();
  const [propertyId, setPropertyId] = useState(id);
  const [currentSection, setCurrentSection] = useState<number>(-1);
  const propertyState = useSelector((state: any) => state.property);
  const property = useSelector(propertyById);
  const dispatch = useDispatch();
  const isDocUpdate = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setPropertyId(id);
      dispatch(
        fetchPropertyById({
          Id: id,
          section: currentSection,
        }) as unknown as UnknownAction
      );
    }
    return () => {
      dispatch(resetpropertyData());
      dispatch(resetProperty());
    };
  }, [id]);

  const cafe = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Cafe'
  );
  const education = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Education'
  );
  const grocery = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Grocery'
  );
  const healthAndMedical = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Healthcare'
  );
  const militaryBase = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) =>
      item.name === 'MilitaryBase'
  );
  const movieTheater = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) =>
      item.name === 'MovieTheater'
  );
  const park = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Park'
  );
  const restaurant = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Restaurant'
  );
  const shoppingCenter = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Shopping'
  );
  const transportation = property.nearby_facilities?.filter(
    (item: { name: string; description: string }) => item.name === 'Transport'
  );

  const editListingInitialValues = {
    section: -1,
    details: {
      landlord: property?.LandlordName,
      propertyTitle: property.title || '',
      propertyDescription: property.description || '',
      propertyType: property.p_type || '',
      propertyStatus: property.status,
      propertyRooms: property.numberOfRooms || 1,
      propertyRoomType: property.rooms || [],
      propertyPrice: '',
      propertyTenure: 'm',
      isEntireUnit: property.isEntireUnit || false,
    },
    location: {
      street: property.address?.street || '',
      state: property.address?.state || '',
      city: property.address?.city || '',
      zip: property.address?.zip || '',
      latitude: property.address?.latitude || 31.9686,
      longitude: property.address?.longitude || -99.9018,
    },
    amenities: {
      p_video_path: property.p_video_path || '',
      amenities: property?.amenities?.map((e: Amenity) => e?.id?.toString()) || [],
      other: property?.other || ''
    },
    nearBy: {
      propertyCafe: cafe?.length > 0,
      propertyCafeDetails: cafe?.length > 0 ? cafe : nearby,
      propertyEducation: education?.length > 0,
      propertyEducationDetails: education?.length > 0 ? education : nearby,
      propertyGroceryStore: grocery?.length > 0,
      propertyGroceryStoreDetails: grocery?.length > 0 ? grocery : nearby,
      propertyHealthAndMedical: healthAndMedical?.length > 0,
      propertyHealthAndMedicalDetails:
        healthAndMedical?.length > 0 ? healthAndMedical : nearby,
      propertyMilitaryBase: militaryBase?.length > 0,
      propertyMilitaryBaseDetails:
        militaryBase?.length > 0 ? militaryBase : nearby,
      propertyMovieTheaters: movieTheater?.length > 0,
      propertyMovieTheatersDetails:
        movieTheater?.length > 0 ? movieTheater : nearby,
      propertyParks: park?.length > 0,
      propertyParksDetails: park?.length > 0 ? park : nearby,
      propertyRestaurants: restaurant?.length > 0,
      propertyRestaurantsDetails: restaurant?.length > 0 ? restaurant : nearby,
      propertyShoppingCenters: shoppingCenter?.length > 0,
      propertyShoppingCentersDetails:
        shoppingCenter?.length > 0 ? shoppingCenter : nearby,
      propertyTransportation: transportation?.length > 0,
      propertyTransportationDetails:
        transportation?.length > 0 ? transportation : nearby,
    },
    propertyMedia: [],
    propertyBathrooms: 1,
    noOfGarages: property.noOfGarages || 0,
  };

  const handleSubmit = async (formData: FormData) => {
    try {
      setIsLoading(true);
      formData.append(`propertyId`, propertyId);
      const url = `/properties/update/${propertyId}`;
      // edit request
      const response = await axiosCall.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const data = response.data;
      if (response.status === 201 || response.status === 200) {
        toast.success(`${SectionData[currentSection]} updated successfully`, toastDefaults());
        setCurrentSection(-99);
        // props?.resetForm();
        // dispatch(resetProperty());
        // dispatch(resetpropertyData());
        // navigate('/dashboard/listing');
        dispatch(
          fetchPropertyById({
            Id: id,
            section: currentSection,
          }) as unknown as UnknownAction
        );
      } else {
        toast.error('Failed to update property listing.', toastDefaults());
      }
    } catch (error: any) {
      console.error(`Edit Listing error: ${error}`);
      if (error?.response?.status === 409) {
        toast.error('Property title already exist.', toastDefaults());
      } else {
        toast.error('Failed to update property listing.', toastDefaults());
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="createListingTab" className="main-content">
      {(isLoading || propertyState.isLoading) && <Loader />}

      <NavigationPrompt
        confirmationText={
          'Are you sure you want to leave without saving changes?'
        }
        icon={<IoWarningOutline className="m-1 warn-popup" />}
        saveButtonText={'Leave'}
        cancelButtonText={'Stay'}
        modalTitle={'Confirmation'}
        isProgress={false}
      />

      {/* Add property heading */}
      <HeadingStatusComponent isEditListing={true} section={currentSection} />
      <div className="font-body">
        {/* Create Listing */}
        <CreateListingComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={editListingInitialValues}
          isEdit={true}
        />
        {/* Location */}
        <LocationComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={editListingInitialValues}
          isEdit={true}
        />
        {/* Detailed Information */}
        <DetailedInfoComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={editListingInitialValues}
          isEdit={true}
        />
        {/* Near by */}
        <NearByComponent
          handleSubmit={handleSubmit}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          initialValues={editListingInitialValues}
          isEdit={true}
        />
        {/* Media & Submit */}
        <AddMediaComponent
          lastUploadedFilePath={property?.documentsLink}
          isEdit={true}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          handleSubmit={handleSubmit}
          isDocUpdate={isDocUpdate}
        />
      </div>
    </div>
  );
}

export default EditListing;
