import axios, { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';

export interface IPropertyList {
  firstName: string;
  lastName: string;
  userEmail: string;
  phoneNo: string;
  city: string;
  state: string;
  zipCode: string;
}

interface IState {
  loading: boolean;
  error: string | undefined;
  success: boolean;
  data: any;
  propertyList: IPropertyList;
}

const initialState: IState = {
  data: [],
  loading: false,
  error: undefined,
  success: false,
  propertyList: {
    firstName: '',
    lastName: '',
    userEmail: '',
    phoneNo: '',
    city: '',
    state: '',
    zipCode: '',
  },
};

export const ListPropertySlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setListPropertyState: (state, action) => {
      state.propertyList = action.payload ;
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addCase(getAllPackages.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = '';
  //     });
  //     builder.addCase(getAllPackages.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.success = true;
  //       state.data = action.payload;
  //       state.error = '';
  //     });
  //     builder.addCase(getAllPackages.rejected, (state, action:any) => {
  //       state.isLoading = false;
  //       state.success = false;
  //       state.error = action.error.message;
  //     });
  //   },
});

export const { setListPropertyState } = ListPropertySlice.actions;

export default ListPropertySlice.reducer;

export const propertyListInitialState = (state: any) =>
  state.propertyListing.propertyList;

// export const getAllPackages = createAsyncThunk<any, any>(
//   'getAllPackages',
//   async () => {
//     try {
//       const response = await axiosCall.get(`/packages`);
//       return response?.data;
//     } catch (error) {
//       if (axios.isAxiosError(error)) {
//         const axiosError = error as AxiosError;
//         if (axiosError.response) {
//           const errorMessage = axiosError.response.data;
//           return Promise.reject(errorMessage);
//         } else {
//           console.error('Error calling backend API:', axiosError.message);
//           return Promise.reject(axiosError.message);
//         }
//       } else {
//         console.error('Error calling backend API:', error);
//         return Promise.reject(error);
//       }
//     }
//   }
// );
