import { useCallback, useEffect, useState } from 'react';
import { ToastType, showToastMessage } from '../utils/ToastOptionCommon';

const apiUrl = process.env.REACT_APP_API_URL;

interface IProps {
  state: string;
}

export const useStateCity = (props: IProps) => {
  const [loading, setLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCitis] = useState([]);

  const getStates = useCallback(async () => {
    setStateLoading(true);
    const res = await fetch(`${apiUrl}/country/United States`)
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setStateLoading(false);
      });
    if (res?.error) {
      showToastMessage('Failed to get states', ToastType.Error);
    } else {
      setStates(res?.states);
    }
  }, []);

  const getCities = useCallback(async () => {
    if (props.state) {
      setLoading(true);
      const res = await fetch(`${apiUrl}/country/United States/${props.state}`)
        .then((r) => r.json())
        .then((r) => r)
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
      if (res?.error) {
        showToastMessage('Failed to get cities', ToastType.Error);
      } else {
        setCitis(res?.cities);
      }
    }
  }, [props.state]);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (props.state !== '') {
      getCities();
    }
  }, [props.state]);

  return { loading, stateLoading, states, cities };
};
