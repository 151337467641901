import moment from "moment";

export const formatDate = (sendTime:any) => {
  const messageDate = new Date(sendTime);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // Check if messageDate is today
  // if (messageDate.toDateString() === today.toDateString()) {
  //   const hours = messageDate.getHours() % 12 || 12;
  //   const minutes = messageDate.getMinutes();
  //   const ampm = messageDate.getHours() >= 12 ? 'PM' : 'AM';
  //   return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  //   // return `Today ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  // }

  // Check if messageDate is yesterday
  // if (messageDate.toDateString() === yesterday.toDateString()) {
  //   const hours = messageDate.getHours() % 12 || 12;
  //   const minutes = messageDate.getMinutes();
  //   const ampm = messageDate.getHours() >= 12 ? 'PM' : 'AM';
  //   return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  //   // return `Yesterday ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  // }

  // return getDateOnly(messageDate.toDateString());


  // Check if messageDate is in the same year
  // if (
  //   messageDate.getFullYear() === today.getFullYear() &&
  //   messageDate.getMonth() === today.getMonth()
  // ) {
  //   const options:any = { day: 'numeric', year: 'numeric' };
  //   return messageDate.toLocaleDateString('en-US', options);
  // }

  // const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
  // return messageDate.toLocaleDateString('en-US', options);

  const hours = messageDate.getHours() % 12 || 12;
  const minutes = messageDate.getMinutes();
  const ampm = messageDate.getHours() >= 12 ? 'PM' : 'AM';
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
};

export const getDateOnly = (date: string) => {
  return moment(date).format(DateFormat);
}

export const formatPopupMsgDate = (sendTime:any) => {
  const messageDate = new Date(sendTime);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // Check if messageDate is today
  if (messageDate.toDateString() === today.toDateString()) {
    return `Today`;
  }

  // Check if messageDate is yesterday
  if (messageDate.toDateString() === yesterday.toDateString()) {
    return `Yesterday`;
  }
  return getDateOnly(sendTime);
};


export const DateFormat = "MM/DD/YYYY";