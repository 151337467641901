import './HousingRequest.scss';
import { HousingRequestForm } from './form/HousingRequestForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  IInitRequest,
  houseRequestInitialState,
  houseRequestState,
  housingRequest,
  resetRequest,
} from '../../redux/slices/HousingRequestSlice';
import { AppDispatch } from '../../redux/store';
import { useEffect, useRef, useState } from 'react';
import { ToastType, showToastMessage } from '../../utils/ToastOptionCommon';

export const HousingRequest = () => {
  const { loading, error, success  } = useSelector(houseRequestState);
  const houseRequest = useSelector(houseRequestInitialState);
  const dispatch = useDispatch<AppDispatch>();
  const resetRef = useRef<any>();

  useEffect(()=>{
    if(success){
      showToastMessage("Request send successfully", ToastType.Success);
      dispatch(resetRequest());
      resetRef.current.click();
    }
  },[success])

  useEffect(()=>{
    if(error){
      showToastMessage("Failed to send request", ToastType.Error);
    }
  },[error])

  const submitHandler = (values: IInitRequest) => {
    let data: any = {};
    data.requester = values.requester;
    data.state = values.state;
    data.city = values.city;
    if (values.requester === 'Traveler' || values.requester === 'Military') {
      data.traveler = values.traveler;
    } else if (values.requester === 'Agency') {
      data.agency = values.agency;
      data.agencies = values.agencies;
    }
    dispatch(housingRequest(data));
  };

  return (
    <HousingRequestForm
      loading={loading}
      initialValues={houseRequest}
      submitHandler={submitHandler}
      resetRef={resetRef}
    />
  );
};
