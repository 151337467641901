import React, { useState } from 'react';
import './PopularProperties.scss';
import CardsPagination from '../../CommonComponents/CardsPagination/CardsPagination';
import SignInPopupHOC from '../../../pages/Login/hoc/SigninHoc';
import SignUpPopupHOC from '../../../pages/SignUp/hoc/SignupHoc';
import SignIn from '../../../pages/Login/Signin';
import SignUp from '../../../pages/SignUp/Signup';

function PopularProperties(props) {
  const { guestProperties } = props;
  const [startIndex, setStartIndex] = useState(3);
  const [endIndex, setEndIndex] = useState(5);
  return (
    <>
      {props.showSignInPopup && (
        <SignIn
          modalClosedSignIn={props.signInToggleHandler}
          modalClosedSignUp={props.signUpToggleHandler}
        />
      )}

      {props.showSignUpPopup && (
        <SignUp
          modalClosedSignUp={props.signUpToggleHandler}
          modalClosedSignIn={props.signInToggleHandler}
        />
      )}
      <section id='guests-favorite' className="properties p-0">

        <div className="container">
          <div className='row mb-4'>
            <div className='col-12'>
              <h2>Guests Favorites</h2>
              {/* <p> Get some Inspirations from 800+ Properties</p> */}
            </div>
          </div>

          <CardsPagination
            items={guestProperties}
            startIndex={startIndex}
            endIndex={endIndex}
            setEndIndex={setEndIndex}
            setStartIndex={setStartIndex}
            signInToggleHandler={props.signInToggleHandler}
            favedId={props.favedId} setFavedId={props.setFavedId}
          />
        </div>
      </section>
    </>
  );
}

export default SignUpPopupHOC(SignInPopupHOC(PopularProperties));
