import React, { useEffect, useState } from 'react';
import { User } from '../../../pages/Dashboard/Dashboard';
import ProfileImage from '../../../assets/images/avatar.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
  resetVerifyEmail,
  sendEmailVerification,
  verifyEmail,
} from '../../../redux/slices/UserSlice';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import Loader from '../../CommonComponents/Loader/Loader';
import { usePermissions } from '../../../utils/Permission/Permission';
import { UnknownAction } from '@reduxjs/toolkit';
import {
  resetMessageNotification,
  setMessageNotification,
} from '../../../redux/slices/LoginSlice';

interface IProps {
  userDetails: User | undefined;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isMessageNotification: boolean;
  setIsMessageNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

function DashboardSettingProfileComponent(props: IProps) {
  const {
    userDetails,
    setIsEditing,
    isMessageNotification,
    setIsMessageNotification,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const verify = useSelector(verifyEmail);
  const { hasPermission } = usePermissions();
  const messageNotificationState = useSelector(
    (state: any) => state.login.messageNotification
  );
  // const [messageNotification, setMessageNotification] = useState(
  //   user?.messageNotification ?? false
  // );

  const handlesetMessageNotification = (messageNotification: boolean) => {
    dispatch(
      setMessageNotification({
        body: {
          isMessageNotification: messageNotification,
          userId: userDetails?._id,
        },
      }) as unknown as UnknownAction
    );
  };

  useEffect(() => {
    if (verify.success) {
      showToastMessage('Verification email sent', ToastType.Success);
      dispatch(resetVerifyEmail());
    }
    if (verify.error) {
      showToastMessage('Unable to send email', ToastType.Error);
      dispatch(resetVerifyEmail());
    }
  }, [verify]);

  useEffect(() => {
    if (messageNotificationState.success) {
      showToastMessage(
        'Notification status successfully updated',
        ToastType.Success
      );
      dispatch(resetMessageNotification());
      setIsMessageNotification(!isMessageNotification);
    }
    if (messageNotificationState.error) {
      showToastMessage('Unable to update notification status', ToastType.Error);
      dispatch(resetMessageNotification());
    }
  }, [
    isMessageNotification,
    messageNotificationState,
    setIsMessageNotification,
  ]);

  return (
    <div className="tab-content">
      {(verify.loading || messageNotificationState.loading) && <Loader />}
      <div id="Update" className="tab-pane active">
        <div className="row">
          <div className="col-12">
            <h3 className="box-title">Profile</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-sm-6 mb-4">
                <div className="d-flex align-items-center">
                  <div className="field-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M480-492.31q-57.75 0-98.87-41.12Q340-574.56 340-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q620-690.06 620-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12ZM180-187.69v-88.93q0-29.38 15.96-54.42 15.96-25.04 42.66-38.5 59.3-29.07 119.65-43.61 60.35-14.54 121.73-14.54t121.73 14.54q60.35 14.54 119.65 43.61 26.7 13.46 42.66 38.5Q780-306 780-276.62v88.93H180Zm60-60h480v-28.93q0-12.15-7.04-22.5-7.04-10.34-19.11-16.88-51.7-25.46-105.42-38.58Q534.7-367.69 480-367.69q-54.7 0-108.43 13.11-53.72 13.12-105.42 38.58-12.07 6.54-19.11 16.88-7.04 10.35-7.04 22.5v28.93Zm240-304.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm0 384.62Z" />
                    </svg>
                  </div>
                  <span className="field-value">
                    {userDetails?.firstName} {userDetails?.lastName}
                  </span>
                </div>
              </div>

              <div className="col-sm-6 mb-4">
                <div className="d-flex align-items-center">
                  <div className="field-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M777.61-140q-113.07 0-227.19-52.58-114.11-52.58-209.69-148.34-95.57-95.77-148.15-209.7Q140-664.54 140-777.61q0-18.17 12-30.28T182-820h130.46q15.15 0 26.73 9.89 11.58 9.88 14.73 24.42L376.85-668q2.38 16.38-1 28.15-3.39 11.77-12.16 19.77l-92.38 89.93q22.3 40.84 50.96 77.27 28.65 36.42 62.04 69.57 32.92 32.93 70 61.16 37.08 28.23 80.08 52.54l89.76-90.54q9.39-9.77 22.74-13.7 13.34-3.92 27.73-1.92l111.07 22.62q15.15 4 24.73 15.46t9.58 26V-182q0 18-12.11 30t-30.28 12ZM242.92-586.92l71.39-68.31q1.92-1.54 2.5-4.23.58-2.69-.19-5l-17.39-89.39q-.77-3.07-2.69-4.61-1.92-1.54-5-1.54H206q-2.31 0-3.85 1.54-1.53 1.54-1.53 3.85 3.07 41 13.42 83.3 10.34 42.31 28.88 84.39Zm348 345.69q39.77 18.54 82.96 28.35 43.2 9.8 80.73 11.65 2.31 0 3.85-1.54t1.54-3.85v-84.15q0-3.08-1.54-5t-4.61-2.69l-84-17.08q-2.31-.77-4.04-.19-1.73.58-3.66 2.5l-71.23 72Zm-348-345.69Zm348 345.69Z" />
                    </svg>
                  </div>
                  <span className="field-value">{userDetails?.phoneNo}</span>
                </div>
              </div>

              <div className="col-sm-6 mb-4">
                <div className="d-flex align-items-center">
                  <div className="field-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31ZM480-457.69 160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410L480-457.69Zm0-62.31 313.85-200h-627.7L480-520ZM160-662.31V-720v467.69q0 5.39 3.46 8.85t8.85 3.46H160v-422.31Z" />
                    </svg>
                  </div>
                  <span className="field-value">{userDetails?.userEmail}</span>
                  {userDetails?.isEmailVerified ? (
                    <span className="field-value text-success">Verified</span>
                  ) : (
                    <span
                      onClick={() => dispatch(sendEmailVerification())}
                      className="text-primary cursor-pointer text-decoration-underline m-2 field-value"
                    >
                      Verify
                    </span>
                  )}
                </div>
              </div>

              <div className="col-sm-6 mb-4">
                <div className="d-flex align-items-center">
                  <div className="field-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M480-288.31q104-83.07 157-159.57T690-594q0-58.71-21.46-100.13-21.46-41.41-53.19-67.06-31.73-25.66-68.37-37.23Q510.35-810 480-810t-66.98 11.58q-36.64 11.57-68.37 37.23-31.73 25.65-53.19 67.06Q270-652.71 270-594q0 69.62 53 146.12t157 159.57Zm0 76Q344-313.62 277-408.54q-67-94.92-67-185.47 0-68.39 24.54-119.92t63.13-86.38q38.59-34.84 86.82-52.27Q432.73-870 480-870t95.51 17.42q48.23 17.43 86.82 52.27 38.59 34.85 63.13 86.38Q750-662.4 750-594.01q0 90.55-67 185.47T480-212.31ZM480-530q29.15 0 49.58-20.42Q550-570.85 550-600t-20.42-49.58Q509.15-670 480-670t-49.58 20.42Q410-629.15 410-600t20.42 49.58Q450.85-530 480-530ZM210-90v-60h540v60H210Zm270-504Z" />
                    </svg>
                  </div>
                  <span className="field-value">
                    {userDetails?.address && userDetails?.address?.length > 0
                      ? userDetails?.address
                      : 'No address provided'}
                  </span>
                </div>
              </div>

              {userDetails?.aboutUser && userDetails?.aboutUser?.length > 0 ? (
                <div className="col-sm-6 mb-4">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M450-290h60v-230h-60v230Zm30-298.46q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm.07 488.46q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                      </svg>
                    </div>
                    <span className="field-value">
                      {userDetails?.aboutUser}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {!hasPermission('admin') ? (
                <div className="col-sm-6 mb-4">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#e8eaed"
                      >
                        <path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                      </svg>
                    </div>
                    <span className="field-value cursor-pointer">
                      Message notification{''}:{' '}
                      <span
                        className="text-primary text-uppercase"
                        onClick={() =>
                          isMessageNotification
                            ? handlesetMessageNotification(
                                !isMessageNotification
                              )
                            : handlesetMessageNotification(
                                !isMessageNotification
                              )
                        }
                      >
                        {isMessageNotification ? 'On' : 'Off'}
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* <div className="mb-3">
              <div className="form-label">
                First Name :{' '}
                <span className="text-secondary">{userDetails?.firstName}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Last Name :{' '}
                <span className="text-secondary">{userDetails?.lastName}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Phone :{' '}
                <span className="text-secondary">{userDetails?.phoneNo}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Email :{' '}
                <span className="text-secondary">{userDetails?.userEmail}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Location :{' '}
                <span className="text-secondary">
                  {userDetails?.address && userDetails?.address.length > 0
                    ? userDetails.address
                    : 'No address provided'}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                About Me :{' '}
                <span className="text-secondary">
                  {userDetails?.aboutUser && userDetails.aboutUser.length > 0
                    ? userDetails.aboutUser
                    : 'Tell us about you'}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Occupation :{' '}
                <span className="text-secondary">
                  {userDetails?.occupation &&
                    userDetails.occupation.name.length > 0
                    ? userDetails.occupation?.name
                    : 'Tell us about what you do'}
                </span>
              </div>
            </div> */}
          </div>
          <div className="col-md-5">
            <div className="update-profile-img text-center">
              <div className="profile-img mb-4 mx-md-auto">
                <img
                  src={
                    userDetails?.profileImage?.length
                      ? userDetails?.profileImage
                      : ProfileImage
                  }
                  className="img-fluid w-100"
                  alt={userDetails?.firstName + ' ' + userDetails?.lastName}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setIsEditing(true)}
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSettingProfileComponent;
