import React, { useCallback, useEffect, useState } from 'react';
import MainHome from '../../components/HomeComponents/MainHome/MainHome';
import FeaturedHomes from '../../components/HomeComponents/FeaturedHomes/FeaturedHomes';
import PopularProperties from '../../components/HomeComponents/PopularProperties/PopularProperties';
import Aboutus from '../../components/HomeComponents/AboutUs/Aboutus';
import ChooseUs from '../../components/HomeComponents/ChooseUs/ChooseUs';
import RegisterCta from '../../components/HomeComponents/RegisterCTA/RegisterCta';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import Loader from '../../components/CommonComponents/Loader/Loader';
import {
  IHome,
  fetchHome,
  setIsFavouriteHome,
  setIsNotFavouriteHome,
} from '../../redux/slices/HomeSlice';
import { toastDefaults } from '../../utils/ToastOptionCommon';
import { toast } from 'react-toastify';
import { IProperty } from '../Listings/Listings';
import { resetListingState } from '../../redux/slices/ListingSlice';
import { useLocation } from 'react-router-dom';
import { usePermissions } from '../../utils/Permission/Permission';

function Home() {
  const dispatch = useDispatch();
  const [favId, setFavId] = useState<string>('');
  const homeData: IHome = useSelector((state: any) => state.home);
  const {
    postFavPropertyError,
    postFavPropertySuccess,
    deleteFavPropertyError,
    deleteFavPropertySuccess,
    deleteFavPropertyLoading,
    postFavPropertyLoading,
  } = useSelector((state: any) => state.listing);
  const userData = useSelector((state: any) => state.login.userData);
  const isLoggedIn = userData ? true : false;
  const { hasPermission } = usePermissions();

  //console.log(isLoggedIn);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    dispatch(
      fetchHome({
        body: {
          // searchText: searchText,
          // Zip: 0,
          // minPrice: minPrice,
          // maxPrice: maxPrice,
          // propertyType: propertyType,
          // minSizeInFt: 1500,
          // status: "available",
          // isActive: true,
          // isFeaturedProperty: true,
          // minRoomPrice: propertyType===2 ? minRoomPrice:0,
          // maxRoomPrice: propertyType===2 ? maxRoomPrice:0,
          // pageNo: 1,
          // pageSize: 10
        },
      }) as unknown as UnknownAction
    );
  }, [isLoggedIn]);

  useEffect(() => {
    if (homeData.error) {
      toast.error('Error Fetching Home', toastDefaults());
    }
  }, [homeData.error]);

  useEffect(() => {
    if (postFavPropertySuccess) {
      toast.success('Marked as Favorite', toastDefaults());
    }
    dispatch(setIsFavouriteHome({ id: favId }));
    dispatch(resetListingState({}));
  }, [postFavPropertySuccess]);

  useEffect(() => {
    if (deleteFavPropertySuccess) {
      toast.success('Unmarked from Favorite', toastDefaults());
    }
    dispatch(setIsNotFavouriteHome({ id: favId }));
    dispatch(resetListingState({}));
  }, [deleteFavPropertySuccess]);

  useEffect(() => {
    if (postFavPropertyError) {
      toast.error('Failed Faving Property', toastDefaults());
    }
    dispatch(resetListingState(null));
  }, [postFavPropertyError]);

  useEffect(() => {
    if (deleteFavPropertyError) {
      toast.error('Failed Un-Faving Property', toastDefaults());
    }
    dispatch(resetListingState(null));
  }, [deleteFavPropertyError]);

  const subscribeEmailState = useSelector(
    (state: any) => state.home.subscribeEmail
  );

  const guestProperties = homeData?.home?.GuestProperties
    ? homeData?.home?.GuestProperties?.map(
        (property: IProperty, index: number) => {
          return {
            id: index + 1,
            _id: property._id,
            title: property.title,
            price: property.p_price ?? 0,
            features: property.amenities,
            availabilityDate: property.available_date ?? [''],
            isNew: false,
            isFeatured: property.isFeaturedProperty ?? false,
            fillImg: property.imagesLink,
            isFavourite: property.isFavoriteForThisUser,
            propertyId: property._id,
            type: property.p_type,
          };
        }
      )
    : [];

  const featuredProperties = homeData?.home?.FeaturedProperties
    ? homeData?.home?.FeaturedProperties?.map(
        (property: IProperty, index: number) => {
          return {
            id: index + 1,
            _id: property._id,
            title: property.title,
            price: property.p_price ?? 0,
            features: property.amenities,
            availabilityDate: property.available_date ?? [''],
            isNew: false,
            isFeatured: property.isFeaturedProperty ?? false,
            fillImg: property.imagesLink,
            isFavourite: property.isFavoriteForThisUser,
            propertyId: property._id,
            type: property.p_type,
          };
        }
      )
    : [];

  return (
    <div className="home">
      {(deleteFavPropertyLoading || postFavPropertyLoading) && <Loader />}
      <MainHome />
      {isLoggedIn && (
        <>
          <div className={isLoggedIn ? 'featured_section bg-white' : ''}>
            {homeData.loading || subscribeEmailState.loading ? (
              <Loader isSmallLoader={true} />
            ) : (
              <>
                {homeData.home.FeaturedProperties?.length > 0 && (
                  <FeaturedHomes
                    featuredProperties={featuredProperties ?? []}
                    favedId={favId}
                    setFavedId={setFavId}
                  />
                )}
                {homeData.home.GuestProperties?.length > 0 && (
                  <PopularProperties
                    guestProperties={guestProperties ?? []}
                    favedId={favId}
                    setFavedId={setFavId}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
      <ChooseUs />
      <Aboutus aboutUsData={homeData.home} />
      {!hasPermission('tenant') ? <RegisterCta /> : <></>}
    </div>
  );
}

export default Home;
