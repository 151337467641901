import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';
import axios, { AxiosError } from 'axios';

export interface ILicenseType {
    id: number;
    name: string;
}

export interface ISubscribedEmail {
    email: string,
    userId: string,
    subscribed_at: string
}

const initialState = {
    getAllSubscribeEmail: {
        loading: false,
        error: '',
        data: [],
        success: false,
    },
};

export const SubscriberEmailSlice = createSlice({
    name: 'SubscriberEmail',
    initialState,
    reducers: {
        resetGetAllSubscriberEmail: (state) => {
            state.getAllSubscribeEmail.loading = false;
            state.getAllSubscribeEmail.success = false;
            state.getAllSubscribeEmail.error = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSubscriberEmail.pending, (state) => {
            state.getAllSubscribeEmail.loading = true;
            state.getAllSubscribeEmail.error = '';
        });
        builder.addCase(getAllSubscriberEmail.fulfilled, (state, action) => {
            state.getAllSubscribeEmail.loading = false;
            state.getAllSubscribeEmail.data = action.payload;
            state.getAllSubscribeEmail.success = true;
            state.getAllSubscribeEmail.error = '';
        });
        builder.addCase(getAllSubscriberEmail.rejected, (state, action) => {
            state.getAllSubscribeEmail.loading = false;
            state.getAllSubscribeEmail.error = action.error.message ?? '';
            state.getAllSubscribeEmail.success = false;
        });
    },
});

export const {
    resetGetAllSubscriberEmail,
} = SubscriberEmailSlice.actions;

export default SubscriberEmailSlice.reducer;

export const selectGetLicenseById = (state: any) =>
    state.document.getLicenseDetail;

export const getAllSubscriberEmail = createAsyncThunk(
    'getAllSubscriberEmail',
    async (arg: { body: any }) => {
        try {
            const response = await axiosCall.get(`/publics/getAllSubscriberEmail`);
            return response?.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    const errorMessage = axiosError.response.data;
                    return errorMessage;
                } else {
                    console.error('Error calling backend API:', axiosError.message);
                    return Promise.reject(axiosError.message);
                }
            } else {
                console.error('Error calling backend API:', error);
                return Promise.reject(error);
            }
        }
    }
);