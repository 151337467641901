import React, { useState } from 'react';
import BlogOne from '../../../assets/images/blog-one.jpg';
import { USDateFormat, shortenString } from '../../../utils/common';
import { useNavigate } from 'react-router';
import { Skeleton } from '@mui/material';

type IProps = {
  _id: string;
  title: string;
  image: string;
  content: string[];
  bulletPoints: string[];
  points: { point: string; paragraph: string; _id: string }[];
  link: string;
  createdAt: string;
  __v: number;
};

function BlogCard(props: IProps) {
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);

  const { _id, title, image, content, bulletPoints, points, link, createdAt } =
    props;
  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-thumbnail">
          {imageLoading && (
            <Skeleton animation='wave' variant="rectangular" width={'100%'} height={250} />
          )}
          <img
            src={
              image === 'https://example.com/image.jpg' || image?.length === 0
                ? BlogOne
                : image
            }
            className="img-fluid cp"
            alt={title}
            onLoad={() => setImageLoading(false)}
            onClick={() => navigate('/blog-detail', { state: { b_id: _id } })}
            style={{ display: imageLoading ? 'none' : 'block' }}
          />
        </div>

        <div className="card-body">
          <span className="card-date">{USDateFormat(createdAt)}</span>
          <h5 className="card-title cp" onClick={() => navigate('/blog-detail', { state: { b_id: _id } })}>{title}</h5>
          <p className="card-text">{shortenString(content[0])}</p>
          <a
            onClick={() => navigate('/blog-detail', { state: { b_id: _id } })}
            className="cursor-pointer"
          >
            Learn More
            <svg
              width="20"
              height="13"
              fill="#1E1D85"
              viewBox="0 0 20 13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.9609 0.547852L12.4141 1.06738C12.2773 1.2041 12.2773 1.42285 12.4141 1.53223L16.6523 5.77051H0.328125C0.136719 5.77051 0 5.93457 0 6.09863V6.86426C0 7.05566 0.136719 7.19238 0.328125 7.19238H16.6523L12.4141 11.458C12.2773 11.5674 12.2773 11.7861 12.4141 11.9229L12.9609 12.4424C13.0703 12.5791 13.2891 12.5791 13.4258 12.4424L19.1406 6.72754C19.2773 6.59082 19.2773 6.39941 19.1406 6.2627L13.4258 0.547852C13.2891 0.411133 13.0703 0.411133 12.9609 0.547852Z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
