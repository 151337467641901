import React from 'react';
import { Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { PDFRender } from '../PDFRender';
import { PdfPreviewType } from '../../../constants/previewType';

interface IProps {
  url?: string;
  showMe: () => void;
  file?: any;
  previewType: PdfPreviewType;
}

const PdfPopup = (props: IProps) => {
  return (
    <>
      <Modal
        className="backdrop-blur-sm blue-theme modal-lg z-40 float-right ml-auto mr-0"
        show={true}
        size={'xl'}
      >
        <Modal.Header className="justify-between pb-0 bg-white border-none">
          <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
            Document View
          </Modal.Title>
          <div
            onClick={() => {
              props.showMe();
            }}
            className="icons8-close"
          >
            <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
            <PDFRender
              previewType={props.previewType}
              file={props.file}
              url={props.url}
            />
          </div>
        </Modal.Body>
      </Modal>{' '}
    </>
  );
};

export default PdfPopup;
