import { useEffect, useState } from 'react';
import './BuyPackage.scss';
import {
  getAllPackages,
  packageState,
} from '../../../redux/slices/PackageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { Link } from 'react-router-dom';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import ShowPaymentPageHOC from '../../../components/CommonComponents/PaymentPage/hoc/ShowPaymentPageHOC';
import PaymentPopup from '../../../components/CommonComponents/PaymentPage/PaymentPopup';

interface IPorps {
  submitPackage: () => void;
  showPaymentPopup: boolean;
  showPopUp: boolean;
  showPaymentHandler: () => void;
  setShowPopUp:any
}

const BuyPackage = (props: IPorps) => {
  const dispatch = useDispatch<AppDispatch>();
  const packages = useSelector(packageState);
  const [selected, setSelected] = useState<number>(0);
  const [selectedPkgId, setSelectedPkgId] = useState<string>('');
  const [termCondition, setTermCondition] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllPackages({}));
  }, []);

  useEffect(() => {
    if (packages.data?.packages?.length > 0) {
      setSelectedPkgId(packages.data?.packages[selected]._id);
    }
  }, [packages.data?.packages]);

  useEffect(()=>{
    if(props.showPopUp){
      props.showPaymentHandler();
      props.setShowPopUp(false);
    }
  },[props.showPopUp])

  return (
    <>
      {props.showPaymentPopup && (
        <PaymentPopup
          showMe={props.showPaymentHandler}
          pkgId={selectedPkgId}
        />
      )}
      <div className="payment">
        {/* <div className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                Approved listings are displayed<br></br>on both of our sites.
              </h1>
            </div>
            <div className="col-12">
              <div className="meta-tags">
                <a href="#">Trusted by 150,000+ Property Owners</a>
                <a href="#">Average Length of Stay is 92 days</a>
                <a href="#">Over 100 Million Searches a year by Travelers</a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <section className="subscription bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="row">
                  <div className="col-12 text-center mb-4">
                    <h5>
                      Complete the payment info below to list your property.
                    </h5>
                    <p>
                      Call us with your questions 7 days a week! +91 9876543210
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {packages.isLoading && <Loader />}
              {packages.data?.packages?.map((e: any, idx: number) => {
                const price =
                  e.currency === 'usd' ? `$${e.price}` : `${e.price}`;
                return (
                  <div key={e._id} className="col-md-4">
                    <div
                      className={`subscription-card ${
                        idx === selected ? 'selected' : ''
                      }`}
                    >
                      <h3 className="plan-price">{price}</h3>
                      <h4 className="plan-name">{e.name}</h4>
                      <ul>
                        <li>{e.items?.propertyListing} Property Listings</li>
                        <li>{e.items?.days} Days Availability</li>
                        {e.items?.featureProperty ? (
                          <li>Feature Properties</li>
                        ) : (
                          <li>
                            <s>Feature Properties</s>
                          </li>
                        )}
                        {e.items?.support ? (
                          <li>Limited Support</li>
                        ) : (
                          <li>
                            <s>Limited Support</s>
                          </li>
                        )}
                      </ul>
                      <div
                        onClick={() => {
                          setSelected(idx);
                          setSelectedPkgId(e._id);
                        }}
                        className="btn btn-outline-primary w-100"
                      >
                        {idx === selected ? 'Selected' : 'Choose'}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="payment-form bg-white mt-5">
            <div className="container">
              <div className="box-view py-4">
                <div className="row">
                  {/* <div className="col-12">
                      <h3 className="box-title">Pay using</h3>
                    </div> */}

                  <div className="col-12">
                    {/* <PaymentPage
                        pkgId={selectedPkgId}
                        handleSubmit={handleSubmit}
                      /> */}
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label>
                          <input
                            name="termCondition"
                            type="checkbox"
                            className="me-1"
                            checked={termCondition}
                            onChange={() => setTermCondition(!termCondition)}
                          />
                          I have read and accept the{' '}
                          <Link to="/terms-and-conditions">
                            Terms and Privacy Policy?
                          </Link>
                        </label>
                      </div>

                      <div className="col-md-5">
                        <button
                          onClick={props.submitPackage}
                          disabled={!termCondition}
                          type="submit"
                          className="btn btn-primary w-100"
                        >
                          Buy Subscription Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ShowPaymentPageHOC(BuyPackage);
