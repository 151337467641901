import React, { useEffect, useRef, useState } from 'react';
import './Listings.scss';
import CategoryComponent from '../../components/ListingsComponents/CategoryComponent/CategoryComponent';

import ListingCardComponent from '../../components/ListingsComponents/ListingCardComponent/ListingCardComponent';
import MapViewComponent from '../../components/ListingsComponents/MapViewComponent/MapViewComponent';
import DefaultViewComponent from '../../components/ListingsComponents/DefaultViewComponent/DefaultViewComponent';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import Loader from '../../components/CommonComponents/Loader/Loader';
import {
  IListingProperty,
  fetchListing,
  listingState,
  resetFetchListingState,
  resetListingState,
  resetListingToInitalState,
  setIsFavourite,
  setIsNotFavourite,
} from '../../redux/slices/ListingSlice';
import { toast } from 'react-toastify';
import {
  ToastType,
  showToastMessage,
  toastDefaults,
} from '../../utils/ToastOptionCommon';
import SignIn from '../Login/Signin';
import SignUp from '../SignUp/Signup';
import SignInPopupHOC from '../Login/hoc/SigninHoc';
import SignUpPopupHOC from '../SignUp/hoc/SignupHoc';
import {
  USDateFormat,
  getPaginationNumberText,
  getRoomsPaginationNumberText,
} from '../../utils/common';
import { IAmenities } from '../../components/PropertyDetail/PropertyDetail';
import NoDataFound from '../../assets/images/noDataFound.png';
import { IReview } from '../../redux/slices/PropertySlice';
import { AppDispatch } from '../../redux/store';
import axiosCall from '../../utils/axiosCall';
import { Amenity } from '../../components/AddListingsComponents/DetailedInfoComponent/DetailedInfoComponent';

export interface IProperty {
  section: number;
  p_price: number | null;
  status: string | null;
  noOfGarages: number;
  isFeaturedProperty: boolean;
  imagesLink: string[];
  totalFavorites: number;
  averageRating: number;
  _id: string;
  title: string;
  description: string;
  price: number;
  yearly_tax_rate: number;
  association_fee: number;
  after_price: number;
  p_type: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
    latitude: number;
    longitude: number;
    _id: string;
  };
  state: string;
  city: string;
  zip: string;
  size_in_ft: number;
  rooms: {
    name: string;
    price: number;
    bathroomType: string;
    amenities: IAmenities[];
    _id: string;
    depositMoney: number;
    cleaningCharges: number;
    petFees: number;
    availableFrom: string;
    imagesLink: string[];
  }[];
  bedrooms: string;
  bathroom: string;
  available_date: string[];
  amenities: IAmenities[];
  other: string;
  user_id: string;
  user_type: number;
  is_active: boolean;
  created_at: string;
  latitude: string;
  longitude: string;
  views: number;
  p_video_path: string;
  more_info: string;
  __v: number;
  nearby_facilities: [
    {
      name: string;
      description: string;
      distance: string;
      _id: string;
    }
  ];
  isFavoriteForThisUser: boolean;
  review: Array<IReview>;
  LandlordName: string;
  isLandLordVerified?: boolean;
  isApproved: boolean;
  documentsLink: string[];
  docsName?: string;
}

interface IProps {
  signInToggleHandler: () => void;
  showSignInPopup: boolean;
  signUpToggleHandler: () => void;
  showSignUpPopup: boolean;
}

const Listings: React.FC<IProps> = (props: IProps) => {
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  // const {searchText,minPrice,maxPrice,propertyType,minRoomPrice,maxRoomPrice,nearBy,moveIn,moveOut}= state;
  const [selectedListingView, setSelectedListingView] = useState<number>(1);
  // const [dummyPropertyData, setDummyPropertyData] = useState<property[]>([]);
  // useEffect(() => {
  //   const getProperties = async () => {
  //     try {
  //       const response = await callBackendAPI('GET', `properties/getAll?${state === null ? "" : `${state.searchText ? `searchText=${state.searchText}&` : ""}${state.Zip ? `Zip=${state.Zip}&` : ""}${state.minPrice ? `minPrice=${state.minPrice}&` : ""}${state.maxPrice ? `maxPrice=${state.maxPrice}&` : ""}${state.propertyType ? `propertyType=${state.propertyType}&` : ""}${state.minSizeInFt ? `minSizeInFt=${state.minSizeInFt}&` : ""}${state.isActive ? `isActive=${state.isActive}&` : ""}${state.isFeaturedProperty ? `isFeaturedProperty=${state.isFeaturedProperty}&` : ""}${state.minRoomPrice ? `minRoomPrice=${state.propertyType !== "Room" ? "" : state.minRoomPrice}&` : ""}${state.maxRoomPrice ? `maxRoomPrice=${state.propertyType !== "Room" ? "" : state.maxRoomPrice}&` : ""}${state.page ? `page=${state.page}&` : ""}${state.limit ? `limit=${state.limit}` : ""}`}`, null, null);
  //       if (response.error) {
  //         console.error('Fetch Properties Failed', response.error);
  //       } else {
  //         let mappedData: property[] = response.data.map((property: IProperty) => {
  //           return {
  //             id: property._id,
  //             title: property.title,
  //             price: property.p_price ?? 0,
  //             features: [
  //               'Fully Furnished',
  //               'Utilities Included',
  //               '3 bedroom',
  //               'Studio',
  //               'Pets',
  //             ],
  //             availabilityDate: property.available_date,
  //             isNew: false,
  //             isFeatured: true,
  //             fillImg: Listing1,
  //           }
  //         })
  //         setDummyPropertyData(mappedData)
  //         // console.log(dummyPropertyData)
  //         // window.alert("Properties Fetched successfully")
  //       }
  //     } catch (error) {
  //       console.error('Fetch failed:', error);
  //     }
  //   };
  //   getProperties();
  // }, [state]);

  const propertyData = useSelector((state: any) =>
    state.listing.listing?.data?.rooms
      .concat(state.listing.listing?.data?.house)
      .concat(state.listing.listing?.data?.condos)
      .concat(state.listing.listing?.data?.apartment)
      .concat(state.listing.listing?.data?.hotel)
  );
  const listing = useSelector(listingState);
  const isFetchListingsuccess = useSelector(
    (state: any) => state.listing.success
  );
  const isFetchListingFailed = useSelector((state: any) => state.listing.error);
  const [favId, setFavId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalListing, setTotalListing] = useState(0);
  const searchState = {
    searchText: state ? state.searchText ?? null : null,
    place: state ? state?.place ?? null : null,
    zip: null,
    propertyType: state ? state.propertyType ?? null : null,
    minSizeInFt: null,
    status: null,
    isActive: null,
    isFeaturedProperty: null,
    minRoomPrice: state ? state.minRoomPrice ?? null : null,
    maxRoomPrice: state ? state.maxRoomPrice ?? null : null,
    pageNo: currentPage,
    pageSize: pageLimit,
    nearBy: state ? state.nearBy ?? null : null,
    moveIn: state ? state.moveIn ?? null : null,
    moveOut: state ? state.moveOut ?? null : null,
    price: state ? state?.price ?? null : null,
    amenities: [],
  };
  const [searchData, setSearchData] = useState(searchState);
  const priceRangeList = [
    { Id: 1, Name: '< $1000', MaxValue: 1000, MinValue: 0 },
    { Id: 2, Name: '$1000- $2000', MaxValue: 2000, MinValue: 1000 },
    { Id: 3, Name: '$2000- $3000', MaxValue: 3000, MinValue: 2000 },
    { Id: 4, Name: '$3000- $4000', MaxValue: 4000, MinValue: 3000 },
    { Id: 5, Name: '$4000- $5000', MaxValue: 5000, MinValue: 4000 },
  ];

  const [amenities, setAmenities] = useState<Amenity[]>([]);
  const [amenitiesLoading, setAmenitiesLoading] = useState(false);

  useEffect(() => {
    getAllAmenities();
  }, []);

  const getAllAmenities = async () => {
    try {
      setAmenitiesLoading(true);
      const response = await axiosCall.get('/properties/getAmenities');
      if (response) {
        const data = response?.data?.map((e: any) => ({
          name: e.name,
          id: e._id,
        }));
        setAmenities(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAmenitiesLoading(false);
    }
  };

  const changePage = (event: any) => {
    const pageNumber = event.selected + 1;
    setCurrentPage(pageNumber);
  };

  // const isLoading = useSelector((state: any) => state.listing.loading);
  const {
    postFavPropertyError,
    postFavPropertySuccess,
    deleteFavPropertyError,
    deleteFavPropertySuccess,
    deleteFavPropertyLoading,
    postFavPropertyLoading,
    loading,
  } = useSelector((state: any) => state.listing);

  const fetchData = () => {
    let data: any = {
      searchText: searchData.searchText,
      place: searchData.place,
      propertyType: searchData.propertyType,
      minRoomPrice: searchData.minRoomPrice,
      maxRoomPrice: searchData.maxRoomPrice,
      nearBy: searchData.nearBy,
      moveIn: searchData.moveIn,
      moveOut: searchData.moveOut,
      amenities: searchData.amenities,
      pageNo: currentPage,
      pageSize: pageLimit,
    };
    if (currentPage === 1) {
      dispatch(resetListingToInitalState());
    }
    dispatch(fetchListing({ body: data }));
  };

  const searchSubmitHandler = (values: any) => {
    const price = priceRangeList.find((e) => e.Name === values.price);
    dispatch(resetListingToInitalState());
    setPageLimit(10);
    setCurrentPage(1);
    setSearchData({
      ...values,
      minRoomPrice: price?.MinValue,
      maxRoomPrice: price?.MaxValue,
    });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageLimit, searchData]);

  useEffect(() => {
    if (isFetchListingsuccess) {
      setTotalPages(listing?.totalPages);
      setPageLimit(listing?.pageSizes);
      setTotalListing(listing?.totalRooms);
      dispatch(resetFetchListingState());
    }
  }, [isFetchListingsuccess]);

  useEffect(() => {
    if (isFetchListingFailed) {
      showToastMessage('Failed to search properties.', ToastType.Error);
      dispatch(resetFetchListingState());
    }
  }, [isFetchListingFailed]);

  useEffect(() => {
    if (postFavPropertySuccess) {
      toast.success('Marked as Favorite', toastDefaults());
      dispatch(setIsFavourite({ id: favId }));
    }
    // dispatch(resetListingState(null));
    // fetchData();
  }, [postFavPropertySuccess]);

  useEffect(() => {
    if (deleteFavPropertySuccess) {
      toast.success('Unmarked from Favorite', toastDefaults());
      dispatch(setIsNotFavourite({ id: favId }));
    }
    // dispatch(resetListingState(null));
    // fetchData();
  }, [deleteFavPropertySuccess]);

  useEffect(() => {
    if (postFavPropertyError) {
      toast.error('Failed Faving Property', toastDefaults());
    }
    // dispatch(resetListingState(null))
  }, [postFavPropertyError]);

  useEffect(() => {
    if (deleteFavPropertyError) {
      toast.error('Failed Un-Faving Property', toastDefaults());
    }
    // dispatch(resetListingState(null))
  }, [deleteFavPropertyError]);

  const loadMoreHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {(deleteFavPropertyLoading || postFavPropertyLoading) && <Loader />}
      {props.showSignInPopup && (
        <SignIn
          modalClosedSignIn={props.signInToggleHandler}
          modalClosedSignUp={props.signUpToggleHandler}
        />
      )}

      {props.showSignUpPopup && (
        <SignUp
          modalClosedSignUp={props.signUpToggleHandler}
          modalClosedSignIn={props.signInToggleHandler}
        />
      )}
      <div className="container">
        <CategoryComponent
          selectedListingView={selectedListingView}
          setSelectedListingView={setSelectedListingView}
          text={getRoomsPaginationNumberText(
            currentPage,
            propertyData?.length,
            totalListing
          )}
          isShow={totalListing > 0}
          initialValues={searchData}
          submitHandler={searchSubmitHandler}
          amenities={amenities}
          amenitiesLoading={amenitiesLoading}
        />
        {selectedListingView === 1 ? (
          <DefaultViewComponent
            propertyData={
              propertyData
                ? propertyData?.map((property: any, index: number) => {
                    return {
                      id: index,
                      _id: property._id,
                      title: property.name,
                      price: property.price ?? 0,
                      // features: property.amenities ?? [
                      //   'Fully Furnished',
                      //   'Utilities Included',
                      //   '3 bedroom',
                      //   'Studio',
                      //   'Pets',
                      // ],
                      features: property.amenities,
                      availabilityDate: USDateFormat(property.availableFrom),
                      isNew: true,
                      isFeatured: property.isFeaturedProperty ?? false,
                      fillImg: property.imagesLink,
                      isFavourite: property.isFavoriteForThisUser,
                      propertyId: property.propertyId,
                      type: property.type,
                    };
                  })
                : []
            }
            signInToggleHandler={props.signInToggleHandler}
            markers={
              propertyData
                ? propertyData?.map((property: IListingProperty) => {
                    return {
                      lat: Number(property.address.latitude),
                      lng: Number(property.address.longitude),
                      propertyName: property.name,
                    };
                  })
                : []
            }
            favedId={favId}
            setFavedId={setFavId}
            loadMoreHandler={loadMoreHandler}
            totalListing={totalListing}
          />
        ) : selectedListingView === 2 ? (
          <div className="max-w-[1180px] mx-auto listings-wrapper grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {propertyData?.length === 0 ? (
              <div>
                <img
                  src={NoDataFound}
                  alt="NO DATA FOUND FOR YOUR SEARCH :("
                  width={500}
                />
                <p>NO DATA FOUND FOR YOUR SEARCH</p>
              </div>
            ) : (
              <div className="row">
                {propertyData
                  ? propertyData
                      ?.map((property: IListingProperty, index: number) => {
                        return {
                          id: index,
                          _id: property.address._id,
                          title: property.name,
                          price: property.price ?? 0,
                          features: property.amenities ?? [],
                          availabilityDate: property.availableFrom,
                          isNew: true,
                          isFeatured: property.isFeaturedProperty ?? false,
                          fillImg: property.imagesLink,
                          isFavourite: property.isFavoriteForThisUser,
                          propertyId: property.propertyId,
                          type: property.type,
                        };
                      })
                      .map((property: any) => (
                        <ListingCardComponent
                          key={property.id}
                          propertiesValues={property}
                          signInToggleHandler={props.signInToggleHandler}
                          favedId={favId}
                          setFavedId={setFavId}
                          selectedView={selectedListingView}
                        />
                      ))
                  : []}
              </div>
            )}
          </div>
        ) : (
          <MapViewComponent
            markers={
              propertyData
                ? propertyData?.map((property: IListingProperty) => {
                    return {
                      lat: Number(property.address.latitude),
                      lng: Number(property.address.longitude),
                      propertyName: property.name,
                    };
                  })
                : []
            }
          />
        )}
      </div>
    </>
  );
};

export default SignUpPopupHOC(SignInPopupHOC(Listings));
