import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const callBackendAPI = async (
  method: string,
  path: string,
  params: { [key: string]: string | number } | null = null,
  body: object | null = null
): Promise<any> => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    let url = `${apiUrl}/${path}`;
    if (params) {
      const queryParams = new URLSearchParams(params as any);
      url += `?${queryParams.toString()}`;
    }

    const requestOptions: AxiosRequestConfig = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      data: body,
    };

    const response = await axios(url, requestOptions);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data;
        return errorMessage;
      } else {
        console.error('Error calling backend API:', axiosError.message);
        return Promise.reject('Network Error');
      }
    } else {
      console.error('Error calling backend API:', error);
      return Promise.reject('Internal Server Error');
    }
  }
};

export const callAPI = async (
  method: string,
  path: string,
  params: { [key: string]: string | number } | null = null,
  body: object | null = null
): Promise<any> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  let url = `${apiUrl}/${path}`;
  if (params) {
    const queryParams = new URLSearchParams(params as any);
    url += `?${queryParams.toString()}`;
  }
  const requestOptions: AxiosRequestConfig = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    data: body,
  };
  const response = await axios(url, requestOptions);
  return response;
};

export default callBackendAPI;
