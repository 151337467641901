import React from 'react'
// import homeImg from '../../../assets/home/Banner-Image.jpg';
import homeImg from '../../../assets/images/Banner-full-image.png';
import './MainHome.scss'
import MidSearch from '../MidSearch/MidSearch';
import SignInPopupHOC from '../../../pages/Login/hoc/SigninHoc';
import SignUpPopupHOC from '../../../pages/SignUp/hoc/SignupHoc';
import SignIn from '../../../pages/Login/Signin';
import SignUp from '../../../pages/SignUp/Signup';

function MainHome(props) {
  const {
    signInToggleHandler,
    showSignInPopup,
    signUpToggleHandler,
    showSignUpPopup,
  } = props;

  return (
    <>{showSignInPopup && (
      <SignIn
        modalClosedSignIn={signInToggleHandler}
        modalClosedSignUp={signUpToggleHandler}
      />
    )}

      {showSignUpPopup && (
        <SignUp
          modalClosedSignUp={signUpToggleHandler}
          modalClosedSignIn={signInToggleHandler}
        />
      )}
      <div className="home-hero ">
        <div className="container">
          <div className="row">
            <div className='col-xl-8 offset-xl-2 animate__animated animate__fadeInLeft'>
              <h1>Find Your Perfect Furnished<br></br>Place With Ease.</h1>
              {/* <h6>Find new and featured property located in your local city.</h6> */}
            </div>
            <div className='col-12 animate__animated animate__fadeInRight'>
              <MidSearch signInToggleHandler={signInToggleHandler} />
            </div>
          </div>
        </div>
      </div></>
  )
}

export default SignUpPopupHOC(SignInPopupHOC(MainHome));