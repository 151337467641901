import React from 'react';

const DisableSection = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(0 0 0 / 5%)',
        zIndex: 88,
        borderRadius: '8px'
      }}
    />
  );
};

export default DisableSection;
