import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RiEqualizerLine } from 'react-icons/ri';
import { FaChevronDown } from 'react-icons/fa';
import { IoSearch } from 'react-icons/io5';
import './MidSearch.scss';
import { useNavigate } from 'react-router';
import SignInPopupHOC from '../../../pages/Login/hoc/SigninHoc';
import SignIn from '../../../pages/Login/Signin';
import CustomAutoComplete from '../../../utils/CustomAutoComplete';
import DatePicker from 'react-datepicker';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import {
  propertyTypeList,
  setSelectedUnit,
} from '../../../redux/slices/HomeSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { dateFormatForBE } from '../../../utils/common';
import { NearBy, NearByOptions } from '../../../constants/NearBy';

interface IProps {
  signInToggleHandler: () => void;
}
interface DropdownProps {
  isOpen: boolean;
  onItemClick: Dispatch<
    SetStateAction<{ Id: number; Name: string; Value: number }>
  >;
  items: Array<{ Id: number; Name: string; Value: number }>;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  return (
    <>
      {props.isOpen && (
        <div className="dropdown-content">
          {props?.items?.map((e) => (
            <p onClick={() => props.onItemClick(() => e)}>{e.Name}</p>
          ))}
        </div>
      )}
    </>
  );
};

function MyDropdown({ isOpen, toggleDropdown }: any) {
  return (
    <div className="dropdown">
      <div className="dropdown-btn" onClick={toggleDropdown}>
        {/* <FaChevronDown /> */}
      </div>
      {isOpen && <div className="dropdown-content"></div>}
    </div>
  );
}

const MidSearch: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signInToggleHandler } = props;
  // const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [locationSearch, setLocationSearch] = useState<string>('');
  const [placeDetails, setPlaceDetails] = useState<any>({});
  const [emptyStringError, setEmptyStringError] = useState<boolean>(false);
  const [priceRange, setPriceRange] = useState<{
    Id: number;
    Name: string;
    MaxValue: number | undefined;
    MinValue: number | undefined;
  }>({ Id: 1, Name: '< $1000', MaxValue: 1000, MinValue: 0 });
  // const [maxPriceRange, setMaxPriceRange] = useState<{ Id: number, Name: string, Value: number }>({ Id: 1, Name: "10,000", Value: 10000 });
  const [propertyType, setPropertyType] = useState<{
    Id: number;
    Name: string;
    Value: number;
  }>({ Id: 1, Name: 'Room', Value: 1 });
  const [nearByType, setnearByType] = useState(NearByOptions[0]);

  const [moveInDate, setmoveInDate] = useState(new Date());
  const [isMoveInOpen, setIsMoveInOpen] = useState(false);
  const [isMoveOutOpen, setIsMoveOutOpen] = useState(false);
  const [moveOutDate, setmoveOutDate] = useState(new Date());
  // const toggleAllDropdowns = () => {
  //     setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen)
  // };
  const nearByTypeList = [...NearByOptions];
  const priceRangeList = [
    { Id: 1, Name: '< $1000', MaxValue: 1000, MinValue: 0 },
    { Id: 2, Name: '$1000- $2000', MaxValue: 2000, MinValue: 1000 },
    { Id: 3, Name: '$2000- $3000', MaxValue: 3000, MinValue: 2000 },
    { Id: 4, Name: '$3000- $4000', MaxValue: 4000, MinValue: 3000 },
    { Id: 5, Name: '$4000- $5000', MaxValue: 5000, MinValue: 4000 },
    { Id: 6, Name: 'No Price', MaxValue: 99999999, MinValue: 0 },
  ];
  const selectedProperty = useSelector((state: any) => state.home.selectedUnit);

  const searchHandler = () => {
    if (localStorage.getItem('isLoggedin')) {
      if (locationSearch) {
        navigate(`/search-properties`, {
          state: {
            searchText: locationSearch,
            place: placeDetails,
            propertyType: selectedProperty.Name,
            minRoomPrice: priceRange.MinValue,
            maxRoomPrice: priceRange.MaxValue,
            nearBy: nearByType.value,
            moveIn: moment(moveInDate).format(dateFormatForBE),
            moveOut: moment(moveOutDate).format(dateFormatForBE),
            price: priceRange.Name,
          },
        });
      } else {
        setEmptyStringError(() => true);
        showToastMessage('Please enter a valid loaction', ToastType.Info);
      }
    } else {
      signInToggleHandler();
    }
  };

  const locationSearchHandler: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setLocationSearch(() => e.target.value);
  };

  const [areDropdownsOpen, setAreDropdownsOpen] = useState(false);

  const toggleAllDropdowns = () => {
    setAreDropdownsOpen(!areDropdownsOpen);
  };

  useEffect(() => {
    if (locationSearch) {
      setEmptyStringError(() => false);
    }
  }, [locationSearch, setEmptyStringError]);

  return (
    <>
      <div className="hero-home-search">
        <div className="row mx-0 w-100">
          <div className="col-sm-6 col-md-4 col-sm-6 mb-3">
            <CustomAutoComplete
              id={emptyStringError ? `search-input-error` : ''}
              value={locationSearch}
              setValue={setLocationSearch}
              setPlaceDetails={setPlaceDetails}
            />
          </div>

          <div className="col-sm-6 col-md-4 col-sm-6 mb-3">
            <label>I'm looking for...</label>
            <select
              className="form-select"
              value={selectedProperty.Id}
              onChange={(e) => {
                let selected = propertyTypeList
                  ?.filter((data) => Number(e.target.value) === data.Id)
                  ?.map((e) => e);
                dispatch(setSelectedUnit({ unit: selected[0] }));
              }}
            >
              {propertyTypeList?.map((e) => (
                <option key={e.Id} value={e.Id}>
                  {e.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6 col-md-4 col-sm-6 mb-3">
            <label>Price Range</label>
            <select
              className="form-select"
              onChange={(e) => {
                setPriceRange(() => {
                  let selected = priceRangeList
                    ?.filter((data) => Number(e.target.value) === data.Id)
                    ?.map((e) => e);
                  return selected[0];
                });
              }}
            >
              {priceRangeList?.map((e) => (
                <option key={e.Id} value={e.Id}>
                  {e.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-sm-6 col-md-4 col-sm-6 mb-3">
            <label>Move In</label>
            <DatePicker
              id="date-move-in"
              // open={isMoveInOpen}
              selected={moveInDate}
              onInputClick={() => {
                setIsMoveInOpen(() => true);
              }}
              minDate={new Date()}
              onChange={(date: Date) => {
                console.log(date);
                setmoveInDate(() => date);
              }}
              onClickOutside={() => {
                setIsMoveInOpen((isOpen) => !isOpen);
              }}
              onSelect={(date: Date) => {
                console.log(date);
                setmoveInDate(() => date);
                setIsMoveInOpen((isOpen) => !isOpen);
              }}
              dateFormat="MM/dd/yyyy"
              dropdownMode="select"
              placeholderText=""
              className="form-control"
            />
          </div>

          <div className="col-sm-6 col-md-4 col-sm-6 mb-3">
            <label>Move Out</label>
            <DatePicker
              id="date-move-out"
              open={isMoveOutOpen}
              selected={moveOutDate}
              onInputClick={() => {
                setIsMoveOutOpen(() => true);
              }}
              minDate={new Date()}
              onChange={(date: Date) => {
                setmoveOutDate(() => date);
              }}
              onClickOutside={() => {
                setIsMoveOutOpen((isOpen) => !isOpen);
              }}
              onSelect={(date: Date) => {
                setmoveOutDate(() => date);
                setIsMoveOutOpen((isOpen) => !isOpen);
              }}
              dateFormat="MM/dd/yyyy"
              dropdownMode="select"
              placeholderText=""
              className="form-control"
            />
          </div>

          <div className="col-sm-6 col-md-4 col-sm-6 mb-3">
            <label>Near By</label>
            <select
              className="form-select"
              onChange={(e) => {
                setnearByType((): any => {
                  //let selected = nearByTypeList.filter((data) => Number(e.target.value) === data.Id)?.map(e => e);
                  let selected = nearByTypeList.find(
                    (data) => e.target.value === data.value
                  );
                  return selected;
                });
              }}
            >
              {nearByTypeList?.map((e) => (
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12">
            <button
              className="btn btn-primary float-end"
              onClick={searchHandler}
            >
              {/* <IoSearch className="w-5 h-5" /> */}
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidSearch;
