import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Listings from '../pages/Listings/Listings';
import Dashboard from '../pages/Dashboard/Dashboard';
import AddListings from '../pages/Dashboard/AddListings/AddListings';
import ErrorPage from '../components/CommonComponents/ErrorPage/ErrorPage';
import DashboardListing from '../pages/Dashboard/DashboardListing/DashboardListing';
import PropertyDetail from '../components/PropertyDetail/PropertyDetail';
import Messageing from '../components/message/Messaging';
import Blog from '../pages/Blog/Blog';
import EditListing from '../pages/Dashboard/EditListing/EditListing';
import Reviews from '../pages/Dashboard/Reviews/Reviews';
import SavedSearch from '../pages/Dashboard/SavedSearch/SavedSearch';
import CreateBlog from '../pages/Dashboard/CreateBlog/CreateBlog';
import BlogList from '../pages/Dashboard/BlogList/BlogList';
import BlogDetail from '../pages/BlogDetail/BlogDetail';
import Settings from '../pages/Dashboard/Settings/Settings';
import { ProtectedRoute } from '../Routes/ProtectedRoute';
import { TenantResource } from '../pages/Resources/Tenant/TenantResource';
import { LandlordResource } from '../pages/Resources/Landlord/LandlordResource';
import { AboutUs } from '../pages/AboutUs/AboutUs';
import { Services } from '../pages/Services/Services';
import { HousingRequest } from '../pages/HousingRequest/HousingRequest';
import Subscription from '../pages/Dashboard/Subscription/Subscription';
import { TermsAndConditions } from '../pages/FooterPages/TermsAndConditions/TermsAndConditions';
import { PrivacyPolicy } from '../pages/FooterPages/PrivacyPolicy/PrivacyPolicy';
import EditBlog from '../pages/Dashboard/EditBlog/EditBlog';
import AddCalender from '../components/CalenderComponents/DashboardCalenderComponent/AddCalender';
import { Finance } from '../pages/Dashboard/Finance/Finance';
import FavouriteProperties from '../pages/Dashboard/FavouriteProperties/FavouriteProperties';
import { CEUs } from '../pages/Dashboard/Documents/CEUs/CEUs';
import { Certificates } from '../pages/Dashboard/Documents/Certificates/Certificates';
import { Licenses } from '../pages/Dashboard/Documents/Licenses/Licenses';
import AddLicense from '../pages/Dashboard/Documents/Licenses/AddLicense/AddLicense';
import PaymentSuccess from '../components/CommonComponents/PaymentPage/PaymentSuccess';
import PaymentFailed from '../components/CommonComponents/PaymentPage/PaymentFailed';
import LandlordComponents from '../pages/Dashboard/Users/LandlordComponents/LandlordComponents';
import TenantsComponents from '../pages/Dashboard/Users/TenantsComponents/TenantsComponents';
import { ListProperty } from '../pages/ListProperty/ListProperty';
import AddCertificate from '../pages/Dashboard/Documents/Certificates/AddCertificate/AddCertificate';
import ReturnPage from '../components/CommonComponents/PaymentPage/ReturnPage';
import AddCEUs from '../pages/Dashboard/Documents/CEUs/AddCEUs/AddCEUs';
import { RefundPolicy } from '../pages/FooterPages/RefundPolicy/RefundPolicy';
import { Disclaimer } from '../pages/FooterPages/Disclaimer/Disclaimer';
import { ContactUs } from '../pages/ContactUs/ContactUs';
import HousingRequestView from '../pages/HousingRequest/HousingRequestView';
import ShowHousingDetail from '../pages/HousingRequest/ShowHousingDetail';
import EmailVerify from '../components/DashboardComponents/DashboardSettingComponent/EmailVerify';
import SubscriberEmail from '../pages/Dashboard/SubscriberEmail/SubscriberEmail';
import Policy from '../pages/Dashboard/Policy/Policy';
import ViewLicense from '../pages/Dashboard/Documents/Licenses/ViewLicense/ViewLicense';
import ViewCertificate from '../pages/Dashboard/Documents/Certificates/ViewCertificate/ViewCertificate';
import ViewCEUs from '../pages/Dashboard/Documents/CEUs/ViewCEUs/ViewCEUs';
import { useEffect, useState } from 'react';
import { loggedInUserState, setUserData } from '../redux/slices/LoginSlice';
import { setUser } from '../redux/slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSocket } from '../context/SocketProvider';
import RouteLayout from './RouteLayout';

const AppRoute = () => {
  const [isDashboard, setIsDashboard] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const socket = useSocket();
  const loggedInUser = useSelector(loggedInUserState);

  useEffect(() => {
    const localUser: any = localStorage.getItem('user');
    const token = localStorage.getItem('accessToken');
    if (localUser && token) {
      socket.emit('add-user', localUser._id);
      dispatch(setUserData(JSON.parse(localUser)));
      dispatch(setUser(JSON.parse(localUser)));
    }
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      socket.emit('add-user', loggedInUser?._id);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (location.pathname.includes('/dashboard')) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<RouteLayout isDashboard={isDashboard} />}>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/housing-inquiry" element={<HousingRequest />} />
        <Route path="/search-properties" element={<Listings />} />
        <Route
          path="/list-property/:propertyListTab"
          element={<ListProperty />}
        />
        <Route
          path="/property-detail/:currentPropertyId"
          element={<PropertyDetail />}
        />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail" element={<BlogDetail />} />
        <Route path="/resources/tenant" element={<TenantResource />} />
        <Route path="/resources/landlord" element={<LandlordResource />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* Footer routes */}
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />

        {/* Dashboard Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute permission="all.user">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/dashboard/listing" element={<DashboardListing />} />
        <Route
          path="/dashboard/add-listing"
          element={
            <ProtectedRoute permission="admin.landlord">
              <AddListings />
            </ProtectedRoute>
          }
        />
        <Route path="/dashboard/edit-listing/:id" element={<EditListing />} />
        <Route path="/dashboard/message" element={<Messageing />} />
        <Route path="/dashboard/reviews" element={<Reviews />} />
        <Route path="/dashboard/saved-search" element={<SavedSearch />} />
        <Route path="/dashboard/create-blog" element={<CreateBlog />} />
        <Route path="/dashboard/edit-blog" element={<EditBlog />} />
        <Route path="/dashboard/blog-list" element={<BlogList />} />
        <Route path="/dashboard/licenses" element={<Licenses />} />
        <Route path="/dashboard/fill-license" element={<AddLicense />} />
        <Route path="/dashboard/view-license" element={<ViewLicense />} />
        <Route path="/dashboard/certificates" element={<Certificates />} />
        <Route
          path="/dashboard/view-certificate"
          element={<ViewCertificate />}
        />
        <Route
          path="/dashboard/fill-certificate"
          element={<AddCertificate />}
        />
        <Route path="/dashboard/CEUs" element={<CEUs />} />
        <Route path="/dashboard/fill-CEUs" element={<AddCEUs />} />
        <Route path="/dashboard/view-CEUs" element={<ViewCEUs />} />
        <Route path="/dashboard/finance" element={<Finance />} />
        <Route path="/dashboard/Package" element={<Subscription />} />
        <Route path="/dashboard/request" element={<HousingRequestView />} />
        <Route path="/dashboard/request/:id" element={<ShowHousingDetail />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/payment/failed" element={<PaymentFailed />} />
        <Route path="/verify-email/:emailId" element={<EmailVerify />} />
        <Route path="/payment/process/:sessionId" element={<ReturnPage />} />
        <Route path="/dashboard/landlords" element={<LandlordComponents />} />
        <Route path="/dashboard/tenants" element={<TenantsComponents />} />
        <Route
          path="/dashboard/calender"
          element={
            <ProtectedRoute permission="landlord">
              <AddCalender />
            </ProtectedRoute>
          }
        />
        <Route path="/dashboard/setting" element={<Settings />} />
        <Route path="/dashboard/favorite" element={<FavouriteProperties />} />
        <Route
          path="/dashboard/subscriber-email"
          element={
            <ProtectedRoute permission="admin">
              <SubscriberEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/policy"
          element={
            <ProtectedRoute permission="admin">
              <Policy />
            </ProtectedRoute>
          }
        />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoute;
