import React, { useEffect, useState } from 'react';
import './EditBlog.scss';
import { IoMdArrowDropright } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosCall from '../../../utils/axiosCall';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import DropZone from '../../../components/CommonComponents/DropZone/DropZone';
import { stringSentenceCase } from '../../../utils/common';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';

interface IProps {}

function EditBlog(props: IProps) {
  const location = useLocation();
  const blogOriginalVersion = location?.state?.blog;
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  // console.log(blogOriginalVersion.image);

  const initilaState = {
    title: blogOriginalVersion?.title,
    image: '',
    status: blogOriginalVersion?.status,
    link: blogOriginalVersion?.link,
  };

  const [inputBlog, setInputBlog] = useState(initilaState);
  const [content, setContent] = useState<string>(
    blogOriginalVersion?.content[0]
  );
  const userData = useSelector((state: any) => state.login.userData);
  const navigate = useNavigate();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputBlog((prevSate: any) => ({
      ...prevSate,
      [e.target.name]: e.target.value,
    }));
  };

  // react quil configs
  const LIST_OF_COLOURS = [
    '#0290D7',
    '#4D4D4D',
    '#999999',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#FFFFFF',
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        {
          color: [...LIST_OF_COLOURS],
        },
        {
          background: [...LIST_OF_COLOURS],
        },
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  useEffect(() => {
    getRawImages();
  }, []);

  const getRawImages = async () => {
    const imgUrl = blogOriginalVersion.image;
    const imgObjects: any = [];
    setImgLoading(true);

    const filename = extractFilenameFromUrl(imgUrl);
    const img = await fetchAndConvertImage(imgUrl, filename);
    if (img) {
      imgObjects.push(img);
    }
    // console.log(imgObjects);
    setImgLoading(() => false);
    setFiles(() => imgObjects);
  };

  function extractFilenameFromUrl(url: string): string {
    // Split the URL by '/'
    const parts = url?.split('/');
    // Get the last part of the path which should contain the filename
    const filenameWithParams = parts[parts.length - 1];
    // Extract the filename by removing any query parameters
    const filename = filenameWithParams?.split('?')[0];
    return filename;
  }
  async function fetchAndConvertToBlob(url: string): Promise<any> {
    try {
      const response = await fetch(url);
      // console.log({response});
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.log(error);
    }
    return;
  }

  function blobToFile(blob: Blob, fileName: string): File {
    // Create a new File object from the Blob
    return new File([blob], fileName, { type: blob?.type });
  }

  async function fetchAndConvertImage(img: string, fileName: string) {
    try {
      const blob = await fetchAndConvertToBlob(img);
      const file = blobToFile(blob, fileName);
      return file;
    } catch (error) {
      console.error('Error fetching or converting image:', error);
      throw error;
    }
  }

  const handlePublish = async () => {
    // check if we habe title & content in state
    if (
      inputBlog.title.trim().length > 0 &&
      content.trim().length > 0 &&
      files.length > 0
    ) {
      setLoading(true);
      const formData = new FormData();
      formData.append('title', inputBlog.title);
      formData.append('image', files?.[0]);
      formData.append('status', inputBlog.status.toLowerCase());
      formData.append('content', content);
      formData.append('user_id', userData._id);
      formData.append('link', 'https://example.com/blog/1');

      try {
        const url = `/blog/update/${blogOriginalVersion?._id}`;
        const response = await axiosCall.put(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          showToastMessage('Blog Updated successfully', ToastType.Success);
          // console.log('Blog Updated');
          setInputBlog(initilaState);
          setContent('');
          navigate('/dashboard/blog-list');
        }
      } catch (error) {
        console.log(error);
        showToastMessage('Blog Updation failed', ToastType.Error);
      } finally {
        setLoading(false);
      }
    } else {
      // show error toast
      showToastMessage('Please provide required details', ToastType.Error);
    }
  };

  return (
    <div className="main-content" style={{ height: '100vh' }}>
      <DashboardHeader />
      <div className="p-4 w-100">
        <div className="below-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h1>Edit Blog</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Blog
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        {loading && <Loader />}
        <div className="create-blog border rounded-3 p-4 mb-2 mt-4 bg-white">
          <form>
            <div className="row">
              <div className="col-md-8">
                <div className="select-post-status mb-2">
                  <div className="status-lebel mb-3">Status</div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="Draft"
                      value="draft"
                      checked={inputBlog.status === 'draft'}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="Draft">
                      Draft
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="Publish"
                      value="publish"
                      checked={inputBlog.status === 'publish'}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="Publish">
                      Publish
                    </label>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="Title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Title"
                    placeholder=""
                    value={inputBlog.title}
                    name="title"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="BlogSummary" className="form-label">
                    Blog Summary
                  </label>
                  <ReactQuill
                    className=""
                    id="BlogSummary"
                    theme="snow"
                    modules={modules}
                    preserveWhitespace={true}
                    readOnly={false}
                    value={content}
                    onChange={setContent}
                  />
                </div>
                {/* {content} */}
              </div>
              {imgLoading ? (
                <Loader />
              ) : (
                <div className="col-md-4">
                  <DropZone files={files} setFiles={setFiles} className="" />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handlePublish}
                  disabled={loading}
                >
                  {stringSentenceCase(inputBlog.status)}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBlog;
