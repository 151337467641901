import React, { useEffect, useState } from 'react';
import CustomCalendar from '../Calender';
import AddCalenderForm, { IEvent } from './AddCalenderForm';
import AddCalenderPopupHOC from './hoc/AddCalenderHoc';
import { Button } from 'react-bootstrap';
import { UnknownAction } from '@reduxjs/toolkit';
import {
  fetchPropertySummaryByUserId,
  resetfetchPropertySummaryState,
} from '../../../redux/slices/PropertySlice';
import { User } from '../../../pages/Dashboard/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import {
  addCalenderEvent,
  deleteCalenderEvent,
  fetchCalenderEvent,
  resetAddCalenderState,
  resetDeleteCalenderState,
  resetFetchCalenderState,
} from '../../../redux/slices/CalenderSlice';
import Loader from '../../CommonComponents/Loader/Loader';
import DashboardHeader from '../../DashboardComponents/DashboardHeader/DashboardHeader';
import ConfirmationPopup from '../../../utils/ConfirmationPopup/ConfirmationPopup';
import ConfirmationPopupHOC from '../../../utils/ConfirmationPopup/hoc/ConfirmationPopupHoc';
import moment from 'moment';

function AddCalender(props: any) {
  const [user, setUser] = useState<User | undefined>(undefined);
  const dispatch = useDispatch();

  const [events, setEvents] = useState<any>([]);
  const [eventIdDelete, setEventIdDelete] = useState<any>('');
  const [event, setEvent] = useState<any>({
    title: '',
    // allDay: boolean,
    start: '',
    end: '',
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null) {
      setUser(JSON.parse(storedUser));
      dispatch(
        fetchPropertySummaryByUserId({
          Id: JSON.parse(storedUser)._id,
        }) as unknown as UnknownAction
      );
    }
  }, []);

  const {
    fetchPropertySummaryLoading,
    fetchPropertySummarySuccess,
    fetchPropertySummaryError,
    propertySummary,
  } = useSelector((state: any) => state.property);
  const { loading, success, error, calenderEvents } = useSelector(
    (state: any) => state.calender
  );

  console.log(fetchPropertySummaryLoading);

  useEffect(() => {
    if (error) {
      showToastMessage(
        'Error Ocurred While Fetching Calender',
        ToastType.Error
      );
    }
    dispatch(resetFetchCalenderState(null));
  }, [error]);

  useEffect(() => {
    setEvents(
      Array.isArray(calenderEvents)
        ? calenderEvents?.map((e: any) => {
            return {
              id: e._id,
              title: e.title,
              start: new Date(e.start),
              end: new Date(e.end),
            };
          })
        : []
    );
  }, [calenderEvents]);

  const {
    addCalenderLoading,
    addCalenderSuccess,
    addCalenderError,
    deleteCalenderError,
    deleteCalenderSuccess,
    deleteCalenderLoading,
  } = useSelector((state: any) => state.calender);

  const propertyList = [
    { Id: '0', Name: 'Select A Property', isEntireUnit: true, rooms: [] },
  ].concat(propertySummary) as Array<{
    Id: string;
    Name: string;
    isEntireUnit: boolean;
    rooms: Array<{ Id: string; Name: string }>;
  }>;

  const [isPropertySelected, setIsPropertySelected] = useState<boolean>(false);
  const [SelectedProperty, setSelectedProperty] = useState<string>('0');
  const [isRoomSelected, setIsRoomSelected] = useState<boolean>(false);
  const [SelectedRoom, setSelectedRoom] = useState<string>('0');
  const [roomList, setRoomList] = useState<Array<{ Id: string; Name: string }>>(
    []
  );
  const [isSelectedPropertyEntire, setIsSelectedPropertyEntire] =
    useState<boolean>(true);

  useEffect(() => {
    if (fetchPropertySummaryError) {
      showToastMessage('Property Fetching Failed', ToastType.Error);
    }
    dispatch(resetfetchPropertySummaryState(null));
  }, [fetchPropertySummaryError]);

  useEffect(() => {
    if (isSelectedPropertyEntire) {
      setSelectedRoom(() => {
        let selected = propertyList
          .filter((e) => e.Id === SelectedProperty)
          .map((e) => e.rooms);
        let selectedRoomId = selected[0].map((e) => e.Id);
        return selectedRoomId[0];
      });
      setIsRoomSelected(true);
    }
  }, [
    SelectedProperty,
    isSelectedPropertyEntire,
    propertyList,
    setSelectedRoom,
  ]);

  useEffect(() => {
    if (Number(SelectedProperty) !== 0 && isSelectedPropertyEntire) {
      if (Number(SelectedRoom) !== 0) {
        dispatch(
          fetchCalenderEvent({
            body: { PropertyId: SelectedRoom },
          }) as unknown as UnknownAction
        );
      }
    }
    if (Number(SelectedProperty) !== 0 && !isSelectedPropertyEntire) {
      if (Number(SelectedRoom) !== 0 && SelectedRoom) {
        debugger;
        dispatch(
          fetchCalenderEvent({
            body: { PropertyId: SelectedRoom },
          }) as unknown as UnknownAction
        );
      }
    }
  }, [SelectedProperty, isSelectedPropertyEntire, SelectedRoom]);

  useEffect(() => {
    if (!isSelectedPropertyEntire) {
      setRoomList(() => {
        let selectedProperty = propertyList
          ?.filter((e) => SelectedProperty === e.Id)
          .map((e) => e.rooms);
        console.log(selectedProperty);
        return selectedProperty[0]?.map((e) => e);
      });
    }
    if (isSelectedPropertyEntire) {
    }
  }, [isSelectedPropertyEntire, SelectedProperty]);

  useEffect(() => {
    if (addCalenderSuccess) {
      showToastMessage('Event Added To Calender', ToastType.Success);
      props.addCalenderToggleHandler();
      //for now added in useEffect will shift it to useCallback.
      if (Number(SelectedRoom) !== 0) {
        dispatch(
          fetchCalenderEvent({
            body: { PropertyId: SelectedRoom },
          }) as unknown as UnknownAction
        );
      }
    }
    dispatch(resetAddCalenderState(null));
  }, [addCalenderSuccess]);

  useEffect(() => {
    if (addCalenderError) {
      showToastMessage('Error Ocurred While Adding Event', ToastType.Error);
      // props.addCalenderToggleHandler();
    }
    dispatch(resetAddCalenderState(null));
  }, [addCalenderError]);

  useEffect(() => {
    if (deleteCalenderSuccess) {
      showToastMessage('Event Deleted From Calender', ToastType.Success);
      //for now deleted in useEffect will shift it to useCallback.
      if (Number(SelectedRoom) !== 0) {
        dispatch(
          fetchCalenderEvent({
            body: { PropertyId: SelectedRoom },
          }) as unknown as UnknownAction
        );
        props.confirmationToggleHandler();
        props.addCalenderToggleHandler();
        dispatch(resetDeleteCalenderState(null));
      }
    }
  }, [deleteCalenderSuccess]);

  useEffect(() => {
    if (deleteCalenderError) {
      showToastMessage('Error Ocurred While Deleting Event', ToastType.Error);
      // props.addCalenderToggleHandler();
      props.confirmationToggleHandler();
      dispatch(resetDeleteCalenderState(null));
    }
  }, [deleteCalenderError]);

  const handleSubmit = (values: any) => {
    debugger;
    if (values.end && values.start) {
      let tempStartDate = '';
      let tempEndDate = '';
      if (values.id) {
        if (values.start) {
          tempStartDate = values.start;
        } else {
          tempStartDate =
            moment(values.start.$d).format('YYYY-MM-DD') + ' 00:00:00';
        }
        if (values.end) {
          tempEndDate = values.end;
        } else {
          tempEndDate =
            moment(values.end.$d).format('YYYY-MM-DD') + ' 23:59:59';
        }
      } else {
        tempStartDate =
          moment(values.start.$d).format('YYYY-MM-DD') + ' 00:00:00';
        tempEndDate = moment(values.end.$d).format('YYYY-MM-DD') + ' 23:59:59';
      }
      let newEvent = {
        title: values.title,
        id: values.id,
        // 'start': new Date(values.start.$d.getFullYear(), values.start.$d.getMonth(), values.start.$d.getDay(), values.start.$d.getHours(),values.start.$d.getMinutes() , values.start.$d.getSeconds()),
        // 'end': new Date(values.end.$d.getFullYear(), values.end.$d.getMonth(), values.end.$d.getDay(), values.end.$d.getHours(),values.end.$d.getMinutes() , values.end.$d.getSeconds()),
        start: tempStartDate,
        end: tempEndDate,
        user_id: user?._id,
        room_id: SelectedRoom,
      };
      dispatch(
        addCalenderEvent({
          body: {
            event: newEvent,
          },
        }) as unknown as UnknownAction
      );
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteCalenderEvent({
        body: {
          id: eventIdDelete,
        },
      }) as unknown as UnknownAction
    );
  };

  const deleteHandler = (id: string) => {
    props.confirmationToggleHandler();
    setEventIdDelete(id);
  };

  return (
    <>
      {props.showAddCalenderPopup && (
        <AddCalenderForm
          submitHandler={handleSubmit}
          initialValues={event}
          isLoading={addCalenderLoading}
          onModalClosed={props.addCalenderToggleHandler}
          deleteHandler={deleteHandler}
        />
      )}

      {props.showConfirmationPopup && (
        <ConfirmationPopup
          onModalClose={props.confirmationToggleHandler}
          onSubmit={handleDelete}
          confirmationText={'Are you sure you want to delete the Event'}
          saveButtonText={'Yes'}
          cancelButtonText={'No'}
          modalTitle={'Delete Event'}
          isProgress={deleteCalenderLoading}
        />
      )}

      <div className="main-content">
        <DashboardHeader />
        {fetchPropertySummaryLoading ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex gap-3 my-3" style={{ maxWidth: '500px' }}>
              <select
                className="form-select"
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedRoom('0');
                  setIsPropertySelected((prevoiusState) => {
                    if (Number(e.target.value) === 0) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                  setSelectedProperty((prevoiusState) => {
                    return e.target.value;
                  });

                  setIsSelectedPropertyEntire((prevoiusState) => {
                    let selectedProperty = propertyList
                      ?.filter((property) => property.Id === e.target.value)
                      ?.map((i) => i.isEntireUnit);
                    if (selectedProperty?.includes(true)) {
                      console.log(selectedProperty);
                      return true;
                    } else {
                      console.log(selectedProperty);
                      return false;
                    }
                  });
                }}
              >
                {propertyList?.map((e) => (
                  <option value={e.Id}>{e.Name}</option>
                ))}
              </select>
              {!isSelectedPropertyEntire && (
                <select
                  className="form-select"
                  value={SelectedRoom}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setIsRoomSelected((prevoiusState) => {
                      if (Number(e.target.value) === 0) {
                        return false;
                      } else {
                        return true;
                      }
                    });
                    setSelectedRoom((prevoiusState) => {
                      return e.target.value;
                    });
                  }}
                >
                  {[
                    {
                      Id: '0',
                      Name: 'Select A Room',
                    },
                  ]
                    .concat(roomList)
                    .map((e) => {
                      console.log(e);
                      return <option value={e.Id}>{e.Name}</option>;
                    })}
                </select>
              )}
              <Button
                onClick={() => {
                  props.addCalenderToggleHandler();
                  setEvent({ id: '', title: '', start: '', end: '' });
                }}
                disabled={
                  !(
                    Number(SelectedProperty) !== 0 && Number(SelectedRoom) !== 0
                  )
                }
                className="py-2"
                style={{ minWidth: '120px' }}
              >
                Add Event
              </Button>
            </div>
            {Number(SelectedProperty) !== 0 && Number(SelectedRoom) !== 0 ? (
              <div className="row">
                <div className="">
                  {loading && <Loader />}
                  <CustomCalendar
                    events={events}
                    onDoubleClickEvent={props.addCalenderToggleHandler}
                    setEvent={setEvent}
                  />
                </div>
              </div>
            ) : (
              <div>Please Select A Property</div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ConfirmationPopupHOC(AddCalenderPopupHOC(AddCalender));
