import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import LoaderGif from '../../../assets/gif/loader.gif';
import './Loader.scss';

function Loader({ isSmallLoader = false }) {
  return (
    <div
      className={`${isSmallLoader ? 'small-loader': 'large-loader'}`}
    >
      {isSmallLoader ? (
        <div
          className="loaderContainer"
          style={{
            position: 'absolute',
            top: '0',
            height: '100%',
          }}
        >
          <div
            className="spinner-loader"
            style={{ left: '50%', top: '40%', position: 'absolute' }}
          >
            {/* <img src={LoaderGif} alt="spinner-loader" style={{ width: "70px", height: '70px' }} /> */}
            <div className="loader-2 center">
              <span></span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="loaderContainer"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: '1000',
            cursor: 'default',
          }}
        >
          <div
            className="spinner-loader"
            style={{ left: '50%', top: '40%', position: 'absolute' }}
          >
            {/* <img src={LoaderGif} alt="spinner-loader" style={{ width: "70px", height: '70px' }} /> */}
            <div className="loader-2 center">
              <span></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Loader;
