import React, { useState } from 'react';

const SignUpPopupHOC = (Component: any) => 
function Comp(props: any){
    const [showSignUpPopup,setShowSignUpPopup] = useState<boolean>(false);

    const signUpToggleHandler = () => {
        setShowSignUpPopup(!showSignUpPopup);
    }
	
	return <Component showSignUpPopup={showSignUpPopup} signUpToggleHandler={signUpToggleHandler} {...props} />;
};

export default SignUpPopupHOC;