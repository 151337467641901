import React, { useRef, useState } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { FieldProps, useField } from 'formik';
import { Library } from '../constants/GoogleLibrary';
import { getPlaceDetails } from './Maps/MapHelper';

interface PlaceFieldProps extends FieldProps {
  description: string;
}
interface IProps {
  description: string;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  maxLength: number;
  shouldnotshowerror: boolean;
  isnegativeallowed: boolean;
  isnegativeanddotallowed: boolean;
  min: number;
  max: number;
  defaultvaluecondition: boolean;
  initialvalue: number;
  show_error_on_tooltip?: boolean;
  type: string;
  autoComplete: string;
  setFieldValue: any;
}

const FormikFieldAutoComplete: React.FC<IProps> = ({
  description,
  ...props
}: IProps) => {
  const [field, meta, helpers] = useField(props);
  const inputRef = useRef<any>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    libraries: Library,
    region: 'us',
  });

  const options = {
    //bounds: defaultBounds,
    // types: ['(regions)'],
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'formatted_address', 'geometry'],
    strictBounds: false,
  };

  const handlePlaceChanged = () => {
    if (inputRef.current) {
      const place = inputRef.current.getPlace();
      if (place) {
        const { state, city, zipCode, formattedAddress, lattitude, longitude } =
          getPlaceDetails(place);
        props.setFieldValue('location.zip', zipCode);
        props.setFieldValue('location.state', state);
        props.setFieldValue('location.city', city);
        helpers.setValue(formattedAddress);
        props.setFieldValue('location.latitude', lattitude);
        props.setFieldValue('location.longitude', longitude);
      }
    }
  };

  return (
    <div>
      <label htmlFor={props.name}>{description}</label>
      {isLoaded && !loadError && (
        // <StandaloneSearchBox
        //   onLoad={(ref) => (inputRef.current = ref)}
        //   onPlacesChanged={handlePlaceChanged}
        // >
        //   <input
        //     {...field}
        //     type="text"
        //     id={props.name}
        //     name={props.name}
        //     //value={props.value}
        //     className="form-control"
        //     placeholder=""
        //     // onFocus={() => { helpers.setValue(""); if (field.value) { document.getElementById(props.name)!.blur(); document.getElementById(props.name)!.focus(); } }}
        //   />
        // </StandaloneSearchBox>
        <Autocomplete
          options={options}
          onLoad={(ref) => (inputRef.current = ref)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            {...field}
            type="text"
            id={props.name}
            name={props.name}
            //value={props.value}
            className="form-control"
            placeholder=""
            // onFocus={() => { helpers.setValue(""); if (field.value) { document.getElementById(props.name)!.blur(); document.getElementById(props.name)!.focus(); } }}
          />
        </Autocomplete>
      )}
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};
export default FormikFieldAutoComplete;
