import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import {
  ToastType,
  showToastMessage,
} from '../../../../utils/ToastOptionCommon';
import { resetPaymentData } from '../../../../redux/slices/PaymentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';

const stripePromise = loadStripe(
  'pk_test_51PAUGH2NMM2A3XeZQb8AF85VOf0jwz0kVQWT22PSm1CTNg9SQkRPMXmSV4vtAjJWUd9Y2eBZN42jva30FFQNsaOh00STGmexEw'
);

const StripePopup = () => {
  const payment = useSelector((state: any) => state.payment);
  const dispatch = useDispatch<AppDispatch>();
  const [stripeOptions, setStripeOptions] = useState({ clientSecret: null });
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (payment.error) {
      showToastMessage('Some error occured', ToastType.Error);
      dispatch(resetPaymentData());
    }
  }, [payment.error]);

  useEffect(() => {
    if (payment.sessionSuccess) {
      setShow(true);
      setStripeOptions({ clientSecret: payment.sessionData.clientCode });
      dispatch(resetPaymentData());
    }
  }, [payment.sessionSuccess]);

  return (
    <>
      {show && (
        <Modal
          className="px-10 backdrop-blur-sm blue-theme"
          show={true}
          centered
        >
          <Modal.Header className="justify-between pb-0 bg-white border-none">
            <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
              Stripe Payment
            </Modal.Title>
            <div
              onClick={() => {
                setShow(false);
              }}
              className="icons8-close"
            >
              <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
            </div>
          </Modal.Header>
          <Modal.Body className="">
            <div className="flex min-h-full flex-1 flex-col justify-center rounded h-auto">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={stripeOptions}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default StripePopup;
