import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { LuImagePlus } from 'react-icons/lu';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import { decodeEncoded, formatBytes } from '../../../utils/common';
import './DropZone.scss';
import { BiSolidTrash } from 'react-icons/bi';
import { ErrorMessage } from 'formik';
import { ListingSection } from '../../../constants/ListingSection';

interface IProps {
  className: string;
  files: any;
  setFiles: any;
  page?: string;
  numberOfFiles?: number;
  maxFileSizeInBytes?: number;
  isSubmit?: boolean;
  section?: ListingSection
}

function DropZone(props: IProps) {
  const {
    className,
    files,
    setFiles,
    numberOfFiles,
    maxFileSizeInBytes,
    isSubmit,
    section
  } = props;
  const maxFileLen = numberOfFiles || 1;
  const [showError, setShowError] = useState(false);
  const initialSubmit = useRef(false);

  useEffect(() => {
    if (isSubmit && files.length < 1) {
      initialSubmit.current = true;
    }
    if (files.length > 0) {
      setShowError(false);
    }
    if (files.length < 1 && initialSubmit.current && section === ListingSection.Details) {
      setShowError(true);
    }
  }, [isSubmit, files]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles?.length > 0) {
        if (maxFileLen < acceptedFiles?.length + files?.length) {
          showToastMessage(
            `Please Select ${maxFileLen} files only...`,
            ToastType.Error
          );
          return;
        }
        setFiles((prevFiles: any) => {
          const newItems = [...prevFiles];
          newItems.push(
            ...acceptedFiles
              ?.filter(
                (file: any) =>
                  !newItems.some((item: any) => item.path === file.path) // remove preselected files
              )
              ?.map(
                (file: any) =>
                  Object.assign(file, { preview: URL.createObjectURL(file) }) // add preview property
              )
          );

          if (newItems?.length > maxFileLen) {
            showToastMessage(
              `Please Select ${maxFileLen} files only...`,
              ToastType.Error
            );
          }

          while (newItems?.length > maxFileLen) {
            newItems.shift(); // Remove the first element if at max size
          }

          return newItems;
        });
      }
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === ErrorCode.FileTooLarge) {
            showToastMessage('Selected file too large..', ToastType.Error);
          }

          if (err.code === ErrorCode.FileInvalidType) {
            showToastMessage(
              'Selected file type not supported',
              ToastType.Error
            );
          }
        });
      });
      if (fileRejections?.[0]?.errors?.[0]?.code === ErrorCode.TooManyFiles) {
        showToastMessage(
          `Please Select ${maxFileLen} files only...`,
          ToastType.Error
        );
      }
    },
    // set filters
    accept: {
      'image/*': [], // only images
    },
    maxSize: maxFileSizeInBytes || 1024 * 1024 * 2, // max file size 2mb
    maxFiles: numberOfFiles || 1,
  });

  const removeFile = (name: string) => {
    setFiles((files: any) => files.filter((file: any) => file.name !== name));
  };
  const strokeFill = showError ? "#ff0000" : "#818181";
  return (
    <div className="row drop-zone-section pt-2 pb-4">
      {/* Upload section */}
      <div
        {...getRootProps({ className })}
        className="col-md-4"
      // style={{ maxWidth: '300px' }}
      >
        <input {...getInputProps()} />
        {!isDragActive ? (
          <div className={`drop-zone mb-3 mb-md-0 ${showError ? "invalid-error" : ""}`}>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2335_2179)">
                <path
                  d="M53.0489 25.4957V7.69195C53.0489 5.65294 51.3959 4 49.3569 4H4.69195C2.65294 4 1 5.65294 1 7.69194V47.5092C1 49.5482 2.65294 51.2012 4.69195 51.2012H27.0244"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.06348 34.8698L17.1072 21.5944C17.2264 21.4959 17.4043 21.5204 17.4923 21.6476L28.3063 37.2883C28.4321 37.4703 28.7126 37.4257 28.7759 37.2137L32.3293 25.3097C32.384 25.1265 32.6095 25.0606 32.7543 25.1855L37.1022 28.9378"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="33.2739"
                  cy="15.352"
                  r="2.57712"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="49.2234"
                  cy="46.4798"
                  r="12.5276"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M49.0288 40.9946V52.0933"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M54.5801 46.5425L43.4814 46.5425"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2335_2179">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="drag-and-drop-title">Drag and drop Image</p>
            <p className="file-size">
              {maxFileSizeInBytes
                ? formatBytes(maxFileSizeInBytes)
                : 'max 2 MB'}
            </p>
          </div>
        ) : (
          <div className={`drop-zone ${showError ? "invalid-error" : ""}`}>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2335_2179)">
                <path
                  d="M53.0489 25.4957V7.69195C53.0489 5.65294 51.3959 4 49.3569 4H4.69195C2.65294 4 1 5.65294 1 7.69194V47.5092C1 49.5482 2.65294 51.2012 4.69195 51.2012H27.0244"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.06348 34.8698L17.1072 21.5944C17.2264 21.4959 17.4043 21.5204 17.4923 21.6476L28.3063 37.2883C28.4321 37.4703 28.7126 37.4257 28.7759 37.2137L32.3293 25.3097C32.384 25.1265 32.6095 25.0606 32.7543 25.1855L37.1022 28.9378"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="33.2739"
                  cy="15.352"
                  r="2.57712"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="49.2234"
                  cy="46.4798"
                  r="12.5276"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M49.0288 40.9946V52.0933"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M54.5801 46.5425L43.4814 46.5425"
                  stroke={strokeFill}
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2335_2179">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="drag-and-drop-title">Drop Image here</p>
            <p className="file-size">
              {maxFileSizeInBytes
                ? formatBytes(maxFileSizeInBytes)
                : 'max 2 MB'}
            </p>
          </div>
        )}
        {showError && <div className="invalid-feedback text-center">Please select atleast 1 image</div>}
      </div>
      {/* Preview section */}
      <div className="col-md-8">
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-5"
        // className={` d-flex gap-3 ${props?.page === 'addListing' ? 'md:grid-cols-9' : 'grid-cols-3'
        //   }`}
        >
          {files?.map((file: any) => (
            <div key={file.name} className="col px-1 pb-2 pt-0">
              <div className="upload-preview rounded-2 overflow-hidden border">
                <img
                  src={file.preview ? file.preview : URL.createObjectURL(file)}
                  alt={decodeEncoded(file.name)}
                  width={100}
                  height={100}
                  className="w-100" //img-fluid
                />
                <button
                  title="Remove"
                  type="button"
                  onClick={() => {
                    removeFile(file.name);
                  }}
                  className="upload-preview-remove"
                >
                  <BiSolidTrash />
                </button>
              </div>
              {/* <button
              onClick={() => removeFile(file.name)}
              className="bg-gray-400 text-white border w-full border-gray-700 my-2 py-1 px-3 rounded-md"
            >
              Remove
            </button> */}
              <p className="mb-0" title={decodeEncoded(file.name)}>
                {decodeEncoded(file.name)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DropZone;
