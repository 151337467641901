import axios, { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';

const initialState = {
  userData: null,
  userDetails: null,
  isLoading: false,
  error: null,
  isLogin: false,
  isSubscription: false,
  messageNotification: {
    loading: false,
    error: '',
    success: false,
  },
};

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    resetUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    resetUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    resetMessageNotification: (state) => {
      state.messageNotification.loading = false;
      state.messageNotification.success = false;
      state.messageNotification.error = '';
    },
    resetFetchData: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSubscription = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscription.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSubscription.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.getSubscription = true;
      state.userData = { ...state.userData, subscription: action.payload.plan };
      state.error = null;
    });
    builder.addCase(getSubscription.rejected, (state: any, action) => {
      state.isLoading = false;
      state.getSubscription = true;
      state.error = action.error.message;
    });
    builder.addCase(setMessageNotification.pending, (state) => {
      state.messageNotification.loading = true;
      state.messageNotification.error = '';
    });
    builder.addCase(setMessageNotification.fulfilled, (state) => {
      state.messageNotification.loading = false;
      state.messageNotification.success = true;
      state.messageNotification.error = '';
    });
    builder.addCase(setMessageNotification.rejected, (state, action) => {
      state.messageNotification.loading = false;
      state.messageNotification.error = action.error.message ?? '';
      state.messageNotification.success = false;
    });
  },
});

export const {
  setUserData,
  resetUserData,
  setUserDetails,
  resetUserDetails,
  resetFetchData,
  resetMessageNotification,
} = LoginSlice.actions;

export default LoginSlice.reducer;

export const loggedInUserState = (state: any) => state.login.userData;

export const getSubscription = createAsyncThunk<any, any>(
  'getSubscription',
  async () => {
    try {
      const response = await axiosCall.get(`/packages/subscription`);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return Promise.reject(errorMessage);
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const setMessageNotification = createAsyncThunk(
  'setMessageNotification',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.post(
        '/user/setMessageNotification',
        arg.body
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);
