import { UnknownAction } from '@reduxjs/toolkit';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Carousel, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';
import PropertySample from '../../assets/images/PropertySample.jpg';
import landlordimg from '../../assets/images/avatar.png';
import veriafied from '../../assets/images/veriafied.jpg';
import { IProperty } from '../../pages/Listings/Listings';
import SignIn from '../../pages/Login/Signin';
import SignInPopupHOC from '../../pages/Login/hoc/SigninHoc';
import SignUp from '../../pages/SignUp/Signup';
import SignUpPopupHOC from '../../pages/SignUp/hoc/SignupHoc';
import {
  fetchCalenderEvent,
  resetFetchCalenderState,
} from '../../redux/slices/CalenderSlice';
import {
  deleteFavProperty,
  postFavProperty,
  resetListingState,
} from '../../redux/slices/ListingSlice';
import {
  addReviewsProperty,
  approveProperty,
  fetchPropertyById,
  resetReviewState,
  setIsFavouritePropertyDetail,
  setIsNotFavouritePropertyDetail,
} from '../../redux/slices/PropertySlice';
import FormikFieldStarRating from '../../utils/FormikFieldStarRating';
import FormikFieldTextArea from '../../utils/FormikFormTextArea';
import { usePermissions } from '../../utils/Permission/Permission';
import {
  ToastType,
  showToastMessage,
  toastDefaults,
} from '../../utils/ToastOptionCommon';
import axiosCall from '../../utils/axiosCall';
import callBackendAPI from '../../utils/callBackendAPI';
import { formatPhoneNumber } from '../../utils/common';
import Loader from '../CommonComponents/Loader/Loader';
import CalenderViewModal from './CalenderViewModal/CalenderViewModal';
import CalenderPopupHOC from './CalenderViewModal/hoc/CalenderPopupHoc';
import { ProfileViewModal } from './ProfileViewModal/ProfileViewModal';
import './PropertyDetail.scss';
import DocumentPopupHOC from './DocumentViewModal/hoc/DocumentPopupHoc';
import DocumentViewModal from './DocumentViewModal/DocumentViewModal';
import Input from 'react-phone-number-input/input';

interface IProps {
  calenderToggleHandler: () => void;
  showCalenderPopup: boolean;
  signInToggleHandler: () => void;
  showSignInPopup: boolean;
  signUpToggleHandler: () => void;
  showSignUpPopup: boolean;
  showDocumentPopup: boolean;
  documentToggleHandler: () => void;
}

export interface IAmenities {
  id: number;
  name: string;
  order: number;
  icon: string;
  _id: string;
}

export interface IRoom {
  name: string;
  price: number;
  bathroomType: string;
  amenities: Array<IAmenities>;
  depositMoney: number;
  cleaningCharges: number;
  petFees: number;
  availableFrom: string;
  _id: string;
  imagesLink: string[];
}

interface ChatData {
  Name: string;
  Phone: string;
  Email: string;
  Message: string;
  senderId: string;
  PropertyId: string;
}

// type propertyStateData = {
//   loader: boolean;
//   propertyDetail: IProperty;
// };

const PropertyDetail = (props: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const propertyDetail: IProperty = useSelector(
    (state: any) => state.property.property
  );
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [landlord, setLandLoard] = useState<any>();
  const [events, setEvents] = useState([]);
  const [landlordRating, setLandoardRating] = useState<number>();
  const isLoading = useSelector((state: any) => state.property.isLoading);
  const loggedUser = useSelector((state: any) => state.user.loggedinUser);
  const [favId, setFavId] = useState<string>('');
  const { addReviewsLoading, addReviewsSuccess, addReviewsError } = useSelector(
    (state: any) => state.property
  );
  const { hasPermission } = usePermissions();
  const { loading, success, error, calenderEvents } = useSelector(
    (state: any) => state.calender
  );
  const { currentPropertyId }: any = useParams();

  const {
    postFavPropertyError,
    postFavPropertySuccess,
    deleteFavPropertyError,
    deleteFavPropertySuccess,
    deleteFavPropertyLoading,
    postFavPropertyLoading,
  } = useSelector((state: any) => state.listing);
  const {
    approvePropertySuccess,
    approvePropertyLoading,
    approvePropertyError,
  } = useSelector((state: any) => state.property);

  const [phoneValue, setPhoneValue] = useState<any>('');

  const location: any = useLocation();

  const propertyUrl = window.location.href;
  const pageTitle = 'FurnishedHomeLocator';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (addReviewsSuccess) {
      showToastMessage('Review Added Successfully', ToastType.Success);
      fetchPropertyData();
    }
    dispatch(resetReviewState(null));
  }, [addReviewsSuccess]);

  useEffect(() => {
    if (addReviewsError) {
      showToastMessage('Review Submit Failed', ToastType.Error);
    }
    dispatch(resetReviewState(null));
  }, [addReviewsError]);

  useEffect(() => {
    if (error) {
      showToastMessage(
        'Error Ocurred While Fetching Calender',
        ToastType.Error
      );
    }
    dispatch(resetFetchCalenderState(null));
  }, [error]);

  useEffect(() => {
    setEvents(
      calenderEvents?.map((e: any) => {
        return {
          title: e.title,
          start: new Date(e.start),
          end: new Date(e.end),
        };
      })
    );
  }, [calenderEvents]);

  useEffect(() => {
    if (currentPropertyId === undefined || currentPropertyId === null) {
      navigate('/');
    }
    dispatch(
      fetchCalenderEvent({
        body: { PropertyId: currentPropertyId },
      }) as unknown as UnknownAction
    );
  }, []);

  useEffect(() => {
    if (postFavPropertySuccess) {
      toast.success('Marked as Favorite', toastDefaults());
    }
    dispatch(setIsFavouritePropertyDetail({ id: favId }));
    dispatch(resetListingState({}));
  }, [postFavPropertySuccess]);

  useEffect(() => {
    if (deleteFavPropertySuccess) {
      toast.success('Unmarked from Favorite', toastDefaults());
    }
    dispatch(setIsNotFavouritePropertyDetail({ id: favId }));
    dispatch(resetListingState({}));
  }, [deleteFavPropertySuccess]);

  useEffect(() => {
    if (approvePropertySuccess) {
      toast.success('Property Approved Successfully', toastDefaults());
    }
  }, [approvePropertySuccess]);

  useEffect(() => {
    if (approvePropertyError) {
      toast.error('Failed Approving Property', toastDefaults());
    }
  }, [approvePropertyError]);

  useEffect(() => {
    if (postFavPropertyError) {
      toast.error('Failed Faving Property', toastDefaults());
    }
    dispatch(resetListingState(null));
  }, [postFavPropertyError]);

  useEffect(() => {
    if (deleteFavPropertyError) {
      toast.error('Failed Un-Faving Property', toastDefaults());
    }
    dispatch(resetListingState(null));
  }, [deleteFavPropertyError]);

  const [selectedRoom, setSelectedRoom] = useState<IRoom>({
    name: '',
    price: 0,
    bathroomType: '',
    amenities: [{ id: 0, name: '', order: -1, icon: '', _id: '' }],
    depositMoney: 0,
    cleaningCharges: 0,
    petFees: 0,
    availableFrom: '',
    _id: '',
    imagesLink: [],
  });

  const [reviewTitle, setReviewTitle] = useState<string>(
    `${propertyDetail.review ? propertyDetail.review?.length : 0} Reviews`
  );
  const handleRoomSelection = (id: string) => {
    let room = propertyDetail.rooms?.filter((e) => e._id === id)[0];
    setSelectedRoom(() => room);
  };

  const fetchPropertyData = () => {
    dispatch(
      fetchPropertyById({
        Id: currentPropertyId,
        section: -1,
      }) as unknown as UnknownAction
    );
  };

  useEffect(() => {
    if (currentPropertyId) {
      fetchPropertyData();
    }
  }, []);

  useEffect(() => {
    setSelectedRoom(() => propertyDetail?.rooms?.[0]);
    if (propertyDetail?.user_id) {
      (async () => {
        const url = `/user/getUserById/${propertyDetail.user_id}`;
        const response = await axiosCall.get(url);
        // console.log(response.data);
        let user = response.data;
        if (user?.keepPhoneNumberPrivate) {
          user.phoneNo = user?.phoneNo?.toString().replace(/\d/g, '*');
        }
        setLandLoard(() => user);
      })();
      (() => {
        const url = `reviews/landlordRating/${propertyDetail.user_id}`;
        axiosCall
          .get(url)
          .then((response) => {
            setLandoardRating(response.data.avgRating);
          })
          .catch((error) => {
            setLandoardRating(0);
          });
      })();
    }
    setChatData({ ...chatData, PropertyId: propertyDetail?._id });
  }, [propertyDetail]);

  useEffect(() => {
    setReviewTitle(() => `${propertyDetail?.review?.length} Reviews`);
  }, [propertyDetail?.review]);

  let isReviewAdded = propertyDetail?.review?.filter(
    (e) => e.userId === JSON.parse(localStorage.getItem('user') ?? '')._id ?? ''
  );
  const userData = useSelector((state: any) => state.login.userData);

  // console.log(userData);

  const handleAprroveProperty = () => {
    let body = {
      isApproved: !propertyDetail.isApproved,
      propertyId: propertyDetail._id,
    };
    dispatch(approveProperty({ body: body }) as unknown as UnknownAction);
  };
  const [chatData, setChatData] = useState<Partial<ChatData>>({
    Name: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}` ?? '',
    Phone: userData?.phoneNo?.toString() ?? '',
    Email: userData?.userEmail ?? '',
    Message: '',
    senderId: userData?._id,
    PropertyId: propertyDetail?._id,
  });
  const [urlCopied, setUrlCopied] = useState(false);

  useEffect(() => {
    setChatData({
      Name: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}` ?? '',
      Phone: userData?.phoneNo?.toString() ?? '',
      Email: userData?.userEmail ?? '',
      Message: '',
      senderId: userData?._id,
      PropertyId: propertyDetail?._id,
    });
    setPhoneValue(() => {
      return userData?.phoneNo?.toString() ?? '';
    });
  }, [propertyDetail?._id, userData]);

  const handleChatInputChange = (e: any) => {
    const { name, value } = e.target;
    setChatData({
      ...chatData,
      [name]: value,
    });
  };

  useEffect(() => {
    setChatData((prev: any) => ({
      ...prev,
      Phone: formatPhoneNumber(phoneValue ?? ''),
    }));
  }, [phoneValue]);

  const handleChatSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await callBackendAPI(
        'POST',
        'messaging/createChat',
        null,
        chatData
      );
      if (response.status === 200) {
        toast.success(
          'Successfully sent the message, Landlord will get back to you. ',
          toastDefaults()
        );
      } else {
        toast.error('faile to send message to Landloard...', toastDefaults());
      }

      setChatData({
        Name: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}` ?? '',
        Phone: userData?.phoneNo?.toString() ?? '',
        Email: userData?.userEmail ?? '',
        Message: '',
        senderId: userData?._id,
        PropertyId: propertyDetail?._id,
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleShowProfile = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleUnLike = (idToUnLike: any) => {
    if (localStorage.getItem('isLoggedin')) {
      dispatch(
        deleteFavProperty({
          body: {
            user_id: JSON.parse(localStorage.getItem('user') ?? '')._id ?? '',
            propertie_id: idToUnLike,
          },
        }) as unknown as UnknownAction
      );
    } else {
      props.signInToggleHandler();
    }
  };

  const handleLike = (idToLike: any) => {
    if (localStorage.getItem('isLoggedin')) {
      dispatch(
        postFavProperty({
          body: {
            user_id: JSON.parse(localStorage.getItem('user') ?? '')._id ?? '',
            propertie_id: idToLike,
          },
        }) as unknown as UnknownAction
      );
    } else {
      props.signInToggleHandler();
    }
  };

  return (
    <>
      {props.showSignInPopup && (
        <SignIn
          modalClosedSignIn={props.signInToggleHandler}
          modalClosedSignUp={props.signUpToggleHandler}
        />
      )}

      {props.showSignUpPopup && (
        <SignUp
          modalClosedSignUp={props.signUpToggleHandler}
          modalClosedSignIn={props.signInToggleHandler}
        />
      )}
      {showProfileModal && (
        <ProfileViewModal
          onModalClose={() => setShowProfileModal(!showProfileModal)}
          userId={propertyDetail.user_id ?? ''}
          modalHeader="Landlord Profile"
        />
      )}
      {props.showCalenderPopup && (
        <CalenderViewModal
          onModalClosed={() => props.calenderToggleHandler()}
          events={events}
        />
      )}
      {props.showDocumentPopup && (
        <DocumentViewModal
          onModalClosed={() => props.documentToggleHandler()}
          fileUrl={propertyDetail.documentsLink[0]}
        />
      )}
      {approvePropertyLoading || isLoading || addReviewsLoading || loading ? (
        <div style={{ marginTop: '50%' }}>
          <Loader />
        </div>
      ) : (
        <div className="details-page">
          <div className="deatils-header">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-7">
                  <div className="details-left d-flex tags">
                    <div className="me-3">
                      <h1 className="property-type">
                        {propertyDetail.title ?? '-'}
                      </h1>
                      <p className="property-address">
                        {/* {propertyDetail.address?.street ?? '-'} */}
                        {/* {', '} */}
                        {propertyDetail.address?.city ?? '-'}
                        {', '}
                        {propertyDetail.address?.state ?? '-'}
                        {', '}
                        {propertyDetail.address?.zip ?? '-'}
                      </p>
                    </div>
                    <div className="properties-thumb-top mt-2">
                      {propertyDetail.isFeaturedProperty && (
                        <span className="thumb-label feature-label">
                          Feature
                        </span>
                      )}
                      {/* {propertyDetail.new && (
                        <span className="thumb-label new-label">new</span>
                      )} */}
                    </div>
                    {/* <div className="property-tags">
                      {propertyDetail.isFeaturedProperty && (
                        <a href="#" className="btn btn-primary">
                          Feature
                        </a>
                      )}
                      {propertyDetail.new && <a href='#' className='btn btn-info'>New</a>}
                    </div> */}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="details-right d-flex justify-content-end gap-2 tags">
                    {/* <button
                      type="button"
                      className="btn btn-light bg-white rounded-pill border"
                    >
                      Available
                    </button> */}
                    <div className="properties-thumb-top mt-2">
                      <span className="thumb-label property-type-label">
                        Available
                      </span>
                    </div>
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-light bg-white rounded-circle border p-0 share-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          width="18"
                          height="19"
                          fill="#000000"
                          className="mx-auto"
                          viewBox="0 0 18 19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.5 4.5C17.5 6.57031 15.8203 8.25 13.75 8.25C12.6172 8.25 11.6016 7.78125 10.8984 6.96094L7.38281 8.71875C7.46094 8.99219 7.5 9.26562 7.5 9.5C7.5 9.77344 7.46094 10.0469 7.38281 10.3203L10.8984 12.0391C11.6016 11.2578 12.6172 10.75 13.75 10.75C15.8203 10.75 17.5 12.4297 17.5 14.5C17.5 16.5703 15.8203 18.25 13.75 18.25C11.6797 18.25 10 16.5703 10 14.5C10 14.2656 10 13.9922 10.0781 13.7188L6.5625 11.9609C5.85938 12.7812 4.84375 13.25 3.75 13.25C1.67969 13.25 0 11.5703 0 9.5C0 7.42969 1.67969 5.75 3.75 5.75C4.84375 5.75 5.85938 6.25781 6.5625 7.03906L10.0781 5.32031C10 5.04688 10 4.77344 10 4.5C10 2.42969 11.6797 0.75 13.75 0.75C15.8203 0.75 17.5 2.42969 17.5 4.5ZM3.75 11.375C4.41406 11.375 5 11.0234 5.35156 10.4375C5.70312 9.89062 5.70312 9.14844 5.35156 8.5625C5 8.01562 4.41406 7.625 3.75 7.625C3.04688 7.625 2.46094 8.01562 2.10938 8.5625C1.75781 9.14844 1.75781 9.89062 2.10938 10.4375C2.46094 11.0234 3.04688 11.375 3.75 11.375ZM15.625 4.5C15.625 3.83594 15.2344 3.25 14.6875 2.89844C14.1016 2.54688 13.3594 2.54688 12.8125 2.89844C12.2266 3.25 11.875 3.83594 11.875 4.5C11.875 5.20312 12.2266 5.78906 12.8125 6.14062C13.3594 6.49219 14.1016 6.49219 14.6875 6.14062C15.2344 5.78906 15.625 5.20312 15.625 4.5ZM13.75 16.375C14.4141 16.375 15 16.0234 15.3516 15.4375C15.7031 14.8906 15.7031 14.1484 15.3516 13.5625C15 13.0156 14.4141 12.625 13.75 12.625C13.0469 12.625 12.4609 13.0156 12.1094 13.5625C11.7578 14.1484 11.7578 14.8906 12.1094 15.4375C12.4609 16.0234 13.0469 16.375 13.75 16.375Z" />
                        </svg>
                      </button>
                      <ul className="dropdown-menu">
                        <li className="m-2">
                          <FacebookShareButton
                            url={propertyUrl}
                            className="dropdown-item"
                          >
                            <FacebookIcon size={32} round /> Facebook
                          </FacebookShareButton>
                        </li>
                        <li className="m-2">
                          <TwitterShareButton
                            url={propertyUrl}
                            title={pageTitle}
                            className="dropdown-item"
                          >
                            <XIcon size={32} round /> Twitter
                          </TwitterShareButton>
                        </li>
                        <li className="m-2">
                          <LinkedinShareButton
                            url={propertyUrl}
                            className="dropdown-item"
                          >
                            <LinkedinIcon size={32} round /> LinkedIn
                          </LinkedinShareButton>
                        </li>
                        <li className="m-2">
                          <WhatsappShareButton
                            url={propertyUrl}
                            title={pageTitle}
                            separator=":: "
                            className="dropdown-item"
                          >
                            <WhatsappIcon size={32} round /> WhatsApp
                          </WhatsappShareButton>
                        </li>
                      </ul>
                    </div>
                    <div className="" aria-expanded="false">
                      <CopyToClipboard
                        onCopy={() => {
                          setUrlCopied(() => true);
                          showToastMessage(
                            'Copied to your clipboard',
                            ToastType.Success
                          );
                        }}
                        text={propertyUrl}
                      >
                        <button className="btn btn-light bg-white rounded-circle border p-0 share-btn">
                          <MdContentCopy size={24} />
                        </button>
                      </CopyToClipboard>
                    </div>
                    {propertyDetail.isFavoriteForThisUser ? (
                      <button
                        type="button"
                        className="btn btn-light bg-white rounded-circle border p-0 watchlist-btn"
                        onClick={() => handleUnLike(propertyDetail._id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="#000000"
                          className="mx-auto"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.0054 20.8203L10.9272 20.7031L4.09131 14.375C2.88037 13.2422 2.21631 11.6797 2.21631 10.0391V9.92188C2.21631 7.1875 4.16943 4.80469 6.86475 4.29688C8.38818 3.98438 9.95068 4.33594 11.2397 5.23438C11.5913 5.46875 11.9038 5.78125 12.2163 6.09375C12.3726 5.9375 12.5288 5.74219 12.7241 5.58594C12.8804 5.46875 13.0366 5.35156 13.1929 5.23438C14.4429 4.33594 16.0054 3.98438 17.5288 4.29688C20.2632 4.80469 22.2163 7.1875 22.2163 9.92188V10.0391C22.2163 11.6797 21.5132 13.2422 20.3022 14.375L13.4663 20.7031L13.3882 20.8203C13.0757 21.0938 12.646 21.2891 12.2163 21.2891C11.7476 21.2891 11.3569 21.0938 11.0054 20.8203ZM11.5522 8.16406C11.5132 8.16406 11.5132 8.16406 11.5132 8.125L10.8101 7.34375C9.91162 6.32812 8.54443 5.89844 7.21631 6.13281C5.38037 6.48438 4.09131 8.08594 4.09131 9.92188V10.0391C4.09131 11.1719 4.521 12.2266 5.34131 12.9688L12.2163 19.3359L19.0522 12.9688C19.8726 12.2266 20.3413 11.1719 20.3413 10.0391V9.92188C20.3413 8.08594 19.0132 6.48438 17.1772 6.13281C15.8491 5.89844 14.4819 6.32812 13.5835 7.34375L12.8804 8.125C12.8804 8.16406 12.8804 8.16406 12.8413 8.20312C12.6851 8.35938 12.4507 8.47656 12.2163 8.47656C11.9429 8.47656 11.7085 8.35938 11.5522 8.20312V8.16406Z" />
                        </svg>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-light bg-white rounded-circle border p-0 watchlist-btn"
                        onClick={() => handleLike(propertyDetail._id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="#000000"
                          className="mx-auto"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.0054 20.8203L10.9272 20.7031L4.09131 14.375C2.88037 13.2422 2.21631 11.6797 2.21631 10.0391V9.92188C2.21631 7.1875 4.16943 4.80469 6.86475 4.29688C8.38818 3.98438 9.95068 4.33594 11.2397 5.23438C11.5913 5.46875 11.9038 5.78125 12.2163 6.09375C12.3726 5.9375 12.5288 5.74219 12.7241 5.58594C12.8804 5.46875 13.0366 5.35156 13.1929 5.23438C14.4429 4.33594 16.0054 3.98438 17.5288 4.29688C20.2632 4.80469 22.2163 7.1875 22.2163 9.92188V10.0391C22.2163 11.6797 21.5132 13.2422 20.3022 14.375L13.4663 20.7031L13.3882 20.8203C13.0757 21.0938 12.646 21.2891 12.2163 21.2891C11.7476 21.2891 11.3569 21.0938 11.0054 20.8203ZM11.5522 8.16406C11.5132 8.16406 11.5132 8.16406 11.5132 8.125L10.8101 7.34375C9.91162 6.32812 8.54443 5.89844 7.21631 6.13281C5.38037 6.48438 4.09131 8.08594 4.09131 9.92188V10.0391C4.09131 11.1719 4.521 12.2266 5.34131 12.9688L12.2163 19.3359L19.0522 12.9688C19.8726 12.2266 20.3413 11.1719 20.3413 10.0391V9.92188C20.3413 8.08594 19.0132 6.48438 17.1772 6.13281C15.8491 5.89844 14.4819 6.32812 13.5835 7.34375L12.8804 8.125C12.8804 8.16406 12.8804 8.16406 12.8413 8.20312C12.6851 8.35938 12.4507 8.47656 12.2163 8.47656C11.9429 8.47656 11.7085 8.35938 11.5522 8.20312V8.16406Z" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="property-deatils bg-white">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-9">
                  <div className="box-view h-auto" style={{ height: '492px' }}>
                    <Carousel>
                      {propertyDetail.rooms
                        ?.filter((room) => room._id === selectedRoom._id)
                        ?.flatMap((selectedRoom) =>
                          selectedRoom.imagesLink?.length > 0
                            ? selectedRoom.imagesLink.map(
                                (image: string, i: number) => (
                                  <Carousel.Item key={i}>
                                    <img
                                      src={image}
                                      alt="villa"
                                      style={{
                                        width: '-webkit-fill-available',
                                        height: '492px',
                                      }}
                                      className=""
                                    />
                                  </Carousel.Item>
                                )
                              )
                            : [
                                <Carousel.Item key="default">
                                  <img
                                    src={PropertySample}
                                    alt="villa"
                                    style={{
                                      width: '-webkit-fill-available',
                                      height: '492px',
                                    }}
                                    className=""
                                  />
                                </Carousel.Item>,
                              ]
                        )}
                    </Carousel>
                  </div>

                  <div className="units-property box-view h-auto">
                    <h3 className="box-title">Units at this property</h3>
                    <div className="row">
                      {propertyDetail.rooms?.map((values) => {
                        return (
                          <div
                            key={values._id}
                            className={
                              values._id === selectedRoom._id
                                ? 'col-md-4 selected-room'
                                : 'col-md-4'
                            }
                            onClick={() => handleRoomSelection(values._id)}
                          >
                            <div className="bg-light rounded-3 p-2 ">
                              <div className="unit-preview">
                                <img
                                  src={
                                    values.imagesLink[0]
                                      ? values.imagesLink[0]
                                      : PropertySample
                                  } // not getting images
                                  alt="Unit Preview"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="p-2">
                                <h6 className="unit-price">
                                  ${values.price} / month
                                </h6>
                                <h5 className="unit-title">{values.name}</h5>
                                <ul className="unit-features">
                                  {values.amenities ? (
                                    values.amenities?.map(
                                      (data: IAmenities | null, i: number) => {
                                        return (
                                          <li key={i}>
                                            <svg
                                              width="14"
                                              height="9"
                                              viewBox="0 0 14 9"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12.9336 0.566406C13.1797 0.839844 13.1797 1.25 12.9336 1.49609L5.71484 8.71484C5.44141 8.98828 5.03125 8.98828 4.78516 8.71484L1.06641 4.99609C0.792969 4.75 0.792969 4.33984 1.06641 4.09375C1.3125 3.82031 1.72266 3.82031 1.96875 4.09375L5.22266 7.34766L12.0039 0.566406C12.25 0.320312 12.6602 0.320312 12.9062 0.566406H12.9336Z"
                                                fill="#818181"
                                              />
                                            </svg>
                                            {data?.name ?? '-'}
                                          </li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="property-description box-view h-auto">
                    <h3 className="box-title">Property Description</h3>
                    <div className="row">
                      <div className="col-12">
                        <p className="mb-3">{propertyDetail.description}</p>
                        {/* <p>Gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.</p> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="property-overview box-view h-auto">
                    <h3 className="box-title">Property Overview</h3>{' '}
                    <div className="row">
                      <div className="col">
                        <div className="property-overview-tile bg-light text-center rounded-2">
                          <svg
                            width="45"
                            height="44"
                            className="mx-auto"
                            viewBox="0 0 45 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_868_454)">
                              <g clipPath="url(#clip1_868_454)">
                                <path
                                  d="M42.2002 24.75V34.375C42.2002 35.134 41.5842 35.75 40.8252 35.75C40.0662 35.75 39.4502 35.134 39.4502 34.375V33H6.4502V34.375C6.4502 35.134 5.8342 35.75 5.0752 35.75C4.3162 35.75 3.7002 35.134 3.7002 34.375V24.75C3.7002 22.4757 5.55095 20.625 7.8252 20.625H38.0752C40.3495 20.625 42.2002 22.4757 42.2002 24.75Z"
                                  fill="#818181"
                                  fillOpacity="0.5"
                                />
                                <path
                                  d="M7.8252 17.875V9.625C7.8252 8.866 8.4412 8.25 9.2002 8.25H36.7002C37.4592 8.25 38.0752 8.866 38.0752 9.625V17.875H33.9502V16.5C33.9502 14.9834 32.7168 13.75 31.2002 13.75H27.0752C25.5586 13.75 24.3252 14.9834 24.3252 16.5V17.875H21.5752V16.5C21.5752 14.9834 20.3418 13.75 18.8252 13.75H14.7002C13.1836 13.75 11.9502 14.9834 11.9502 16.5V17.875H7.8252Z"
                                  fill="#818181"
                                  fillOpacity="0.5"
                                />
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_868_454">
                                <rect
                                  width="44"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.450195)"
                                />
                              </clipPath>
                              <clipPath id="clip1_868_454">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.450195)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h4>Bedrooms</h4>
                          <h6>4 Bedrooms / 1 Guestroom</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="property-overview-tile bg-light text-center rounded-2">
                          <svg
                            width="46"
                            height="44"
                            className="mx-auto"
                            viewBox="0 0 46 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_868_480)">
                              <g clipPath="url(#clip1_868_480)">
                                <g clipPath="url(#clip2_868_480)">
                                  <mask
                                    id="mask0_868_480"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="45"
                                    height="44"
                                  >
                                    <path
                                      d="M44.7998 0H0.799805V44H44.7998V0Z"
                                      fill="white"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_868_480)">
                                    <path
                                      d="M34.4014 21.0547C34.4014 19.6331 33.2448 18.4766 31.8232 18.4766H29.2451C27.8235 18.4766 26.667 19.6331 26.667 21.0547V30.0781H34.4014V21.0547Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                    <path
                                      d="M6.042 6.44531C6.042 4.3132 7.77708 2.57812 9.90919 2.57812H12.4873C14.2296 2.57812 15.7062 3.73691 16.1881 5.324C13.3331 5.98589 11.1982 8.5482 11.1982 11.6016V12.8906H24.0889V11.6016C24.0889 8.45041 21.8152 5.82201 18.8226 5.26625C18.2669 2.27339 15.6381 0 12.4873 0H9.90919C6.35567 0 3.46387 2.89094 3.46387 6.44531V18.4766H6.042V6.44531Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                    <path
                                      d="M36.9795 32.6562H24.0889V28.7891H3.46387V32.6562C3.46387 36.0294 5.32442 38.9755 8.07287 40.5237L6.41961 42.1769L8.24251 44L10.7343 41.5082C11.3015 41.6203 11.8876 41.6797 12.4873 41.6797H33.1123C33.712 41.6797 34.2981 41.6203 34.8653 41.5082L37.3571 44L39.1801 42.177L37.5268 40.5237C40.2752 38.9755 42.1357 36.0294 42.1357 32.6562V28.7891H36.9795V32.6562Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                    <path
                                      d="M42.2217 21.0547H36.9795V26.2109H42.2217C43.6431 26.2109 44.7998 25.0542 44.7998 23.6328C44.7998 22.2114 43.6431 21.0547 42.2217 21.0547Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                    <path
                                      d="M24.0889 21.0547H3.37792C1.95652 21.0547 0.799805 22.2114 0.799805 23.6328C0.799805 25.0542 1.95652 26.2109 3.37792 26.2109H24.0889V21.0547Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_868_480">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.299805)"
                                />
                              </clipPath>
                              <clipPath id="clip1_868_480">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.299805)"
                                />
                              </clipPath>
                              <clipPath id="clip2_868_480">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.299805)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h4>Bedrooms</h4>
                          <h6>4 Bedrooms / 1 Guestroom</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="property-overview-tile bg-light text-center rounded-2">
                          <svg
                            width="46"
                            height="44"
                            className="mx-auto"
                            viewBox="0 0 46 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_868_465)">
                              <g clipPath="url(#clip1_868_465)">
                                <g clipPath="url(#clip2_868_465)">
                                  <mask
                                    id="mask0_868_465"
                                    maskUnits="userSpaceOnUse"
                                    x="1"
                                    y="0"
                                    width="45"
                                    height="44"
                                  >
                                    <path
                                      d="M45.1201 0H1.12012V44H45.1201V0Z"
                                      fill="white"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_868_465)">
                                    <path
                                      d="M11.1604 8.60864H9.72559V11.4782H11.1604C11.9515 11.4782 12.5952 10.8345 12.5952 10.0434C12.5952 9.25223 11.9515 8.60864 11.1604 8.60864Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                    <path
                                      d="M42.2506 37.9965C43.917 37.4023 45.1201 35.8246 45.1201 33.9565V31.0869C45.1201 29.0908 43.7479 27.4232 41.9017 26.9385L39.9611 21.1173C39.3745 19.3569 37.7341 18.1738 35.8781 18.1738H24.7099C22.854 18.1738 21.2136 19.3568 20.627 21.1167L18.6862 26.9384C16.8401 27.4231 15.468 29.0907 15.468 31.0869V33.9564C15.468 35.8244 16.6711 37.4021 18.3375 37.9963V39.6955C18.3375 40.201 18.4412 40.6793 18.6019 41.1303H12.5983V22.8419C17.4576 22.142 21.207 17.9627 21.207 12.9131V10.0435C21.207 4.50562 16.7019 0 11.1636 0C5.62539 0 1.12012 4.50562 1.12012 10.0435V12.9131C1.12012 17.9627 4.86957 22.142 9.72882 22.8419V41.1305H2.55493C1.76285 41.1305 1.12012 41.7727 1.12012 42.5652C1.12012 43.3578 1.76285 44 2.55493 44H43.6854C44.4775 44 45.1202 43.3578 45.1202 42.5652C45.1202 41.7726 44.4775 41.1304 43.6854 41.1304H41.9862C42.1469 40.6794 42.2506 40.2011 42.2506 39.6956V37.9965ZM9.72882 14.3479V15.7827C9.72882 16.5753 9.0861 17.2175 8.29401 17.2175C7.50192 17.2175 6.8592 16.5753 6.8592 15.7827V12.9131V7.17389C6.8592 6.38129 7.50192 5.73908 8.29401 5.73908H11.1635C13.537 5.73908 15.4679 7.66992 15.4679 10.0434C15.4679 12.4169 13.537 14.3478 11.1635 14.3478L9.72882 14.3479ZM23.349 22.0243C23.5452 21.4377 24.0917 21.0435 24.7101 21.0435H35.8783C36.4967 21.0435 37.0432 21.4377 37.2393 22.0248L38.8252 26.7827C33.6011 26.7827 27.1327 26.7827 21.7628 26.7827L23.349 22.0243ZM19.7722 35.3913C18.9811 35.3913 18.3374 34.7476 18.3374 33.9565V31.0869C18.3374 30.2958 18.9811 29.6521 19.7722 29.6521H21.608L23.5211 35.3913H19.7722ZM26.6818 41.1305C26.8425 40.6795 26.9461 40.2012 26.9461 39.6956V38.2608H33.6418V39.6956C33.6418 40.2011 33.7455 40.6795 33.9061 41.1305H26.6818ZM40.8157 35.3913H37.067L38.98 29.6521H40.8158C41.607 29.6521 42.2506 30.2958 42.2506 31.0869V33.9565C42.2506 34.7476 41.6069 35.3913 40.8157 35.3913Z"
                                      fill="#818181"
                                      fillOpacity="0.5"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_868_465">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.620117)"
                                />
                              </clipPath>
                              <clipPath id="clip1_868_465">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.620117)"
                                />
                              </clipPath>
                              <clipPath id="clip2_868_465">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.620117)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h4>Bedrooms</h4>
                          <h6>4 Bedrooms / 1 Guestroom</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="property-overview-tile bg-light text-center rounded-2">
                          <svg
                            width="44"
                            height="44"
                            className="mx-auto"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_868_498)">
                              <g clipPath="url(#clip1_868_498)">
                                <g clipPath="url(#clip2_868_498)">
                                  <mask
                                    id="mask0_868_498"
                                    maskUnits="userSpaceOnUse"
                                    x="-1"
                                    y="0"
                                    width="45"
                                    height="44"
                                  >
                                    <path
                                      d="M43.9902 0H-0.00976562V44H43.9902V0Z"
                                      fill="white"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_868_498)">
                                    <mask
                                      id="mask1_868_498"
                                      maskUnits="userSpaceOnUse"
                                      x="-1"
                                      y="0"
                                      width="45"
                                      height="44"
                                    >
                                      <path
                                        d="M43.9902 0H-0.00976562V44H43.9902V0Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M40.5527 5.15625V27.5C40.5527 28.4468 41.3184 29.2152 42.2651 29.2187C43.2168 29.2223 43.9902 28.4517 43.9902 27.5V5.15625C43.9902 2.30854 41.6817 0 38.834 0H16.5761C15.6245 0 14.8539 0.773353 14.8574 1.72502C14.8609 2.67188 15.6294 3.4375 16.5761 3.4375H38.834C39.7832 3.4375 40.5527 4.20698 40.5527 5.15625Z"
                                        fill="white"
                                      />
                                    </mask>
                                    <g mask="url(#mask1_868_498)">
                                      <mask
                                        id="mask2_868_498"
                                        maskUnits="userSpaceOnUse"
                                        x="-1"
                                        y="0"
                                        width="45"
                                        height="44"
                                      >
                                        <path
                                          d="M-0.00976562 0H43.9902V44H-0.00976562V0Z"
                                          fill="white"
                                        />
                                      </mask>
                                      <g mask="url(#mask2_868_498)">
                                        <path
                                          d="M40.9389 37.6406H11.5058C8.65814 37.6406 6.34961 35.3321 6.34961 32.4844V3.86722"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M1.70898 5.32813L4.61255 2.46367C5.61931 1.4704 7.25178 1.4704 8.25853 2.46367L11.1621 5.32813"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M38.5762 32.8281L41.5164 35.7317C42.5233 36.7385 42.5233 38.3708 41.5164 39.3777L38.5762 42.2812"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M40.5527 5.15625V27.5C40.5527 28.4468 41.3184 29.2152 42.2651 29.2187C43.2168 29.2223 43.9902 28.4517 43.9902 27.5V5.15625C43.9902 2.30854 41.6817 0 38.834 0H16.5761C15.6245 0 14.8539 0.773353 14.8574 1.72502C14.8609 2.67188 15.6294 3.4375 16.5761 3.4375H38.834C39.7832 3.4375 40.5527 4.20698 40.5527 5.15625Z"
                                          fill="#818181"
                                          fillOpacity="0.5"
                                        />
                                        <path
                                          d="M13.2246 20.0986C13.2246 18.4375 14.5712 17.0908 16.2324 17.0908C17.8936 17.0908 19.2402 18.4375 19.2402 20.0986V27.7578"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M13.2246 27.7578V17.0156"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M19.2402 20.0986C19.2402 18.4374 20.5868 17.0908 22.248 17.0908C23.9092 17.0908 25.2558 18.4374 25.2558 20.0986V27.7484"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M35.3965 19.25H30.7559C30.7559 19.25 35.3 15.125 35.3 13.25C35.3 12.0125 34.2896 11 33.0546 11"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M30.7559 13.25C30.7559 12.0126 31.7664 11 33.0014 11"
                                          stroke="#818181"
                                          strokeOpacity="0.5"
                                          strokeWidth="2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_868_498">
                                <rect
                                  width="44"
                                  height="44"
                                  fill="white"
                                  transform="translate(-0.00976562)"
                                />
                              </clipPath>
                              <clipPath id="clip1_868_498">
                                <rect
                                  width="44"
                                  height="44"
                                  fill="white"
                                  transform="translate(-0.00976562)"
                                />
                              </clipPath>
                              <clipPath id="clip2_868_498">
                                <rect
                                  width="44"
                                  height="44"
                                  fill="white"
                                  transform="translate(-0.00976562)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h4>Bedrooms</h4>
                          <h6>4 Bedrooms / 1 Guestroom</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="property-overview-tile bg-light text-center rounded-2">
                          <svg
                            width="46"
                            height="44"
                            className="mx-auto"
                            viewBox="0 0 46 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_868_529)">
                              <g clipPath="url(#clip1_868_529)">
                                <path
                                  d="M37.9354 36.6667C37.9354 37.6792 37.1146 38.5 36.102 38.5H10.4354C9.42287 38.5 8.60205 37.6792 8.60205 36.6667V20.1667H3.10205L22.0354 2.95444C22.7346 2.31873 23.8027 2.31873 24.502 2.95444L43.4354 20.1667H37.9354V36.6667ZM23.2687 27.5C25.8 27.5 27.852 25.448 27.852 22.9167C27.852 20.3854 25.8 18.3333 23.2687 18.3333C20.7374 18.3333 18.6854 20.3854 18.6854 22.9167C18.6854 25.448 20.7374 27.5 23.2687 27.5Z"
                                  fill="#818181"
                                  fillOpacity="0.5"
                                />
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_868_529">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.77002)"
                                />
                              </clipPath>
                              <clipPath id="clip1_868_529">
                                <rect
                                  width="45"
                                  height="44"
                                  fill="white"
                                  transform="translate(0.77002)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h4>Bedrooms</h4>
                          <h6>4 Bedrooms / 1 Guestroom</h6>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="amenities box-view h-auto">
                    <h3 className="box-title">Amenities</h3>
                    <div className="row">
                      <div className="col-12">
                        <ul>
                          {propertyDetail.amenities?.map((amenity, i) => {
                            if (amenity?.name?.toLowerCase() === 'others') {
                              return;
                            }
                            return (
                              <li key={i}>
                                <div className="d-flex align-items-center">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: amenity.icon,
                                    }}
                                    className="me-1"
                                  ></div>
                                  {amenity.name}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {propertyDetail.p_video_path ? (
                    <div className="property-description box-view h-auto">
                      <h3 className="box-title">Video Tour</h3>
                      <div className="row">
                        <div className="col-12">
                          <iframe
                            width="100%"
                            height="450px"
                            src={
                              propertyDetail.p_video_path
                                ? propertyDetail.p_video_path
                                    .split('/')
                                    [
                                      propertyDetail.p_video_path.split('/')
                                        ?.length - 1
                                    ].split('?v=')[1]
                                  ? `https://www.youtube.com/embed/${
                                      propertyDetail.p_video_path
                                        .split('/')
                                        [
                                          propertyDetail.p_video_path.split('/')
                                            ?.length - 1
                                        ].split('?v=')[1]
                                    }`
                                  : `https://www.youtube.com/embed/${
                                      propertyDetail.p_video_path?.split('/')[
                                        propertyDetail.p_video_path?.split('/')
                                          ?.length - 1
                                      ]
                                    }`
                                : ''
                            }
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="property-reviews box-view h-auto">
                    <div className="row">
                      <div className="col-6">
                        <h3 className="box-title">{reviewTitle}</h3>
                      </div>
                      <div className="col-6">
                        <ul className="nav nav-pills border-1 rounded-3 float-end p-1">
                          <li className="nav-item">
                            <a
                              className="btn border-0 active"
                              data-bs-toggle="tab"
                              href="#AllReviews"
                              onClick={() =>
                                setReviewTitle(
                                  () =>
                                    `${propertyDetail.review.length} Reviews`
                                )
                              }
                            >
                              All Reviews
                            </a>
                          </li>
                          {loggedUser?._id !== propertyDetail.user_id ? (
                            <li className="nav-item">
                              <a
                                className="btn"
                                data-bs-toggle="tab"
                                href="#WriteReview"
                                onClick={() =>
                                  setReviewTitle(() => `Write a review`)
                                }
                              >
                                Write a review
                              </a>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="tab-content">
                          <div
                            id="AllReviews"
                            className="container tab-pane active"
                          >
                            {propertyDetail.review?.map((reviewDetail, i) => (
                              <div className="d-flex mt-3" key={i}>
                                <div className="review-user-img">
                                  <img
                                    src={landlordimg}
                                    alt="landlord"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="ms-3">
                                  <div className="flex">
                                    <h6>{reviewDetail.reviewerName}</h6>
                                    <div
                                      style={{
                                        marginLeft: '10px',
                                        paddingTop: '0px',
                                        marginTop: '0px',
                                      }}
                                    >
                                      <Rating
                                        initialValue={reviewDetail.rating}
                                        // onPointerEnter={()=>{}}
                                        // onPointerLeave={()=>{}}
                                        // onPointerMove={handleRating}
                                        SVGstyle={{
                                          display: 'inline',
                                          height: '15px',
                                          width: '15px',
                                        }}
                                        readonly={true}
                                      />
                                    </div>
                                  </div>
                                  <p>{reviewDetail.content}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div
                            id="WriteReview"
                            className="container tab-pane fade"
                          >
                            {isReviewAdded?.length > 0 ? (
                              <div>
                                You have Already Written Review For This
                                Property
                              </div>
                            ) : (
                              <Formik
                                initialValues={{
                                  propertyId: '',
                                  userId: '',
                                  reviewerName: '',
                                  rating: 0,
                                  title: '',
                                  content: '',
                                }}
                                // validationSchema={CreateLisingSchema}
                                onSubmit={(values) => {
                                  const user = JSON.parse(
                                    localStorage.getItem('user') ?? ''
                                  );
                                  const data = {
                                    propertyId:
                                      currentPropertyId ?? values.propertyId,
                                    userId: user._id ?? values.userId,
                                    reviewerName: `${user.firstName} ${user.lastName}`,
                                    rating: values.rating,
                                    title: values.title,
                                    content: values.content,
                                  };

                                  dispatch(
                                    addReviewsProperty({
                                      body: data,
                                    }) as unknown as UnknownAction
                                  );
                                }}
                              >
                                {() => (
                                  <div className="font-body">
                                    <Form>
                                      <div className="mb-3 flex">
                                        <Field
                                          name="rating"
                                          as={FormikFieldStarRating}
                                        />
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label"
                                        >
                                          Title
                                        </label>
                                        <Field
                                          type="text"
                                          name="title"
                                          id="exampleFormControlInput1"
                                          placeholder=""
                                          autoComplete="off"
                                          className={`mt-2 form-control`}
                                        />
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="exampleFormControlTextarea1"
                                          className="form-label"
                                        >
                                          Reviews
                                        </label>
                                        <Field
                                          type="text"
                                          name="content"
                                          id="exampleFormControlTextarea1"
                                          placeholder=""
                                          autoComplete="off"
                                          className={`mt-2 form-control`}
                                          as={FormikFieldTextArea}
                                        />
                                      </div>

                                      <button
                                        className="btn btn-primary text-white"
                                        type="submit"
                                      >
                                        Submit Review
                                      </button>
                                    </Form>
                                  </div>
                                )}
                              </Formik>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="details-sidebar">
                    <div className="contact-landlord box-view h-auto">
                      <div className="landlord-img">
                        <img
                          src={landlord?.profileImage || landlordimg}
                          alt="landlord Image"
                          className="img-fluid"
                        />
                      </div>
                      {propertyDetail?.isLandLordVerified && (
                        <span className="verified-status active">
                          <img
                            src={veriafied}
                            alt="Veriafied Image"
                            className="img-fluid"
                          />
                          {/* VERIAFIED
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="#5f6368"
                          className='verified'
                        >
                          <path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z" />
                        </svg>
                        <svg className='unverified' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m336-293.85 144-144 144 144L666.15-336l-144-144 144-144L624-666.15l-144 144-144-144L293.85-624l144 144-144 144L336-293.85ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                        </span>
                      )}

                      <h4 className="landlord-name">
                        {propertyDetail.LandlordName}
                        <br></br>
                        <span>Landlord</span>
                        <br />
                        <Rating
                          initialValue={landlordRating}
                          // onPointerEnter={()=>{}}
                          // onPointerLeave={()=>{}}
                          // onPointerMove={handleRating}
                          SVGstyle={{
                            display: 'inline',
                            height: '15px',
                            width: '15px',
                          }}
                          readonly={true}
                        />
                      </h4>
                      <form onSubmit={handleChatSubmit}>
                        <div className="mb-3">
                          <label htmlFor="Name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="Name"
                            value={chatData.Name}
                            onChange={handleChatInputChange}
                            required
                            disabled={
                              loggedUser?._id === propertyDetail.user_id
                            }
                          />
                        </div>
                        {
                          <div className="mb-3">
                            <label htmlFor="Phone">Phone</label>
                            {/* <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="Phone"
                            value={chatData.Phone}
                            onChange={handleChatInputChange}
                            required
                            disabled={
                              loggedUser?._id === propertyDetail.user_id
                            }
                          /> */}
                            <Input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="Phone"
                              required={hasPermission('tenant') ? true : false}
                              value={phoneValue}
                              onChange={setPhoneValue}
                              defaultCountry={'US'}
                              countries={['US']}
                              disabled={
                                loggedUser?._id === propertyDetail.user_id
                              }
                            />
                          </div>
                        }
                        <div className="mb-3">
                          <label htmlFor="Email">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                            name="Email"
                            value={chatData.Email}
                            onChange={handleChatInputChange}
                            required
                            disabled={
                              loggedUser?._id === propertyDetail.user_id
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="Message">Message</label>
                          <textarea
                            className="form-control"
                            placeholder=""
                            name="Message"
                            value={chatData.Message}
                            onChange={handleChatInputChange}
                            required
                            disabled={
                              loggedUser?._id === propertyDetail.user_id
                            }
                          ></textarea>
                        </div>
                        {loggedUser?._id !== propertyDetail.user_id ? (
                          <button type="submit" className="btn btn-primary">
                            Contact Landlord
                          </button>
                        ) : (
                          <></>
                        )}
                      </form>
                      <button
                        onClick={() => {
                          handleShowProfile();
                        }}
                        className="btn btn-primary mt-3"
                      >
                        See Landlord Profile
                      </button>
                    </div>

                    <div className="box-view h-auto">
                      <h3 className="box-title">Property Fees</h3>
                      <div className="property-describe">
                        <span>
                          Deposit (Refundable)
                          <span className="ms-auto">
                            ${selectedRoom?.depositMoney}
                          </span>
                        </span>
                        <span>
                          Cleaning Fee
                          <span className="ms-auto">
                            ${selectedRoom?.cleaningCharges}
                          </span>
                        </span>
                        <span>
                          Pet Fee
                          <span className="ms-auto">
                            ${selectedRoom?.petFees}
                          </span>
                        </span>
                      </div>
                      {/* <button type="submit" className="btn btn-primary mt-3">
                        Subscribe
                      </button> */}
                    </div>

                    <div className="box-view h-auto">
                      <h3 className="box-title">Property Details</h3>
                      <div className="property-describe mt-0">
                        <span>
                          Price
                          <span className="ms-auto">
                            ${selectedRoom?.price}
                          </span>
                        </span>
                        <span>
                          Property Type
                          <span className="ms-auto text-capitalize">
                            {propertyDetail.p_type}
                          </span>
                        </span>
                        <span>
                          Property Status
                          <span className="ms-auto">
                            {propertyDetail.is_active ? 'Active' : 'Inactive'}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="box-view h-auto">
                      <h3 className="box-title mb-0">
                        Near by{' '}
                        <span
                          className="ms-auto"
                          style={{ float: 'right', fontSize: '15px' }}
                        >
                          Miles
                        </span>
                      </h3>

                      {propertyDetail.nearby_facilities?.map((nearBy, i) => {
                        return (
                          <div key={i} className="property-describe mt-3">
                            <h4>{nearBy.name}</h4>
                            <div className="d-flex">
                              <span className="me-3">{nearBy.description}</span>
                              <span className="ms-auto">{nearBy.distance}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="box-view h-auto">
                      <h3 className="box-title">Calender</h3>
                      {/* <div
                        className="mt-2"
                        style={{ height: '100%', width: '100%' }}
                        onClick={() => {
                          props.calenderToggleHandler();
                        }}
                      >
                        <CustomCalendar events={events} />
                      </div> */}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          props.calenderToggleHandler();
                        }}
                      >
                        Show Calender
                      </button>
                    </div>

                    {hasPermission('admin') ? (
                      <div className="box-view h-auto">
                        <h3 className="box-title">Property Documents</h3>
                        <span>
                          {propertyDetail.docsName
                            ? propertyDetail.docsName
                            : 'Document Not Available'}
                          <svg
                            height="20"
                            viewBox="0 0 24 24"
                            width="20"
                            onClick={() => props.documentToggleHandler()}
                            className="cursor-pointer"
                          >
                            <path d="m10 3v2h-5v14h14v-5h2v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-16c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071v-16c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289zm7.586 2h-4.586v-2h8v8h-2v-4.586l-7 7-1.414-1.414z" />
                          </svg>
                        </span>
                        {!propertyDetail.isApproved ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginTop: '15px' }}
                            onClick={() => handleAprroveProperty()}
                          >
                            Approve
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleAprroveProperty()}
                          >
                            Reject
                          </button>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentPopupHOC(
  SignUpPopupHOC(SignInPopupHOC(CalenderPopupHOC(PropertyDetail)))
);
