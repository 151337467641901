import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../../redux/store';
import {
  fetchUserById,
  authUserState,
  recieveEmailVerification,
  resetVerifyEmail,
  setUser,
  verifyEmail,
} from '../../../redux/slices/UserSlice';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import './EmailVerify.scss';
import { loggedInUserState, setUserData } from '../../../redux/slices/LoginSlice';
import Loader from '../../CommonComponents/Loader/Loader';

const EmailVerify = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const verify = useSelector(verifyEmail);
  const [successStatus, setSuccessStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [statusAvailable, setStatusAvailable] = useState(false);
  const userData = useSelector(loggedInUserState);
  const loggedInUser = useSelector(authUserState);

  useEffect(() => {
    if (userData?._id && successStatus) {
      dispatch(setUserData({ ...userData, isEmailVerified: true }));
      dispatch(setUser({ ...loggedInUser, isEmailVerified: true }));
    }
  }, [successStatus]);

  useEffect(() => {
    if (params && params.emailId) {
      dispatch(recieveEmailVerification({ id: params.emailId }));
    }
  }, []);

  const timeOut = () => {
    setTimeout(() => {
      navigate('/');
    }, 3000);
  };

  useEffect(() => {
    if (verify.success) {
      showToastMessage('Email verified successfully', ToastType.Success);
      dispatch(resetVerifyEmail());
      timeOut();
      setStatusAvailable(true);
      setSuccessStatus(true);
    }
    if (verify.error?.length > 0) {
      showToastMessage('Verification link expired! Try again', ToastType.Error);
      dispatch(resetVerifyEmail());
      timeOut();
      setErrorStatus(true);
      setStatusAvailable(true);
    }
  }, [verify]);

  return (
    <div
      className={`alert-box shadow-sm text-center bg-white p-5 rounded-3 border 
        ${successStatus && statusAvailable ? 'success' : ''} 
        ${errorStatus && statusAvailable ? 'failed' : ''}`}
    >
      {/* <div className={`message-box ${successStatus ? '_success' : '_success _failed'}`}> */}
      {!statusAvailable ? (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient
              id="a11"
              cx=".66"
              fx=".66"
              cy=".3125"
              fy=".3125"
              gradientTransform="scale(1.5)"
            >
              <stop offset="0" stopColor="#0B3C6E"></stop>
              <stop offset=".3" stopColor="#0B3C6E" stopOpacity=".9"></stop>
              <stop offset=".6" stopColor="#0B3C6E" stopOpacity=".6"></stop>
              <stop offset=".8" stopColor="#0B3C6E" stopOpacity=".3"></stop>
              <stop offset="1" stopColor="#0B3C6E" stopOpacity="0"></stop>
            </radialGradient>
            <circle
              transform-origin="center"
              fill="none"
              stroke="url(#a11)"
              strokeWidth="10"
              strokeLinecap="round"
              strokeDasharray="200 1000"
              stroke-dashoffset="0"
              cx="100"
              cy="100"
              r="70"
            >
              <animateTransform
                type="rotate"
                attributeName="transform"
                calcMode="spline"
                dur="1.1"
                values="360;0"
                keyTimes="0;1"
                keySplines="0 0 1 1"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              transform-origin="center"
              fill="none"
              opacity=".2"
              stroke="#0B3C6E"
              strokeWidth="10"
              strokeLinecap="round"
              cx="100"
              cy="100"
              r="70"
            ></circle>
          </svg>
          <h5>Pending... </h5>
        </>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path d="M74.62-140 480-840l405.38 700H74.62ZM178-200h604L480-720 178-200Zm302-47.69q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02T480-312.31q-13.73 0-23.02 9.29T447.69-280q0 13.73 9.29 23.02t23.02 9.29Zm-30-104.62h60v-200h-60v200ZM480-460Z" />
          </svg>
          {errorStatus && <h5>Verification failed! </h5>}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z" />
          </svg>
          {successStatus && <h5>Verified </h5>}
        </>
      )}
      {/* </div> */}
    </div>
  );
};

export default EmailVerify;
