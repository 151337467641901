import React, { useRef } from 'react';
import { FieldProps, useField } from 'formik';

interface PlaceFieldProps extends FieldProps {
    description: string;
}
interface IProps {
    description: string;
    name: string;
    onBlur: () => void;
    onChange: () => void;
    placeholder: string;
    value: string;
    disabled: boolean;
    readOnly: boolean;
    maxLength: number;
    shouldnotshowerror: boolean;
    isnegativeallowed: boolean;
    isnegativeanddotallowed: boolean;
    min: number;
    max: number;
    defaultvaluecondition: boolean;
    initialvalue: number;
    show_error_on_tooltip?: boolean;
    type: string;
    autoComplete: string;
    setFieldValue: any;
}
const FormikFieldTextArea: React.FC<IProps> = ({
    description,
    ...props
}: IProps) => {
    const [field, meta, helpers] = useField(props);

    return (
        <div>
            <textarea
                {...field}
                {...props}
            >
            </textarea>
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};
export default FormikFieldTextArea;
