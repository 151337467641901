import axios, { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';

interface IState {
  isLoading: boolean;
  error: string | undefined;
  success: boolean;
  data: any;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: undefined,
  success: false,
};

export const PackageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPackages.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getAllPackages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.data = action.payload;
      state.error = '';
    });
    builder.addCase(getAllPackages.rejected, (state, action: any) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error.message;
    });
  },
});

export const {} = PackageSlice.actions;

export default PackageSlice.reducer;

export const packageState = (state: any) => state.packages;

export const getAllPackages = createAsyncThunk<any, any>(
  'getAllPackages',
  async () => {
    const response = await axiosCall.get(`/packages`);
    return response?.data;
  }
);
