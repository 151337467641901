import React, { useEffect, useRef, useState } from 'react';
import ShowPdfHOC from '../../../utils/PDFViewer/hoc/ShowPdfHOC';
import PdfPopup from '../../../utils/PDFViewer/hoc/PdfPopup';
import { ReactComponent as PdfSvg } from '../../../assets/images/pdf-svg.svg';
import './AddMediaComponent.scss';
import { FaEye } from 'react-icons/fa';
import { PdfPreviewType } from '../../../constants/previewType';
import { decodeEncoded, getDocNameFromUrl } from '../../../utils/common';
import { ListingSection } from '../../../constants/ListingSection';
import DisableSection from '../../../utils/DisableSection/DisableSection';
import { BsPencilFill } from 'react-icons/bs';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { resetpropertyState } from '../../../redux/slices/PropertySlice';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { setFormDirty } from '../../../redux/slices/LocationSlice';

function AddMediaComponent({
  isEdit,
  lastUploadedFilePath,
  isDocUpdate,
  showPdfHandler,
  showPdfPopup,
  currentSection,
  setCurrentSection,
  handleSubmit,
  savedSection
}: {
  isEdit: boolean;
  lastUploadedFilePath?: string;
  isDocUpdate: any;
  showPdfHandler?: any;
  showPdfPopup?: any;
  currentSection: ListingSection;
  setCurrentSection: any;
  handleSubmit: (formData: FormData) => void;
  savedSection?: any;
}) {
  const [isUpdate, setIsUpdate] = useState<boolean>();
  const [showError, setShowError] = useState(false);
  const [preview, setPreview] = useState<PdfPreviewType>(PdfPreviewType.File);
  const [files, setFiles] = useState<any>([]);
  const propertyState = useSelector((state: any) => state.property);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (propertyState.success) {
      dispatch(resetpropertyState());
      setFiles([]);
      setIsUpdate(false);
    }
  }, [propertyState.success]);

  useEffect(() => {
    if (files.length > 0) {
      dispatch(setFormDirty(true));
      setShowError(false);
    }
    else{
      dispatch(setFormDirty(false));
    }
  }, [files]);

  const submitHandler = () => {
    if (files.length > 0) {
      let newProperty: any = {
        section: currentSection,
      };
      const formData = new FormData();
      // Create Form
      createMultipartFormData(newProperty, formData);
      for (let i = 0; i < files?.length; i++) {
        formData.append(`documents/doc1`, files?.[i]);
      }
      dispatch(setFormDirty(false));
      handleSubmit(formData);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (currentSection !== ListingSection.Documents) {
      setShowError(false);
    }
  }, [currentSection]);

  return (
    <>
      {showPdfPopup && (
        <PdfPopup
          url={lastUploadedFilePath ? lastUploadedFilePath[0] : ''}
          showMe={showPdfHandler}
          file={files[0]}
          previewType={preview}
        />
      )}
      <div id="documentsTab">
        {currentSection !== ListingSection.Documents && <DisableSection />}
        <div className="box-view">
          <h3 className="box-title">Property Documents</h3>
          {(isEdit || savedSection[ListingSection.Documents]) && (
            <div title="Edit" className="edit-property">
              {currentSection !== ListingSection.Documents && (
                <button
                  type="button"
                  className="edit-btn-listing"
                  onClick={() => {
                    setCurrentSection(ListingSection.Documents);
                  }}
                >
                  <BsPencilFill className="pencil_icon" />
                </button>
              )}
              {currentSection === ListingSection.Documents && (
                <button
                  type="button"
                  className="edit-btn-listing"
                  onClick={() => {
                    setCurrentSection(-1);
                  }}
                >
                  <IoCloseCircleOutline className="cancel_icon" />
                </button>
              )}
            </div>
          )}
          {isEdit && (
            <>
              <div className="mb-3">
                <label>
                  <input
                    type="checkbox"
                    checked={isUpdate}
                    name="isUpdate"
                    onChange={() => {
                      setIsUpdate(!isUpdate);
                      isDocUpdate.current = !isUpdate;
                    }}
                  />{' '}
                  Would you like to update existing document.
                </label>
                <br />
                {lastUploadedFilePath && lastUploadedFilePath?.length > 0 && (
                  <span
                    onClick={() => {
                      setPreview(PdfPreviewType.Url);
                      showPdfHandler();
                    }}
                    className="show_pdf_doc"
                  >
                    <PdfSvg />{' '}
                    {decodeEncoded(
                      getDocNameFromUrl(
                        lastUploadedFilePath ? lastUploadedFilePath[0] : ''
                      )
                    )}
                  </span>
                )}
              </div>
            </>
          )}
          {(!isEdit || (isEdit && isUpdate)) && (
            <>
              <div className="upload-pdf">
                <input
                  type="file"
                  id="pdf-upload"
                  onChange={(e) => {
                    setFiles(() => e.target.files);
                  }}
                  // value={files.length === 0 ? ""}
                  accept=".pdf"
                />
                <label htmlFor="pdf-upload">
                  <PdfSvg />{' '}
                  {files && files.length > 0
                    ? files[0].name
                    : 'Upload documnet'}
                </label>
                {files && files.length > 0 && (
                  <span>
                    <FaEye
                      onClick={() => {
                        setPreview(PdfPreviewType.File);
                        showPdfHandler();
                      }}
                      className="eye_icon"
                    />
                  </span>
                )}
              </div>
            </>
          )}
          {showError && (
            <div className="invalid-feedback">Please provide document.</div>
          )}
          <div className="row mb-3 mt-4">
            {isEdit && currentSection === ListingSection.Documents && (
              <div className="col-12 text-center">
                <a
                  href="#nearByTab"
                  className="btn btn-outline-primary me-2"
                  onClick={() => setCurrentSection(ListingSection.NearBy)}
                >
                  Previous
                </a>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={submitHandler}
                  // disabled={props?.isSubmitting}
                >
                  {/* {props?.isSubmitting ? 'Submitting...' : 'Submit'} */}
                  Update
                </button>
              </div>
            )}
            {!isEdit && currentSection === ListingSection.Documents && (
              <div className="col-12 text-center">
                <a
                  href="#nearByTab"
                  className="btn btn-outline-primary me-2"
                  onClick={() => setCurrentSection(ListingSection.NearBy)}
                >
                  Previous
                </a>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={submitHandler}
                  // disabled={props?.isSubmitting}
                >
                  {/* {props?.isSubmitting ? 'Submitting...' : 'Submit'} */}
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowPdfHOC(AddMediaComponent);
