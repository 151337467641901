import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';
import axios, { AxiosError } from 'axios';

export interface ILicenseType {
  id: number;
  name: string;
}

const initialState = {
  addLicenseDetail: {
    loading: false,
    error: '',
    data: {
      user_id: '',
      license_type: -1,
      license_state: '',
      license_number: '',
      expiration_date: '',
      first_name: '',
      last_name: '',
      license_document_url: '',
      docLink: '',
    },
    success: false,
  },
  getLicenseDetail: {
    loading: false,
    error: '',
    success: false,
    isEdit: false,
    id: '',
  },
  deleteLicenseDetail: {
    loading: false,
    error: '',
    success: false,
  },
  licenseTypeDetail: {
    loading: false,
    error: '',
    data: [],
    success: false,
  },
  getAllLicenseDetails: {
    loading: false,
    error: '',
    data: [],
    success: false,
  },
  addCertificateDetail: {
    loading: false,
    error: '',
    data: {
      certification_type: '',
      issuing_body: '',
      expiration_date: '',
      document_url: '',
    },
    success: false,
  },
  getCertificateDetail: {
    loading: false,
    error: '',
    success: false,
    isEdit: false,
    id: '',
  },
  deleteCertificateDetail: {
    loading: false,
    error: '',
    success: false,
  },
  getAllCertificateDetails: {
    loading: false,
    error: '',
    data: [],
    success: false,
  },
  addCeusDetail: {
    loading: false,
    error: '',
    data: {
      state: '',
      total: '',
      required: '',
      year: '',
      document_url: '',
    },
    success: false,
  },
  getCeusDetail: {
    loading: false,
    error: '',
    success: false,
    isEdit: false,
    id: '',
  },
  deleteCeusDetail: {
    loading: false,
    error: '',
    success: false,
  },
  getAllCeusDetails: {
    loading: false,
    error: '',
    data: [],
    success: false,
  },
};

export const DocumnentSlice = createSlice({
  name: 'Document',
  initialState,
  reducers: {
    resetAddLicenseSuccess: (state, action) => {
      state.addLicenseDetail.loading = false;
      state.addLicenseDetail.data = initialState.addLicenseDetail.data;
      state.addLicenseDetail.success = false;
      state.addLicenseDetail.error = '';
    },
    resetAddLicenseError: (state, action) => {
      state.addLicenseDetail.loading = false;
      state.addLicenseDetail.success = false;
      state.addLicenseDetail.error = '';
    },
    resetGetLicense: (state, action) => {
      state.getAllLicenseDetails.loading = false;
      state.getAllLicenseDetails.success = false;
      state.getAllLicenseDetails.error = '';
    },
    resetGetLicenseDetail: (state) => {
      state.getLicenseDetail.loading = false;
      state.getLicenseDetail.success = false;
      state.getLicenseDetail.error = '';
    },
    resetDeleteLicenseDetail: (state) => {
      state.deleteLicenseDetail.loading = false;
      state.deleteLicenseDetail.success = false;
      state.deleteLicenseDetail.error = '';
    },
    resetGetLicenseFilledDetail: (state) => {
      state.getLicenseDetail.isEdit = false;
      state.getLicenseDetail.id = '';
      state.addLicenseDetail.data = initialState.addLicenseDetail.data;
    },
    resetAddCertificateSuccess: (state) => {
      state.addCertificateDetail.loading = false;
      state.addCertificateDetail.data = initialState.addCertificateDetail.data;
      state.addCertificateDetail.success = false;
      state.addCertificateDetail.error = '';
    },
    resetAddCertificateError: (state) => {
      state.addCertificateDetail.loading = false;
      state.addCertificateDetail.success = false;
      state.addCertificateDetail.error = '';
    },
    resetGetCertificate: (state) => {
      state.getAllCertificateDetails.loading = false;
      state.getAllCertificateDetails.success = false;
      state.getAllCertificateDetails.error = '';
    },
    resetGetCertificateDetail: (state) => {
      state.getCertificateDetail.loading = false;
      state.getCertificateDetail.success = false;
      state.getCertificateDetail.error = '';
    },
    resetDeleteCertificateDetail: (state) => {
      state.deleteCertificateDetail.loading = false;
      state.deleteCertificateDetail.success = false;
      state.deleteCertificateDetail.error = '';
    },
    resetGetCertificateFilledDetail: (state) => {
      state.getCertificateDetail.isEdit = false;
      state.getCertificateDetail.id = '';
      state.addCertificateDetail.data = initialState.addCertificateDetail.data;
    },

    resetAddCeusSuccess: (state) => {
      state.addCeusDetail.loading = false;
      state.addCeusDetail.data = initialState.addCeusDetail.data;
      state.addCeusDetail.success = false;
      state.addCeusDetail.error = '';
    },
    resetAddCeusError: (state) => {
      state.addCeusDetail.loading = false;
      state.addCeusDetail.success = false;
      state.addCeusDetail.error = '';
    },
    resetGetCeus: (state) => {
      state.getAllCeusDetails.loading = false;
      state.getAllCeusDetails.success = false;
      state.getAllCeusDetails.error = '';
    },
    resetGetCeusDetail: (state) => {
      state.getCeusDetail.loading = false;
      state.getCeusDetail.success = false;
      state.getCeusDetail.error = '';
    },
    resetDeleteCeusDetail: (state) => {
      state.deleteCeusDetail.loading = false;
      state.deleteCeusDetail.success = false;
      state.deleteCeusDetail.error = '';
    },
    resetGetCeusFilledDetail: (state) => {
      state.getCeusDetail.isEdit = false;
      state.getCeusDetail.id = '';
      state.addCeusDetail.data = initialState.addCeusDetail.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addLicense.pending, (state) => {
      state.addLicenseDetail.loading = true;
      state.addLicenseDetail.error = '';
    });
    builder.addCase(addLicense.fulfilled, (state, action) => {
      state.addLicenseDetail.loading = false;
      state.addLicenseDetail.data = action.payload;
      state.addLicenseDetail.success = true;
      state.addLicenseDetail.error = '';
    });
    builder.addCase(addLicense.rejected, (state, action) => {
      state.addLicenseDetail.loading = false;
      state.addLicenseDetail.error = action.error.message ?? '';
      state.addLicenseDetail.success = false;
    });
    builder.addCase(updateLicense.pending, (state) => {
      state.addLicenseDetail.loading = true;
      state.addLicenseDetail.error = '';
    });
    builder.addCase(updateLicense.fulfilled, (state, action) => {
      state.addLicenseDetail.loading = false;
      state.addLicenseDetail.data = { ...action.payload };
      state.addLicenseDetail.success = true;
      state.addLicenseDetail.error = '';
    });
    builder.addCase(updateLicense.rejected, (state, action) => {
      state.addLicenseDetail.loading = false;
      state.addLicenseDetail.error = action.error.message ?? '';
      state.addLicenseDetail.success = false;
    });
    builder.addCase(getLicenseById.pending, (state) => {
      state.getLicenseDetail.loading = true;
      state.getLicenseDetail.error = '';
      state.getLicenseDetail.isEdit = false;
    });
    builder.addCase(getLicenseById.fulfilled, (state, action) => {
      state.getLicenseDetail.loading = false;
      state.addLicenseDetail.data = action.payload;
      state.getLicenseDetail.success = true;
      state.getLicenseDetail.error = '';
      state.getLicenseDetail.isEdit = true;
      state.getLicenseDetail.id = action.payload?._id;
    });
    builder.addCase(getLicenseById.rejected, (state, action) => {
      state.getLicenseDetail.loading = false;
      state.getLicenseDetail.error = action.error.message ?? '';
      state.getLicenseDetail.success = false;
      state.getLicenseDetail.isEdit = false;
    });
    builder.addCase(deleteLicenseById.pending, (state) => {
      state.deleteLicenseDetail.loading = true;
      state.deleteLicenseDetail.error = '';
    });
    builder.addCase(deleteLicenseById.fulfilled, (state, action) => {
      state.deleteLicenseDetail.loading = false;
      state.deleteLicenseDetail.success = true;
      state.deleteLicenseDetail.error = '';
    });
    builder.addCase(deleteLicenseById.rejected, (state, action: any) => {
      state.deleteLicenseDetail.loading = false;
      state.deleteLicenseDetail.error = action?.payload?.error ?? '';
      state.deleteLicenseDetail.success = false;
    });
    builder.addCase(getAllLicense.pending, (state) => {
      state.getAllLicenseDetails.loading = true;
      state.getAllLicenseDetails.error = '';
    });
    builder.addCase(getAllLicense.fulfilled, (state, action) => {
      state.getAllLicenseDetails.loading = false;
      state.getAllLicenseDetails.data = action.payload;
      state.getAllLicenseDetails.success = true;
      state.getAllLicenseDetails.error = '';
    });
    builder.addCase(getAllLicense.rejected, (state, action) => {
      state.getAllLicenseDetails.loading = false;
      state.getAllLicenseDetails.error = action.error.message ?? '';
      state.getAllLicenseDetails.success = false;
    });
    builder.addCase(addCertificate.pending, (state) => {
      state.addCertificateDetail.loading = true;
      state.addCertificateDetail.error = '';
    });
    builder.addCase(addCertificate.fulfilled, (state, action) => {
      state.addCertificateDetail.loading = false;
      state.addCertificateDetail.data = action.payload;
      state.addCertificateDetail.success = true;
      state.addCertificateDetail.error = '';
    });
    builder.addCase(addCertificate.rejected, (state, action) => {
      state.addCertificateDetail.loading = false;
      state.addCertificateDetail.error = action.error.message ?? '';
      state.addCertificateDetail.success = false;
    });
    builder.addCase(updateCertificate.pending, (state) => {
      state.addCertificateDetail.loading = true;
      state.addCertificateDetail.error = '';
    });
    builder.addCase(updateCertificate.fulfilled, (state) => {
      state.addCertificateDetail.loading = false;
      state.addCertificateDetail.success = true;
      state.addCertificateDetail.error = '';
    });
    builder.addCase(updateCertificate.rejected, (state, action) => {
      state.addCertificateDetail.loading = false;
      state.addCertificateDetail.error = action.error.message ?? '';
      state.addCertificateDetail.success = false;
    });
    builder.addCase(getCertificateById.pending, (state) => {
      state.getCertificateDetail.loading = true;
      state.getCertificateDetail.error = '';
      state.getCertificateDetail.isEdit = false;
    });
    builder.addCase(getCertificateById.fulfilled, (state, action) => {
      state.getCertificateDetail.loading = false;
      state.addCertificateDetail.data = action.payload;
      state.getCertificateDetail.success = true;
      state.getCertificateDetail.error = '';
      state.getCertificateDetail.isEdit = true;
      state.getCertificateDetail.id = action.payload?._id;
    });
    builder.addCase(getCertificateById.rejected, (state, action) => {
      state.getCertificateDetail.loading = false;
      state.getCertificateDetail.error = action.error.message ?? '';
      state.getCertificateDetail.success = false;
      state.getCertificateDetail.isEdit = false;
    });
    builder.addCase(deleteCertificateById.pending, (state) => {
      state.deleteCertificateDetail.loading = true;
      state.deleteCertificateDetail.error = '';
    });
    builder.addCase(deleteCertificateById.fulfilled, (state, action) => {
      state.deleteCertificateDetail.loading = false;
      state.deleteCertificateDetail.success = true;
      state.deleteCertificateDetail.error = '';
    });
    builder.addCase(deleteCertificateById.rejected, (state, action: any) => {
      state.deleteCertificateDetail.loading = false;
      state.deleteCertificateDetail.error = action?.payload?.error ?? '';
      state.deleteCertificateDetail.success = false;
    });
    builder.addCase(getAllCertificates.pending, (state) => {
      state.getAllCertificateDetails.loading = true;
      state.getAllCertificateDetails.error = '';
    });
    builder.addCase(getAllCertificates.fulfilled, (state, action) => {
      state.getAllCertificateDetails.loading = false;
      state.getAllCertificateDetails.data = action.payload;
      state.getAllCertificateDetails.success = true;
      state.getAllCertificateDetails.error = '';
    });
    builder.addCase(getAllCertificates.rejected, (state, action) => {
      state.getAllCertificateDetails.loading = false;
      state.getAllCertificateDetails.error = action.error.message ?? '';
      state.getAllCertificateDetails.success = false;
    });

    builder.addCase(addCeus.pending, (state) => {
      state.addCeusDetail.loading = true;
      state.addCeusDetail.error = '';
    });
    builder.addCase(addCeus.fulfilled, (state, action) => {
      state.addCeusDetail.loading = false;
      state.addCeusDetail.data = action.payload;
      state.addCeusDetail.success = true;
      state.addCeusDetail.error = '';
    });
    builder.addCase(addCeus.rejected, (state, action) => {
      state.addCeusDetail.loading = false;
      state.addCeusDetail.error = action.error.message ?? '';
      state.addCeusDetail.success = false;
    });
    builder.addCase(updateCeus.pending, (state) => {
      state.addCeusDetail.loading = true;
      state.addCeusDetail.error = '';
    });
    builder.addCase(updateCeus.fulfilled, (state) => {
      state.addCeusDetail.loading = false;
      state.addCeusDetail.success = true;
      state.addCeusDetail.error = '';
    });
    builder.addCase(updateCeus.rejected, (state, action) => {
      state.addCeusDetail.loading = false;
      state.addCeusDetail.error = action.error.message ?? '';
      state.addCeusDetail.success = false;
    });
    builder.addCase(getCeusById.pending, (state) => {
      state.getCeusDetail.loading = true;
      state.getCeusDetail.error = '';
      state.getCeusDetail.isEdit = false;
    });
    builder.addCase(getCeusById.fulfilled, (state, action) => {
      state.getCeusDetail.loading = false;
      state.addCeusDetail.data = action.payload;
      state.getCeusDetail.success = true;
      state.getCeusDetail.error = '';
      state.getCeusDetail.isEdit = true;
      state.getCeusDetail.id = action.payload?._id;
    });
    builder.addCase(getCeusById.rejected, (state, action) => {
      state.getCeusDetail.loading = false;
      state.getCeusDetail.error = action.error.message ?? '';
      state.getCeusDetail.success = false;
      state.getCeusDetail.isEdit = false;
    });
    builder.addCase(deleteCeusById.pending, (state) => {
      state.deleteCeusDetail.loading = true;
      state.deleteCeusDetail.error = '';
    });
    builder.addCase(deleteCeusById.fulfilled, (state, action) => {
      state.deleteCeusDetail.loading = false;
      state.deleteCeusDetail.success = true;
      state.deleteCeusDetail.error = '';
    });
    builder.addCase(deleteCeusById.rejected, (state, action: any) => {
      state.deleteCeusDetail.loading = false;
      state.deleteCeusDetail.error = action?.payload?.error ?? '';
      state.deleteCeusDetail.success = false;
    });
    builder.addCase(getAllCeus.pending, (state) => {
      state.getAllCeusDetails.loading = true;
      state.getAllCeusDetails.error = '';
    });
    builder.addCase(getAllCeus.fulfilled, (state, action) => {
      state.getAllCeusDetails.loading = false;
      state.getAllCeusDetails.data = action.payload;
      state.getAllCeusDetails.success = true;
      state.getAllCeusDetails.error = '';
    });
    builder.addCase(getAllCeus.rejected, (state, action) => {
      state.getAllCeusDetails.loading = false;
      state.getAllCeusDetails.error = action.error.message ?? '';
      state.getAllCeusDetails.success = false;
    });
  },
});

export const {
  resetAddCeusError,
  resetAddCeusSuccess,
  resetDeleteCeusDetail,
  resetGetCeus,
  resetGetCeusDetail,
  resetGetCeusFilledDetail,
  resetAddCertificateSuccess,
  resetAddCertificateError,
  resetDeleteCertificateDetail,
  resetGetCertificate,
  resetGetCertificateDetail,
  resetGetCertificateFilledDetail,
  resetAddLicenseSuccess,
  resetDeleteLicenseDetail,
  resetGetLicenseFilledDetail,
  resetGetLicenseDetail,
  resetAddLicenseError,
  resetGetLicense,
} = DocumnentSlice.actions;

export default DocumnentSlice.reducer;

export const selectGetLicenseById = (state: any) =>
  state.document.getLicenseDetail;
export const licenseInitialState = (state: any) =>
  state.document.addLicenseDetail.data;
export const deleteLicenseDetail = (state: any) =>
  state.document.deleteLicenseDetail;

export const certificateInitialState = (state: any) =>
  state.document.addCertificateDetail.data;
export const selectCertificateById = (state: any) =>
  state.document.getCertificateDetail;
export const getCertificates = (state: any) =>
  state.document.getAllCertificateDetails;
export const deleteCertificateDetail = (state: any) =>
  state.document.deleteCertificateDetail;

export const ceusInitialState = (state: any) =>
  state.document.addCeusDetail.data;
export const selectCeusById = (state: any) => state.document.getCeusDetail;
export const getCeus = (state: any) => state.document.getAllCeusDetails;
export const deleteCeusDetail = (state: any) => state.document.deleteCeusDetail;

export const fetchLicenseType = createAsyncThunk(
  'fetchLicenseType',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.get(`/document/getLicenseTypes`);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const addLicense = createAsyncThunk(
  'addLicense',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.post(`/document/licenses`, arg.body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const updateLicense = createAsyncThunk(
  'updateLicense',
  async (arg: { id: string; body: any }) => {
    try {
      const response = await axiosCall.put(
        `/document/licenses/${arg.id}`,
        arg.body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const getAllLicense = createAsyncThunk(
  'getAllLicense',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.post(
        `/document/getLicensesByUserId`,
        arg.body
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const getLicenseById = createAsyncThunk(
  'getLicenseById',
  async (arg: { id: string }) => {
    try {
      const response = await axiosCall.get(`/document/licenses/${arg.id}`);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const deleteLicenseById = createAsyncThunk(
  'deleteLicenseById',
  async (arg: { id: string }, { rejectWithValue }) => {
    try {
      const response = await axiosCall.delete(`/document/licenses/${arg.id}`);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return rejectWithValue(errorMessage);
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const addCertificate = createAsyncThunk(
  'addCertificate',
  async (arg: { body: any }) => {
    const response = await axiosCall.post(`/document/certificates`, arg.body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response?.data;
  }
);

export const updateCertificate = createAsyncThunk(
  'updateCertificate',
  async (arg: { id: string; body: any }) => {
    const response = await axiosCall.put(
      `/document/certificates/${arg.id}`,
      arg.body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response?.data;
  }
);

export const getAllCertificates = createAsyncThunk(
  'getAllCertificates',
  async () => {
    const response = await axiosCall.get(`/document/certificates`);
    return response?.data;
  }
);

export const getCertificateById = createAsyncThunk(
  'getCertificateById',
  async (arg: { id: string }) => {
    const response = await axiosCall.get(`/document/certificates/${arg.id}`);
    return response?.data;
  }
);

export const deleteCertificateById = createAsyncThunk(
  'deleteCertificateById',
  async (arg: { id: string }, { rejectWithValue }) => {
    const response = await axiosCall.delete(`/document/certificates/${arg.id}`);
    return response?.data;
  }
);

export const addCeus = createAsyncThunk(
  'addCeus',
  async (arg: { body: any }) => {
    const response = await axiosCall.post(`/document/ceus`, arg.body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response?.data;
  }
);

export const updateCeus = createAsyncThunk(
  'updateCeus',
  async (arg: { id: string; body: any }) => {
    const response = await axiosCall.put(`/document/ceus/${arg.id}`, arg.body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response?.data;
  }
);

export const getAllCeus = createAsyncThunk('getAllCeus', async () => {
  const response = await axiosCall.get(`/document/ceus`);
  return response?.data;
});

export const getCeusById = createAsyncThunk(
  'getCeusById',
  async (arg: { id: string }) => {
    const response = await axiosCall.get(`/document/ceus/${arg.id}`);
    return response?.data;
  }
);

export const deleteCeusById = createAsyncThunk(
  'deleteCeusById',
  async (arg: { id: string }, { rejectWithValue }) => {
    const response = await axiosCall.delete(`/document/ceus/${arg.id}`);
    return response?.data;
  }
);
