import { useNavigate, useParams } from 'react-router-dom';
import './PaymentPage.scss';
// import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch } from '../../../redux/store';
// import { useEffect } from 'react';
// import {
//   resetStatusData,
//   sendPaymentStatus,
// } from '../../../redux/slices/PaymentSlice';

const PaymentFailed = () => {
  // const params = useParams();
  // const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  // const payment = useSelector((state: any) => state.payment);

  // useEffect(() => {
  //   if (params && params?.sessionId) {
  //     dispatch(sendPaymentStatus({ sessionId: params.sessionId }));
  //   }
  // }, [params]);

  // useEffect(() => {
  //   if (payment.success || payment.error) {
  //     dispatch(resetStatusData());
  //   }
  // }, [payment.success, payment.error]);

  return (
    <div className="alert-box failed shadow-sm text-center bg-white p-5 rounded-3 border">
      <svg className='shadow-sm' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-290.77q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm-30-146.15h60v-240h-60v240ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
      <h5>Your payment failed <br></br><span>Try again later</span></h5>
      <button
        className="btn btn-primary"
        onClick={() => {
          navigate('/dashboard');
        }}
      >
        Dashboard
      </button>
    </div>
  );
};

export default PaymentFailed;
