import React, { useEffect, useRef, useState } from 'react';
import './CreateBlog.scss';
import { IoMdArrowDropright } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import axiosCall from '../../../utils/axiosCall';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import DropZone from '../../../components/CommonComponents/DropZone/DropZone';
import Loader from '../../../components/CommonComponents/Loader/Loader';
import DashboardHeader from '../../../components/DashboardComponents/DashboardHeader/DashboardHeader';

const initilaState = {
  title: '',
  image: '',

  status: 'Draft',
  link: '',
};

function CreateBlog() {
  const [inputBlog, setInputBlog] = useState(initilaState);
  const [content, setContent] = useState<string>('');
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state: any) => state.login.userData);
  const navigate = useNavigate();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputBlog((prevSate) => ({
      ...prevSate,
      [e.target.name]: e.target.value,
    }));
  };

  // react quil configs
  const LIST_OF_COLOURS = [
    '#0290D7',
    '#4D4D4D',
    '#999999',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#FFFFFF',
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        {
          color: [...LIST_OF_COLOURS],
        },
        {
          background: [...LIST_OF_COLOURS],
        },
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const handlePublish = async () => {
    // check if we habe title & content in state
    if (
      inputBlog.title.trim()?.length > 0 &&
      content.trim()?.length > 0 &&
      files?.length > 0
    ) {
      // const reqBody = {
      //   title: inputBlog.title,
      //   image: inputBlog.image,
      //   status: inputBlog.status.toLowerCase(),
      //   content: [content],
      //   user_id: userData._id,
      //   link: 'https://example.com/blog/1',
      // };
      setLoading(true);
      const formData = new FormData();
      formData.append('title', inputBlog.title);
      formData.append('image', files?.[0]);
      formData.append('status', inputBlog.status.toLowerCase());
      formData.append('content', content);
      formData.append('user_id', userData._id);
      formData.append('link', 'https://example.com/blog/1');

      try {
        const url = '/blog/create';
        const response = await axiosCall.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 201) {
          showToastMessage('Blog created successfully', ToastType.Success);
          //console.log('Blog created');
          setInputBlog(initilaState);
          setContent('');
          navigate('/dashboard/blog-list');
        }
      } catch (error) {
        showToastMessage('Blog creation failed.', ToastType.Error);
      } finally {
        setLoading(false);
      }
    } else {
      // show error toast
      showToastMessage('Please provide required details', ToastType.Error);
    }
  };

  return (
    <div className="main-content" style={{ height: '100vh' }}>
      <DashboardHeader />
      <div className="below-header">
        <div className="row align-items-center">
          <div className="col-md-12">
            <h1>Create Blog</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Blog
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="create-blog box-view">
        <form>
          <div className="row">
            <div className="col-md-8">
              <div className="select-post-status mb-2">
                <div className="status-lebel mb-3">Status</div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="Draft"
                    value="Draft"
                    checked={inputBlog.status === 'Draft'}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="Draft">
                    Draft
                  </label>
                </div>
                <div className="form-check form-check-inline ms-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="Publish"
                    value="Publish"
                    checked={inputBlog.status === 'Publish'}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="Publish">
                    Publish
                  </label>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="Title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Title"
                  placeholder=""
                  value={inputBlog.title}
                  name="title"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="BlogSummary" className="form-label">
                  Blog Summary
                </label>
                <ReactQuill
                  className=""
                  id="BlogSummary"
                  theme="snow"
                  modules={modules}
                  preserveWhitespace={true}
                  readOnly={false}
                  value={content}
                  onChange={setContent}
                />
              </div>
              {/* {content} */}
            </div>
            <div className="col-md-4">
              <DropZone files={files} setFiles={setFiles} className="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handlePublish}
                disabled={loading}
              >
                {inputBlog.status}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateBlog;
