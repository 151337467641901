import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IProperty } from '../../pages/Listings/Listings';
import axiosCall from '../../utils/axiosCall';
import { ListingSection } from '../../constants/ListingSection';
const apiUrl = process.env.REACT_APP_API_URL;

export interface IApiResponse {
  data: {
    rooms: IListingProperty[]
    house: IListingProperty[]
    condos: IListingProperty[]
    apartment: IListingProperty[]
    hotel: IListingProperty[]
  };
  totalPages: number;
  currentPage: number;
  pageSizes: number;
  totalPropertyCount: number;
}

export interface IListingProperty {
  name: string,
  price: number,
  bathroomType: string,
  depositMoney: number,
  cleaningCharges: number,
  petFees: number,
  availableFrom: string,
  _id: string,
  propertyId: string,
  propertyTitle: string,
  propertyStatus: string,
  isFeaturedProperty: true,
  address: {
    street: string,
    city: string,
    state: string,
    zip: string,
    latitude: string,
    longitude: string,
    _id: string
  },
  imagesLink:string[],
  amenities: [
    {
      name: string,
      order: number,
      icon: string,
      _id: string
    },
  ],
  isActive: boolean,
  isFavoriteForThisUser: boolean
  type: string;
}

const initialState: {
  listing: IApiResponse;
  propertyListing: any;
  loading: boolean;
  isPropertyLoading: boolean;
  error: string | undefined;
  success: boolean;
  isPropertyDeleted: boolean;
  postFavPropertyLoading: boolean;
  postFavPropertyError: string | undefined;
  postFavPropertySuccess: boolean;
  deleteFavPropertyLoading: boolean;
  deleteFavPropertyError: string | undefined;
  deleteFavPropertySuccess: boolean;
  section: ListingSection.Details;
} = {
  listing: {
    data: {
      rooms: [],
      house: [],
      condos: [],
      apartment: [],
      hotel: []
    },
    totalPages: 1,
    currentPage: 1,
    pageSizes: 10,
    totalPropertyCount: 1
  },
  propertyListing: [],
  loading: false,
  isPropertyLoading: false,
  error: '',
  success: false,
  isPropertyDeleted: false,
  postFavPropertyLoading: false,
  postFavPropertyError: '',
  postFavPropertySuccess: false,
  deleteFavPropertyLoading: false,
  deleteFavPropertyError: "",
  deleteFavPropertySuccess: false,
  section: ListingSection.Details,
};

export const ListingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    //set cerateListing state.createListing
    getListing: (state, action) => {
      const { listing, error, loading } = action.payload;
      state.listing = listing;
      state.error = error;
      state.loading = loading;
    },
    resetListingState: (state, action) => {
      // state.error = "";
      // state.loading = false;
      // state.success = false;
      state.isPropertyDeleted = false;
      state.postFavPropertyLoading = false;
      state.postFavPropertyError = '';
      state.postFavPropertySuccess = false;
      state.deleteFavPropertyLoading = false;
      state.deleteFavPropertyError = "";
      state.deleteFavPropertySuccess = false;
    },
    setSection: (state, data:any) => {
      state.section = data;
    },
    setIsFavourite: (state, action) => {
      const { id } = action.payload;

      // if (state.postFavPropertySuccess) {
        state.listing.data?.rooms?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.rooms[index].isFavoriteForThisUser = true;
          }
        })
        state.listing.data?.apartment?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.apartment[index].isFavoriteForThisUser = true;
          }
        })
        state.listing.data?.condos?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.condos[index].isFavoriteForThisUser = true;
          }
        })
        state.listing.data?.house?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.house[index].isFavoriteForThisUser = true;
          }
        })
        state.listing.data?.hotel?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.hotel[index].isFavoriteForThisUser = true;
          }
        })
        state.isPropertyDeleted = false;
        state.postFavPropertyLoading = false;
        state.postFavPropertyError = '';
        state.postFavPropertySuccess = false;
        state.deleteFavPropertyLoading = false;
        state.deleteFavPropertyError = "";
        state.deleteFavPropertySuccess = false;
      // }
    },
    setIsNotFavourite: (state, action) => {
      const { id } = action.payload;
      // if (state.deleteFavPropertySuccess) {
        state.listing.data?.rooms?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.rooms[index].isFavoriteForThisUser = false;
          }
        })
        state.listing.data?.apartment?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.apartment[index].isFavoriteForThisUser = false;
          }
        })
        state.listing.data?.condos?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.condos[index].isFavoriteForThisUser = false;
          }
        })
        state.listing.data?.house?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.house[index].isFavoriteForThisUser = false;
          }
        })
        state.listing.data?.hotel?.forEach((element, index) => {
          if (element._id === id) {
            state.listing.data.hotel[index].isFavoriteForThisUser = false;
          }
        })
        state.isPropertyDeleted = false;
        state.postFavPropertyLoading = false;
        state.postFavPropertyError = '';
        state.postFavPropertySuccess = false;
        state.deleteFavPropertyLoading = false;
        state.deleteFavPropertyError = "";
        state.deleteFavPropertySuccess = false;
      // }
    },
    resetFetchListingState: (state) =>{
      state.success = false;
      state.error = "";
    },
    resetListingToInitalState: (state) =>{
      state.listing = initialState.listing;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListing.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(fetchListing.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.listing = {...action.payload, data: {
        rooms: [...state.listing.data.rooms, ...action.payload.data.rooms],
        house: [...state.listing.data.house, ...action.payload.data.house],
        condos: [...state.listing.data.condos, ...action.payload.data.condos],
        apartment: [...state.listing.data.apartment, ...action.payload.data.apartment],
        hotel: [...state.listing.data.hotel, ...action.payload.data.hotel]
      }};
    });
    builder.addCase(fetchListing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchPropertiesByUser.pending, (state) => {
      state.isPropertyLoading = true;
    });
    builder.addCase(fetchPropertiesByUser.fulfilled, (state, action) => {
      state.isPropertyLoading = false;
      state.propertyListing = action.payload;
    });
    builder.addCase(fetchPropertiesByUser.rejected, (state, action) => {
      state.isPropertyLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(deletePropertiesById.pending, (state) => {
      state.isPropertyLoading = true;
    });
    builder.addCase(deletePropertiesById.fulfilled, (state, action) => {
      state.isPropertyLoading = false;
      state.isPropertyDeleted = true;
    });
    builder.addCase(deletePropertiesById.rejected, (state, action) => {
      state.isPropertyLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(postFavProperty.pending, (state, action) => {
      state.postFavPropertyLoading = true;
      state.postFavPropertyError = '';
      state.postFavPropertySuccess = false;
    });
    builder.addCase(postFavProperty.fulfilled, (state, action) => {
      state.postFavPropertyLoading = false;
      state.postFavPropertySuccess = true;
      state.postFavPropertyError = '';
    });
    builder.addCase(postFavProperty.rejected, (state, action) => {
      state.postFavPropertyLoading = false;
      state.postFavPropertyError = action.error.message;
      state.postFavPropertySuccess = false;
    });
    builder.addCase(deleteFavProperty.pending, (state, action) => {
      state.deleteFavPropertyLoading = true;
      state.deleteFavPropertyError = "";
      state.deleteFavPropertySuccess = false;
    });
    builder.addCase(deleteFavProperty.fulfilled, (state, action) => {
      state.deleteFavPropertyLoading = false;
      state.deleteFavPropertySuccess = true;
      state.deleteFavPropertyError = "";
    });
    builder.addCase(deleteFavProperty.rejected, (state, action) => {
      state.deleteFavPropertyLoading = false;
      state.deleteFavPropertyError = action.error.message;
      state.deleteFavPropertySuccess = false;
    });

  },
});

export const { getListing, setSection, resetListingState, resetListingToInitalState, setIsFavourite, setIsNotFavourite, resetFetchListingState } = ListingSlice.actions;

export default ListingSlice.reducer;

export const listingState = (state:any) => state.listing.listing;

export const fetchListing = createAsyncThunk<any, any>(
  'fetchCreateListing',
  async (args: { body: any }) => {
    const response = await axiosCall.post(
      '/properties/getAll', args.body
    );
    return response?.data;
  }
);

export const fetchPropertiesByUser = createAsyncThunk<any, any>(
  'fetchPropertiesByUser',
  async ({ userId, pageNumber, pageLimit, text }) => {
    try {
      const data = {
        UserID: userId,
        pageNo: pageNumber,
        pageSize: pageLimit,
        text
      };
      const response = await axiosCall.post(
        '/properties/getPropertiesbyUserId',
        data
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const deletePropertiesById = createAsyncThunk<any, any>(
  'deletePropertiesById',
  async (propertyId) => {
    try {
      const response = await axiosCall.delete('/properties/delete/' + propertyId);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const postFavProperty = createAsyncThunk<any, any>("postFavProperty", async (arg: { body: any }) => {
  try {
    const response = await axiosCall.post(
      '/favorites/creatFavorite',
      arg.body
    );
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data;
        return errorMessage;
      } else {
        console.error('Error calling backend API:', axiosError.message);
        return Promise.reject(axiosError.message);
      }
    } else {
      console.error('Error calling backend API:', error);
      return Promise.reject(error);
    }
  }
});



export const deleteFavProperty = createAsyncThunk("deleteFavProperty", async (arg: { body: any }) => {

  try {
    const response = await axiosCall.delete(
      // '/favorites/deleteFavorite/'+ arg.body.propertie_id
      `/favorites/deleteFavorite/${arg.body.propertie_id}`
    );
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data;
        return errorMessage;
      } else {
        console.error('Error calling backend API:', axiosError.message);
        return Promise.reject(axiosError.message);
      }
    } else {
      console.error('Error calling backend API:', error);
      return Promise.reject(error);
    }
  }
});
