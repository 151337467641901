import * as Yup from 'yup';

export const signUpValidateSchema = Yup.object().shape({
  FirstName: Yup.string().required('Please enter your First Name...'),
  //LastName: Yup.string().required('Please enter your Last Name...'),
  Email: Yup.string().email('Invalid email address').required('Please enter your Email...'),
  Password: Yup.string().required('Please enter your Password...'),
  // PhoneNo: Yup.string()
  //   .min(10, 'Phone No must not deteriorate 10')
  //   .max(10, 'Phone No must not exceed 10')
  //   .required('Please give a Phone No...'),
  // ConfirmPassword: Yup.string().required('Please give a ConfirmPassword...'),
  Role: Yup.string().required('Please select user type...'),
  // UserType: Yup.string().required('Please give a User Type...'),
  TermsConditions: Yup.bool().oneOf([true], 'Please accept terms & conditions')
});

export const signInValidateSchema = Yup.object().shape({
  Email: Yup.string().email('Invalid email address').required('Please enter your Email'),
  Password: Yup.string().required('Please enter your Password'),
});
