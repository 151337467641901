import './ListProperty.scss';
import { useEffect, useRef, useState } from 'react';
import ListPropertyForm from './ListPropertyForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  IPropertyList,
  propertyListInitialState,
  setListPropertyState,
} from '../../redux/slices/ListPropertySlice';
import { Link, useParams } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import {
  fetchUserById,
  resetUpdateUserDetail,
  updateUserDetail,
  userDetail,
  userUpdate,
} from '../../redux/slices/UserSlice';
import { ToastType, showToastMessage } from '../../utils/ToastOptionCommon';
import { loggedInUserState } from '../../redux/slices/LoginSlice';
import BuyPackage from './BuyPackage/BuyPackage';

export const ListProperty = () => {
  const { propertyListTab } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const tab = propertyListTab?.toLowerCase();
  const initialState = useSelector(propertyListInitialState);
  const userState = useSelector(userDetail);
  const userUpdateState = useSelector(userUpdate);
  const loggedInUser = useSelector(loggedInUserState);
  const [submittedValues, setSubmittedValues] = useState(initialState);
  const [isPackageShow, setIsPackageShow] = useState(false);
  const stateRef = useRef<string>(userState.data?.state);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  const onSubmitHandler = (values: IPropertyList) => {
    setSubmittedValues(values);
    setIsPackageShow(!isPackageShow);
  };

  const onSubmitPackage = () => {
    dispatch(updateUserDetail({ data: submittedValues }));
  };

  useEffect(() => {
    if (loggedInUser?._id)
      dispatch(fetchUserById({ body: { userId: loggedInUser?._id } }));
  }, [loggedInUser]);

  useEffect(() => {
    if (userUpdateState.success) {
      dispatch(resetUpdateUserDetail());
      setShowPopUp(true);
    }
    if (userUpdateState.error) {
      showToastMessage('Error occured', ToastType.Error);
      dispatch(resetUpdateUserDetail());
    }
  }, [userUpdateState]);

  useEffect(() => {
    const data = {
      firstName: userState.data?.firstName ?? '',
      lastName: userState.data?.lastName ?? '',
      userEmail: userState.data?.userEmail ?? '',
      phoneNo: userState.data?.phoneNo ?? '',
      city: userState.data?.city ?? '',
      state: userState.data?.state ?? '',
      zipCode: userState.data?.zipCode ?? '',
    };
    stateRef.current = userState.data?.state;
    dispatch(setListPropertyState(data));
  }, [userState]);

  useEffect(() => {
    if (initialState) {
      setSubmittedValues(initialState);
    }
  }, [initialState]);

  return (
    <>
      <div>
        {/* Property Lising Page Design */}
        <div className="set-prices">
          <div className="inner-banner">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {isPackageShow ? (
                    <h1>
                      Approved listings are displayed<br></br>on both of our
                      sites.
                    </h1>
                  ) : (
                    <h1>
                      <span className='border-img-one'>Personalized Leases</span> for Travelling Nurses and Busy <span className='border-img-two'>Professionals</span>
                    </h1>
                  )}
                </div>
                <div className="col-12">
                  <div className="meta-tags">
                    <a>Trusted by 150,000+ Property Owners</a>
                    <a>Average Length of Stay is 92 days</a>
                    <a>Over 100 Million Searches a year by Travelers</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <section className="set-prices-form bg-white">
            {isPackageShow ? (
              <>
                <div className="offset-md-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => setIsPackageShow(!isPackageShow)}
                  >
                    Back to Form
                  </button>
                </div>
                <BuyPackage
                  showPopUp={showPopUp}
                  setShowPopUp={setShowPopUp}
                  submitPackage={onSubmitPackage}
                />
              </>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="text-center mb-4">
                          Landlords set the price and keep 100% of the Rent.
                        </h5>
                      </div>
                      <div className="col-12">
                        <ul
                          className="nav nav-pills justify-content-center py-3"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                tab === 'room' ? 'active' : ''
                              }`}
                              to="/list-property/room"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M33.333 18.3337H6.66634C5.78221 18.3335 4.93422 18.6846 4.30905 19.3098C3.68388 19.935 3.33276 20.7829 3.33301 21.6671V28.2171C3.33334 29.1374 4.07934 29.8834 4.99967 29.8837H16.6663"
                                  stroke="#323232"
                                  strokeWidth="2.50006"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.66602 18.3334V11.6667C6.66593 10.3406 7.19269 9.06882 8.13039 8.13112C9.06809 7.19342 10.3399 6.66666 11.666 6.66675H28.3327C29.6588 6.66666 30.9306 7.19342 31.8683 8.13112C32.806 9.06882 33.3328 10.3406 33.3327 11.6667V18.3334"
                                  stroke="#323232"
                                  strokeWidth="2.50006"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.99935 29.884V33.329"
                                  stroke="#323232"
                                  strokeWidth="2.50006"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.66699 18.3337H33.3337C34.2178 18.3335 35.0658 18.6846 35.6909 19.3098C36.3161 19.935 36.6672 20.7829 36.667 21.6671V28.2171C36.6667 29.1374 35.9207 29.8834 35.0003 29.8837H16.667"
                                  stroke="#323232"
                                  strokeWidth="2.50006"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M35.0007 29.8839V33.3289"
                                  stroke="#323232"
                                  strokeWidth="2.50006"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M15.8333 13.3336H24.1667C26.0076 13.3336 27.5 14.826 27.5 16.667V18.3336H12.5V16.667C12.5 14.826 13.9924 13.3336 15.8333 13.3336Z"
                                  stroke="#323232"
                                  strokeWidth="2.50006"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Room
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                tab === 'house' ? 'active' : ''
                              }`}
                              to="/list-property/house"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 17.8567L20 5L35 17.8567"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.14355 16.02V35H32.8569V5H26.4286V10.51"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.7148 35V27.6184C15.7148 25.7767 17.2065 24.285 19.0482 24.285H20.9532C22.7948 24.285 24.2865 25.7767 24.2865 27.6184V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              House
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                tab === 'condos' ? 'active' : ''
                              }`}
                              to="/list-property/condos"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M36.6673 35H3.33398"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M32.4896 30V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <rect
                                  x="28.334"
                                  y="18.3333"
                                  width="8.33333"
                                  height="11.6667"
                                  rx="4.16667"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.667 35V8.33329C11.667 7.41282 12.4132 6.66663 13.3337 6.66663H26.667C27.5875 6.66663 28.3337 7.41282 28.3337 8.33329V15"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.6676 16.6666H6.66764C5.74717 16.6666 5.00098 17.4128 5.00098 18.3333V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.667 12.5H23.3337"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.667 17.5H23.3337"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.667 22.5H23.3337"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M17.501 34.9999V29.1666C17.501 28.7063 17.8741 28.3333 18.3343 28.3333H21.6676C22.1279 28.3333 22.501 28.7063 22.501 29.1666V34.9999"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Condos
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                tab === 'apartment' ? 'active' : ''
                              }`}
                              to="/list-property/apartment"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M28.3327 35V6.66667C28.3327 5.74667 27.586 5 26.666 5H13.3327C12.4127 5 11.666 5.74667 11.666 6.66667V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.6663 15H4.99967C4.07967 15 3.33301 15.7467 3.33301 16.6667V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M36.6663 35V16.6667C36.6663 15.7467 35.9197 15 34.9997 15H28.333"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.833 28.3333H24.1663"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M22.4993 28.3334V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M17.4993 35V28.3334"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.666 17.5H23.3327"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.666 22.5H23.3327"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.666 12.5H23.3327"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.33301 22.5H11.6663"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.33301 28.3333H11.6663"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M28.333 22.5H31.6663"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M28.333 28.3333H31.6663"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M38.3327 35H1.66602"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Apartment
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                tab === 'hotel' ? 'active' : ''
                              }`}
                              to="/list-property/hotel"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M29.1663 12.3924C29.1073 7.37185 25.0206 3.33289 19.9997 3.33289C14.9787 3.33289 10.892 7.37185 10.833 12.3924C10.833 16.0414 14.4341 19.9087 17.1217 22.2877C18.7861 23.6813 21.2099 23.6818 22.8747 22.2887C25.5643 19.9099 29.1663 16.0419 29.1663 12.3924Z"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M17.5003 9.58337V15.4167"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M22.5003 9.58337V15.4167"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M17.5 12.5H22.5"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M29.0481 13.5603L32.8063 12.3076C33.3145 12.1382 33.8732 12.2234 34.3078 12.5367C34.7425 12.8499 35 13.353 35 13.8887V30.5554C35 31.2728 34.541 31.9097 33.8604 32.1365L25.527 34.9143C25.1849 35.0284 24.8151 35.0284 24.4729 34.9143L15.527 31.9323C15.1849 31.8183 14.8151 31.8183 14.473 31.9323L7.19372 34.3588C6.68547 34.5282 6.12677 34.4429 5.69215 34.1297C5.25754 33.8164 5 33.3134 5 32.7776V16.111C5.00001 15.3936 5.45906 14.7567 6.13962 14.5298L10.8613 12.956"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.0003 26.6666V31.8469"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M25.0003 26.6666V35"
                                  stroke="#323232"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Hotel
                            </Link>
                          </li>
                        </ul>
                        {!isPackageShow && (
                          <ListPropertyForm
                            initialState={submittedValues}
                            submitHandler={onSubmitHandler}
                            stateRef={stateRef}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};
