import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { IPropertyList } from '../../redux/slices/ListPropertySlice';
import FormikFormInput from '../../utils/FormikFormInput';
import { useParams } from 'react-router-dom';
import { useStateCity } from '../../hooks/useStateCity';
import FormikFormSelectField from '../../utils/FormikFormSelect';
import { PropertyListingSchema } from '../../utils/yupSchemas/PropertyListingSchema';
import FormikFormPhone from '../../utils/FormikFieldPhoneNumberInput';

interface IProps {
  initialState: IPropertyList;
  submitHandler: (values: IPropertyList) => void;
  stateRef: any;
}
const ListPropertyForm = (props: IProps) => {
  const { propertyListTab } = useParams();
  const [state, setState] = useState(props.initialState.state);
  const [allCity, setAllCity] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const { loading, states, stateLoading, cities } = useStateCity({ state });
  
  useEffect(() => {
    if (props.initialState.state !== "") {
      setState(props.initialState.state);
    }
  }, [props.initialState.state]);

  useEffect(() => {
    if (states.length) {
      const state = states?.map((e: string) => {
        return { value: e, label: e };
      });
      setAllState(state);
    }
  }, [states]);

  useEffect(() => {
    if (cities.length) {
      const city = cities?.map((e: string) => {
        return { value: e, label: e };
      });
      setAllCity(city);
    }
  }, [cities]);

  const FormValue = () => {
    const { values, setFieldValue } = useFormikContext<IPropertyList>();
    useEffect(() => {
      if (values.state !== '' && values.state !== props.stateRef.current) {
        setState(values.state);
        props.stateRef.current = values.state;
        setFieldValue('city', '');
      }
    }, [values.state]);

    return null;
  };

  return (
    <Formik
      initialValues={props.initialState}
      enableReinitialize
      validationSchema={PropertyListingSchema}
      onSubmit={(values) => {
        props.submitHandler(values);
      }}
    >
      {() => (
        <Form>
          <FormValue />
          <div className="tab-content my-3">
            <div id={propertyListTab} className="container tab-pane active">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">First Name</label>
                  <Field
                    as={FormikFormInput}
                    className="form-control"
                    name="firstName"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Last Name</label>
                  <Field
                    as={FormikFormInput}
                    className="form-control"
                    name="lastName"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Email</label>
                  <Field
                    as={FormikFormInput}
                    className="form-control"
                    name="userEmail"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Phone Number</label>
                  <Field
                    as={FormikFormPhone}
                    className="form-control"
                    name="phoneNo"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">City</label>
                  <Field
                    as={FormikFormSelectField}
                    isLabelValueSame={true}
                    id="city"
                    name="city"
                    className="form-control"
                    options={allCity}
                    loading={loading}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">State</label>
                      <Field
                        as={FormikFormSelectField}
                        isLabelValueSame={true}
                        id="state"
                        name="state"
                        className="form-control"
                        options={allState}
                        loading={stateLoading}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Zip Code</label>
                      <Field
                        as={FormikFormInput}
                        className="form-control"
                        name="zipCode"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <button type="submit" className="btn btn-primary w-100">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ListPropertyForm;
