import ScrollToTop from '../utils/ScrollToTop';
import NavBar from '../components/CommonComponents/Navbar/Navbar';
import Footer from '../components/CommonComponents/Footer/Footer';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

interface IProps {
  isDashboard: boolean;
}

const RouteLayout = (props: IProps) => {
  return (
    <div
      className={`${
        props.isDashboard ? 'dashboard-view' : ''
      } normal-site-view blue-theme`}
    >
      <ToastContainer />
      <ScrollToTop />
      <NavBar isDashboard={props.isDashboard} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default RouteLayout;
