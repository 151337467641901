import axios, { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';

export interface ITraveler {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  moveIn: string;
  moveOut: string;
  travelReason: string;
  working: string;
  occupant: string;
  propertyType: string;
  bedroom: string;
  budget: string;
  withPet: string;
  description: string;
  contacted: string;
}

export interface IAgencies {
  firstName: string;
  lastName: string;
  moveIn: string;
  moveOut: string;
  travelReason: string;
  working: string;
  occupant: string;
  propertyType: string;
  bedroom: string;
  budget: string;
  withPet: string;
  description: string;
}

export interface ISelect {
  value: string;
  label: string;
}

export interface IInitRequest {
  requester: string;
  state: string;
  city: string;
  agency: {
    name: string;
    contact: string;
    email: string;
  };
  termCondition: boolean;
  traveler: ITraveler;
  agencies: IAgencies[];
}

export interface IHouseRequest {
  houseRequest: IInitRequest;
  loading: boolean;
  error: string | undefined;
  success: boolean;
  reqData: {
    data: {
      requests: Array<any>
      totalPages: number;
      currentPage: number;
      pageSizes: number;
      totalCount: number;
    };
    loading: boolean;
    error: string | undefined;
    success: boolean;
  };
  reqByIdData: {
    data: Object;
    loading: boolean;
    error: string | undefined;
    success: boolean;
  };
}

export const Select: ISelect = {
  value: '',
  label: '',
};

export const travelerState: ITraveler = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  moveIn: '',
  moveOut: '',
  travelReason: '',
  working: '',
  occupant: '',
  propertyType: '',
  bedroom: '',
  budget: '',
  withPet: 'Yes',
  description: '',
  contacted: 'Phone',
};

export const agenciesState: IAgencies = {
  firstName: '',
  lastName: '',
  moveIn: '',
  moveOut: '',
  travelReason: '',
  working: '',
  occupant: '',
  propertyType: '',
  bedroom: '',
  budget: '',
  withPet: 'Yes',
  description: '',
};

export const houseReqState: IInitRequest = {
  requester: 'Traveler',
  state: '',
  city: '',
  agency: {
    name: '',
    contact: '',
    email: '',
  },
  termCondition: false,
  traveler: travelerState,
  agencies: [agenciesState],
};

const initialState: IHouseRequest = {
  houseRequest: houseReqState,
  loading: false,
  error: undefined,
  success: false,
  reqData: {
    data: {
      requests: [],
      totalPages: 0,
      currentPage: 1,
      pageSizes: 10,
      totalCount: 0,
    },
    loading: false,
    error: undefined,
    success: false,
  },
  reqByIdData: {
    data: {},
    loading: false,
    error: undefined,
    success: false,
  },
};

export const HouseRequestSlice = createSlice({
  name: 'houseRequest',
  initialState,
  reducers: {
    resetRequest: (state) => {
      state.houseRequest = initialState.houseRequest;
      state.loading = false;
      state.error = undefined;
      state.success = false;
    },
    resetGetRequest: (state) => {
      state.reqData.loading = false;
      state.reqData.error = undefined;
      state.reqData.success = false;
    },
    resetGetByIdRequest: (state) => {
      state.reqByIdData.loading = false;
      state.reqByIdData.data = {};
      state.reqByIdData.error = undefined;
      state.reqByIdData.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(housingRequest.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(housingRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = undefined;
    });
    builder.addCase(housingRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(getHousingRequest.pending, (state) => {
      state.reqData.loading = true;
      state.reqData.error = undefined;
    });
    builder.addCase(getHousingRequest.fulfilled, (state, action) => {
      state.reqData.loading = false;
      state.reqData.success = true;
      state.reqData.data = action.payload;
      state.reqData.error = undefined;
    });
    builder.addCase(getHousingRequest.rejected, (state, action) => {
      state.reqData.loading = false;
      state.reqData.error = action.error.message;
      state.reqData.success = false;
    });
    builder.addCase(getHousingRequestById.pending, (state) => {
      state.reqByIdData.loading = true;
      state.reqByIdData.error = undefined;
    });
    builder.addCase(getHousingRequestById.fulfilled, (state, action) => {
      state.reqByIdData.loading = false;
      state.reqByIdData.success = true;
      state.reqByIdData.data = action.payload;
      state.reqByIdData.error = undefined;
    });
    builder.addCase(getHousingRequestById.rejected, (state, action) => {
      state.reqByIdData.loading = false;
      state.reqByIdData.error = action.error.message;
      state.reqByIdData.success = false;
    });
  },
});

export const { resetRequest, resetGetRequest, resetGetByIdRequest } = HouseRequestSlice.actions;

export default HouseRequestSlice.reducer;

export const houseRequestState = (state: any) => state.houseRequest;
export const houseRequestInitialState = (state: any) => state.houseRequest.houseRequest;
export const getRequestState = (state: any) => state.houseRequest.reqData;
export const getRequestById = (state: any) => state.houseRequest.reqByIdData;

export const housingRequest = createAsyncThunk<any, any>(
  'housingRequest',
  async (args: any) => {
    const response = await axiosCall.post(`/housing/sendRequest`, args);
    return response?.data;
  }
);

export const getHousingRequest = createAsyncThunk<any, any>(
  'getHousingRequest',
  async (args:any) => {
    const response = await axiosCall.post(`/housing/getRequest`, args);
    return response?.data;
  }
);

export const getHousingRequestById = createAsyncThunk<any, any>(
  'getHousingRequestById',
  async (args:any) => {
    const response = await axiosCall.get(`/housing/getRequest/${args.id}`);
    return response?.data;
  }
);
