import React from 'react';
import { useSelector } from 'react-redux';
import ReactRouterPrompt from 'react-router-prompt';
import { LocationState } from '../../redux/slices/LocationSlice';
import { IoWarningOutline } from 'react-icons/io5';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';

interface IProps {
  confirmationText: string;
  saveButtonText: string;
  cancelButtonText: string;
  modalTitle: string;
  isProgress: boolean;
  icon?: React.ReactElement;
}

const NavigationPrompt = (props: IProps) => {
  const isFormDirty = useSelector(LocationState);

  return (
    <>
      <ReactRouterPrompt when={isFormDirty}>
        {({ isActive, onConfirm, onCancel }) => (
          <ConfirmationPopup
            onModalClose={onCancel}
            onSubmit={onConfirm}
            confirmationText={props.confirmationText}
            icon={<IoWarningOutline className="m-1 warn-popup" />}
            saveButtonText={props.saveButtonText}
            cancelButtonText={props.cancelButtonText}
            modalTitle={props.modalTitle}
            isProgress={false}
          />
        )}
      </ReactRouterPrompt>
    </>
  );
};

export default NavigationPrompt;
