import React from 'react';
import './DefaultViewComponent.scss';
import ListingCardComponent from '../ListingCardComponent/ListingCardComponent';
import { property } from '../../../utils/dummyData';
import CustomMapComponent from '../CustomMapComponent';
import NoDataFound from '../../../assets/images/noDataFound.png';

interface IProps {
  propertyData: property[];
  markers: { lat: number; lng: number; propertyName: string }[];
  signInToggleHandler: () => void;
  favedId: string;
  setFavedId: React.Dispatch<React.SetStateAction<string>>;
  loadMoreHandler: () => void;
  totalListing: number;
}
function DefaultViewComponent(props: IProps) {
  return (
    <div className="row mb-5">
      <div className="col-md-8">
        {props.propertyData?.length === 0 ? (
          <div>
            <img
              src={NoDataFound}
              alt="NO DATA FOUND FOR YOUR SEARCH :("
              width={500}
            />
            <p>NO DATA FOUND FOR YOUR SEARCH</p>
          </div>
        ) : (
          <div className="row">
            {props.propertyData?.map((property) => (
              <ListingCardComponent
                key={property.id}
                propertiesValues={property}
                signInToggleHandler={props.signInToggleHandler}
                favedId={props.favedId}
                setFavedId={props.setFavedId}
                selectedView={1}
              />
            ))}
            {props.propertyData.length < props.totalListing && (
              <div className="mb-3 d-flex justify-content-center align-items-center">
                <button
                  className="btn btn-primary"
                  onClick={props.loadMoreHandler}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="col-md-4">
        <CustomMapComponent mapClassName={''} markers={props.markers} />
      </div>
    </div>
  );
}

export default DefaultViewComponent;
