import './ProfileViewModal.scss';
import { Button, Modal, Row } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Busy from '../../assets/gif/busy.gif';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUserReview, fetchUserById } from '../../../redux/slices/UserSlice';
import { UnknownAction } from '@reduxjs/toolkit';
import ProfileImage from '../../../assets/images/avatar.png';
import { Rating } from 'react-simple-star-rating';
import Loader from '../../CommonComponents/Loader/Loader';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import { usePermissions } from '../../../utils/Permission/Permission';

export const ProfileViewModal = (props: any) => {
  const takeFeedback = props?.takeFeedback || false;
  const dispatch = useDispatch();
  const [ratings, setRatings] = useState<number>(0);
  const [feedback, setFeedback] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);
  const { hasPermission } = usePermissions();

  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(true);

  const confirmationToggleHandler = () => {
    props.onModalClose();
  };

  function handleRating(rating: number) {
    setRatings(rating);
  }

  const addFeddback = async () => {
    if (ratings === 0) {
      return showToastMessage(
        'Please give a rating from 1 - 5 star.',
        ToastType.Error
      );
    }
    if (feedback?.trim()?.length === 0) {
      return showToastMessage('Please give a feedback.', ToastType.Error);
    }
    dispatch(
      addUserReview({
        rating: ratings,
        feedback: feedback,
        userID: props.userId,
      }) as unknown as UnknownAction
    );
    if (userDetails?.success) {
      showToastMessage('Review added successfully', ToastType.Success);
      setInputDisabled(() => true);
    } else {
      showToastMessage(userDetails?.error, ToastType.Error);
    }
  };

  useEffect(() => {
    if (props.userId) {
      dispatch(
        fetchUserById({
          body: { userId: props.userId },
        }) as unknown as UnknownAction
      );
    }
  }, [props.userId]);

  const userDetails = useSelector((state: any) => state.user.userDetail);

  useEffect(() => {
    if (props.userId) {
      const reviews = userDetails?.data?.reviews || [];
      if (reviews.length > 0) {
        reviews.forEach((review: any) => {
          if (review?.userId === loggedinUserDetails?._id) {
            setInputDisabled(() => true);
            setRatings(() => review?.rating);
            setFeedback(() => review?.comment);
          }
        });
      }
    }
  }, []);

  const loggedinUserDetails = useSelector(
    (state: any) => state.user.loggedinUser
  );
  // const { loading, error, data, success } = userState;

  return (
    <>
      <Modal show={showConfirmationPopup} centered className="modal-lg">
        <Modal.Header className="border-bottom">
          <Modal.Title className="box-title">{props?.modalHeader}</Modal.Title>
          <div
            onClick={() => {
              confirmationToggleHandler();
            }}
            className="icons8-close ms-auto"
          >
            <IoClose />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="tab-content">
            <div id="Update" className="tab-pane active" style={{minHeight: '100px'}}>
              {/* <div className="row">
                <div className="col-12">
                  <h3 className="box-title">Profile</h3>
                </div>
              </div> */}
              {userDetails?.loading ? (
                <Loader isSmallLoader={true}/>
              ) : (
                <div className="row">
                  <div className="col-md-3">
                    <div className="update-profile-img text-center">
                      <div
                        className="profile-img mx-auto rounded-3 overflow-hidden"
                        style={{ maxWidth: '150px' }}
                      >
                        <img
                          src={
                            userDetails?.data?.profileImage?.length
                              ? userDetails?.data?.profileImage
                              : ProfileImage
                          }
                          className="img-fluid w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="d-flex align-items-center">
                          <div className="field-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M480-492.31q-57.75 0-98.87-41.12Q340-574.56 340-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q620-690.06 620-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12ZM180-187.69v-88.93q0-29.38 15.96-54.42 15.96-25.04 42.66-38.5 59.3-29.07 119.65-43.61 60.35-14.54 121.73-14.54t121.73 14.54q60.35 14.54 119.65 43.61 26.7 13.46 42.66 38.5Q780-306 780-276.62v88.93H180Zm60-60h480v-28.93q0-12.15-7.04-22.5-7.04-10.34-19.11-16.88-51.7-25.46-105.42-38.58Q534.7-367.69 480-367.69q-54.7 0-108.43 13.11-53.72 13.12-105.42 38.58-12.07 6.54-19.11 16.88-7.04 10.35-7.04 22.5v28.93Zm240-304.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm0 384.62Z" />
                            </svg>
                          </div>
                          <span className="field-value">
                            {userDetails?.data?.firstName}{' '}
                            {userDetails?.data?.lastName}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <div className="d-flex align-items-center">
                          <div className="field-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M777.61-140q-113.07 0-227.19-52.58-114.11-52.58-209.69-148.34-95.57-95.77-148.15-209.7Q140-664.54 140-777.61q0-18.17 12-30.28T182-820h130.46q15.15 0 26.73 9.89 11.58 9.88 14.73 24.42L376.85-668q2.38 16.38-1 28.15-3.39 11.77-12.16 19.77l-92.38 89.93q22.3 40.84 50.96 77.27 28.65 36.42 62.04 69.57 32.92 32.93 70 61.16 37.08 28.23 80.08 52.54l89.76-90.54q9.39-9.77 22.74-13.7 13.34-3.92 27.73-1.92l111.07 22.62q15.15 4 24.73 15.46t9.58 26V-182q0 18-12.11 30t-30.28 12ZM242.92-586.92l71.39-68.31q1.92-1.54 2.5-4.23.58-2.69-.19-5l-17.39-89.39q-.77-3.07-2.69-4.61-1.92-1.54-5-1.54H206q-2.31 0-3.85 1.54-1.53 1.54-1.53 3.85 3.07 41 13.42 83.3 10.34 42.31 28.88 84.39Zm348 345.69q39.77 18.54 82.96 28.35 43.2 9.8 80.73 11.65 2.31 0 3.85-1.54t1.54-3.85v-84.15q0-3.08-1.54-5t-4.61-2.69l-84-17.08q-2.31-.77-4.04-.19-1.73.58-3.66 2.5l-71.23 72Zm-348-345.69Zm348 345.69Z" />
                            </svg>
                          </div>
                          <span className="field-value">
                            {!userDetails?.data?.keepPhoneNumberPrivate
                              ? userDetails?.data?.phoneNo
                              : '**********'}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <div className="d-flex align-items-center">
                          <div className="field-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31ZM480-457.69 160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410L480-457.69Zm0-62.31 313.85-200h-627.7L480-520ZM160-662.31V-720v467.69q0 5.39 3.46 8.85t8.85 3.46H160v-422.31Z" />
                            </svg>
                          </div>
                          <span className="field-value">
                            {!userDetails?.data?.keepEmailPrivate
                              ? userDetails?.data?.userEmail
                              : userDetails?.data?.userEmail?.replace(
                                  /.*(?=@)/,
                                  (str: string) => '*'.repeat(str.length)
                                )}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <div className="d-flex align-items-center">
                          <div className="field-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M480-288.31q104-83.07 157-159.57T690-594q0-58.71-21.46-100.13-21.46-41.41-53.19-67.06-31.73-25.66-68.37-37.23Q510.35-810 480-810t-66.98 11.58q-36.64 11.57-68.37 37.23-31.73 25.65-53.19 67.06Q270-652.71 270-594q0 69.62 53 146.12t157 159.57Zm0 76Q344-313.62 277-408.54q-67-94.92-67-185.47 0-68.39 24.54-119.92t63.13-86.38q38.59-34.84 86.82-52.27Q432.73-870 480-870t95.51 17.42q48.23 17.43 86.82 52.27 38.59 34.85 63.13 86.38Q750-662.4 750-594.01q0 90.55-67 185.47T480-212.31ZM480-530q29.15 0 49.58-20.42Q550-570.85 550-600t-20.42-49.58Q509.15-670 480-670t-49.58 20.42Q410-629.15 410-600t20.42 49.58Q450.85-530 480-530ZM210-90v-60h540v60H210Zm270-504Z" />
                            </svg>
                          </div>
                          <span className="field-value">
                            {userDetails?.data?.address
                              ? userDetails?.data?.address
                              : 'No address provided'}
                          </span>
                        </div>
                      </div>

                      {userDetails?.aboutUser &&
                      userDetails?.aboutUser?.length > 0 ? (
                        <div className="col-md-6 mb-4">
                          <div className="d-flex align-items-center">
                            <div className="field-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                              >
                                <path d="M450-290h60v-230h-60v230Zm30-298.46q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm.07 488.46q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                              </svg>
                            </div>
                            <span className="field-value">
                              {userDetails?.aboutUser}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {takeFeedback && hasPermission('landlord') ? (
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label mb-0 d-block">
                              Rate Us
                            </label>
                            <Rating
                              onClick={handleRating}
                              initialValue={ratings}
                              // onPointerEnter={()=>{}}
                              // onPointerLeave={()=>{}}
                              // onPointerMove={handleRating}
                              SVGstyle={{
                                display: 'inline',
                                height: '20px',
                                width: '20px',
                              }}
                              readonly={inputDisabled}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {takeFeedback && hasPermission('landlord') ? (
                      <div className="">
                        <label className="form-label">Give Your Review</label>
                        <textarea
                          className="form-control"
                          value={feedback}
                          // defaultValue={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                          disabled={inputDisabled}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* <div className="mb-3">
              <div className="form-label">
                First Name :{' '}
                <span className="text-secondary">{userDetails?.firstName}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Last Name :{' '}
                <span className="text-secondary">{userDetails?.lastName}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Phone :{' '}
                <span className="text-secondary">{userDetails?.phoneNo}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Email :{' '}
                <span className="text-secondary">{userDetails?.userEmail}</span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Location :{' '}
                <span className="text-secondary">
                  {userDetails?.address && userDetails?.address.length > 0
                    ? userDetails.address
                    : 'No address provided'}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                About Me :{' '}
                <span className="text-secondary">
                  {userDetails?.aboutUser && userDetails.aboutUser.length > 0
                    ? userDetails.aboutUser
                    : 'Tell us about you'}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">
                Occupation :{' '}
                <span className="text-secondary">
                  {userDetails?.occupation &&
                    userDetails.occupation.name.length > 0
                    ? userDetails.occupation?.name
                    : 'Tell us about what you do'}
                </span>
              </div>
            </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top py-1">
          {takeFeedback && !inputDisabled && !hasPermission('admin') ? (
            <Button
              className="py-2"
              variant="primary"
              onClick={addFeddback}
              // disabled={inputDisabled}
            >
              Save Feedback
            </Button>
          ) : (
            <></>
          )}
          <Button
            className="py-2"
            variant="secondary"
            onClick={() => {
              confirmationToggleHandler();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
