import React, { useEffect, useRef, useState } from 'react';

import './CategoryComponent.scss';
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import CustomAutoComplete from '../../../utils/CustomAutoComplete';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import DatePicker from 'react-datepicker';
import { dateFormatForBE } from '../../../utils/common';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import FormikFormDateTimePicker from '../../../utils/FormikFieldDateTimePicker';
import FormikFormSelectField from '../../../utils/FormikFormSelect';
import axiosCall from '../../../utils/axiosCall';
import { Amenity } from '../../AddListingsComponents/DetailedInfoComponent/DetailedInfoComponent';
import { propertyTypeList } from '../../../redux/slices/HomeSlice';
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import { NearBy, NearByOptions } from '../../../constants/NearBy';

function CategoryComponent({
  selectedListingView,
  setSelectedListingView,
  text,
  isShow,
  AppDispatch,
  initialValues,
  submitHandler,
  amenities,
  amenitiesLoading,
}: any) {
  //TODO: set type defs
  const [locationSearch, setLocationSearch] = useState<any>(
    initialValues.searchText
  );
  const [placeDetails, setPlaceDetails] = useState(initialValues.place);
  const amenitiesRef = useRef<any>(initialValues.amenities);
  const [selectedValue, setSelectedValue] = useState<Array<any>>(
    initialValues.amenities
  );
  const [emptyStringError, setEmptyStringError] = useState<boolean>(false);
  const nearByTypeList = [...NearByOptions];
  const priceRangeList = [
    { value: '< $1000', label: '< $1000' },
    { value: '$1000- $2000', label: '$1000- $2000' },
    { value: '$2000- $3000', label: '$2000- $3000' },
    { value: '$3000- $4000', label: '$3000- $4000' },
    { value: '$4000- $5000', label: '$4000- $5000' },
  ];

  useEffect(() => {
    if (amenities.length) {
      const refData = amenities.filter((e: any) =>
        selectedValue.includes(e.id)
      );
      amenitiesRef.current = refData;
    }
  }, [amenities]);

  // useEffect(() => {
  //   if(!amenitiesLocalRef.current.length){
  //     getAllAmenities();
  //   }
  // }, []);

  // const getAllAmenities = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosCall.get('/properties/getAmenities');
  //     if (response) {
  //       const data = response?.data?.map((e: any) => ({
  //         name: e.name,
  //         id: e._id,
  //       }));
  //       const refData = data.filter((e: any) => selectedValue.includes(e.id));
  //       amenitiesRef.current = refData;
  //       amenitiesLocalRef.current = data;
  //       setAmenities(data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const propertyTypeList = [
  //   { value: 'rooms', label: 'Room' },
  //   { value: 'house', label: 'House' },
  //   { value: 'condos', label: 'Condo' },
  //   { value: 'apartment', label: 'Apartment' },
  //   { value: 'hotel', label: 'Hotel' },
  // ];

  // const [priceRange, setPriceRange] = useState<any>(searchData.price);
  // console.log(searchData);
  // const searchHandler = () => {
  //   if (!locationSearch) {
  //     setEmptyStringError(() => true);
  //     showToastMessage('Please enter a valid loaction', ToastType.Info);
  //   }
  // };

  const FormConsumer = () => {
    const { values, setFieldValue } = useFormikContext<any>();

    useEffect(() => {
      setFieldValue('searchText', locationSearch);
    }, [locationSearch]);

    // useEffect(() => {
    //   if(values?.amenities?.length > 0){
    //     amenitiesRef.current =
    //   }
    // }, []);

    return null;
  };

  const selectHandler = (selectedList: any, selectedItem: any): void => {
    const value = selectedList.map((e: any) => e.id);
    amenitiesRef.current = selectedList;
    setSelectedValue(value);
  };

  const onRemoveHandler = (selectedList: any, selectedItem: any): void => {
    const value = selectedList.map((e: any) => e.id);
    amenitiesRef.current = selectedList;
    setSelectedValue(value);
  };

  return (
    <div className="">
      <div className="below-header">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1>Search Properties</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Search Property
                </li>
              </ol>
              {isShow && (
                <div className="listing-table">
                  <span className="showing-txt">{text}</span>
                </div>
              )}
            </nav>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-end gap-2">
              <div
                className="btn-group nav nav-pills tabs-pills-btn"
                role="group"
              >
                <a
                  className="btn active"
                  data-bs-toggle="tab"
                  href="#Update"
                  onClick={() => setSelectedListingView(1)}
                >
                  Default View
                </a>
                <a
                  className="btn"
                  data-bs-toggle="tab"
                  href="#ChangePassword"
                  onClick={() => setSelectedListingView(2)}
                >
                  List View
                </a>
                <a
                  className="btn"
                  data-bs-toggle="tab"
                  href="#ChangePassword"
                  onClick={() => setSelectedListingView(3)}
                >
                  Map View
                </a>
              </div>
              <div className="property-filter">
                <div className="d-flex align-items-center gap-2">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#FilterProperty"
                    aria-controls="FilterProperty"
                    className="btn filter-btn d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="#034991"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                    </svg>
                    Search
                  </button>
                </div>
                <div
                  className="offcanvas offcanvas-end"
                  data-bs-backdrop="static"
                  id="FilterProperty"
                  aria-labelledby="staticBackdropLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="staticBackdropLabel">
                      Search
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body">
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize
                      // validationSchema={HomeRequestSchema}
                      onSubmit={(values: any) => {
                        values.amenities = selectedValue;
                        values.place = placeDetails;
                        submitHandler(values);
                      }}
                    >
                      {({ values, setFieldValue, resetForm }) => (
                        <Form>
                          <FormConsumer />
                          <div className="mb-3">
                            <CustomAutoComplete
                              id={emptyStringError ? `search-input-error` : ''}
                              value={locationSearch}
                              setValue={setLocationSearch}
                              setPlaceDetails={setPlaceDetails}
                              name="searchText"
                              className="text-bold"
                            />
                            {/* <button
                          className="btn search-icon bg-transparent border-0"
                          type="button"
                          onClick={searchHandler}
                        >
                          <svg
                            width="16"
                            height="16"
                            fill="#818181"
                            viewBox="0 0 17 17"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.5 6.5C11.5 4.71875 10.5312 3.09375 9 2.1875C7.4375 1.28125 5.53125 1.28125 4 2.1875C2.4375 3.09375 1.5 4.71875 1.5 6.5C1.5 8.3125 2.4375 9.9375 4 10.8438C5.53125 11.75 7.4375 11.75 9 10.8438C10.5312 9.9375 11.5 8.3125 11.5 6.5ZM10.5312 11.625C9.40625 12.5 8 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.03125 12.4688 9.4375 11.5938 10.5625L15.7812 14.7188C16.0625 15.0312 16.0625 15.5 15.7812 15.7812C15.4688 16.0938 15 16.0938 14.7188 15.7812L10.5312 11.625Z" />
                          </svg>
                        </button> */}
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-bold">
                              Move In
                            </label>
                            <DatePicker
                              id="moveIn"
                              // open={isMoveInOpen}
                              selected={values.moveIn}
                              // onInputClick={() => { setIsMoveInOpen(() => true) }}
                              minDate={new Date()}
                              onChange={(date: Date) => {
                                setFieldValue(
                                  'moveIn',
                                  moment(date).format(dateFormatForBE)
                                );
                              }}
                              // onClickOutside={() => { setIsMoveInOpen((isOpen) => !isOpen) }}
                              onSelect={(date: Date) => {
                                // console.log(date)
                                setFieldValue(
                                  'moveIn',
                                  moment(date).format(dateFormatForBE)
                                );
                                // setmoveInDate(() => date)
                                // setIsMoveInOpen((isOpen) => !isOpen)
                              }}
                              dateFormat="MM/dd/yyyy"
                              dropdownMode="select"
                              placeholderText=""
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-bold">
                              Move Out
                            </label>
                            <DatePicker
                              id="moveOut"
                              // open={isMoveInOpen}
                              selected={values.moveOut}
                              // onInputClick={() => { setIsMoveInOpen(() => true) }}
                              minDate={new Date()}
                              onChange={(date: Date) => {
                                setFieldValue(
                                  'moveOut',
                                  moment(date).format(dateFormatForBE)
                                );
                              }}
                              // onClickOutside={() => { setIsMoveInOpen((isOpen) => !isOpen) }}
                              onSelect={(date: Date) => {
                                // console.log(date)
                                setFieldValue(
                                  'moveOut',
                                  moment(date).format(dateFormatForBE)
                                );
                                // setmoveInDate(() => date)
                                // setIsMoveInOpen((isOpen) => !isOpen)
                              }}
                              dateFormat="MM/dd/yyyy"
                              dropdownMode="select"
                              placeholderText=""
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-bold">
                              Price
                            </label>
                            <Field
                              as={FormikFormSelectField}
                              id="price"
                              name="price"
                              className="form-select"
                              isLabelValueSame={true}
                              options={priceRangeList}
                            />
                            {/* <span className="input-group-text bg-transparent border-0">
                                <svg
                                  width="14"
                                  height="14"
                                  fill="#818181"
                                  viewBox="0 0 14 14"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M2 1.5C1.71875 1.5 1.5 1.75 1.5 2V12C1.5 12.2812 1.71875 12.5 2 12.5H12C12.25 12.5 12.5 12.2812 12.5 12V2C12.5 1.75 12.25 1.5 12 1.5H2ZM0 2C0 0.90625 0.875 0 2 0H12C13.0938 0 14 0.90625 14 2V12C14 13.125 13.0938 14 12 14H2C0.875 14 0 13.125 0 12V2ZM7.625 3.1875V3.625C7.9375 3.65625 8.25 3.75 8.53125 3.84375C8.59375 3.84375 8.65625 3.875 8.71875 3.875C9.0625 4 9.28125 4.375 9.1875 4.71875C9.09375 5.09375 8.71875 5.28125 8.34375 5.1875C8.3125 5.1875 8.25 5.15625 8.21875 5.15625C8 5.09375 7.78125 5.03125 7.5625 5C7.125 4.90625 6.65625 4.9375 6.28125 5.125C5.9375 5.25 5.65625 5.625 6.03125 5.875C6.34375 6.0625 6.71875 6.15625 7.0625 6.28125C7.15625 6.28125 7.21875 6.3125 7.28125 6.3125C7.78125 6.46875 8.40625 6.65625 8.875 6.96875C9.46875 7.375 9.75 8.0625 9.625 8.78125C9.5 9.46875 9 9.9375 8.4375 10.1875C8.1875 10.2812 7.90625 10.3438 7.625 10.375V10.8438C7.625 11.2188 7.34375 11.5312 6.96875 11.5312C6.59375 11.5312 6.28125 11.2188 6.28125 10.8438V10.3125C5.84375 10.1875 5.40625 10.0625 4.9375 9.90625C4.59375 9.78125 4.40625 9.40625 4.53125 9.0625C4.65625 8.6875 5.03125 8.5 5.375 8.625C5.46875 8.65625 5.53125 8.6875 5.625 8.71875C5.96875 8.8125 6.3125 8.9375 6.6875 9C7.21875 9.09375 7.65625 9.03125 7.9375 8.9375C8.3125 8.78125 8.46875 8.3125 8.09375 8.0625C7.78125 7.875 7.40625 7.75 7.03125 7.65625C6.96875 7.625 6.875 7.625 6.8125 7.59375C6.34375 7.46875 5.75 7.28125 5.3125 7C4.6875 6.625 4.40625 5.96875 4.53125 5.25C4.65625 4.5625 5.1875 4.125 5.75 3.875C5.90625 3.78125 6.09375 3.75 6.28125 3.6875V3.1875C6.28125 2.8125 6.59375 2.5 6.96875 2.5C7.34375 2.5 7.625 2.8125 7.625 3.1875Z" />
                                </svg>
                              </span> */}
                          </div>
                          <div className="mb-3 text-start row">
                            <label className="property-type form-label mb-3 text-bold">
                              Property Types
                            </label>
                            {propertyTypeList.map((property: any) => (
                              <div
                                key={property.Id}
                                className="col-md-6 col-lg-4 mb-3"
                              >
                                <label key={property.Id}>
                                  <Field
                                    type="radio"
                                    name="propertyType"
                                    value={property.Name}
                                  />
                                  {property.Name}
                                </label>
                              </div>
                            ))}
                            <div
                              className="mb-3 text-start row"
                              aria-labelledby="checkbox-group"
                            >
                              <label className="property-type form-label text-bold">
                                Amenities
                              </label>
                              <Multiselect
                                showCheckbox={true}
                                loading={amenitiesLoading}
                                closeIcon="cancel"
                                className="mb-3"
                                hidePlaceholder={true}
                                // hideSelectedList={true}
                                avoidHighlightFirstOption={true}
                                options={amenities} // Options to display in the dropdown
                                selectedValues={amenitiesRef.current} // Preselected value to persist in dropdown
                                onSelect={selectHandler} // Function will trigger on select event
                                onRemove={onRemoveHandler} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                              />
                              {/* <Field
                                as={FormikFormSelectField}
                                isLabelValueSame={true}
                                id="State"
                                name={`state`}
                                className="form-select"
                                options={countryState}
                                loading={loading}
                              /> */}
                              {/* {amenities?.map((amenity: Amenity) => (
                                <div
                                  key={amenity._id}
                                  className="col-md-6 col-lg-4 mb-3"
                                >
                                  <label key={amenity._id}>
                                    <Field
                                      type="checkbox"
                                      name="amenities"
                                      value={amenity._id}
                                      className="me-1"
                                    />
                                    {amenity.name}
                                  </label>
                                </div>
                              ))} */}
                              <div className="mb-3">
                                <label className="form-label text-bold">
                                  Near By
                                </label>
                                <Field
                                  isClearable={true}
                                  as={FormikFormSelectField}
                                  id="nearBy"
                                  name="nearBy"
                                  className="form-select"
                                  isLabelValueSame={true}
                                  options={nearByTypeList}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            data-bs-dismiss="offcanvas"
                            className="btn btn-primary w-100"
                          >
                            Search
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="category-overview">
        <p className="font-body">Home / Listing</p>
        <h2 className="font-heading font-semibold">Search Properties</h2>
      </div>
      <div className="category-filter gap-2 text-sm font-body">
        <div className="flex flex-row items-center justify-center h-11 bg-[#ffffff] rounded-[8px] border-solid border-[#eeeeee] border-[1px]">
          <div
            className={`ml-1 h-9 px-4 py-2 cursor-pointer   ${selectedListingView === 1 &&
              ' font-semibold rounded-[6px] bg-[#EEEEEE]'
              }`}
            onClick={() => setSelectedListingView(1)}
          >
            Default View
          </div>
          <div
            className={`h-9 px-4 py-2 cursor-pointer  ${selectedListingView === 2 &&
              ' font-semibold rounded-[6px] bg-[#EEEEEE]'
              }`}
            onClick={() => setSelectedListingView(2)}
          >
            List View
          </div>
          <div
            className={`h-9 px-4 mr-1 py-2 cursor-pointer  ${selectedListingView === 3 &&
              ' font-semibold rounded-[6px] bg-[#EEEEEE]'
              }`}
            onClick={() => setSelectedListingView(3)}
          >
            Map View
          </div>
        </div>
        <div className="flex flex-row width-[184px] gap-2 h-11 bg-[#ffffff] rounded-[8px] border-solid border-[#eeeeee] border-[1px] px-[20px] py-[12px]">
          <div className="text-sm font-semibold">Price : Low To High</div>
          <img className="w-4 h-4" src={downArrow} alt="arrow-down" />
        </div>
      </div> */}
    </div>
  );
}

export default CategoryComponent;
