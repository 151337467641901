import './StripeGateway.scss';

interface IProps {
  handleSubmit: (pkgId: string) => void;
  pkgId: string;
}

export const StripeGateway = (props: IProps) => {
  return (
    <>
      <div className="stripe-container">
        <button
          className="stripe-button"
          onClick={()=> props.handleSubmit(props.pkgId)}
        >
          Pay with <b>stripe</b>
        </button>
      </div>
    </>
  );
};
