import { useState } from 'react';

const ImageEditorHOC = (Component: any) =>
  function Comp(props: any) {
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const showHandler = () => {
      setShowPopup(!showPopup);
    };

    return (
      <Component showPopup={showPopup} showHandler={showHandler} {...props} />
    );
  };

export default ImageEditorHOC;
