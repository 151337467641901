import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { USDateFormat, shortenString } from '../../../../utils/common';
import {
  BsEyeFill,
  BsFillCheckCircleFill,
  BsFillDashCircleFill,
} from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { BiSolidTrash } from 'react-icons/bi';

interface IProps {
  item: any;
  gotoDetail: (id: string) => void;
  handleDelete: (item: any) => void;
  handleEdit: (item: any) => void;
}

const LandlordRowItem = (props: IProps) => {
  const { item, gotoDetail, handleDelete, handleEdit } = props;
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <tr>
      {/* <td>
          <div className="d-flex align-items-center">
            <div className="property-listing-preview">
              {imageLoading && (
                <Skeleton animation='wave' variant="rounded" width={'100%'} height={550} />
              )}
              <img
                src={item?.image || propertylistingpreview}
                alt="property-listing-preview"
                className="img-fluid rounded-md"
                onLoad={() => setImageLoading(false)}
                style={{ display: imageLoading ? 'none' : 'block' }}
              />
            </div>
            <div className="property-details ms-2">
              <h5>{item?.title}</h5>
            </div>
          </div>
        </td> */}
      {/* <td>{USDateFormat(item?.createdAt)}</td>
        <td>{shortenString(item?.content[0])}</td> */}
      <td>{`${item.firstName} ${item.lastName}`}</td>
      <td>{item.userEmail}</td>
      <td>{item.phoneNo}</td>
      <td>
        <span
          className={`status ${item?.isActive ? 'for-active' : 'for-inactive'}`}
        >
          {item?.isActive ? `Active` : `InActive`}
        </span>
      </td>
      <td>
        <div className="d-flex align-items-center gap-3">
          <button
            type="button"
            title="View"
            onClick={() => {
              gotoDetail(item?._id);
            }}
          >
            <BsEyeFill />
          </button>
          {/* <button
            type="button"
            onClick={() => {
              handleEdit(item);
            }}
          >
            <FaEdit />
          </button> */}
          <button
            type="button"
            title={item.isActive ? 'Disable' : 'Enable'}
            onClick={() => {
              handleDelete(item);
            }}
          >
            {item.isActive ? (
              <BsFillDashCircleFill />
            ) : (
              <BsFillCheckCircleFill />
            )}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default LandlordRowItem;
