import * as Yup from 'yup';

export const PropertyListingSchema = Yup.object({
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  userEmail: Yup.string().required('Email is required'),
  phoneNo: Yup.string().required('Phone Number is required'),
  zipCode: Yup.string().required('Zip Code is required'),
});
