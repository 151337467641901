import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';
import axios, { AxiosError } from 'axios';

const initialState = {
  loggedinUser: {},
  verifyEmail: {
    loading: false,
    error: '',
    success: false,
  },
  approveUser: {
    loading: false,
    error: '',
    success: false,
  },
  userDetail: {
    loading: false,
    error: '',
    data: {
      userEmail: '',
      firstName: '',
      lastName: '',
      phoneNo: '',
      address: '',
      occupation: '',
      profileImage: '',
      state: '',
      city: '',
      zipCode: '',
    },
    success: false,
  },
  updateUserDetail: {
    loading: false,
    error: '',
    success: false,
  },
};

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    // set User
    setUser: (state, action) => {
      state.loggedinUser = { ...action.payload };
      // console.log(action.payload);
    },
    removeUser: (state) => {
      state.loggedinUser = {};
      state.userDetail = initialState.userDetail;
    },
    resetUpdateUserDetail: (state) => {
      state.updateUserDetail.loading = false;
      state.updateUserDetail.success = false;
      state.updateUserDetail.error = '';
    },
    resetVerifyEmail: (state) => {
      state.verifyEmail.loading = false;
      state.verifyEmail.success = false;
      state.verifyEmail.error = '';
    },
    resetUserDetailSuccess: (state) => {
      state.userDetail.success = false;
    },
    resetApproveState: (state) => {
      state.approveUser = { ...initialState.approveUser };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserById.pending, (state) => {
      state.userDetail.loading = true;
      state.userDetail.error = '';
    });
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      state.userDetail.loading = false;
      state.userDetail.data = action.payload;
      state.userDetail.success = true;
      state.userDetail.error = '';
    });
    builder.addCase(fetchUserById.rejected, (state, action) => {
      state.userDetail.loading = false;
      state.userDetail.error = action.error.message ?? '';
      state.userDetail.success = false;
    });
    builder.addCase(addUserReview.pending, (state) => {
      state.userDetail.loading = true;
      state.userDetail.error = '';
    });
    builder.addCase(addUserReview.fulfilled, (state, action) => {
      state.userDetail.loading = false;
      state.userDetail.data = action.payload;
      state.userDetail.success = true;
      state.userDetail.error = '';
    });
    builder.addCase(addUserReview.rejected, (state, action) => {
      state.userDetail.loading = false;
      state.userDetail.error = action.error.message ?? '';
      state.userDetail.success = false;
    });
    builder.addCase(updateUserDetail.pending, (state) => {
      state.updateUserDetail.loading = true;
      state.updateUserDetail.error = '';
    });
    builder.addCase(updateUserDetail.fulfilled, (state) => {
      state.updateUserDetail.loading = false;
      state.updateUserDetail.success = true;
      state.updateUserDetail.error = '';
    });
    builder.addCase(updateUserDetail.rejected, (state, action) => {
      state.updateUserDetail.loading = false;
      state.updateUserDetail.error = action.error.message ?? '';
      state.updateUserDetail.success = false;
    });
    builder.addCase(sendEmailVerification.pending, (state) => {
      state.verifyEmail.loading = true;
      state.verifyEmail.error = '';
    });
    builder.addCase(sendEmailVerification.fulfilled, (state) => {
      state.verifyEmail.loading = false;
      state.verifyEmail.success = true;
      state.verifyEmail.error = '';
    });
    builder.addCase(sendEmailVerification.rejected, (state, action) => {
      state.verifyEmail.loading = false;
      state.verifyEmail.error = action.error.message ?? '';
      state.verifyEmail.success = false;
    });
    builder.addCase(recieveEmailVerification.pending, (state) => {
      state.verifyEmail.loading = true;
      state.verifyEmail.error = '';
    });
    builder.addCase(recieveEmailVerification.fulfilled, (state) => {
      state.verifyEmail.loading = false;
      state.verifyEmail.success = true;
      state.verifyEmail.error = '';
    });
    builder.addCase(recieveEmailVerification.rejected, (state, action) => {
      state.verifyEmail.loading = false;
      state.verifyEmail.error = action.error.message ?? '';
      state.verifyEmail.success = false;
    });
    builder.addCase(postApproveUser.pending, (state) => {
      state.approveUser.loading = true;
      state.approveUser.error = '';
    });
    builder.addCase(postApproveUser.fulfilled, (state) => {
      state.approveUser.loading = false;
      state.approveUser.success = true;
      state.approveUser.error = '';
    });
    builder.addCase(postApproveUser.rejected, (state, action) => {
      state.approveUser.loading = false;
      state.approveUser.error = action.error.message ?? '';
      state.approveUser.success = false;
    });
  },
});

export const {
  setUser,
  removeUser,
  resetUpdateUserDetail,
  resetVerifyEmail,
  resetUserDetailSuccess,
  resetApproveState,
} = UserSlice.actions;

export default UserSlice.reducer;

export const userDetail = (state: any) => state.user.userDetail;
export const userUpdate = (state: any) => state.user.updateUserDetail;
export const verifyEmail = (state: any) => state.user.verifyEmail;
export const authUserState = (state: any) => state.user.loggedinUser;

export const fetchUserById = createAsyncThunk(
  'fetchUserById',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.get(
        `/user/getUserById/${arg.body.userId}`
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const updateUserDetail = createAsyncThunk(
  'updateUserDetail',
  async (arg: { data: any }) => {
    const response = await axiosCall.put(`/user/updateUserDetail`, arg.data);
    return response?.data;
  }
);

export const sendEmailVerification = createAsyncThunk(
  'sendEmailVerification',
  async () => {
    const response = await axiosCall.get(`/mail/sendVerification`);
    return response?.data;
  }
);

export const recieveEmailVerification = createAsyncThunk(
  'recieveEmailVerification',
  async (args: any) => {
    const response = await axiosCall.get(`/mail/verifyEmail/${args.id}`);
    return response?.data;
  }
);

export const addUserReview = createAsyncThunk(
  'addUserReview',
  async (args: { rating: number; feedback: string; userID: string }) => {
    const body = {
      rating: args.rating,
      feedback: args.feedback,
      userID: args.userID,
    };
    const response = await axiosCall.post(`/user/addReview`, body);

    return response?.data;
  }
);

export const postApproveUser = createAsyncThunk(
  'approveUser',
  async (arg: { body: any }) => {
    try {
      const response = await axiosCall.post('/user/approveUser', arg.body);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return errorMessage;
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);
