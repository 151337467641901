import axios, { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosCall from '../../utils/axiosCall';

interface IState {
  sessionData: object | null;
  isLoading: boolean;
  error: string | undefined;
  sessionSuccess: boolean;
  success: boolean;
  getResult: boolean
}

const initialState: IState = {
  sessionData: null,
  isLoading: false,
  error: undefined,
  sessionSuccess: false,
  success: false,
  getResult: false
};

export const PaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPaymentData: (state) => {
      state.error = undefined;
      state.isLoading = false;
      state.sessionData = null;
      state.sessionSuccess = false;
    },
    resetStatusData: (state) => {
      state.error = undefined;
      state.isLoading = false;
      state.success = false;
      state.getResult = false;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(createStripeSession.pending, (state) => {
    //   state.isLoading = true;
    //   state.error = '';
    // });
    // builder.addCase(createStripeSession.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.sessionData = action.payload;
    //   state.sessionSuccess = true;
    //   state.error = '';
    // });
    // builder.addCase(createStripeSession.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.error.message;
    //   state.sessionSuccess = false;
    // });
    builder.addCase(createCheckoutSession.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(createCheckoutSession.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sessionData = action.payload;
      state.sessionSuccess = true;
      state.error = '';
    });
    builder.addCase(createCheckoutSession.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.sessionSuccess = false;
    });
    // builder.addCase(sendPaymentStatus.pending, (state) => {
    //   state.isLoading = true;
    //   state.error = '';
    // });
    // builder.addCase(sendPaymentStatus.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.success = true;
    //   state.error = '';
    // });
    // builder.addCase(sendPaymentStatus.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.error.message;
    //   state.success = false;
    // });
    builder.addCase(getPaymentStatus.pending, (state) => {
      state.isLoading = true;
      state.getResult = false;
      state.error = '';
    });
    builder.addCase(getPaymentStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getResult = true;
      state.success = action.payload;
      state.error = '';
    });
    builder.addCase(getPaymentStatus.rejected, (state, action:any) => {
      state.isLoading = false;
      state.getResult = true;
      state.error = action.error.message;
      state.success = action.payload;
    });
  },
});

export const { resetPaymentData, resetStatusData } = PaymentSlice.actions;

export default PaymentSlice.reducer;

// export const createStripeSession = createAsyncThunk<any, any>(
//   'createStripeSession',
//   async (args: any) => {
//     try {
//       const response = await axiosCall.post(`/payment/stripe/checkout`, args);
//       return response?.data;
//     } catch (error) {
//       if (axios.isAxiosError(error)) {
//         const axiosError = error as AxiosError;
//         if (axiosError.response) {
//           const errorMessage = axiosError.response.data;
//           return Promise.reject(errorMessage);
//         } else {
//           console.error('Error calling backend API:', axiosError.message);
//           return Promise.reject(axiosError.message);
//         }
//       } else {
//         console.error('Error calling backend API:', error);
//         return Promise.reject(error);
//       }
//     }
//   }
// );

// export const sendPaymentStatus = createAsyncThunk<any, any>(
//   'sendPaymentStatus',
//   async (args: any) => {
//     try {
//       const response = await axiosCall.post(`/payment/stripe/status`, args);
//       return response?.data;
//     } catch (error) {
//       if (axios.isAxiosError(error)) {
//         const axiosError = error as AxiosError;
//         if (axiosError.response) {
//           const errorMessage = axiosError.response.data;
//           return Promise.reject(errorMessage);
//         } else {
//           console.error('Error calling backend API:', axiosError.message);
//           return Promise.reject(axiosError.message);
//         }
//       } else {
//         console.error('Error calling backend API:', error);
//         return Promise.reject(error);
//       }
//     }
//   }
// );

export const getPaymentStatus = createAsyncThunk<any, any>(
  'getPaymentStatus',
  async (args: any) => {
    try {
      const response = await axiosCall.get(`/payment/stripe/status/${args.id}`);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return Promise.reject(errorMessage);
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const createCheckoutSession = createAsyncThunk<any, any>(
  'createCheckoutSession',
  async (args: any) => {
    try {
      const response = await axiosCall.post(`/payment/stripe/session`, args);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return Promise.reject(errorMessage);
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);

export const paypalCancelHandler = createAsyncThunk<any, any>(
  'paypalCancelHandler',
  async (args: any) => {
    try {
      const response = await axiosCall.post(`/payment/paypal/order`, args);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorMessage = axiosError.response.data;
          return Promise.reject(errorMessage);
        } else {
          console.error('Error calling backend API:', axiosError.message);
          return Promise.reject(axiosError.message);
        }
      } else {
        console.error('Error calling backend API:', error);
        return Promise.reject(error);
      }
    }
  }
);