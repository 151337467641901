import { UnderDevelopment } from '../../utils/UnderDevelopment/UnderDevelopment';
import Challenge from '../../assets/images/challenge.jpg';
import Mission from '../../assets/images/mission.jpg';
import Accessibility from '../../assets/images/accessibility.jpg';
import './AboutUs.scss';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const AboutUs = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className='about-us'>
      <div className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row">
              <div className="col-12 animate__animated animate__fadeInDown">
                <h1 className='border-img-one'>About Us</h1>
                <p>Welcome to Furnished Home Locator, your premier platform for connecting landlords and travel professional seeking furnished accommodations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className='bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6 pe-md-5 mb-4 mb-md-0 animate__animated animate__fadeInLeft'>
              <div className='about-img rounded-3 overflow-hidden'>
                <img src={Challenge} className='img-fluid' alt='...' />
              </div>
            </div>
            <div className='col-md-6 animate__animated animate__fadeInRight'>
              <h2>Our Challenges</h2>
              <p>At Furnished Home Locator, we understand the challenges faced by both landlords and travel professional when it comes to finding short-mid-term furnished rental solutions. Our platform was born out of a desire to bridge this gap and provide a seamless experience for both parties involved.</p>
              <p>As the founder of Furnished Home Locator, I embarked on this journey with a vision to revolutionize the way people find Furnished rental accommodations. The excitement and possibility of creating a platform that could positively impact the lives of individuals across the country fueled my dedication and commitment to our mission.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-light'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-8 offset-md-2 animate__animated animate__fadeInDown'>
              <h2 className='text-center'>Our Goal</h2>
              <p className='text-center'>Our primary goal is to facilitate quick and reliable connections between landlords and travel professional in need of furnished rooms, houses, apartments, or hotels. We strive to make the rental process efficient and hassle-free, ensuring that both landlords and healthcare professional, Military can find suitable arrangements with ease.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6 animate__animated animate__fadeInLeft'>
              <h2>Singular Mission</h2>
              <p>In a fast-paced world where mobility is the norm, finding temporary furnished accommodations can often be a daunting task. Recognizing the challenges faced by both landlords and tenants in this dynamic landscape, we founded Furnished Home Locator with a singular mission: to revolutionize the rental experience and make it as effortless as possible for everyone involved.</p>
              <p>As the driving force behind Furnished Home Locator, I embarked on this journey fueled by a deep-seated passion for innovation and a genuine desire to make a positive impact on the lives of individuals seeking temporary furnished housing solutions. The prospect of creating a platform that could transform the way people access rental properties, while fostering a sense of community and empowerment, served as a constant source of inspiration.</p>
            </div>
            <div className='col-md-6 ps-md-5 animate__animated animate__fadeInRight'>
              <div className='about-img rounded-3 overflow-hidden'>
                <img src={Mission} className='img-fluid' alt='...' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-light'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-8 offset-md-2 animate__animated animate__fadeInDown'>
              <h2 className='text-center'>Trust & Security</h2>
              <p className='text-center'>At Furnished Home Locator, we understand that trust and security are paramount when it comes to rental transactions. That's why we go above and beyond to ensure the safety and well-being of our users. Before a listing is published on our platform, landlords undergo a rigorous verification process to guarantee their legitimacy and reliability. This vetting process not only protects our users but also instills confidence in the authenticity of the listings showcased on our platform.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6 pe-md-5 mb-4 mb-md-0 animate__animated animate__fadeInLeft'>
              <div className='about-img rounded-3 overflow-hidden'>
                <img src={Accessibility} className='img-fluid' alt='...' />
              </div>
            </div>
            <div className='col-md-6 animate__animated animate__fadeInRight'>
              <h2>Accessibility</h2>
              <p>Moreover, we empower landlords by providing them with the tools and resources they need to screen potential tenants effectively. Our innovative screening tools enable landlords to assess whether a traveling professional is the right fit for their property, ensuring a mutually beneficial arrangement for both parties involved.</p>
              <p>But our commitment to excellence doesn't stop there. At Furnished Home Locator, we are dedicated to fostering a sense of inclusivity and accessibility within the rental market. By breaking down barriers such as language and cultural differences, we strive to create a welcoming environment where everyone feels valued and respected.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 animate__animated animate__fadeInLeft'>
              <h2>User Friendly Platform</h2>
              <p>Whether you're a landlord looking to fill vacancies or a tenant in search of your next temporary home, Furnished Home Locator is here to support you every step of the way. With our user-friendly platform and unwavering commitment to safety and security, your journey to finding the perfect furnished rental has never been easier.</p>
            </div>
            <div className='col-md-6 animate__animated animate__fadeInRight'>
              <h2>Join Us</h2>
              <p>Join us as we continue to redefine the rental experience, one connection at a time. At Furnished Home Locator, your satisfaction is our top priority, At Furnished Home Locator, your journey to finding the perfect furnished rental begins here.</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};