import React, {
  createRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './CreateListingComponent.scss';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormikContext,
} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DropZone from '../../CommonComponents/DropZone/DropZone';
import { usePermissions } from '../../../utils/Permission/Permission';
import FormikFormInput from '../../../utils/FormikFormInput';
import FormikFormSelectField from '../../../utils/FormikFormSelect';
import axiosCall from '../../../utils/axiosCall';
import { ToastType, showToastMessage } from '../../../utils/ToastOptionCommon';
import DisableSection from '../../../utils/DisableSection/DisableSection';
import { ListingSection } from '../../../constants/ListingSection';
import { BsPencilFill } from 'react-icons/bs';
import { IoCloseCircleOutline, IoWarningOutline } from 'react-icons/io5';
import { ListingDetailSchema } from '../../../utils/yupSchemas/CreateListingSchema';
import Loader from '../../CommonComponents/Loader/Loader';
import { createMultipartFormData } from '../../../utils/createMultipartFormdata';
import {
  propertyById,
  resetpropertyState,
} from '../../../redux/slices/PropertySlice';
import { AppDispatch } from '../../../redux/store';
import { setFormDirty } from '../../../redux/slices/LocationSlice';

function CreateListingComponent({
  isEdit,
  handleSubmit,
  currentSection,
  setCurrentSection,
  initialValues,
  savedSection,
}: {
  isEdit?: boolean;
  handleSubmit: (formData: FormData) => void;
  currentSection: ListingSection;
  setCurrentSection: any;
  initialValues: any;
  savedSection?: any;
}) {
  const showBedroomLabelFor = ['house', 'apartment', 'condos'];
  const { hasPermission } = usePermissions();
  const [isAdmin, setIsAdmin] = useState(false);
  const userData = useSelector((state: any) => state.login.userData);
  const [loading, setLoading] = useState(false);
  const [landlord, setLandlord] = useState([]);
  const [files1, setFiles1] = useState<any>([]);
  const [files2, setFiles2] = useState<any>([]);
  const [files3, setFiles3] = useState<any>([]);
  const [files4, setFiles4] = useState<any>([]);
  const [imgLoading, setImgLoading] = useState(false);
  const propertyState = useSelector((state: any) => state.property);
  const property = useSelector(propertyById);
  const formObjectRef = createRef<any>();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      propertyState.success &&
      (property?.section === ListingSection.Details || property?.section === -1)
    ) {
      getRawImages();
      dispatch(resetpropertyState());
    }
  }, [propertyState.success, property?.section]);

  const getRawImages = async () => {
    const room1ImagesUrl = property?.rooms?.[0]?.imagesLink || [];
    const room2ImagesUrl = property?.rooms?.[1]?.imagesLink || [];
    const room3ImagesUrl = property?.rooms?.[2]?.imagesLink || [];
    const room4ImagesUrl = property?.rooms?.[3]?.imagesLink || [];

    // const imgObjects: any = [];
    const room1ImgObjects: any = [];
    const room2ImgObjects: any = [];
    const room3ImgObjects: any = [];
    const room4ImgObjects: any = [];
    setImgLoading(() => true);
    for (let j = 0; j < property?.rooms?.length; j++) {
      if (j === 0) {
        for (let i = 0; i < room1ImagesUrl?.length; i++) {
          const filename = extractFilenameFromUrl(room1ImagesUrl[i]);
          const img = await fetchAndConvertImage(room1ImagesUrl[i], filename);
          if (img) {
            room1ImgObjects.push(img);
          }
        }
        setFiles1(() => room1ImgObjects);
      } else if (j === 1) {
        for (let i = 0; i < room2ImagesUrl?.length; i++) {
          const filename = extractFilenameFromUrl(room2ImagesUrl[i]);
          const img = await fetchAndConvertImage(room2ImagesUrl[i], filename);
          if (img) {
            room2ImgObjects.push(img);
          }
        }
        setFiles2(() => room2ImgObjects);
      } else if (j === 2) {
        for (let i = 0; i < room3ImagesUrl?.length; i++) {
          const filename = extractFilenameFromUrl(room3ImagesUrl[i]);
          const img = await fetchAndConvertImage(room3ImagesUrl[i], filename);
          if (img) {
            room3ImgObjects.push(img);
          }
        }
        setFiles3(() => room3ImgObjects);
      } else if (j === 3) {
        for (let i = 0; i < room4ImagesUrl?.length; i++) {
          const filename = extractFilenameFromUrl(room4ImagesUrl[i]);
          const img = await fetchAndConvertImage(room4ImagesUrl[i], filename);
          if (img) {
            room4ImgObjects.push(img);
          }
        }
        setFiles4(() => room4ImgObjects);
      }
    }

    // for (let i = 0; i < imgUrls?.length; i++) {
    //   const filename = extractFilenameFromUrl(imgUrls[i]);
    //   const img = await fetchAndConvertImage(imgUrls[i], filename);
    //   if (img) {
    //     imgObjects.push(img);
    //   }
    // }
    // console.log(imgObjects);
    // setFiles(() => imgObjects);
    setImgLoading(() => false);
  };

  function extractFilenameFromUrl(url: string): string {
    // Split the URL by '/'
    const parts = url?.split('/');
    // Get the last part of the path which should contain the filename
    const filenameWithParams = parts[parts?.length - 1];
    // Extract the filename by removing any query parameters
    const filename = filenameWithParams?.split('?')[0];
    return filename;
  }
  async function fetchAndConvertToBlob(url: string): Promise<any> {
    try {
      const response = await fetch(url);
      // console.log({response});
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.log(error);
    }
    return;
  }

  function blobToFile(blob: Blob, fileName: string): File {
    // Create a new File object from the Blob
    return new File([blob], fileName, { type: blob?.type });
  }

  async function fetchAndConvertImage(img: string, fileName: string) {
    try {
      const blob = await fetchAndConvertToBlob(img);
      const file = blobToFile(blob, fileName);
      return file;
    } catch (error) {
      console.error('Error fetching or converting image:', error);
      throw error;
    }
  }

  useEffect(() => {
    let role = userData?.currentRole;
    const storedUser = localStorage.getItem('user');
    if (!role && storedUser !== null) {
      const user = JSON.parse(storedUser);
      role = user?.currentRole;
    }
    if (role === 'Admin') {
      setIsAdmin(true);
      getAllLandlord();
    }
  }, []);

  const getAllLandlord = async () => {
    try {
      setLoading(true);
      const url = `/user/all-landlords`;
      const result = await axiosCall.get(url);
      if (result && result.data) {
        const d = result.data?.map((e: any) => ({
          value: `${e.firstName} ${e.lastName}`,
          label: `${e.firstName} ${e.lastName}`,
          id: e._id,
        }));
        setLandlord(d);
      }
      // setOccupations([{ name: 'Select Occupation', id: 0 }, ...result.data]);
    } catch (error) {
      showToastMessage('Failed to get landlords', ToastType.Error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const emptyRoomDetails = {
    name: '',
    bathroomType: 'shared',
    price: '',
    depositMoney: '',
    cleaningCharges: '',
    petFees: '',
    availableFrom: new Date()?.toISOString(),
    isFeaturedProperty: false,
  };

  const submitHandler = (values: any) => {
    let newProperty: any = {
      section: values.section,
    };
    let isError = false;
    const formData = new FormData();
    const data = { ...values.details };
    let landlord_s: any = undefined;
    let userId = isEdit ? property?.user_id : userData?._id;
    if (userData.currentRole === 'Admin') {
      landlord_s = landlord.find((e: any) => e.value === data.landlord);
      if (landlord_s) {
        userId = landlord_s?.id;
      }
    }
    newProperty = {
      ...newProperty,
      title: data.propertyTitle,
      description: data.propertyDescription,
      p_type: data.propertyType,
      status: data.propertyStatus,
      numberOfRooms: data.propertyRooms,
      user_id: userId,
      isEntireUnit: data.isEntireUnit || false,
      p_price: 0,
      yearly_tax_rate: 0.02,
      association_fee: 1000,
      after_price: 210000,
      rooms: data.propertyRoomType?.map((room: any) => ({
        name: String(room.name),
        price: Number(room.price) || 0,
        bathroomType: room.bathroomType,
        depositMoney: Number(room.depositMoney) || 0,
        cleaningCharges: Number(room.cleaningCharges) || 0,
        petFees: Number(room.petFees) || 0,
        availableFrom: room.availableFrom,
        isFeaturedProperty: room?.isFeaturedProperty,
      })),
      size_in_ft: 0,
      available_date: '2024-03-23T12:42:32.369Z',
      noOfGarages: Number(data.noOfGarages || 0),
      is_active: false,
      isFeaturedProperty: data.isFeaturedProperty || false,
      views: 0,
      totalFavorites: 0,
      more_info: 'For more information, contact us at info@example.com',
    };
    for (let i = 0; i < newProperty?.rooms?.length; i++) {
      if (i === 0 && files1?.length === 0) {
        isError = true;
        break;
      } else if (i === 1 && files2?.length === 0) {
        isError = true;
        break;
      } else if (i === 2 && files3?.length === 0) {
        isError = true;
        break;
      } else if (i === 3 && files4?.length === 0) {
        isError = true;
        break;
      }
    }
    if (isError) {
      return;
    }
    // Create Form
    createMultipartFormData(newProperty, formData);
    for (let i = 0; i < files1?.length; i++) {
      formData.append(`room1/images`, files1?.[i]);
    }
    for (let i = 0; i < files2?.length; i++) {
      formData.append(`room2/images`, files2?.[i]);
    }
    for (let i = 0; i < files3?.length; i++) {
      formData.append(`room3/images`, files3?.[i]);
    }
    for (let i = 0; i < files4?.length; i++) {
      formData.append(`room4/images`, files4?.[i]);
    }
    handleSubmit(formData);
  };

  const populateArray = (value: any) => {
    formObjectRef.current.populateArray(value);
  };

  const FormObject = React.forwardRef((props, ref) => {
    const { setFieldValue, values, dirty, isSubmitting } =
      useFormikContext<any>();
    const propertyTypeRef = useRef(values.details.propertyType);

    useEffect(() => {
      if (dirty && !isSubmitting) {
        dispatch(setFormDirty(dirty));
      }
    }, [dirty, isSubmitting]);

    const populateArray = (value: number) => {
      if (values.details.isEntireUnit) {
        setFieldValue('details.propertyRooms', value);
      } else if (value < values.details.propertyRoomType.length) {
        let x = [...values.details.propertyRoomType];
        x.length = value;
        setFieldValue('details.propertyRooms', value);
        setFieldValue('details.propertyRoomType', x);
      } else if (value > values.details.propertyRoomType.length) {
        const d = value - values.details.propertyRoomType.length;
        const arr = new Array(d).fill(emptyRoomDetails);
        let x = [...values.details.propertyRoomType, ...arr];
        setFieldValue('details.propertyRooms', value);
        setFieldValue('details.propertyRoomType', x);
      }
    };

    useImperativeHandle(ref, () => ({
      populateArray,
    }));

    useEffect(() => {
      if (
        propertyTypeRef.current &&
        values.details.propertyType !== propertyTypeRef.current
      ) {
        propertyTypeRef.current = values.details.propertyType;
        setFieldValue('details.isEntireUnit', false);
      } else if (!propertyTypeRef.current) {
        propertyTypeRef.current = values.details.propertyType;
      }
    }, [values.details.propertyType]);
    return null;
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        isAdmin: isAdmin,
        section: ListingSection.Details,
      }}
      validationSchema={ListingDetailSchema}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      {({values, setFieldValue, touched, errors, isSubmitting, resetForm}: any) => (
        <Form>
          {imgLoading && <Loader />}
          <FormObject ref={formObjectRef} />
          <div id="create-listing">
            {currentSection !== ListingSection.Details && <DisableSection />}
            <div className="box-view">
              <h3 className="box-title">Create Listing</h3>
              {(isEdit || savedSection[ListingSection.Details]) && (
                <div title="Edit" className="edit-property">
                  {currentSection !== ListingSection.Details && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(ListingSection.Details);
                      }}
                    >
                      <BsPencilFill className="pencil_icon" />
                    </button>
                  )}
                  {currentSection === ListingSection.Details && (
                    <button
                      type="button"
                      className="edit-btn-listing"
                      onClick={() => {
                        setCurrentSection(-1);
                        resetForm();
                      }}
                    >
                      <IoCloseCircleOutline className="cancel_icon" />
                    </button>
                  )}
                </div>
              )}
              {hasPermission('admin') ? (
                <div className="row">
                  <div className="col-12 mb-3">
                    <label className="form-label">Landlord</label>
                    <Field
                      as={FormikFormSelectField}
                      isLabelValueSame={true}
                      id="landlord"
                      name="details.landlord"
                      className="form-select"
                      options={landlord}
                      loading={loading}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="propertyTitle">
                    Property Title
                  </label>
                  <Field
                    type="text"
                    name="details.propertyTitle"
                    placeholder=""
                    autoComplete="off"
                    className={`form-control`}
                    as={FormikFormInput}
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="propertyDescription">
                    Description
                  </label>
                  <Field
                    component="textarea"
                    name="details.propertyDescription"
                    placeholder=""
                    autoComplete="off"
                    className={`form-control form-textarea ${
                      touched.propertyDescription && errors.propertyDescription
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="details.propertyDescription"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label" htmlFor="propertyType">
                    Type
                  </label>
                  <Field
                    as="select"
                    name="details.propertyType"
                    className="form-select"
                  >
                    <option value="rooms">Room</option>
                    <option value="house">House</option>
                    <option value="condos">Condo</option>
                    <option value="apartment">Apartment</option>
                    <option value="hotel">Hotel</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="details.propertyType"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label" htmlFor="propertyStatus">
                    Status
                  </label>
                  <Field
                    as="select"
                    name="details.propertyStatus"
                    className="form-select"
                    disabled
                  >
                    {/* <option value="">Select Option</option> */}
                    <option value="InActive">In Active</option>
                    <option value="Active">Active</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="details.propertyStatus"
                    className="invalid-feedback"
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <div>
                    <label className="form-label" htmlFor="propertyRooms">
                      {showBedroomLabelFor.includes(values.details.propertyType)
                        ? 'Bedrooms'
                        : 'Rooms'}
                    </label>
                    <Field
                      as="select"
                      name="details.propertyRooms"
                      onChange={(e: any) => {
                        populateArray(parseInt(e.target.value));
                      }}
                      className="form-select"
                    >
                      {[1, 2, 3, 4].map((val) => (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="details.propertyRooms"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  {showBedroomLabelFor.includes(
                    values.details.propertyType
                  ) && (
                    <label
                      className="mb-3 mt-1"
                      onClick={() => {
                        setFieldValue('details.propertyRooms', 1);
                        setFieldValue('details.propertyRoomType', [
                          emptyRoomDetails,
                        ]);
                        // setRooms([emptyRoomDetails]);
                      }}
                    >
                      <Field
                        type="checkbox"
                        name="details.isEntireUnit"
                        className="form-check-input me-2"
                        checked={values.details.isEntireUnit}
                      />
                      Entire Unit
                    </label>
                  )}
                </div>
              </div>

              <FieldArray
                name="propertyRoomType"
                render={() => (
                  <>
                    {values.details.propertyRoomType?.map(
                      (_: any, index: number) => {
                        const ctx = `details.propertyRoomType[${index}]`;
                        return (
                          <div
                            key={index}
                            className="row align-items-center border-bottom mb-3 bg-light shadow-sm pt-3 mt-2"
                          >
                            {!values.details.isEntireUnit && (
                              <>
                                <div className="col-md-4 mb-3">
                                  <label className="form-label">
                                    {showBedroomLabelFor.includes(
                                      values.details.propertyType
                                    )
                                      ? 'Bedrooms'
                                      : 'Rooms'}
                                  </label>
                                  <Field
                                    type="text"
                                    name={`${ctx}.name`}
                                    className="form-control"
                                    as={FormikFormInput}
                                  />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label className="form-label">Bathroom</label>
                                  <Field
                                    as="select"
                                    name={`${ctx}.bathroomType`}
                                    className="form-select"
                                  >
                                    {['shared', 'private'].map((val) => (
                                      <option key={val} value={val}>
                                        {val
                                          .charAt(0)
                                          .toUpperCase()
                                          .concat(val.slice(1))}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    component="div"
                                    name={`${ctx}.bathroomType`}
                                    className="invalid-feedback"
                                  />
                                </div>
                              </>
                            )}
                            {/* Available From DatePicker */}
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Available From
                              </label>
                              <DatePicker
                                selected={
                                  values.details.propertyRoomType[index]
                                    ?.availableFrom
                                }
                                className="form-control"
                                name={`${ctx}.availableFrom`}
                                onChange={(date: any) =>
                                  setFieldValue(
                                    `${ctx}.availableFrom`,
                                    date?.toISOString()
                                  )
                                }
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date()}
                              />
                              <ErrorMessage
                                component="div"
                                name={`${ctx}.availableFrom`}
                                className="invalid-feedback"
                              />
                            </div>
                            {/* Second row */}
                            {/* Image Field */}
                            {/* <div className="col-md-4 mb-3">
                        <label className="form-label">Image</label>
                        <div className="input-group">
                          <input type="file" className="form-control" id="Image" />
                          <label className="input-group-text" htmlFor="Image">
                            Upload
                          </label>
                        </div>                 
                      </div> */}
                            {/* Price per Month Field */}
                            <div className="col-md-8 col-lg-4 mb-md-3">
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <label className="form-label">
                                    Price per month
                                  </label>

                                  <Field
                                    type="number"
                                    name={`${ctx}.price`}
                                    className={`form-control`}
                                    as={FormikFormInput}
                                    min={1}
                                  />
                                </div>
                                {/* Deposit Field */}
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <label className="form-label">Deposit</label>
                                  <Field
                                    type="number"
                                    name={`${ctx}.depositMoney`}
                                    className="form-control"
                                    as={FormikFormInput}
                                    min={1}
                                  ></Field>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-8 col-lg-4 mb-md-3">
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <label className="form-label">
                                    Cleaning Fee
                                  </label>
                                  <Field
                                    type="number"
                                    name={`${ctx}.cleaningCharges`}
                                    className="form-control"
                                    as={FormikFormInput}
                                    min={1}
                                  ></Field>
                                </div>
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <label className="form-label">Pet Fee</label>
                                  <Field
                                    type="number"
                                    name={`${ctx}.petFees`}
                                    className="form-control"
                                    as={FormikFormInput}
                                    min={1}
                                  ></Field>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mt-md-2">
                              <label className="form-check-label">
                                <Field
                                  type="checkbox"
                                  name={`${ctx}.isFeaturedProperty`}
                                  className="form-check-input me-2"
                                />
                                Is this is a feature property?
                              </label>
                            </div>
                            {/* Third Row Image upload*/}
                            <div className="col-md-12 mt-3">
                              <DropZone
                                files={
                                  index === 0
                                    ? files1
                                    : index === 1
                                    ? files2
                                    : index === 2
                                    ? files3
                                    : files4
                                }
                                setFiles={
                                  index === 0
                                    ? setFiles1
                                    : index === 1
                                    ? setFiles2
                                    : index === 2
                                    ? setFiles3
                                    : setFiles4
                                }
                                className="bg-light border text-center p-4 rounded-3"
                                page="addListing"
                                numberOfFiles={10}
                                maxFileSizeInBytes={5242880} // 5mb
                                isSubmit={isSubmitting}
                                section={currentSection}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </>
                )}
              />

              <div id="locationTab" className="row my-3">
                {isEdit && currentSection === ListingSection.Details && (
                  <div className="col-12 text-center">
                    <a href="#locationTab">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => submit?.click()}
                      >
                        Update
                      </button>
                    </a>
                  </div>
                )}
                {!isEdit && currentSection === ListingSection.Details && (
                  <div className="col-12 text-center">
                    <a href="#locationTab">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => submit?.click()}
                      >
                        Next
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateListingComponent;
