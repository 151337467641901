import React, { useState } from 'react';
import { useField } from 'formik';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

interface IProps {
    children: React.ReactNode;
    className: string;
    "data-testid"?: string;
    intl: any;//IntlConfig;
    name: string;
    onBlur: () => void;
    onChange: () => void;
    placeholder: string;
    value: string;
    disabled: boolean;
    readOnly: boolean;
    spaceAllowed: boolean;
    validcharacter?: Array<string>;
    invalidcharacter?: Array<string>;
    maxLength: number;
    isallcharactervalid?: boolean;
}

const FormikFormPasswordField = (props: IProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const [field, meta, helpers] = useField(props);
    const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;
    const { validcharacter, invalidcharacter, isallcharactervalid } = props;
    const _placeholder = props.placeholder
        ? props.intl.formatMessage({ id: props.placeholder })
        : props.placeholder;

    let subStrArr = ["?", ">", "<", "'", '"', ":", ";", ",", "+", "-", "(", ")", "*", "&", "^", "%", "$", "#", "{", "[", "]", "}", "|", "/", "=", "_", '~', '\\', '`']

    if (validcharacter)
        subStrArr = subStrArr.filter(el => !validcharacter.includes(el));
    if (invalidcharacter)
        invalidcharacter.forEach(el => subStrArr.push(el));

    if (isallcharactervalid) subStrArr = [];

    return (
        <div className='position-relative'>
            <input {...field} {...props}
                placeholder={""}
                // className={props.className + ' ' + errorClass + ' '}
                disabled={props.disabled}
                type={showPassword ? "text" : "password"}
                readOnly={props.readOnly}
                maxLength={props.maxLength}
                onChange={event => {
                    //   if (event.target.value.length > props.maxLength)
                    //     event.target.value = event.target.value.slice(0, props.maxLength);
                    //   if (!props.spaceAllowed && (event.target.value === '' || !subStrArr.some((subStr: any) => event.target.value.includes(subStr)))) {
                    //     event.target.value = event.target.value.replace(/ +(?= )/g, '');
                    //     helpers.setValue(event.target.value);
                    //   } else if ((props.spaceAllowed === undefined || props.spaceAllowed) && (event.target.value === '' || !subStrArr.some((subStr: any) => event.target.value.includes(subStr)))) {
                    //     helpers.setValue(event.target.value);
                    //   }
                    helpers.setValue(event.target.value);
                }}
            />
            <span className="show-hide-password" onClick={() => { setShowPassword((e) => !e) }}>{showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</span>
        </div>
    );
};

export default FormikFormPasswordField