import React, { useCallback, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import callBackendAPI from '../../utils/callBackendAPI';
import SignupForm from './form/SignupForm';

interface IProps {
  modalClosedSignUp: () => void;
  modalClosedSignIn: () => void;
}

const SignUp = (props: IProps) => {
  const { modalClosedSignUp, modalClosedSignIn } = props;
  const [userName, setuserName] = useState('');
  const [userEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [role, setRole] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoginError('');
    try {
      if (password !== confirmPassword) {
        setPasswordError('Passwords do not match');
        return;
      }
      //console.log("before res");
      const response = await callBackendAPI('POST', 'auth/register', null, { userName, userEmail, password, role, phoneNo });
      if (response.error) {
        setLoginError(response.error);
      } else {
        window.alert("sign up successfully")
        navigate('/signin');
      }

    } catch (error) {
      setLoginError("someting went wrong!!!");
      console.error('Sign up failed:', error);
    }
  };

  const onModalClosedSignUp = useCallback(() => {
    modalClosedSignUp();
  }, [modalClosedSignUp]);

  const onModalClosedSignIn = useCallback(() => {
    modalClosedSignIn();
  }, [modalClosedSignIn]);

  return (
    <SignupForm
      submitHandler={handleSubmit}
      onModalClosedSignUp={onModalClosedSignUp}
      onModalClosedSignIn={onModalClosedSignIn}
      initialValues={{
        Email: "",
        Password: "",
        FirstName: "",
        LastName: "",
        // PhoneNo: "",
        ConfirmPassword: "",
        Role: "",
        UserType:"",
        TermsConditions: false
      }}
      isLoading={false}
      setLoginError={setLoginError}
      loginError={loginError}
      passwordError={passwordError}
      setPasswordError={setPasswordError}
    />
  );
};

export default SignUp;
