import React, { useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
  InfoWindowF,
  InfoWindow,
  Libraries,
} from '@react-google-maps/api';
import { Library } from '../../constants/GoogleLibrary';
import './CustomMapComponent.scss';

interface IProps {
  mapClassName: string;
  markers: { lat: number; lng: number; propertyName: string }[];
}

const CustomMapComponent: React.FC<IProps> = (props: IProps) => {
  const { mapClassName, markers } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}` ?? '',
    libraries: Library,
    region: 'us',
  });

  const [mapRef, setMapRef] = useState<any>(null);
  // &libraries=places
  const center = useMemo(
    () => ({
      lat: markers?.length > 0 && markers[0].lat ? markers[0].lat : 31.9686,
      lng: markers?.length > 0 && markers[0].lng ? markers[0].lng : -99.9018,
    }),
    [markers]
  );

  const [infoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);
  const [infoIndex, setinfoIndex] = useState<number>(-1);

  const showInfoWindow = () => {
    setInfoWindowOpen((e) => true);
  };

  const showInfoWindowClose = () => {
    setInfoWindowOpen((e) => false);
  };

  const onLoad = React.useCallback(async (map: any) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    if (markers.some((item) => item.lat !== undefined)) {
      markers?.forEach(({ lat, lng }) => {
        bounds.extend({ lat, lng });
      });
      map.fitBounds(bounds);
    }
    setMapRef(map);
  }, []);

  const handleMarkerClick = (lat: any, lng: any, index: number) => {
    mapRef?.setZoom(15);
    mapRef?.panTo({ lat, lng });
    showInfoWindow();
    setinfoIndex(() => index);
  };
  const onUnmount = React.useCallback(function callback() {
    setMapRef(null);
  }, []);

  return (
    <div className="properties-location-map">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="h-100"
          // mapContainerStyle={{ height: '100%', width: '100%' }}
          center={center}
          zoom={5}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {markers.some((item) => item.lat !== undefined) &&
            markers?.map(({ lat, lng, propertyName }, index) => (
              <MarkerF
                key={index}
                onClick={() => {
                  handleMarkerClick(lat, lng, index);
                }}
                icon={{
                  url: require('./../../assets/images/icons8-home-25.png'),
                }}
                position={{ lat, lng }}
              >
                {propertyName && infoWindowOpen && infoIndex === index && (
                  <InfoWindowF onCloseClick={showInfoWindowClose} key={index}>
                    <div className="custom-info-window">
                      <h1>{propertyName}</h1>
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default CustomMapComponent;
