import * as lds from 'lodash';
import { ListingSection } from '../constants/ListingSection';

export const nearby = [
  {
    address: '',
    name: '',
    distance: '',
  },
];

export const ListingDetailInitialValues = {
  details: {
    landlord: '',
    propertyTitle: '',
    propertyDescription: '',
    propertyType: 'rooms',
    propertyStatus: 'available',
    propertyRooms: 1,
    propertyRoomType: [
      {
        name: '',
        bathroomType: 'shared',
        price: '',
        depositMoney: '',
        cleaningCharges: '',
        petFees: '',
        availableFrom: new Date()?.toISOString(),
        isFeaturedProperty: false,
      },
    ],
    propertyPrice: '',
    propertyTenure: 'm',
    isFeaturedProperty: true,
    isEntireUnit: false,
  },
};

export const ListingLocationInitialValues = {
  location: {
    street: '',
    state: '',
    city: '',
    zip: '',
    latitude: undefined,
    longitude: undefined,
  },
};

export const ListingAmenitiesInitialValues = {
  amenities: {
    p_video_path: '',
    amenities: [],
    other: ''
  },
};

export const ListingNearByInitialValues = {
  nearBy: {
    propertyEducation: false,
    propertyEducationDetails: lds.cloneDeep(nearby),
    propertyCafe: false,
    propertyCafeDetails: lds.cloneDeep(nearby),
    propertyGroceryStore: false,
    propertyGroceryStoreDetails: lds.cloneDeep(nearby),
    propertyHealthAndMedical: false,
    propertyHealthAndMedicalDetails: lds.cloneDeep(nearby),
    propertyMilitaryBase: false,
    propertyMilitaryBaseDetails: lds.cloneDeep(nearby),
    propertyMovieTheaters: false,
    propertyMovieTheatersDetails: lds.cloneDeep(nearby),
    propertyParks: false,
    propertyParksDetails: lds.cloneDeep(nearby),
    propertyRestaurants: false,
    propertyRestaurantsDetails: lds.cloneDeep(nearby),
    propertyShoppingCenters: false,
    propertyShoppingCentersDetails: lds.cloneDeep(nearby),
    propertyTransportation: false,
    propertyTransportationDetails: lds.cloneDeep(nearby),
  },
};
