interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleMapsApiResponse {
  address_components: AddressComponent[];
  formatted_address: string;
  name: string;
  geometry?: any;
}

export const getPlaceDetails = (data: GoogleMapsApiResponse) => {
  let country = '';
  let state = '';
  let city = '';
  let zipCode = '';
  let shortState = '';
  let street = '';
  let lattitude = data?.geometry?.location?.lat() ?? undefined;
  let longitude = data?.geometry?.location?.lng() ?? undefined;
  const formattedAddress = data?.formatted_address ?? '';
  const name = data?.name ?? '';

  data.address_components.forEach((component) => {
    if (component.types.includes('country')) {
      country = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      shortState = component.short_name;
      state = component.long_name;
    } else if (component.types.includes('locality')) {
      city = component.long_name;
    } else if (component.types.includes('postal_code')) {
      zipCode = component.long_name;
    } else if (component.types.includes('route')) {
      street = component.long_name;
    }
  });

  return {
    country,
    state,
    shortState,
    city,
    street,
    zipCode,
    formattedAddress,
    name,
    lattitude,
    longitude,
  };
};
