import React, { useState, useEffect } from 'react';
// import './Styles/Contacts.scss';
import userone from '../../assets/images/avatar.png';
import { formatDate } from '../../utils/formatDateTimeForMessage';
import { showToastMessage, ToastType } from '../../utils/ToastOptionCommon';
import { useSelector } from 'react-redux';
import { useSocket } from '../../context/SocketProvider';
import { loggedInUserState } from '../../redux/slices/LoginSlice';
import { activeUsers } from '../../redux/slices/ChatSlice';
import propertyImg from '../../assets/images/PropertySample.jpg';

const Contacts = ({ contacts, currentUser, changeChat, chatId }: any) => {
  const socket = useSocket();
  // const [currentUserImage, setCurrentUserImage] = useState(undefined); //later on add it
  const loggedInUser = useSelector(loggedInUserState);
  const [currentSelected, setCurrentSelected] = useState<any>(undefined);
  const [search, setSearch] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [messageNotification, setMessageNotification] = useState();
  const [localContacts, setLocalContacts] = useState<any>([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const userData = useSelector((state: any) => state.login.userData);
  const onlineUsers = useSelector(activeUsers);
  const isAdmin = userData?.currentRole?.toLowerCase() === "admin";
  const markContactActive = (cntct: Array<any>) => {
    if (cntct.length) {
      let ctc = [...cntct];
      ctc.forEach((e: any) => {
        if (onlineUsers.includes(e?.oppositeUserId)) {
          e.active = true;
        }
        else {
          e.active = false;
        }
      })
      return ctc;
    }
    return cntct;
  }

  useEffect(() => {
    if (contacts.length) {
      setLocalContacts(markContactActive(contacts));
    }
  }, [contacts])

  useEffect(() => {
    if (localContacts.length && onlineUsers.length) {
      setLocalContacts(markContactActive(localContacts));
    }
  }, [onlineUsers])

  // useEffect(() => {
  //   socket.on('msg-notification', (res) => {
  //     setMessageNotification(res);
  //   });
  //   return () => {
  //     socket.off('msg-notification');
  //   }

  // }, [socket]);

  // useEffect(() => {
  //   if (messageNotification) {
  //     updateChatNotification(messageNotification.chatId);
  //   }
  // }, [messageNotification]);

  // const updateChatNotification = (chatId, isChatRead = false) => {
  //   let contact = localContacts.find((i) => i._id === chatId);
  //   console.log("update notification", chatId)
  //   console.log("currentSelected", currentSelected)

  //   if(contact){
  //     if(chatId === currentChat?._id){
  //       contact.notificationCount = 0;
  //     }else{
  //     if (!isChatRead) {
  //       contact.notificationCount += 1;
  //     } else {
  //       contact.notificationCount = 0;
  //      }
  //   }
  // }
  //   setLocalContacts([...localContacts]);
  // };

  const updatecurrentChatNotification = (chatId: string) => {
    const contactIndex = localContacts.findIndex(
      (contact: any) => contact?.message?.chatId?._id === chatId
    );
    if (contactIndex !== -1) {
      const updatedContacts = [...localContacts];
      updatedContacts[contactIndex] = {
        ...updatedContacts[contactIndex],
        message: {
          ...updatedContacts[contactIndex]?.message,
          notification: 0,
        },
      };
      setLocalContacts(updatedContacts);
    }
  };

  const isEmptyOrSpaces = (str: string) => {
    return /^\s*$/.test(str);
  };

  const handleMarkAsRead = async (chatId: string, userId: string) => {
    updatecurrentChatNotification(chatId);
    socket.emit('mark-as-read', {
      chatId,
      userId,
    });
  };

  // Change Current Chat
  const changeCurrentChat = (index: number, contact: any, oppUser: string, pic: string, userId: string) => {
    setCurrentSelected(index);
    setCurrentChat(contact?.chatId?._id);
    handleMarkAsRead(contact?.chatId?._id, currentUser._id);
    updatecurrentChatNotification(contact?._id);
    changeChat(contact, oppUser, pic, userId);
  };

  useEffect(() => {
    if (chatId && contacts.length > 0) {
      handleMarkAsRead(chatId, currentUser._id);
    }
  }, [chatId]);

  // Search Contacts Logic
  useEffect(() => {
    const re = RegExp(
      `.*${search.toLowerCase().replace(/\s+/g, '').split('').join('.*')}.*`
    );
    const searchResults = localContacts.filter((v: any) => {
      if (v?.message?.chatId?.propertyName?.toLowerCase().match(re) || v?.oppositeUser?.toLowerCase().match(re))
        return true;
      return false;
    }
    );

    setFilteredContacts(searchResults);
  }, [localContacts, search]);

  //show Last message
  const showLastMsg = (msg: any) => {
    return (
      <p className="mb-0">
        {(msg?.lastMessage?.message && msg?.lastMessage?.message.length) > 30
          ? msg?.lastMessage?.message?.slice(0, 30) + '...'
          : msg?.lastMessage?.message}
        <br />
        <span>{' ' + formatDate(msg?.lastMessage?.date)}</span>
      </p>
    );
  };

  const showName = (str: string) => {
    return str?.length > 15
      ? str?.slice(0, 15) + '...'
      : str;
  };

  // Show each contacts
  const showContacts = (data: any, key: any) => {
    const contact = data?.message;
    const name = data?.oppositeUser;
    const pic = data?.profilePic;
    const activeStatus = data?.active ?? false;
    return (
      <div
        className={`contact ${key === currentSelected ? 'selected' : ''}`}
        role="button"
        key={key}
        onClick={() => changeCurrentChat(key, contact, name, pic, data?.oppositeUserId)}
      >
        <div className="messages-user d-flex align-items-center gap-3">
          <div className='position-relative'>
            <div className="user-img">
              <img src={pic?.length ? pic : isAdmin ? propertyImg : userone} className="img-fluid w-100" alt={`${isAdmin ? "property" : name}_pic`} />
            </div>
            {!isAdmin && <span className={`user-status ${activeStatus ? "live-status" : "busy-status"}`}></span>}
          </div>
          <div className="user-details">
            <div className='fw-bold'>{showName(contact?.chatId?.propertyName)}<br></br>{!isAdmin && <span className='fw-normal'>{showName(name)}</span>}</div>
            <div className="message-excerpt">{showLastMsg(contact)}</div>
          </div>
          {contact?.notification > 0 && (
            <span className="message-count">
              {contact?.notification > 9 ? (
                <>
                  {' '}
                  9 <span className="plus-icon">+</span>
                </>
              ) : (
                contact?.notification
              )}
            </span>
          )}
          {/* {contact?.notificationCount > 0 ? (
            <span className="message-count">{(contact?.totalNotificationCount + contact?.notificationCount)}</span>
          ) : (
            <span className="message-count">{contact?.totalNotificationCount ? contact?.totalNotificationCount : ''}</span>
          )} */}
        </div>
      </div>
    );
  };

  return (
    <>
      {contacts.length > 0 && (
        <>
          <form>
            <div className="position-relative mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name or Property"
                onChange={(e) => setSearch(e.target.value)}
              />
              <svg
                className="search-icon"
                width="14"
                height="14"
                viewBox="0 0 17 17"
                fill="#818181"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.5 6.5C11.5 4.71875 10.5312 3.09375 9 2.1875C7.4375 1.28125 5.53125 1.28125 4 2.1875C2.4375 3.09375 1.5 4.71875 1.5 6.5C1.5 8.3125 2.4375 9.9375 4 10.8438C5.53125 11.75 7.4375 11.75 9 10.8438C10.5312 9.9375 11.5 8.3125 11.5 6.5ZM10.5312 11.625C9.40625 12.5 8 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.03125 12.4688 9.4375 11.5938 10.5625L15.7812 14.7188C16.0625 15.0312 16.0625 15.5 15.7812 15.7812C15.4688 16.0938 15 16.0938 14.7188 15.7812L10.5312 11.625Z" />
              </svg>
            </div>
          </form>

          {isEmptyOrSpaces(search) ? (
            localContacts?.map((contact: any, i: number) =>
              showContacts(contact, i)
            )
          ) : filteredContacts.length > 0 ? (
            filteredContacts.map((contact, i) => showContacts(contact, i))
          ) : (
            <p>No Contacts Found.</p>
          )}
        </>
      )}
    </>
  );
};

export default Contacts;
