import React, { Dispatch, SetStateAction, useRef } from 'react';
import {
  Autocomplete,
  StandaloneSearchBox,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Library } from '../constants/GoogleLibrary';
import { getPlaceDetails } from './Maps/MapHelper';

interface IProps {
  id: string;
  value: string;
  setValue: Dispatch<SetStateAction<any>>;
  setPlaceDetails: Dispatch<SetStateAction<any>>;
  name?: string;
  className?: string;
}
const CustomAutoComplete: React.FC<IProps> = (props: IProps) => {
  const inputRef = useRef<any>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    libraries: Library,
    region: 'us',
  });

  // const options = {
  //   //bounds: defaultBounds,
  //   types: ['locality', 'administrative_area_level_1'],
  //   componentRestrictions: { country: 'us' },
  //   fields: ['address_components', 'name'],
  //   strictBounds: false,
  // };
  const options = {
    types: ['locality', 'administrative_area_level_1'],
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'name'],
    strictBounds: false,
  };

  const handlePlaceChanged = () => {
    if (inputRef.current) {
      const places = inputRef.current.getPlace();
      if (places) {
        // const place = places[0];
        if (props?.setPlaceDetails) {
          const { state, shortState, city, street, zipCode } =
            getPlaceDetails(places);
          props?.setPlaceDetails({ state, shortState, city, street, zipCode });
        }
        props.setValue(() => places.name);
      }
    }
  };

  return (
    <div>
      <label className={props.className ?? ''}>Location</label>
      {isLoaded && !loadError && (
        // <StandaloneSearchBox
        //     onLoad={(ref) => (inputRef.current = ref)}
        //     onPlacesChanged={handlePlaceChanged}
        // >
        //     <input
        //         id={props.id}
        //         type="text"
        //         onChange={(e) => props.setValue(e.target.value)}
        //         value={props.value}
        //         name={props?.name}
        //         placeholder=""
        //         className="form-control"
        //     />
        // </StandaloneSearchBox>
        <Autocomplete
          options={options}
          onLoad={(ref) => (inputRef.current = ref)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            id={props.id}
            type="text"
            onChange={(e) => props.setValue(e.target.value)}
            value={props.value}
            name={props?.name}
            placeholder=""
            className="form-control"
          />
        </Autocomplete>
      )}
    </div>
  );
};

export default CustomAutoComplete;
