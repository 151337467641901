import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { IoClose } from 'react-icons/io5';
import Busy from '../../../assets/gif/busy.gif';
import { useDispatch } from 'react-redux';
import FormikFormDateTimePicker from '../../../utils/FormikFieldDateTimePicker';
import CustomCalendar from '../../CalenderComponents/Calender';
import { Document, Page, pdfjs } from 'react-pdf';

interface IProps {
  onModalClosed: () => void;
  fileUrl: string;
}

const DocumentViewModal = (props: IProps) => {
  const [totalPages, setTotalPages] = useState();
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setTotalPages(numPages);
  };

  return (
    <>
      <Modal
        className="px-10 backdrop-blur-sm blue-theme modal-lg"
        show={true}
        centered
        size={'xl'}
      >
        <Modal.Header className="justify-between pb-0 bg-white border-none">
          <Modal.Title className="w-full pt-3 text-center font-heading font-extrabold text-2xl leading-9 tracking-tight text-gray-900">
            Document View
          </Modal.Title>
          <div
            onClick={() => {
              props.onModalClosed();
            }}
            className="icons8-close"
          >
            <IoClose className="w-6 h-6 text-gray-400 cursor-pointer" />
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="px-2" style={{ height: '100%', width: '100%' }}>
            {props.fileUrl ? (
              <Document
                file={props.fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(totalPages))
                  .map((x, i) => i + 1)
                  .map((page) => {
                    return (
                      <Page
                        scale={1.5}
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={true}
                        // renderMode='none'
                      />
                    );
                  })}
              </Document>
            ) : (
              <>No Document Available</>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentViewModal;
