import { configureStore } from '@reduxjs/toolkit';
import CreateLisingSlice from './slices/CreateLisingSlice';
import LoginReducer from './slices/LoginSlice';
import ListingSlice from './slices/ListingSlice';
import HomeSlice from './slices/HomeSlice';
import PropertySlice from './slices/PropertySlice';
import CalenderSlice from './slices/CalenderSlice';
import FavouriteSlice from './slices/FavouriteSlice';
import PaymentSlice from './slices/PaymentSlice';
import UserSlice from './slices/UserSlice';
import DocumentSlice from './slices/DocumentSlice';
import HouseRequestSlice from './slices/HousingRequestSlice';
import PackageSlice from './slices/PackageSlice';
import ListPropertySlice from './slices/ListPropertySlice';
import SubscriberEmailSlice from './slices/SubscribeEmailSlice';
import ChatSlice from './slices/ChatSlice';
import LocationSlice from './slices/LocationSlice';

export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    createListing: CreateLisingSlice,
    login: LoginReducer,
    listing: ListingSlice,
    home: HomeSlice,
    property: PropertySlice,
    calender: CalenderSlice,
    favourites: FavouriteSlice,
    payment: PaymentSlice,
    user: UserSlice,
    document: DocumentSlice,
    houseRequest: HouseRequestSlice,
    packages: PackageSlice,
    propertyListing: ListPropertySlice,
    subscriberEmail: SubscriberEmailSlice,
    chat: ChatSlice,
    location: LocationSlice,
  },
});
