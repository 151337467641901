import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import callBackendAPI from '../../utils/callBackendAPI';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserData } from '../../redux/slices/LoginSlice';
import { Button } from 'react-bootstrap';
import DashboardHeader from '../../components/DashboardComponents/DashboardHeader/DashboardHeader';
import StatsSectionComponent from '../../components/DashboardComponents/StatsSectionComponent/StatsSectionComponent';
import { io, Socket } from 'socket.io-client';
import { allUsersRoute, apiUrl } from '../../utils/APIRoutes';
import { ToastType, showToastMessage } from '../../utils/ToastOptionCommon';
import { useSocket } from '../../context/SocketProvider';
import { setActiveUsers } from '../../redux/slices/ChatSlice';

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  userEmail: string;
  phoneNo: string;
  role: string[];
  address?: string;
  aboutUser?: string;
  currentRole: string;
  profileImage: string;
  occupation?: string;
  subscription?: {
    id: string;
    isActive: boolean;
    leftDays: number;
  };
  isEmailVerified: boolean;
  keepPhoneNumberPrivate: boolean;
  keepEmailPrivate: boolean;
  messageNotification: boolean;
  reviews: any[],
  removeProfile?: boolean;
}

function Dashboard() {
  const [user, setUser] = useState<User | null>(null);
  const socket = useSocket();
  const [viewMode, setViewMode] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null) {
      setUser(JSON.parse(storedUser));
    }
    else{
      navigate("/")
    }
    // console.log(user);
  }, []);

  useEffect(() => {
    if (user) {
      socket.emit('add-user', user._id);
    }
  }, [user]);

  useEffect(() => {
    socket.on('set-active', (res: any) => {
      dispatch(setActiveUsers(res.activeUsers));
    });
    return () => {
      socket.off('set-active');
    };
  }, [socket]);

  // const handleLogout = async () => {
  //   const answer = window.confirm('Are you sure you want to log out?');
  //   if (answer) {
  //     try {
  //       const userID = user?._id;
  //       await callBackendAPI('POST', 'auth/logout', null, { userID });
  //       localStorage.clear();
  //       dispatch(resetUserData(null));
  //       navigate('/');
  //     } catch (error) {
  //       console.error('Logout failed:', error);
  //     }
  //   } else {
  //     // Do nothing!
  //   }
  // };

  return (
    <div className="main-content">
      {user && (
        <div>
          {/* Search section */}
          <DashboardHeader />
          {/* Salutation */}

          <div className="below-header">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h1>
                  Welcome {user?.firstName} {user?.lastName}
                </h1>
              </div>
              {/* <div className="col-md-6 text-end">
                <div className="btn-group tabs-pills-btn" role="group">
                  <button type="button" className="btn active">
                    Months
                  </button>
                  <button type="button" className="btn">
                    Week
                  </button>
                </div>
              </div> */}
            </div>

            {/* <div className={`d-flex align-items-center  ${viewMode === 1 && ''}`} onClick={() => setViewMode(1)} >
                Months
              </div>
              <div className={`d-flex align-items-center ${viewMode === 2 && ''}`} onClick={() => setViewMode(2)}>
                Week
              </div> */}
          </div>
          <StatsSectionComponent />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
