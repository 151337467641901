import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
// import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useEffect, useRef } from 'react';
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
// noDataToDisplay(Highcharts);

interface IProps {
  isLoading?: boolean;
  options: Highcharts.Options;
  title?: string;
}

const BarChart = (props: IProps) => {
  const chartRef = useRef<HighchartsReactRefObject | null>(null);
  useEffect(() => {
    if (chartRef.current) {
      if (props.isLoading) {
        chartRef.current.chart.showLoading();
      } else {
        chartRef.current.chart.hideLoading();
      }
    }
  }, [props.isLoading]);
  return (
    <>
      <div className="col-md-8">
        <div className="box-view h-100">
          <div className="d-flex align-items-center">
            {props?.title && <h3 className="box-title">{props.title}</h3>}
          </div>
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={props.options}
          />
        </div>
      </div>
    </>
  );
};

export default BarChart;
