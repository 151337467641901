export enum ListingSection {
    Details = 1,
    Location,
    Amenities,
    NearBy,
    Documents
}

export const SectionData:any = {
    1: 'Property details',
    2: 'Location',
    3: 'Detailed information',
    4: 'Near by',
    5: 'Property documents',
}